/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useLayout } from "../core";

const Footer: FC = () => {
  const { classes } = useLayout();

  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row flex-stack`}
      >
        {/* begin::Copyright */}
        <div className="text-dark order-2 order-md-1">
          <span className="text-gray-400 fw-bold me-1">
            © 2022-{new Date().getFullYear()} OxaPay
          </span>
        </div>
        {/* end::Copyright */}

        {/* begin::Menu */}
        <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
          <li className="menu-item">
            <a
              href="https://x.com/OxaPay"
              target={"_blank"}
              rel="noreferrer"
              className="menu-link px-2"
            >
              X
            </a>
          </li>
          <li className="menu-item">
            <a
              href="https://www.telegram.me/OxaPay"
              target={"_blank"}
              rel="noreferrer"
              className="menu-link px-2"
            >
              Telegram
            </a>
          </li>
          <li className="menu-item">
            <a
              href="https://medium.com/@oxapay"
              target={"_blank"}
              rel="noreferrer"
              className="menu-link px-2"
            >
              Medium
            </a>
          </li>
          <li className="menu-item">
            <a
              href="https://oxapay.com/blog"
              target={"_blank"}
              rel="noreferrer"
              className="menu-link px-2"
            >
              Blog
            </a>
          </li>
        </ul>
        {/* end::Menu */}
      </div>
      {/* end::Container */}
    </div>
  );
};

export { Footer };
