import React, { useEffect, useState } from "react";

interface Props {
  activePage: number;
  pages: number;
  onChange: (pageNumber: number) => void;
  loading?: boolean;
}

export const Pagination = ({
  pages,
  onChange,
  activePage,
  loading = false,
}: Props) => {
  const [activePageNumber, setActivePageNumber] = useState(1);
  const pageNumbers = Array.from({ length: pages }, (_, index) => index + 1);

  const clickHandler = (pageNumber: number) => {
    setActivePageNumber(pageNumber);
  };

  useEffect(() => {
    setActivePageNumber(activePage);
  }, [activePage]);

  useEffect(() => {
    onChange(activePageNumber);
    // eslint-disable-next-line
  }, [activePageNumber]);

  const renderPageNumbers = () => {
    const visiblePages = 6;
    const startPage = Math.max(
      1,
      activePageNumber - Math.floor(visiblePages / 2)
    );
    const endPage = Math.min(startPage + visiblePages - 1, pages);

    return pageNumbers.slice(startPage - 1, endPage).map((pageNumber) => (
      <li
        key={pageNumber}
        className={`page-item ${
          pageNumber === activePageNumber ? "active" : ""
        }`}
      >
        <button onClick={() => clickHandler(pageNumber)} className="page-link">
          {pageNumber}
        </button>
      </li>
    ));
  };

  return (
    <ul className={`pagination mt-5 mb-12 ${loading && "d-none"}`}>
      <li className={`page-item next ${activePageNumber === 1 && "disabled"}`}>
        <button onClick={() => setActivePageNumber(1)} className="page-link">
          First
        </button>
      </li>
      <li
        className={`page-item previous ${activePageNumber === 1 && "disabled"}`}
      >
        <button
          onClick={() => setActivePageNumber(activePageNumber - 1)}
          className="page-link"
        >
          <i className="previous"></i>
        </button>
      </li>
      {renderPageNumbers()}
      <li
        className={`page-item next ${activePageNumber === pages && "disabled"}`}
      >
        <button
          onClick={() => setActivePageNumber(activePageNumber + 1)}
          className="page-link"
        >
          <i className="next"></i>
        </button>
      </li>
      <li
        className={`page-item next ${activePageNumber === pages && "disabled"}`}
      >
        <button
          onClick={() => setActivePageNumber(pages)}
          className="page-link"
        >
          Last
        </button>
      </li>
    </ul>
  );
};
