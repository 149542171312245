/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { TwoFactor } from "./components/TwoFactor";
import { Terms } from "../../../_oxapay/partials/modals/terms";
import { loadRecaptchaScript } from "../../services/loadRecaptchaScript";
import { LoadingComponent } from "../../../components/LoadingComponent";
import { LoginVerifyEmail } from "./components/LoginVerifyEmail";
import { loadTawkScript } from "../../services/loadTawk";
import TypingEffect from "../../../components/TypingEffect";
import { AbsoluteCircle } from "../../../components/AbsoluteCircle";
import { TelegramChatWidget } from "../../../components/TelegramChatWidget";

export const AuthLayout = () => {
  const [scripteLoaded, setScriptLoaded] = useState(false);
  const [recaptchaReady, setRecaptchaReady] = useState(false);
  const [showTelegramWidget, setShowTelegramWidget] = useState(true);

  useEffect(() => {
    const checkRecaptchaReady = () => {
      if (window.grecaptcha !== undefined) {
        setRecaptchaReady(true);
        clearInterval(interval);
      }
    };

    const interval = setInterval(checkRecaptchaReady, 1000);
    const cleanup = loadRecaptchaScript(() => {
      setScriptLoaded(true);
    });

    loadTawkScript();
    (window as any).Tawk_API = (window as any).Tawk_API || {};
    (window as any).Tawk_API.onStatusChange = function (status: any) {
      if (status === "offline") {
        setShowTelegramWidget(true);
        (window as any)?.Tawk_API.hideWidget();
      } else {
        setShowTelegramWidget(false);
        (window as any)?.Tawk_API.showWidget();
      }
    };

    return () => {
      cleanup();
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {scripteLoaded && recaptchaReady ? (
        <>
          <div className="d-flex flex-column flex-root justify-content-center">
            <div className="d-flex flex-column flex-lg-row flex-column-fluid justify-content-center">
              <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 justify-content-center">
                <div className="d-flex flex-center flex-column flex-lg-row-fluid justify-content-center">
                  <div className="w-lg-500px">
                    <Outlet />
                  </div>
                </div>
              </div>
              <div className="d-none d-xl-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 multicolor-gradient bg-sm-transparent justify-content-center m-4 rounded-20px">
                <div className="p-10 d-flex flex-column justify-content-center align-items-center multicolor-gradient w-100 py-20 overflow-hidden rounded-20px">
                  <AbsoluteCircle position="top-left" />
                  <AbsoluteCircle position="bottom-right" />
                  <div className="z-index-1">
                    <p className="fs-2 text-center text-white m-0 mb-3 d-none d-lg-block opacity-80">
                      OxaPay, a crypto payment gateway that makes it easy to
                    </p>
                    <p className="m-0 text-center text-white large-title fw-bold mb-5">
                      Accept Crypto In <br />
                      Your{" "}
                      <TypingEffect
                        textArray={["Business", "Website", "Apps"]}
                      />
                    </p>
                    <p className="fs-md-2 fs-5 text-center text-white m-0 opacity-80">
                      From 0.4% Fees, 24/7 Support, No KYC/KYB
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showTelegramWidget && <TelegramChatWidget />}
          <Terms />
        </>
      ) : (
        <LoadingComponent />
      )}
    </>
  );
};

const AuthPage = () => (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID as string}>
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="signin" element={<Login />} />
        <Route path="signup" element={<Registration />} />
        <Route path="signup/:ref/*" element={<Registration />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="twofactor" element={<TwoFactor />} />
        <Route path="verifyemail/*" element={<LoginVerifyEmail />} />
        <Route index element={<Login />} />
        <Route path="/*" element={<Login />} />
      </Route>
    </Routes>
  </GoogleOAuthProvider>
);

export { AuthPage };
