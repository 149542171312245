import axios from "axios";
import { AuthModel, UserModel } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify_token`;
export const LOGIN_URL = `${API_URL}/auth/login`;
export const LOGIN_URL_GOOGLE = `${API_URL}/auth/login/google`;
export const GOOGLE_LOGIN_VERIFY = `${API_URL}/auth/login/google/verify`;
export const LOGIN_URL_TELEGRAM = `${API_URL}/auth/login/telegram`;
export const TELEGRAM_LOGIN_VERIFY = `${API_URL}/auth/login/telegram/verify`;
export const REGISTER_URL = `${API_URL}/auth/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot_password`;
export const RECOVERY_PASSWORD = `${API_URL}/auth/new_password`;
export const VERIFY_EMAIL = `${API_URL}/auth/verify/email`;
export const RESEND_EMAIL_AUTH = `${API_URL}/auth/verify/email/send`;
export const RESEND_EMAIL_BY_TOKEN = `${API_URL}/auth/verify/email/send/byToken`;
export const CONFIRM_SET_EMAIL_PASSWORD = `${API_URL}/user/profile/setEmailPassword/confirm`;
export const RESEND_SET_EMAIL_PASSWORD_MESSAGE = `${API_URL}/user/profile/setEmailPassword/byToken`;

export function login(
  email: string,
  password: string,
  recaptcha: string,
  twoFa?: string
) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
    recaptcha,
    twoFa,
  });
}

export function resendEmailAuth(
  email: string,
  password: string,
  recaptcha: string
) {
  return axios.post(RESEND_EMAIL_AUTH, {
    email,
    password,
    recaptcha,
  });
}

export function resendEmailByToken(token: string, recaptcha: string) {
  return axios.post(RESEND_EMAIL_BY_TOKEN, {
    token,
    recaptcha,
  });
}

export function resendSetEmailPasswordMessageByToken(
  token: string,
  recaptcha: string
) {
  return axios.post(RESEND_SET_EMAIL_PASSWORD_MESSAGE, {
    token,
    recaptcha,
  });
}

export function googleLogin(
  oAuth: string,
  ref: string | undefined,
  referrer: string | undefined,
  twoFa?: string
) {
  return axios.post<AuthModel>(LOGIN_URL_GOOGLE, {
    oAuth,
    ref,
    referrer,
    twoFa,
  });
}

export function googleLoginVerify(
  requestToken: string,
  recaptcha: string,
  twoFa: string
) {
  return axios.post<AuthModel>(GOOGLE_LOGIN_VERIFY, {
    requestToken,
    recaptcha,
    twoFa,
  });
}

export function telegramLogin(
  authData: any,
  ref: string | undefined,
  referrer: string | undefined
) {
  return axios.post<AuthModel>(LOGIN_URL_TELEGRAM, {
    authData,
    ref,
    referrer,
  });
}

export function telegramLoginVerify(
  requestToken: string,
  recaptcha: string,
  twoFa: string
) {
  return axios.post<AuthModel>(TELEGRAM_LOGIN_VERIFY, {
    requestToken,
    recaptcha,
    twoFa,
  });
}

export function register(
  email: string,
  password: string,
  passwordConfirmation: string,
  recaptcha: string,
  ref: string | undefined,
  referrer: string | undefined
) {
  return axios.post(REGISTER_URL, {
    email,
    password,
    passwordConfirmation,
    recaptcha,
    ref,
    referrer,
  });
}

export function requestPassword(email: string, recaptcha: string) {
  return axios.post<{ status: number; result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
    recaptcha,
  });
}

export function recoveryPassword(
  token: string,
  recaptcha: string,
  password: string,
  passwordConfirmation: string
) {
  return axios.post(RECOVERY_PASSWORD, {
    password,
    passwordConfirmation,
    recaptcha,
    token,
  });
}

export function verifyRegisterEmail(token: string, recaptcha: string) {
  return axios.post(VERIFY_EMAIL, {
    token,
    recaptcha,
  });
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    apiToken: token,
  });
}

export function confirmSetEmailPassword(token: string, recaptcha: string) {
  return axios.post(CONFIRM_SET_EMAIL_PASSWORD, {
    token,
    recaptcha,
  });
}
