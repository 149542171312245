import { DisableSidebar, PageTitle } from "../../_oxapay/layout/core";
import { PayoutApiForm } from "../../components/forms/PayoutApiForm";
import { PayoutApiTable } from "../../components/tables/PayoutApiTable";
import { PayoutApiSummary } from "../../components/pageSummary/PayoutApiSummary";
import { PayoutApiChart } from "../../components/pageCharts/PayoutApiChart";
import { PayoutApiTxsTable } from "../../components/tables/PayoutApiTxsTable";

export function PayoutApi() {
  return (
    <DisableSidebar>
      <PageTitle breadcrumbs={[]}>Payout API</PageTitle>
      <div className="row gy-5 g-xl-10">
        <div className="col-xl-4 mb-10">
          <PayoutApiForm />
        </div>

        <div className="col-xl-8 mb-5">
          <PayoutApiSummary />
          <PayoutApiChart />
        </div>
      </div>
      <PayoutApiTable />
      <PayoutApiTxsTable />
    </DisableSidebar>
  );
}
