/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { getUserByToken, login } from "../core/_requests";
import { useAuth } from "../core/Auth";
import { LoginWithGoogle } from "./LoginWithGoogle";
import { generateToken } from "../../../services/ReCaptchaGenerator";
import { FailedModalFire } from "../../../services/failedSwalFire";
import { alertsMapLogin } from "../../../services/alertsMap";
import { WaitButtonSpan } from "../../../../components/WaitButtonSpan";
import { errorHandler } from "../../../services/errorHandler";
import LoginWithTelegram from "./LoginWithTelegram";
import { PasswordInput } from "../../../../components/PasswordInput";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const {
    returnPage,
    setReturnPage,
    saveAuth,
    setCurrentUser,
    setLoginInfo,
    setGoogleInfo,
    setTelegramInfo,
  } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const returnQueryString = searchParams.get("return");

  useEffect(() => {
    returnQueryString && setReturnPage(returnQueryString);
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setLoginInfo({ userAuth: values.email, password: values.password });
      try {
        const loginToken = await generateToken("login");
        const { data: auth } = await login(
          values.email,
          values.password,
          loginToken
        );
        saveAuth(auth);
        if (auth.status === 200 && auth.apiToken) {
          const { data: user } = await getUserByToken(auth.apiToken);
          if (user.status === 200) {
            setCurrentUser(user);
            returnPage && navigate("/connect");
          } else {
            setCurrentUser(undefined);
          }
        } else if (auth.status === 201 && auth.errorNum === -7) {
          setTelegramInfo(undefined);
          setGoogleInfo(undefined);
          navigate("/twofactor");
        } else if (auth.status === 202 && auth.errorNum === -6) {
          navigate("/verifyemail");
        } else if (auth.status === 0 && auth.errorNum) {
          saveAuth(undefined);
          FailedModalFire(alertsMapLogin[auth.errorNum.toString()]);
        }
      } catch (error) {
        errorHandler(error as Error);
        FailedModalFire(
          "Please try again.",
          "Something went wrong with your connection"
        );
        saveAuth(undefined);
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <>
      <form className="form w-100" onSubmit={formik.handleSubmit}>
        <div className="text-center mb-11">
          <h1 className="text-dark mb-3">Sign In to OxaPay</h1>
          <div className="text-gray-500 fw-semibold fs-6">
            Your Social Campaigns
          </div>
        </div>

        <div className="row g-3 mb-9">
          <div className="col-md-6">
            <LoginWithGoogle setLoading={(status) => setLoading(status)} />
          </div>
          <div className="col-md-6">
            <LoginWithTelegram setLoading={(status) => setLoading(status)} />
          </div>
        </div>

        <div className="separator separator-content my-14">
          <span className="w-125px text-gray-500 fw-semibold fs-7">
            Or with email
          </span>
        </div>

        <div className="fv-row mb-8">
          <input
            placeholder="Email"
            {...formik.getFieldProps("email")}
            className={clsx("form-control bg-transparent", {
              "is-invalid border border-danger":
                formik.touched.email && formik.errors.email,
            })}
            type="email"
            name="email"
            autoComplete="off"
          />
          {formik.errors.email && formik.touched.email && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.email}
            </p>
          )}
        </div>

        <div className="fv-row mb-3">
          <PasswordInput
            autoComplete="off"
            placeholder="Password"
            {...formik.getFieldProps("password")}
            className={clsx("form-control bg-transparent rounded", {
              "is-invalid border border-danger":
                formik.touched.password && formik.errors.password,
            })}
          />
          {formik.errors.password && formik.touched.password && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.password}
            </p>
          )}
        </div>

        <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
          <Link to="/forgot-password" className="link-primary">
            Forgot Password ?
          </Link>
        </div>

        <div className="d-grid mb-10">
          <button
            type="submit"
            className="btn btn-primary w-100 mb-5"
            disabled={formik.isSubmitting || loading}
          >
            {loading ? <WaitButtonSpan /> : "Sign in"}
          </button>
        </div>

        <div className="text-gray-500 text-center fw-semibold fs-6">
          New Here?
          <Link to="/signup" className="link-primary ms-1">
            Create an Account
          </Link>
        </div>
      </form>
    </>
  );
}
