import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useRequest } from "../../app/services/userHttpRequests";
import { useAuth, UserModel } from "../../app/modules/auth";
import { alertsMapEnableTwoFa } from "../../app/services/alertsMap";
import { errorHandler } from "../../app/services/errorHandler";
import { WebappInput } from "./WebappInput";
import { WebappModalContext } from "../../_oxapay/layout/core/WebappModalContext";
import { WebappFailAlert } from "./AlertsModal/WebappFailAlert";
import { WebappSuccessAlert } from "./AlertsModal/WebappSuccessAlert";
import { useNavigate } from "react-router-dom";
import { WebappTextCopy } from "./webappTextCopy";
import { ShowAlertContext } from "../../_oxapay/layout/core/ShowAlertContext";

export const WalletWebappEnableTwoFa = () => {
  const [qrLoading, setQrLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [twoFa, setTwoFa] = useState({ sKey: "", qrUrl: "" });
  const { twoFactor, enableTwoFa } = useRequest();
  const { setCurrentUser, currentUser } = useAuth();
  const [error, setError] = useState<string>();
  const { showModal, setComponent, setShowModal } =
    useContext(WebappModalContext);
  const { setShowAlert, setAlertMessage } = useContext(ShowAlertContext);
  const navigate = useNavigate();

  useEffect(() => {
    twoFactor()
      .then((res) => {
        if (res.status === 200) {
          setTwoFa({ sKey: res.secretKey, qrUrl: res.qrUrl });
        } else {
          navigate("/webapp-wallet/send");
          setShowModal(true);
          setComponent(
            <WebappFailAlert
              content={"Somethings went wrong, please try again later"}
            />
          );
        }
      })
      .catch((error) => {
        errorHandler(error as Error);
      });
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.number().required("Authentication code is required"),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        const data = await enableTwoFa(values.code);
        if (data.status === 200) {
          const updatedUser = {
            ...currentUser,
            twoFa: true,
          };
          setCurrentUser(updatedUser as UserModel);
          resetForm();
          navigate("/webapp-wallet/send");
          setShowModal(true);
          setComponent(
            <WebappSuccessAlert content="Your Two-factor authentication was activated" />
          );
        } else if (data.status === 0 && data.errorNum) {
          setError(alertsMapEnableTwoFa[data.errorNum.toString()]);
        }
      } catch (error) {
        errorHandler(error as Error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    setError("");
    // eslint-disable-next-line
  }, [formik.errors.code && formik.touched.code]);

  const WebappFn = (window as any)?.Telegram?.WebApp;

  useEffect(() => {
    const backBtnFn = () => {
      navigate(`/webapp-wallet/send`);
    };

    WebappFn?.BackButton?.onClick(backBtnFn).show();

    return () => {
      WebappFn?.BackButton?.offClick(backBtnFn).hide();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!showModal) {
      if (loading) {
        WebappFn?.MainButton?.show()
          .setText("Please wait")
          .offClick(formik.handleSubmit)
          .showProgress(true);
      } else {
        WebappFn?.MainButton?.show()
          .setText("Submit")
          .onClick(formik.handleSubmit)
          .hideProgress();
      }
    }

    return () => {
      WebappFn?.MainButton?.hide().offClick(formik.handleSubmit).hideProgress();
    };
    // eslint-disable-next-line
  }, [loading, showModal]);

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center pt-15 pb-8">
        <div className="mb-7">
          <p className="fw-600 fs-2 mb-2">Authenticator Apps</p>
          <p className="text-gray-600 fs-6 lh-2 mw-300px">
            Using an authenticator app like{" "}
            <a
              href="https://support.google.com/accounts/answer/1066447?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              Google Authenticator
            </a>
            ,{" "}
            <a
              href="https://www.microsoft.com/en-us/account/authenticator"
              target="_blank"
              rel="noreferrer"
            >
              Microsoft Authenticator
            </a>
            ,{" "}
            <a
              href="https://authy.com/download/"
              target="_blank"
              rel="noreferrer"
            >
              Authy
            </a>
            , or{" "}
            <a
              href="https://support.1password.com/one-time-passwords/"
              target="_blank"
              rel="noreferrer"
            >
              1Password
            </a>{" "}
            , scan the QR code. It will generate a 6 digit code for you to enter
            below.
          </p>
        </div>
        <div className="card mb-3 p-4">
          {qrLoading && (
            <div className="h-150px w-150px bg-secondary rounded m-auto shine"></div>
          )}
          {twoFa.qrUrl && (
            <img
              src={twoFa.qrUrl}
              alt="qr"
              className={`mw-150px ${qrLoading && "d-none"}`}
              onLoad={() => {
                setQrLoading(false);
              }}
            />
          )}
        </div>
      </div>

      <div className="bg-gray-300 rounded-12px px-10 py-10 mb-7">
        <p className="fs-6 fw-400 m-0 text-start">
          If you having trouble using the QR code, select manual entry on your
          app, and enter your username and the code:
          {twoFa.sKey ? (
            <WebappTextCopy
              text={twoFa.sKey}
              onCopy={() => {
                setShowAlert(true);
                setAlertMessage("Key copied to clipoboard");
              }}
            >
              <div className="text-dark pt-2">
                <p className="m-0 fw-600 fm-jetbrain fs-5">{twoFa.sKey}</p>
              </div>
            </WebappTextCopy>
          ) : (
            <div className="h-20px w-100 bg-gray-400 rounded shine mt-2"></div>
          )}
        </p>
      </div>

      <div className="mb-5">
        <WebappInput
          label="Authentication code"
          type="number"
          placeholder="Code"
          autoComplete="off"
          inputMode="numeric"
          name="code"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.code}
          error={
            (formik.errors.code && formik.touched.code) || error ? true : false
          }
          clear={formik.resetForm}
        />
        {formik.errors.code && formik.touched.code && (
          <p className="text-danger fw-semibold ms-3 fs-5 mt-1 mb-0">
            {formik.errors.code}
          </p>
        )}
        {error && (
          <p className="text-danger fw-semibold ms-3 fs-5 mt-1 mb-0">{error}</p>
        )}
      </div>
    </>
  );
};
