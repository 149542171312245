interface props {
  onClick: () => void;
  disable?: boolean;
}

export const TableGetCodeButton = ({ onClick, disable = false }: props) => {
  return (
    <button
      onClick={onClick}
      className={`btn btn-icon btn-bg-light btn-sm mx-1 w-150px h-45px text-gray-600 text-hover-primary rounded ${
        disable && "disable"
      }`}
    >
      <span className="svg-icon svg-icon-1 me-2">
        <i className="fa-solid fa-code"></i>
      </span>
      <span className="fs-6">Get code</span>
    </button>
  );
};
