import React, {ReactNode, useEffect, useState} from 'react'
import {existValidator} from '../app/services/existValidator'
import {v4 as uuidv4} from 'uuid'

export interface Item {
  id: string
  value: string
  error: string | null
  onBlur: boolean
}

interface props {
  value: Item[]
  title: ReactNode
  addBtnTitle?: string
  placeholder: string
  onChange: (items: Item[]) => void
  validator: (value: string) => {result: boolean; message: string | null}
}

export const ListMakerWidget = ({
  title,
  placeholder,
  addBtnTitle = 'Add more',
  onChange,
  validator,
  value,
}: props) => {
  const [items, setItems] = useState<Item[]>(value)

  const handleAddItem = () => {
    const newItem = {
      id: uuidv4(),
      value: '',
      error: null,
      onBlur: true,
    }
    setItems([...items, newItem])
  }

  const handleRemoveItem = (id: string) => {
    setItems(items.filter((item) => item.id !== id))
  }

  const handleInputChange = (id: string, value: string) => {
    const {result} = validator(value)
    if (result === false) {
      const {result, message} = validator(value)
      setItems(
        items.map((item) =>
          item.id === id
            ? {...item, value, error: result === false ? message : null, onBlur: false}
            : item
        )
      )
    } else {
      const {result, message} = existValidator(value, items)
      setItems(
        items.map((item) =>
          item.id === id
            ? {...item, value, error: result === false ? message : null, onBlur: false}
            : item
        )
      )
    }
  }

  const handleBlurChange = (id: string) => {
    setItems(items.map((item) => (item.id === id ? {...item, onBlur: true} : item)))
  }

  useEffect(() => {
    setItems(value)
  }, [value])

  useEffect(() => {
    onChange(items)
    // eslint-disable-next-line
  }, [items])

  return (
    <>
      <div className='form-group'>
        <h4 className='fs-5 fw-semibold text-gray-800'>{title}</h4>

        {items.map((item) => (
          <div key={item.id} className='mb-2'>
            <div className='form-group row mx-0'>
              <div className='col-md-10 pe-2 px-0'>
                <div className='input-group input-group-solid'>
                  <input
                    type='text'
                    className={`form-control mb-2 mb-md-0 ${
                      item.value !== '' &&
                      item.error !== null &&
                      item.onBlur === true &&
                      'is-invalid border border-danger'
                    }`}
                    placeholder={placeholder}
                    value={item.value}
                    onBlur={() => handleBlurChange(item.id)}
                    onChange={(e) => handleInputChange(item.id, e.target.value)}
                  />
                </div>
              </div>
              <div className='col-md-2 px-0'>
                <button
                  type='button'
                  className='btn btn-md btn-light-danger px-3 w-100'
                  onClick={() => handleRemoveItem(item.id)}
                >
                  <i className='la la-trash-o fs-2'></i>
                </button>
              </div>
            </div>
            {item.value !== '' && item.error !== null && item.onBlur === true && (
              <p className='text-danger fw-semibold ms-3 mt-1 mb-0'>{item.error}</p>
            )}
          </div>
        ))}
      </div>
      <div className='form-group my-5'>
        <button className='btn btn-light-primary' type='button' onClick={handleAddItem}>
          <i className='la la-plus'></i>
          {addBtnTitle}
        </button>
      </div>
    </>
  )
}
