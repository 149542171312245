import React, { useContext } from "react";
import { toAbsoluteUrl } from "../_oxapay/helpers";
import { AppContext } from "../_oxapay/layout/core/AppContext";

interface props {
  coinsList: string[];
}

export const CoinsListWidget = ({ coinsList }: props) => {
  const { coinsListMap } = useContext(AppContext);

  return (
    <div className="scroll-y mx-5 mx-xl-18 pt-0 pb-15">
      <div className="mh-375px scroll-y">
        {coinsList.map((coin, index) => (
          <div
            key={index}
            className={`d-flex flex-stack justify-content-center py-5 ${
              index !== coinsList.length - 1 &&
              "border-bottom border-gray-300 border-bottom-dashed"
            }`}
          >
            <div
              className={`d-flex align-items-center justify-content-center ${
                coinsListMap[coin]?.depositEnable === false &&
                "disable filter-grayscale"
              }`}
            >
              <div className="symbol symbol-35px">
                <img
                  alt="Pic"
                  src={toAbsoluteUrl(
                    `/media/svg/coins/${coinsListMap[coin]?.slug}.svg`
                  )}
                />
              </div>

              <div className="ms-3">
                <div className="d-flex align-items-center fs-5 fw-bold text-dark">
                  <span>{coinsListMap[coin]?.name}</span>
                  <span className="badge badge-light fs-8 fw-semibold ms-2">
                    {coin.toUpperCase()}
                  </span>
                  {coinsListMap[coin]?.depositEnable === false && (
                    <span className="fs-8 fw-semibold ms-2">(disabled)</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
