import { DisableSidebar, PageTitle } from "../../_oxapay/layout/core";
import { MerchantsForm } from "../../components/forms/MerchantsForm";
import { MerchantsTable } from "../../components/tables/MerchantsTable";
import { MerchantSummary } from "../../components/pageSummary/MerchantSummary";
import { MerchantChart } from "../../components/pageCharts/MerchantChart";
import { MerchantTxsTable } from "../../components/tables/MerchantTxsTable";

export function Merchants() {
  return (
    <DisableSidebar>
      <PageTitle breadcrumbs={[]}>Merchants</PageTitle>
      <div className="row gy-5 g-xl-10">
        <div className="col-xl-4 mb-10">
          <MerchantsForm />
        </div>

        <div className="col-xl-8 mb-5">
          <MerchantSummary />
          <MerchantChart />
        </div>
      </div>
      <MerchantsTable />
      <MerchantTxsTable />
    </DisableSidebar>
  );
}
