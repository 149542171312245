import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { tx } from "../TransactionsList";
import { errorHandler } from "../../app/services/errorHandler";
import { walletTxsInterval } from "../../app/services/intervalManage";
import { useRequest } from "../../app/services/userHttpRequests";
import { WebappNoData } from "./WebappNoData";
import { WebappListSkeleton } from "./WebappListSkeleton";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { DateTime, formatAmount } from "../../app/services/functionServices";

interface props {
  coin?: string;
}
export const WalletWebappHistoryList = ({ coin }: props) => {
  const { coinsListMap } = useContext(AppContext);
  const [trasnactionsFetching, setTrasnactionsFetching] = useState(true);
  const [transactions, setTransactions] = useState<tx[]>([]);
  const { walletTransactions } = useRequest();
  const [copied, setCopied] = useState<boolean[]>(
    transactions.map(() => false)
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchTxs = async () => {
      try {
        const response = await walletTransactions(
          {
            type: "",
            coin: coin ? [coin] : [],
            start: "",
            end: "",
          },
          signal
        );
        if (response.status === 200) {
          const TransactionsData = response;
          setTransactions(TransactionsData.data);
          setTrasnactionsFetching(false);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };

    fetchTxs();
    const interval = setInterval(fetchTxs, walletTxsInterval);

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval on component unmount
    };
    // eslint-disable-next-line
  }, []);

  const handleCopy = (id: number, index: number) => {
    const transaction = transactions.find((tx) => tx.id === id.toString());
    if (transaction) {
      navigator.clipboard.writeText(transaction.txHash);
      setCopied((prevCopied) => {
        const newCopied = [...prevCopied];
        newCopied[index] = true;
        return newCopied;
      });
      setTimeout(() => {
        setCopied((prevCopied) => {
          const newCopied = [...prevCopied];
          newCopied[index] = false;
          return newCopied;
        });
      }, 2000);
    }
  };

  return (
    <div className="card py-3">
      {transactions?.length === 0 && !trasnactionsFetching && <WebappNoData />}
      {trasnactionsFetching ? (
        <React.Fragment>
          <WebappListSkeleton count={10} />
        </React.Fragment>
      ) : (
        transactions.map((transaction, index) => (
          <React.Fragment key={index}>
            <div className="d-flex align-items-center justify-content-between ps-4 pe-5 ">
              <div className="d-flex align-items-center">
                <div className="w-45px overflow-hidden me-3">
                  <div className="symbol-label">
                    <img
                      src={toAbsoluteUrl(
                        `/media/icons/wallet/${transaction.type
                          .replace(/\s+/g, "")
                          .toLowerCase()}-type.svg`
                      )}
                      alt={"send-type"}
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <h4 className="d-flex align-items-center fs-2 fw-semibold text-dark lh-1 mb-0 mt-1">
                    {transaction.url ? (
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        overlay={
                          <Popover>
                            <Popover.Header>Transaction Hash</Popover.Header>
                            <Popover.Body>
                              <strong>{transaction.txHash}</strong>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <a
                          href={transaction.url}
                          className="mb-1 text-dark text-hover-primary fw-bold h4 d-flex align-items-center cursor-pointer"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {transaction.txHash.substring(0, 3) +
                            "..." +
                            transaction.txHash.substring(
                              transaction.txHash.length - 3
                            )}
                          <span
                            className={`badge badge-${
                              transaction.status === "y"
                                ? "success"
                                : transaction.status === "f"
                                ? "danger"
                                : transaction.status === "r"
                                ? "danger"
                                : "warning"
                            } ms-1`}
                          >
                            {transaction.status === "y"
                              ? "Confirmed"
                              : transaction.status === "w"
                              ? `Confirming (${transaction.confirm}/${transaction.confirmRequired})`
                              : transaction.status === "p"
                              ? "Pending"
                              : transaction.status === "f"
                              ? "Failed"
                              : transaction.status === "r"
                              ? "Canceled"
                              : "Proccessing"}
                          </span>
                        </a>
                      </OverlayTrigger>
                    ) : (
                      <span className="mb-1 text-dark text-hover-primary fw-bold h4 d-flex align-items-center cursor-pointer">
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          placement="top"
                          overlay={
                            <Popover>
                              {transaction.txHash && (
                                <Popover.Header>
                                  Internal transfer track ID
                                </Popover.Header>
                              )}
                              <Popover.Body>
                                {transaction.txHash ? (
                                  <strong>{transaction.txHash}</strong>
                                ) : (
                                  <span>
                                    {transaction.status === "r"
                                      ? "Transaction was canceled"
                                      : "Transaction is pending"}
                                  </span>
                                )}
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <div
                            className="d-flex justify-content-center"
                            onClick={
                              transaction.txHash
                                ? () =>
                                    handleCopy(Number(transaction.id), index)
                                : undefined
                            }
                          >
                            {copied[index] ? (
                              <span className="fs-6 text-success">
                                Copied to clipboard
                              </span>
                            ) : (
                              <>
                                {transaction.txHash &&
                                  transaction.txHash.substring(0, 3) +
                                    "..." +
                                    transaction.txHash.substring(
                                      transaction.txHash.length - 3
                                    )}
                                <span
                                  className={`badge badge-${
                                    transaction.status === "y"
                                      ? "success"
                                      : transaction.status === "f"
                                      ? "danger"
                                      : transaction.status === "r"
                                      ? "danger"
                                      : "warning"
                                  } ${transaction.txHash && "ms-1"} `}
                                >
                                  {transaction.status === "y"
                                    ? "Confirmed"
                                    : transaction.status === "w"
                                    ? `Confirming (${transaction.confirm}/${transaction.confirmRequired})`
                                    : transaction.status === "p"
                                    ? "Pending"
                                    : transaction.status === "f"
                                    ? "Failed"
                                    : transaction.status === "r"
                                    ? "Canceled"
                                    : "Proccessing"}
                                </span>
                              </>
                            )}
                          </div>
                        </OverlayTrigger>
                      </span>
                    )}
                  </h4>
                  <span className="d-flex align-items-center text-gray-600 fs-5">
                    {DateTime(parseInt(transaction.date))}
                  </span>
                </div>
              </div>
              <div className="d-flex flex-column align-items-end">
                <h5
                  className={`fw-semibold ${
                    transaction.plus ? "text-success" : "text-danger"
                  } lh-1 letter-spacing-n-1 mb-0 fs-2 mt-1`}
                >
                  {transaction.plus ? "+" : "-"}
                  {formatAmount(
                    parseFloat(transaction.amount),
                    coinsListMap[transaction.coin]?.displayPrecision
                  )}{" "}
                  {transaction.coin}
                </h5>
                <span className="text-gray-600 fw-semibold fs-5">
                  {transaction.type}
                </span>
              </div>
            </div>
            {transactions?.length - 1 !== index && (
              <hr
                key={`hr-${index}`}
                className="border-gray-500 my-3 w-100 align-self-end"
                style={{ maxWidth: "290px" }}
              />
            )}
          </React.Fragment>
        ))
      )}
    </div>
  );
};
