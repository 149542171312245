import React from "react";
import { GeneralApiKeyForm } from "./forms/GeneralApiKeyForm";
import { GeneralApiTable } from "./tables/GeneralApiTable";

export const GeneralApiKey = () => {
  return (
    <div className="row gy-5 g-xl-10">
      <div className="col-xl-4 mb-10">
        <GeneralApiKeyForm />
      </div>

      <div className="col-xl-8 mb-5">
        <GeneralApiTable />
      </div>
    </div>
  );
};
