import { createContext, useState } from "react";
import { WithChildren } from "../../helpers";

export interface WebappModalContextModel {
  component?: JSX.Element;
  setComponent: (_component: JSX.Element) => void;
  showModal: boolean;
  setShowModal: (_showModal: boolean) => void;
  dFlex: boolean;
  setDFlex: (_dFlex: boolean) => void;
}

export const WebappModalContext = createContext<WebappModalContextModel>({
  component: undefined,
  setComponent: (_component?: JSX.Element) => {},
  showModal: false,
  setShowModal: (_showModal: boolean) => {},
  dFlex: true,
  setDFlex: (_dFlex: boolean) => {},
});

const WebappModalProvider: React.FC<WithChildren> = ({ children }) => {
  const [component, setComponent] = useState<JSX.Element | undefined>(
    undefined
  );
  const [showModal, setShowModal] = useState(false);
  const [dFlex, setDFlex] = useState(true);
  const value = {
    component,
    setComponent,
    showModal,
    setShowModal,
    dFlex,
    setDFlex,
  };

  return (
    <WebappModalContext.Provider value={value}>
      {children}
    </WebappModalContext.Provider>
  );
};

export default WebappModalProvider;
