import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { LayoutSplashScreen } from "../../../../_oxapay/layout/core";
import { AuthModel, logInfoModel, UserModel } from "./_models";
import * as authHelper from "./AuthHelpers";
import { getUserByToken } from "./_requests";
import { WithChildren } from "../../../../_oxapay/helpers";
import { errorHandler } from "../../../services/errorHandler";

type AuthContextProps = {
  returnPage: string | null;
  setReturnPage: (returnPage: string | null) => void;
  auth: AuthModel | undefined;
  setAuth: (auth: AuthModel | undefined) => void;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  loginInfo: logInfoModel | undefined;
  setLoginInfo: (loginInfo: logInfoModel | undefined) => void;
  googleInfo: string | undefined;
  setGoogleInfo: (googleInfo: string | undefined) => void;
  telegramInfo: string | undefined;
  setTelegramInfo: (telegramInfo: string | undefined) => void;
  logout: () => void;
};

const initAuthContextPropsState = {
  returnPage: null,
  setReturnPage: () => {},
  auth: authHelper.getAuth(),
  setAuth: () => {},
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  loginInfo: undefined,
  setLoginInfo: () => {},
  googleInfo: undefined,
  setGoogleInfo: () => {},
  telegramInfo: undefined,
  setTelegramInfo: () => {},
  logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
    }
  };
  const [loginInfo, setLoginInfo] = useState<logInfoModel>();
  const [googleInfo, setGoogleInfo] = useState<string>();
  const [telegramInfo, setTelegramInfo] = useState<string>();
  const [returnPage, setReturnPage] = useState<string | null>(null);

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
  };

  return (
    <AuthContext.Provider
      value={{
        returnPage,
        setReturnPage,
        auth,
        setAuth,
        saveAuth,
        currentUser,
        setCurrentUser,
        loginInfo,
        setLoginInfo,
        logout,
        googleInfo,
        setGoogleInfo,
        telegramInfo,
        setTelegramInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application

  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      setShowSplashScreen(true);
      try {
        if (!didRequest.current) {
          try {
            const { data: user } = await getUserByToken(apiToken);
            if (user && user.status === 200) {
              setCurrentUser(user);
            } else {
              logout();
              setShowSplashScreen(false);
            }
          } catch (error) {
            errorHandler(error as Error);
          } finally {
            setShowSplashScreen(false);
          }
        }
      } catch (error) {
        errorHandler(error as Error);
        if (!didRequest.current) {
          logout();
          setShowSplashScreen(false);
        }
      }

      return () => (didRequest.current = true);
    };

    if (auth && auth.apiToken) {
      requestUser(auth.apiToken as string);
    } else {
      logout();
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
