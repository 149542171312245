import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useRequest } from "../app/services/userHttpRequests";
import { SendContext } from "../_oxapay/layout/core/SendContext";
import { FailedModalFire } from "../app/services/failedSwalFire";
import { errorHandler } from "../app/services/errorHandler";
import { WaitButtonSpan } from "./WaitButtonSpan";
import { AppContext } from "../_oxapay/layout/core/AppContext";
import { alertsMapSendSign } from "../app/services/alertsMap";
import { formatAmount } from "../app/services/functionServices";
import { KTSVG } from "../_oxapay/helpers";
import { TextArea } from "./TextArea";
import { Collapse } from "react-bootstrap";

interface props {
  onSuccess: () => void;
  onBack: () => void;
}

export const SendStepThree = ({ onBack, onSuccess }: props) => {
  const [loading, setLoading] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const { sendAssetSign } = useRequest();
  const {
    stepThreeOptions,
    setStepThreeOptions,
    setStepTwoOptions,
    stepTwoOptions,
    setShowSuccessModal,
    setTxData,
  } = useContext(SendContext);
  const { coinsListMap } = useContext(AppContext);
  const [networkName, setNetworkName] = useState<string>();

  useEffect(() => {
    if (stepThreeOptions?.coin) {
      const Coin = coinsListMap[stepThreeOptions?.coin];
      if (Coin && Coin.networkList) {
        const name = Coin.networkList[stepThreeOptions.network].name;
        setNetworkName(name);
      }
    }
    // eslint-disable-next-line
  }, []);

  const validationSchema =
    stepThreeOptions?.twoFa && stepThreeOptions?.emailconfirmation
      ? Yup.object({
          verification: Yup.string().required("Verification code is required"),
          twoFa: Yup.string().required(
            "Two-factor authentication code is required"
          ),
        })
      : stepThreeOptions?.twoFa === false && stepThreeOptions?.emailconfirmation
      ? Yup.object({
          verification: Yup.string().required("Verification code is required"),
          twoFa: Yup.string(),
        })
      : stepThreeOptions?.twoFa && stepThreeOptions?.emailconfirmation === false
      ? Yup.object({
          verification: Yup.string(),
          twoFa: Yup.string().required(
            "Two-factor authentication code is required"
          ),
        })
      : stepThreeOptions?.twoFa === false &&
        stepThreeOptions?.emailconfirmation === false &&
        Yup.object({
          verification: Yup.string(),
          twoFa: Yup.string(),
        });

  const formik = useFormik({
    initialValues: {
      verification: "",
      twoFa: "",
      note: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        if (stepThreeOptions?.requestToken) {
          const res = await sendAssetSign(
            stepThreeOptions?.requestToken,
            values.verification,
            values.twoFa,
            values.note
          );
          if (res.status === 200) {
            setShowSuccessModal(true);
            setTxData(res.tx);
            onSuccess();
            setStepTwoOptions(undefined);
            setStepThreeOptions(undefined);
          } else {
            FailedModalFire(alertsMapSendSign[res.errorNum.toString()]);
          }
        }
      } catch (error) {
        errorHandler(error as Error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="bg-light-secondary ps-6 pt-4 pb-5 rounded mb-5">
        <p className="m-0 fs-4 text-dark">Currency</p>
        <p className="m-0 fs-7 text-primary">
          {stepThreeOptions?.coin && coinsListMap[stepThreeOptions?.coin].name}{" "}
          ({stepThreeOptions?.coin})
        </p>
        {!stepTwoOptions?.intenal && (
          <>
            <hr className="border-gray-500 my-3" />
            <p className="m-0 fs-4 text-dark">Network</p>
            <p className="m-0 fs-7 text-primary">
              {networkName === null ? stepThreeOptions?.network : networkName}
            </p>
          </>
        )}
        <hr className="border-gray-500 my-3" />
        <p className="m-0 fs-4 text-dark">Amount</p>
        <p className="m-0 fs-7 text-primary">
          {stepThreeOptions &&
            formatAmount(
              parseFloat(stepThreeOptions?.amount),
              coinsListMap[stepThreeOptions?.coin]?.displayPrecision
            )}{" "}
          {stepThreeOptions?.coin}
        </p>
        <hr className="border-gray-500 my-3" />
        <p className="m-0 fs-4 text-dark">Fee</p>
        <p className="m-0 fs-7 text-primary">
          {stepThreeOptions?.fee} {stepThreeOptions?.coin}
        </p>
        <hr className="border-gray-500 my-3" />
        <p className="m-0 fs-4 text-dark">Receiver address</p>
        <p className="m-0 fs-7 text-primary pe-3">
          {stepThreeOptions?.address}
        </p>
        {stepThreeOptions?.memo && (
          <>
            <hr className="border-gray-500 my-3" />
            <p className="m-0 fs-4 text-dark">Memo</p>
            <p className="m-0 fs-7 text-primary pe-3">
              {stepThreeOptions?.memo}
            </p>
          </>
        )}
      </div>

      {stepThreeOptions?.emailconfirmation && (
        <div className="w-100 mb-5">
          <h4 className="fs-5 fw-semibold text-gray-800">
            {stepThreeOptions?.source &&
              stepThreeOptions?.source.charAt(0).toUpperCase() +
                stepThreeOptions?.source.slice(1)}{" "}
            verification code
          </h4>
          <input
            type="text"
            className={`form-control form-control-solid me-3 flex-grow-1 ${
              formik.errors.verification &&
              formik.touched.verification &&
              "is-invalid border border-danger"
            }`}
            name="verification"
            placeholder="Code"
            autoComplete="off"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.verification}
          />
          {formik.errors.verification && formik.touched.verification && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.verification}
            </p>
          )}
          <span className="text-gray-600 fw-semibold fs-7">
            The verification code was sent to your{" "}
            <b className="text-primary">{stepThreeOptions?.source}</b>
          </span>
        </div>
      )}
      {stepThreeOptions?.twoFa && (
        <div className="w-100 mb-5">
          <h4 className="fs-5 fw-semibold text-gray-800">
            2-FA verification code
          </h4>
          <input
            type="text"
            className={`form-control form-control-solid me-3 flex-grow-1 ${
              formik.errors.twoFa &&
              formik.touched.twoFa &&
              "is-invalid border border-danger"
            }`}
            name="twoFa"
            placeholder="Code"
            autoComplete="off"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.twoFa}
          />
          {formik.errors.twoFa && formik.touched.twoFa && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.twoFa}
            </p>
          )}
          <span className="text-gray-600 fw-semibold fs-7">
            The code is found in your authenticator app, like Google
            Authenticator or others.
          </span>
        </div>
      )}
      <div className="mb-10">
        <button
          className="btn text-primary p-0 mb-2"
          type="button"
          onClick={() => {
            showNote && formik.setFieldValue("note", "");
            setShowNote(!showNote);
          }}
        >
          <KTSVG
            path="media/icons/duotune/communication/com003.svg"
            className="svg-icon-primary svg-icon-1"
          />
          {showNote ? "Remove" : "Add"} note
        </button>
        <Collapse in={showNote}>
          <div className="w-100">
            <TextArea
              maxLength={100}
              row={4}
              placeholder="Note..."
              name="note"
              value={formik.values.note}
              onChange={formik.handleChange}
            />
          </div>
        </Collapse>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <button
          type="button"
          className="btn fs-3 text-muted text-hover-primary mx-5"
          onClick={onBack}
        >
          Back
        </button>
        <button
          type="submit"
          className="btn btn-primary fs-3 w-200px"
          disabled={formik.isSubmitting}
        >
          {loading ? <WaitButtonSpan /> : "Sign"}
        </button>
      </div>
    </form>
  );
};
