/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useAuth } from "../../../../app/modules/auth";
import { AccountManager } from "../../../../components/AccountManager";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { UserMenu } from "../user-menu/UserMenu";

const AsideUserMenu: FC = () => {
  const { currentUser } = useAuth();
  const [showPopover, setShowPopover] = useState(false);
  const [formattedUsername, setFormattedUsername] = useState<string | null>(
    null
  );
  const formatUsername = (username: string) => {
    if (username && username.length > 27) {
      setShowPopover(true);
      let firstPart = username.substring(0, 12);
      let lastPart = username.substring(username.length - 12);
      setFormattedUsername(`${firstPart}...${lastPart}`);
    } else {
      setShowPopover(false);
      setFormattedUsername(username);
    }
  };

  useEffect(() => {
    if (currentUser?.username) {
      formatUsername(currentUser.username);
    }
  }, [currentUser]);

  return (
    <>
      <AccountManager />
      <a
        href="https://docs.oxapay.com/"
        target="_blank"
        rel="noreferrer"
        className="menu-link text-gray-700 text-hover-dark fw-semibold px-0 d-block mb-3"
      >
        <span className="menu-icon">
          <span className="svg-icon svg-icon-1">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
                fill="currentColor"
              />
              <path
                d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                fill="currentColor"
              />
            </svg>
          </span>
        </span>
        <span className="ms-1">OxaPay documentation</span>
      </a>
      <a
        href="https://oxapay.tawk.help/"
        target="_blank"
        rel="noreferrer"
        className="menu-link text-gray-700 text-hover-dark fw-semibold px-0 d-block mb-10"
      >
        <span className="menu-icon">
          <span className="svg-icon svg-icon-1">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z"
                fill="currentColor"
              />
              <rect
                x="6"
                y="12"
                width="7"
                height="2"
                rx="1"
                fill="currentColor"
              />
              <rect
                x="6"
                y="7"
                width="12"
                height="2"
                rx="1"
                fill="currentColor"
              />
            </svg>
          </span>
        </span>
        <span className="ms-1">Customer support</span>
      </a>
      <div className="d-flex flex-stack">
        {/* begin::Wrapper */}
        <div className="d-flex align-items-center flex-wrap">
          {/* begin::Avatar */}
          <div className="symbol symbol-circle symbol-40px">
            <img src={toAbsoluteUrl("/media/avatars/blank.png")} alt="avatar" />
          </div>
          {/* end::Avatar */}
          {/* begin::User info */}
          <div className="ms-2">
            {showPopover ? (
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="top"
                overlay={
                  <Popover>
                    <Popover.Body>{currentUser?.username}</Popover.Body>
                  </Popover>
                }
              >
                <span className="text-muted fw-bold d-block fs-7 lh-1">
                  {formattedUsername}
                </span>
              </OverlayTrigger>
            ) : (
              <span className="text-muted fw-bold d-block fs-7 lh-1">
                {formattedUsername}
              </span>
            )}
          </div>
          {/* end::User info */}
        </div>
        {/* end::Wrapper */}

        {/* begin::User menu */}
        <div className="ms-1">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary position-relative me-n2"
            data-kt-menu-trigger="click"
            data-kt-menu-overflow="false"
            data-kt-menu-placement="top-end"
          >
            <KTSVG
              path="/media/icons/duotune/coding/cod001.svg"
              className="svg-icon-1"
            />
          </div>
          <UserMenu />
        </div>
        {/* end::User menu */}
      </div>
    </>
  );
};

export { AsideUserMenu };
