import { useContext } from "react";
import { Carousel } from "react-bootstrap";
import { toAbsoluteUrl } from "../_oxapay/helpers";
import { AppContext } from "../_oxapay/layout/core/AppContext";

interface props {
  coinsList: string[];
  activeIndex: number;
  [key: string]: any;
  coinWidth?: string;
}

const CoinCarousel = ({
  coinWidth = "w-150px",
  coinsList,
  activeIndex,
  ...rest
}: props) => {
  const { coinsListMap } = useContext(AppContext);

  return (
    <Carousel
      activeIndex={activeIndex}
      controls={false}
      indicators={false}
      {...rest}
    >
      {coinsList.map((symbol, index) => (
        <Carousel.Item key={index}>
          <img
            src={toAbsoluteUrl(
              `/media/3dcoins/${coinsListMap[symbol]?.slug}.png`
            )}
            className={`${coinWidth} rotate-90-n`}
            alt={coinsListMap[symbol]?.name}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CoinCarousel;
