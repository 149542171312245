import React from 'react'

interface props {
  list: string[]
}

export const TextListWidget = ({list}: props) => {
  return (
    <>
      <div className='scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
        <div className='mh-375px scroll-y'>
          {list.map((item, index) => (
            <div
              key={index}
              className={`d-flex flex-stack justify-content-center py-5 ${
                index !== list.length - 1 && 'border-bottom border-gray-300 border-bottom-dashed'
              }`}
            >
              <div className='d-flex align-items-center fs-5 fw-bold text-dark'>
                <span>{item}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
