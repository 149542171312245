import { DisableSidebar, PageTitle } from "../../_oxapay/layout/core";
import { DonationButtonForm } from "../../components/forms/DonationButtonForm";
import DonationButtonProvider from "../Core/DonationButtonContext";
import { DonationButtonTable } from "../../components/tables/DonationButtonTable";
import { DonationSummary } from "../../components/pageSummary/DonationSummary";
import { DonationChart } from "../../components/pageCharts/DonationChart";
import { DonationTxsTable } from "../../components/tables/DonationTxsTable";

export function DonationButton() {
  return (
    <DisableSidebar>
      <DonationButtonProvider>
        <PageTitle breadcrumbs={[]}>Donations</PageTitle>
        <div className="row gy-5 g-xl-10">
          <div className="col-xl-4 mb-10">
            <DonationButtonForm />
          </div>

          <div className="col-xl-8 mb-5">
            <DonationSummary />
            <DonationChart />
          </div>
        </div>

        <DonationButtonTable />
        <DonationTxsTable />
      </DonationButtonProvider>
    </DisableSidebar>
  );
}
