import axios from "axios";
import React, { useEffect, useState } from "react";
import { errorHandler } from "../../app/services/errorHandler";
import { payoutApiChartInterval } from "../../app/services/intervalManage";
import { useRequest } from "../../app/services/userHttpRequests";
import { ChartsWidget1 } from "../charts/ChartWidget1";

export const PayoutApiChart = () => {
  const { payoutApiChart } = useRequest();
  const [chartData, setChartData] = useState({
    categories: ["-", "-", "-", "-", "-", "-", "-"],
    data: [0, 0, 0, 0, 0, 0, 0],
  });

  useEffect(() => {
    const controller = new AbortController();

    const fetchChart = async () => {
      try {
        const response = await payoutApiChart({ signal: controller.signal });
        if (response.status === 200) {
          const chartData = response.chart;
          setChartData({
            categories: chartData.categories,
            data: chartData.data,
          });
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };
    fetchChart();
    const interval = setInterval(fetchChart, payoutApiChartInterval);

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval on component unmount
    };
    // eslint-disable-next-line
  }, []);

  return (
    <ChartsWidget1
      className="card card-flush overflow-hidden"
      chartTitle={"Payouts statistics"}
      chartSubtitle={"The last 7 days Payouts"}
      height={420}
      categories={chartData.categories}
      data={[
        {
          name: "Payout",
          data: chartData.data.map((item) =>
            parseFloat(Number(item).toFixed(3).toLocaleString())
          ),
        },
      ]}
    />
  );
};
