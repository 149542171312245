import axios from "axios";
import React, { useEffect, useState } from "react";
import { errorHandler } from "../app/services/errorHandler";
import { refDetailInterval } from "../app/services/intervalManage";
import { useRequest } from "../app/services/userHttpRequests";
import { IconReferral } from "./staticIcons/IconReferral";

export const ReferralsDetail = () => {
  const { referralsDetail } = useRequest();
  const [loading, setLoading] = useState(true);
  const [refDetail, setRefDetail] = useState({
    today: 0,
    all: 0,
  });

  useEffect(() => {
    const controller = new AbortController();
    const fetchDetail = async () => {
      try {
        const response = await referralsDetail({
          signal: controller.signal,
        });

        if (response.status === 200) {
          const detailNewData = response.data;
          setRefDetail(detailNewData);
          setLoading(false);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };
    fetchDetail();
    const interval = setInterval(fetchDetail, refDetailInterval); // Fetch balance every 10 seconds

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval on component unmount
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="row g-0 mt-5 mb-6 w-100 justify-content-center">
      <div className="col-6">
        <div className="d-flex align-items-center me-2">
          <div className="symbol symbol-50px me-3">
            <div className="symbol-label bg-light-info">
              <span className="svg-icon svg-icon-info svg-icon-2hx">
                <IconReferral />
              </span>
            </div>
          </div>
          <div>
            {loading ? (
              <div className="h-25px w-100 bg-secondary rounded m-auto shine"></div>
            ) : (
              <div className="fs-4 text-dark fw-bold">{refDetail.all}</div>
            )}
            <div className="fs-7 text-muted fw-bold">All referrals</div>
          </div>
        </div>
      </div>

      <div className="col-6">
        <div className="d-flex align-items-center me-2">
          <div className="symbol symbol-50px me-3">
            <div className="symbol-label bg-light-danger">
              <span className="svg-icon svg-icon-danger svg-icon-2hx">
                <IconReferral />
              </span>
            </div>
          </div>
          <div>
            {loading ? (
              <div className="h-25px w-100 bg-secondary rounded m-auto shine"></div>
            ) : (
              <div className="fs-4 text-dark fw-bold">{refDetail.today}</div>
            )}
            <div className="fs-7 text-muted fw-bold">Today referrals</div>
          </div>
        </div>
      </div>
    </div>
  );
};
