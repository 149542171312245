import React, { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { DateTime, shortenString } from "../../app/services/functionServices";
import { useRequest } from "../../app/services/userHttpRequests";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { AddressBookFormModal } from "./AddressBookFormModal";
import { VerifiedAddress } from "./VerifiedAddress";

interface RecentAddress {
  name?: string;
  whitelist?: boolean;
  address: string;
  date: string;
}

interface props {
  onSelect: (address: string) => void;
}

export const RecentAddresses = ({ onSelect }: props) => {
  const [recentAddresses, setRecentAddresses] = useState<RecentAddress[]>([]);
  const [showAddressBookForm, setShowAddressBookForm] = useState(false);
  const { addressbook } = useContext(AppContext);
  const [address, setAddress] = useState("");
  const { recentAddress } = useRequest();

  useEffect(() => {
    recentAddress().then((res) => {
      if (res.status === 200) {
        setRecentAddresses(res.data);
      }
    });
    // eslint-disable-next-line
  }, []);

  return recentAddresses.length !== 0 ? (
    <div>
      <h4 className="fs-5 fw-semibold text-gray-800">Recents</h4>
      <div className="bg-light-secondary rounded pb-1 pt-1 mb-5 mh-250px overflow-scroll">
        {recentAddresses.map((item, index) => (
          <div key={index}>
            <div className="d-flex flex-stack rounded py-2">
              <div className="d-flex align-items-center cursor-pointer">
                <div className="ms-5">
                  {addressbook.find(
                    (addressItem) => addressItem.address === item.address
                  ) ? (
                    <p
                      className="fs-6 fw-bolder text-gray-900 text-hover-primary cursor-pointer mb-0"
                      onClick={() => onSelect(item.address)}
                    >
                      {
                        addressbook.find(
                          (addressItem) => addressItem.address === item.address
                        )?.name
                      }
                      {addressbook.find(
                        (addressItem) => addressItem.address === item.address
                      )?.whitelist && <VerifiedAddress />}
                    </p>
                  ) : (
                    <p
                      className="d-inline-block fs-6 text-primary mb-0"
                      onClick={() => {
                        setAddress(item.address);
                        setShowAddressBookForm(true);
                      }}
                    >
                      Add to address book
                    </p>
                  )}
                  {item?.address.length < 28 ? (
                    <p
                      className="address fw-semibold fs-7 text-gray-600 text-hover-primary cursor-pointer mb-0"
                      onClick={() => onSelect(item.address)}
                    >
                      {shortenString(item.address, 28)}
                    </p>
                  ) : (
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="top"
                      overlay={
                        <Popover>
                          <Popover.Body>{item.address}</Popover.Body>
                        </Popover>
                      }
                    >
                      <p
                        className="address fw-semibold fs-7 text-gray-600 text-hover-primary cursor-pointer mb-0"
                        onClick={() => onSelect(item.address)}
                      >
                        {shortenString(item.address, 28)}
                      </p>
                    </OverlayTrigger>
                  )}

                  <p className="text-muted fs-8 mb-0">
                    {DateTime(parseInt(item.date))}
                  </p>
                </div>
              </div>
            </div>
            {recentAddresses.length - 1 !== index && (
              <hr className="border-gray-500 m-0" />
            )}
          </div>
        ))}
      </div>
      <AddressBookFormModal
        address={address}
        showAddressBookForm={showAddressBookForm}
        onClose={() => setShowAddressBookForm(false)}
      />
    </div>
  ) : (
    <></>
  );
};
