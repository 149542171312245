import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const COINS_LIST = `${API_URL}/core/coins`;
const COINS_PRICE = `${API_URL}/core/coins/price`;
const ERROR_LOG = `${API_URL}/core/error`;

export function getCoinsList() {
  return axios.post(COINS_LIST);
}

export function getCoinsPrice(options?: any) {
  return axios.post(COINS_PRICE, options);
}

export function logError(error: any) {
  return axios.post(ERROR_LOG, { error });
}
