import React, { useEffect, useState } from "react";
import { WaitButtonSpan } from "../../../../components/WaitButtonSpan";
import { toAbsoluteUrl } from "../../../../_oxapay/helpers";
import { useFormik } from "formik";
import { FailedModalFire } from "../../../services/failedSwalFire";
import { generateToken } from "../../../services/ReCaptchaGenerator";
import {
  confirmSetEmailPassword,
  resendSetEmailPasswordMessageByToken,
} from "../core/_requests";
import { useNavigate, useParams } from "react-router-dom";
import {
  alertsMapConfirmVerifyEmail,
  alertsMapResendEmailByToken,
} from "../../../services/alertsMap";
import { errorHandler } from "../../../services/errorHandler";
import { SuccessModalFire } from "../../../services/successSwalFire";
import { UserModel } from "../core/_models";
import { useAuth } from "../core/Auth";

export const ConfirmSetEmailPassword = () => {
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = useAuth();

  const resendMessage = async () => {
    setLoadingResend(true);
    try {
      const resendToken = await generateToken("set_emailpassword_bytoken");
      const { data: res } = await resendSetEmailPasswordMessageByToken(
        token as string,
        resendToken
      );
      if (res.status === 200 && res.result === true) {
        SuccessModalFire("New message was sent to your telegram account");
      } else if (res.status === 0 && res.errorNum) {
        if (res.errorNum === -20) {
          navigate("/settings");
          FailedModalFire("You set email and password and can't set again");
        } else {
          FailedModalFire(alertsMapResendEmailByToken[res.errorNum.toString()]);
        }
      }
    } catch (error) {
      errorHandler(error as Error);
    } finally {
      setLoadingResend(false);
    }
  };

  const confirmFunction = async () => {
    setLoading(true);
    try {
      const tokenConfirm = await generateToken("confirm_set_emailpassword");
      const { data: res } = await confirmSetEmailPassword(
        token as string,
        tokenConfirm
      );

      if (res.status === 200 && res.result === true) {
        if (currentUser) {
          const updatedUser = {
            ...currentUser,
            submittedEmail: res.submittedEmail,
          };
          setCurrentUser(updatedUser as UserModel);
        }
        navigate("/settings");
        SuccessModalFire(
          "The defined Email & password have been set for your account"
        );
      } else {
        if (res.errorNum === -20) {
          navigate("/settings");
          FailedModalFire("You set email and password and can't set again");
        } else {
          FailedModalFire(alertsMapConfirmVerifyEmail[res.errorNum.toString()]);
        }
      }
    } catch (error) {
      errorHandler(error as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    confirmFunction();
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      confirmFunction();
    },
  });

  return (
    <form className="form w-100 mb-13 p-10" onSubmit={formik.handleSubmit}>
      <div className="text-center mb-10">
        <img
          alt="Logo"
          className="mh-200px"
          src={toAbsoluteUrl("/media/auth/please-verify-your-email.png")}
        />
      </div>
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Confirm setting email & password</h1>
        <div className="fs-6 mb-8">
          <span className="fw-semibold text-gray-500">
            Need new confirmation message?
          </span>
          <button
            className="btn px-1 link-primary fw-bold"
            onClick={resendMessage}
            disabled={loadingResend}
          >
            {loadingResend ? "Please wait" : "Resend"}
          </button>
        </div>
      </div>
      <div className="d-grid mb-10">
        <button
          type="submit"
          className="btn btn-primary w-100"
          disabled={loading}
        >
          {loading ? <WaitButtonSpan /> : "Confirm"}
        </button>
      </div>
    </form>
  );
};
