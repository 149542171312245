import React, { Component, ErrorInfo, ReactNode } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../_oxapay/helpers";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can log the error or perform any additional error handling here
    console.error("Error:", error);
    console.error("Error Info:", errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-column flex-center flex-column-fluid p-10">
            <img
              src={toAbsoluteUrl("/media/illustrations/sketchy-1/18.png")}
              alt=""
              className="mw-100 mb-10 h-lg-450px"
            />
            <h1 className="fw-bold text-center" style={{ color: "#A3A3C7" }}>
              Somethings went wrong!
            </h1>
            <p className="text-gray-600 fs-5 mb-10">
              If you encounter this again, contact with your account manager
            </p>
            <Link to="/" className="btn btn-primary">
              Return Home
            </Link>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
