import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface props {
  text: string;
  className: string;
  substring?: number;
}

export const DescriptionTable = ({
  text,
  className,
  substring = 10,
}: props) => {
  const [convertedString, setConvertedString] = useState("");

  const convertString = (originalString: string) => {
    const maxLength = substring;
    if (text.length > substring) {
      const converted = originalString.substring(0, maxLength) + "...";
      setConvertedString(converted);
    } else {
      setConvertedString(text);
    }
  };

  useEffect(() => {
    convertString(text);
    // eslint-disable-next-line
  }, [text]);

  return (
    <span className={className}>
      {text.length > substring ? (
        <OverlayTrigger placement="top" overlay={<Tooltip>{text}</Tooltip>}>
          <span>{convertedString}</span>
        </OverlayTrigger>
      ) : (
        <span>{text}</span>
      )}
    </span>
  );
};
