import React, { createContext, useState } from "react";
import { WithChildren } from "../../helpers";

interface WebappColorsSheme {
  bgGray200: string;
  bgModal: string;
  bgBackdrop: string;
}

export interface WebappContextModel {
  webappStatus: boolean;
  setWebappStatus: (_webapp: boolean) => void;
  webappColors: WebappColorsSheme;
  setWebappColors: (webappColors: WebappColorsSheme) => void;
}

export const WebappContext = createContext<WebappContextModel>({
  webappStatus: false,
  setWebappStatus: (_webapp: boolean) => {},
  webappColors: {
    bgGray200: "#eff2f5",
    bgModal: "#ffffff",
    bgBackdrop: "#a8a9ab",
  },
  setWebappColors: (_webappColors: WebappColorsSheme) => {},
});

const WebappProvider: React.FC<WithChildren> = ({ children }) => {
  const [webappStatus, setWebappStatus] = useState<boolean>(false);
  const [webappColors, setWebappColors] = useState<WebappColorsSheme>({
    bgGray200: "#eff2f5",
    bgModal: "#ffffff",
    bgBackdrop: "#a8a9ab",
  });

  const value: WebappContextModel = {
    webappStatus,
    setWebappStatus,
    webappColors,
    setWebappColors,
  };

  return (
    <WebappContext.Provider value={value}>{children}</WebappContext.Provider>
  );
};

export default WebappProvider;
