import React from "react";

export const WalletPlaceholder = () => {
  return (
    <div className="w-100 d-flex flex-row justify-content-between align-items-center mb-5">
      <div className="d-flex flex-row">
        <div className="w-45px h-45px rounded bg-secondary shine-sm me-2"></div>
        <div className="d-flex flex-column align-items-start">
          <div className="w-100px h-20px rounded bg-secondary shine-sm mb-1"></div>
          <div className="w-70px h-20px rounded bg-secondary shine-sm"></div>
        </div>
      </div>
      <div className="d-flex flex-column align-items-end">
        <div className="w-50px h-20px rounded bg-secondary shine-sm mb-1"></div>
        <div className="w-70px h-20px rounded bg-secondary shine-sm"></div>
      </div>
    </div>
  );
};
