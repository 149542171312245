import React from "react";

export const ChatSkeleton = () => {
  return (
    <>
      <div className="h-100px rounded-16px overflow-hidden w-50 mt-2 mb-1 me-15 ms-3 bg-message-skeleton backdrop-filter-15px shine"></div>
      <div className="h-150px rounded-16px overflow-hidden w-80 mt-2 mb-1 me-15 ms-3 bg-message-skeleton backdrop-filter-15px shine"></div>
      <div className="h-80px rounded-16px overflow-hidden mt-2 mb-1 me-15 ms-3 bg-message-skeleton backdrop-filter-15px shine"></div>
      <div className="h-200px rounded-16px overflow-hidden w-75 mt-2 mb-1 me-15 ms-3 bg-message-skeleton backdrop-filter-15px shine"></div>
      <div className="h-150px rounded-16px overflow-hidden mt-2 mb-1 me-15 ms-3 bg-message-skeleton backdrop-filter-15px shine"></div>
      <div className="h-100px rounded-16px overflow-hidden w-75 mt-2 mb-1 me-15 ms-3 bg-message-skeleton backdrop-filter-15px shine"></div>
      <div className="h-80px rounded-16px overflow-hidden mt-2 mb-1 me-15 ms-3 bg-message-skeleton backdrop-filter-15px shine"></div>
      <div className="h-200px rounded-16px overflow-hidden w-75 mt-2 mb-1 me-15 ms-3 bg-message-skeleton backdrop-filter-15px shine"></div>
      <div className="h-150px rounded-16px overflow-hidden mt-2 mb-1 me-15 ms-3 bg-message-skeleton backdrop-filter-15px shine"></div>
    </>
  );
};
