import { createContext, useState, ReactNode } from "react";

export interface GeneralApi {
  id: string;
  name: string;
  limit: {
    day: number;
    transaction: number;
  };
  allowedIps: string[];
  apiKey: string;
  date: string;
}

interface GeneralApiPage {
  page: number;
  pages: number;
}

export interface GeneralApiContextModel {
  generalApi?: GeneralApi[];
  setGeneralApi: (generalApi: GeneralApi[]) => void;
  generalApiPage: GeneralApiPage;
  setGeneralApiPage: (generalApiPage: GeneralApiPage) => void;
  generalApiSearch: string;
  setGeneralApiSearch: (generalApiSearch: string) => void;
}

export const GeneralApiContext = createContext<GeneralApiContextModel>({
  generalApi: undefined,
  setGeneralApi: () => {},
  generalApiPage: {
    page: 1,
    pages: 0,
  },
  setGeneralApiPage: () => {},
  generalApiSearch: "",
  setGeneralApiSearch: () => {},
});

interface GeneralApiProviderProps {
  children: ReactNode;
}

const GeneralApiProvider: React.FC<GeneralApiProviderProps> = ({
  children,
}) => {
  const [generalApi, setGeneralApi] = useState<GeneralApi[] | undefined>(
    undefined
  );
  const [generalApiPage, setGeneralApiPage] = useState({
    page: 1,
    pages: 0,
  });
  const [generalApiSearch, setGeneralApiSearch] = useState("");

  return (
    <GeneralApiContext.Provider
      value={{
        generalApi,
        setGeneralApi,
        generalApiPage,
        setGeneralApiPage,
        generalApiSearch,
        setGeneralApiSearch,
      }}
    >
      {children}
    </GeneralApiContext.Provider>
  );
};

export default GeneralApiProvider;
