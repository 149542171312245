import React, { useContext, useEffect } from "react";
import { WebappModalContext } from "../../../_oxapay/layout/core/WebappModalContext";
import { Warning } from "../Lotties/Warning";

interface props {
  title?: string;
  content: string;
  mainButtonText?: string;
  closeWebapp?: boolean;
}
export const WebappFailAlert = ({
  title = "Oops!",
  content,
  mainButtonText = "Got it",
  closeWebapp = false,
}: props) => {
  const { setShowModal, setComponent } = useContext(WebappModalContext);
  useEffect(() => {
    const WebappFn = (window as any).Telegram.WebApp;
    WebappFn.HapticFeedback?.notificationOccurred("error");
    WebappFn.MainButton.setText(mainButtonText)
      .setParams({ color: "#3478f6" })
      .show()
      .onClick(() => {
        closeWebapp && WebappFn?.close();
        setShowModal(false);
        setComponent(<></>);
      });

    return () => {
      WebappFn.MainButton.offClick(() => {
        closeWebapp && WebappFn?.close();
        setShowModal(false);
        setComponent(<></>);
      }).hide();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center flex-column align-items-center">
        <div className="w-150px h-150px mb-12">
          <Warning loop />
        </div>
        <h1 className="mb-3 text-center">{title}</h1>
        <p className="fs-5 fw-400 mw-250px text-center">{content}</p>
      </div>
    </>
  );
};
