import React, { ReactNode } from "react";
import { Slide, toast } from "react-toastify";
import { errorHandler } from "../../app/services/errorHandler";

interface Props {
  children: ReactNode;
  text: string;
  onCopy: () => void;
}

export const WebappTextCopy = ({ children, text, onCopy }: Props) => {
  const copyToClipboard = async () => {
    const WebappFn = (window as any).Telegram.WebApp;

    try {
      await navigator.clipboard.writeText(text);
      onCopy();
      WebappFn.HapticFeedback?.notificationOccurred("success");
    } catch (error) {
      // Clipboard API writeText failed, falling back to document.execCommand for compatibility
      try {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        onCopy();
        WebappFn?.HapticFeedback.notificationOccurred("success");
      } catch (fallbackError) {
        // Unable to copy using Clipboard API and document.execCommand
        errorHandler(fallbackError as Error);
        toast.info("Clipboard copy failed", {
          toastId: "copyFailed",
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          theme: "dark",
          closeButton: true,
          closeOnClick: true,
          draggable: true,
          transition: Slide,
          icon: false,
        });
      }
    }
  };

  return (
    <div className="cursor-pointer" onClick={() => copyToClipboard()}>
      {children}
    </div>
  );
};
