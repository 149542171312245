import React, { useContext, useEffect, useState } from "react";
import { WalletWebappSwapContext } from "../../app/Core/WalletWebappSwapContext";
import { errorHandler } from "../../app/services/errorHandler";
import { useRequest } from "../../app/services/userHttpRequests";
import CoinCarousel from "../CoinCarousel";
import { ValuesModel } from "../SwapStepOne";
import { WalletWebappSwapSkeleton } from "./WalletWebappSwapSkeleton";
import { WalletWebappSwapStepOne } from "./WalletWebappSwapStepOne";
import { WalletWebappSwapStepTwo } from "./WalletWebappSwapStepTwo";
import { WebappSwapPairNames } from "./WebappSwapPairNames";

interface PairModel {
  fromCurrency: string | undefined;
  toCurrency: string | undefined;
}

export const WalletWebappSwapContent = () => {
  const [step, setStep] = useState(0);
  const [values, setValues] = useState<ValuesModel>();
  const [pair, setPair] = useState<PairModel | undefined>();
  const [pairsLoading, setPairsLoading] = useState(true);
  const { pairsData, setPairsData } = useContext(WalletWebappSwapContext);
  const [coinsList, setCoinsList] = useState<string[]>([]);
  const [resetFormTrigger, setResetFormTrigger] = useState(0);
  const { swapPairs } = useRequest();

  const findIndex = (targetCurrency: string) => {
    return coinsList.indexOf(targetCurrency);
  };

  useEffect(() => {
    swapPairs()
      .then((res) => {
        if (res.status === 200) {
          setPairsData(res.data);
          setCoinsList(Object.keys(res.data));
          setPairsLoading(false);
        }
      })
      .catch((error) => {
        errorHandler(error as Error);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="pt-5">
        <div className="w-100">
          {pair?.fromCurrency && pair.toCurrency ? (
            <>
              <div className="position-relative h-115px mt-5 mb-3">
                <CoinCarousel
                  coinsList={coinsList}
                  activeIndex={findIndex(pair.toCurrency)}
                  className="position-absolute w-115px rotate-90"
                  coinWidth="w-115px"
                  style={{ left: "43%" }}
                />
                <CoinCarousel
                  coinsList={coinsList}
                  activeIndex={findIndex(pair.fromCurrency)}
                  className="position-absolute w-115px rotate-90"
                  coinWidth="w-115px"
                  style={{ left: "25%" }}
                />
              </div>
              <WebappSwapPairNames
                fromCurrency={pair?.fromCurrency}
                toCurrency={pair?.toCurrency}
              />
            </>
          ) : (
            <div className="mb-10 mw-300px m-auto mt-12 mb-16">
              <p className="fw-600 fs-2 mb-3">How to Swap?</p>
              <p className="text-gray-600 fs-6 lh-2">
                Select the currency you wish to convert, designate the
                destination currency along with your desired amount, then click
                "Swap." Confirm the details, and receive the destination
                currency instantly.
              </p>
            </div>
          )}
        </div>
        {step === 0 && (
          <>
            {pairsLoading || !pairsData ? (
              <WalletWebappSwapSkeleton />
            ) : (
              <WalletWebappSwapStepOne
                values={values}
                resetFormTrigger={resetFormTrigger}
                pairsData={pairsData}
                fromCurrencyChange={(value) =>
                  setPair((prevPair) => ({
                    ...prevPair,
                    fromCurrency: value,
                    toCurrency: prevPair?.toCurrency,
                  }))
                }
                toCurrencyChange={(value) =>
                  setPair((prevPair) => ({
                    ...prevPair,
                    toCurrency: value,
                    fromCurrency: prevPair?.fromCurrency,
                  }))
                }
                onConfirm={(values) => {
                  setValues(values);
                  setStep(1);
                }}
              />
            )}
          </>
        )}
        {step === 1 && (
          <>
            <WalletWebappSwapStepTwo
              fromAmount={values?.fromAmount ?? ""}
              toAmount={values?.toAmount ?? ""}
              fromCurrency={values?.fromCurrency ?? ""}
              toCurrency={values?.toCurrency ?? ""}
              onBack={() => {
                setStep(0);
              }}
              resetForm={() => {
                setValues(undefined);
                setStep(0);
                setResetFormTrigger(resetFormTrigger + 1);
              }}
            />
          </>
        )}
      </div>
    </>
  );
};
