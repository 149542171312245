import React, { useContext, useEffect } from "react";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { Collapse, OverlayTrigger, Popover } from "react-bootstrap";
import { useRequest } from "../../app/services/userHttpRequests";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import {
  alertsMapAddAddressbook,
  alertsMapEditAddressbook,
} from "../../app/services/alertsMap";
import { WebappInput } from "./WebappInput";
import { WebappModalContext } from "../../_oxapay/layout/core/WebappModalContext";
import { WebappFailAlert } from "./AlertsModal/WebappFailAlert";
import { ShowAlertContext } from "../../_oxapay/layout/core/ShowAlertContext";

interface props {
  address?: string;
  onSuccess?: () => void;
  updateFields?: {
    id: string;
    name: string;
    address: string;
    whitelist: boolean;
  };
}
export const WebappAddressbookForm = ({
  onSuccess,
  address,
  updateFields,
}: props) => {
  const { setAlertIcon, setAlertMessage, setShowAlert } =
    useContext(ShowAlertContext);
  const [disableUpdate, setDisableUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requireTwoFa, setRequireTwoFa] = useState(false);
  const { addAddressbook, editAddressbook } = useRequest();
  const { setAddressbook } = useContext(AppContext);
  const { showModal, setShowModal, setComponent } =
    useContext(WebappModalContext);

  const formik = useFormik({
    initialValues: {
      name: updateFields ? updateFields.name : "",
      address: updateFields ? updateFields.address : "",
      whitelist: updateFields ? updateFields.whitelist : false,
      twoFa: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Name must be at least 3 characters")
        .max(30, "Name must be less than 30 characters")
        .required("Name is required"),
      address: Yup.string()
        .min(3, "Address must be at least 10 characters")
        .required("Address is required"),
      twoFa: requireTwoFa
        ? Yup.number()
            .typeError("Authentication code must be a number")
            .required("Two-factor authentication code is required")
        : Yup.number().typeError("Authentication code must be a number"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      if (updateFields) {
        editAddressbook(
          updateFields.id,
          values.name,
          values.address,
          values.whitelist,
          values.twoFa
        ).then((res) => {
          if (res.status === 200 && res.result === true) {
            setAddressbook(res.data);
            setAlertIcon("check");
            setAlertMessage("Address was edited successful");
            setShowAlert(true);
            onSuccess && onSuccess();
            resetForm();
            setLoading(false);
          } else if (res.status === 0) {
            setShowModal(true);
            setComponent(
              <WebappFailAlert
                content={alertsMapEditAddressbook[res.errorNum.toString()]}
              />
            );

            setLoading(false);
          }
        });
      } else {
        addAddressbook(
          values.name,
          values.address,
          values.whitelist,
          values.twoFa
        ).then((res) => {
          if (res.status === 200 && res.result === true) {
            setAddressbook(res.data);
            setAlertIcon("check");
            setAlertMessage("Address was added successful");
            setShowAlert(true);
            onSuccess && onSuccess();
            resetForm();
            setLoading(false);
          } else if (res.status === 0) {
            setShowModal(true);
            setComponent(
              <WebappFailAlert
                content={alertsMapAddAddressbook[res.errorNum.toString()]}
              />
            );
            setLoading(false);
          }
        });
      }
    },
  });

  useEffect(() => {
    if (address !== undefined) {
      formik.setFieldValue("address", address);
    }
    // eslint-disable-next-line
  }, [address]);

  useEffect(() => {
    setRequireTwoFa(formik.values.whitelist);
    // eslint-disable-next-line
  }, [formik.values.whitelist]);

  useEffect(() => {
    if (
      updateFields &&
      updateFields?.name === formik.values.name &&
      updateFields?.address === formik.values.address &&
      updateFields?.whitelist === formik.values.whitelist
    ) {
      setDisableUpdate(true);
    } else {
      setDisableUpdate(false);
    }
  }, [updateFields, formik.values]);

  const WebappFn = (window as any)?.Telegram?.WebApp;

  useEffect(() => {
    if (!showModal) {
      if (disableUpdate) {
        WebappFn?.MainButton?.disable();
      } else {
        WebappFn?.MainButton?.enable();
      }

      if (loading) {
        WebappFn?.MainButton?.show()
          .setText("Please wait")
          .offClick(formik.handleSubmit)
          .showProgress(true)
          .disable();
      } else {
        if (disableUpdate) {
          WebappFn?.MainButton?.show()
            .setText(updateFields ? "Update" : "Add Address")
            .onClick(formik.handleSubmit)
            .hideProgress()
            .disable();
        } else {
          WebappFn?.MainButton?.show()
            .setText(updateFields ? "Update" : "Add Address")
            .onClick(formik.handleSubmit)
            .hideProgress()
            .enable();
        }
      }
    }
    return () => {
      WebappFn?.MainButton?.hide()
        .offClick(formik.handleSubmit)
        .hideProgress()
        .enable();
    };
    // eslint-disable-next-line
  }, [loading, showModal, disableUpdate]);

  return (
    <>
      <div className="mb-7">
        <WebappInput
          name="name"
          label="Name"
          type="text"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.name}
          autoComplete="off"
          placeholder={"Name"}
          error={formik.errors.name && formik.touched.name ? true : false}
          clear={() => formik.setFieldValue("name", "")}
        />
        {formik.errors.name && formik.touched.name && (
          <p className="text-danger fw-semibold ms-3 fs-5 mt-1 mb-0">
            {formik.errors.name}
          </p>
        )}
      </div>

      <div className="mb-7">
        <WebappInput
          qrCode
          onGetData={(value) => formik.setFieldValue("address", value)}
          name="address"
          label="Address"
          type="text"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.address}
          autoComplete="off"
          placeholder={"Address"}
          error={formik.errors.address && formik.touched.address ? true : false}
          clear={() => formik.setFieldValue("address", "")}
        />
        {formik.errors.address && formik.touched.address && (
          <p className="text-danger fw-semibold ms-3 fs-5 mt-1 mb-0">
            {formik.errors.address}
          </p>
        )}
        <div className="mt-4">
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input w-20px h-20px"
              type="checkbox"
              name={`whitelist`}
              checked={formik.values.whitelist}
              onChange={formik.handleChange}
            />
            <label className="form-check-label">
              Set as whitelist address{" "}
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="top"
                overlay={
                  <Popover>
                    <Popover.Body>
                      Once an address is verfied as whitelist, you can transfer
                      assets to it without the need for entering any
                      confirmation or two-factor authentication codes.
                    </Popover.Body>
                  </Popover>
                }
              >
                <span className="badge badge-circle badge-sm badge-outline badge-primary cursor-pointer ms-1">
                  ?
                </span>
              </OverlayTrigger>
            </label>
          </div>
        </div>
      </div>

      <Collapse in={formik.values.whitelist}>
        <div className="w-100 mb-7">
          <WebappInput
            label="2-FA verification code"
            type="number"
            inputMode="numeric"
            name="twoFa"
            placeholder="Code"
            autoComplete="off"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.twoFa}
            error={formik.errors.twoFa && formik.touched.twoFa ? true : false}
            clear={() => formik.setFieldValue("twoFa", "")}
          />
          {formik.errors.twoFa && formik.touched.twoFa && (
            <p className="text-danger fw-semibold fs-5 ms-3 mt-1 mb-0">
              {formik.errors.twoFa}
            </p>
          )}
        </div>
      </Collapse>
    </>
  );
};
