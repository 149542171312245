import React from "react";

interface props {
  className?: string;
  value: string;
  onChange: (val: string) => void;
  valueLabel?: boolean;
  min?: string;
  max?: string;
  step?: string;
  alertValue?: string;
  alertMessage?: string;
}

export const Slider = ({
  className,
  value,
  onChange,
  valueLabel = false,
  min = "0",
  max = "100",
  step = "0.01",
  alertValue,
  alertMessage,
}: props) => {
  return (
    <>
      {valueLabel && (
        <h3 className="text-dark fw-bold text-center">{value}%</h3>
      )}
      <div className={`position-relative ${className}`}>
        <input
          id="range-input"
          type="range"
          onChange={(event) => onChange(event.target.value)}
          value={value}
          min={min}
          max={max}
          step={step}
        />
      </div>
      {alertValue && Number(value) > Number(alertValue) && (
        <div className="mt-3 py-4 px-4 rounded bg-light-warning fade-down">
          <p className="m-0 text-gray">{alertMessage}</p>
        </div>
      )}
    </>
  );
};
