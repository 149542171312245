import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { errorHandler } from "../../app/services/errorHandler";
import { DateTime, formatAmount } from "../../app/services/functionServices";
import { homeRecentTxs } from "../../app/services/intervalManage";
import { useRequest } from "../../app/services/userHttpRequests";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { ModalContext } from "../../_oxapay/layout/core/ModalContext";
import { AmountCurrency } from "../AmountCurrency";
import { HomeTxsFilterCard } from "../HomeTxsFilterCard";
import { TablePlaceholder } from "../TablePlaceholder";
import { TableSearch } from "../TableSearch";
import { TablesWidget } from "../TableWidget";
import { TxStatus } from "../TxStatus";

export interface transactions {
  id: string;
  type: string;
  amount: string;
  currency: string;
  date: string;
  status: string;
  confirm?: string;
  confirmRequired?: string;
  txHash?: string;
  fee?: string;
  txUrl?: string;
  note?: string;
  receiverAddress?: string;
}

export const HomeTxsTable = () => {
  const { coinsListMap } = useContext(AppContext);
  const [trasnactionsFetching, setTrasnactionsFetching] = useState(true);
  const [transactions, setTransactions] = useState<transactions[]>();
  const [search, setSearch] = useState("");
  const { setShowModal, setModalWidth, setComponent } =
    useContext(ModalContext);
  const [trasnactionsPage, setTransactionsPage] = useState({
    page: 1,
    pages: 0,
  });
  const [filter, setFilter] = useState({
    type: "",
    coin: [],
    start: "",
    end: "",
  });
  const { homeTransactions } = useRequest();

  useEffect(() => {
    setTrasnactionsFetching(true);
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchTxs = async () => {
      try {
        const response = await homeTransactions(
          search,
          trasnactionsPage.page,
          filter,

          {
            signal,
          }
        );
        if (response.status === 200) {
          const TransactionsData = response;
          setTransactions(TransactionsData.data);
          setTransactionsPage(TransactionsData.meta);
          setTrasnactionsFetching(false);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };

    if (trasnactionsPage.page === 1) {
      fetchTxs();
      const interval = setInterval(fetchTxs, homeRecentTxs);

      return () => {
        controller.abort(); // Cancel the fetch request
        clearInterval(interval); // Clean up the interval on component unmount
      };
    } else {
      fetchTxs();

      return () => {
        controller.abort(); // Cancel the fetch request
      };
    }
    // eslint-disable-next-line
  }, [trasnactionsPage.page, filter, search]);

  return (
    <TablesWidget
      loading={trasnactionsFetching}
      noData={
        transactions?.length === 0 && !trasnactionsFetching ? true : false
      }
      searchInput={
        <TableSearch
          value={search}
          onChange={() => setTrasnactionsFetching(true)}
          onDebouncedChange={(searchContent) => {
            setSearch(searchContent);
            setTransactionsPage({ ...trasnactionsPage, page: 1 });
          }}
          searchPlaceholder={"Track ID, Hash, Address, Note..."}
        />
      }
      activePage={trasnactionsPage.page}
      pages={trasnactionsPage.pages}
      pageChange={(page) =>
        setTransactionsPage({ ...trasnactionsPage, page: page })
      }
      title="Recent transactions"
      className="mb-5 mb-xl-10"
      tableHead={["Type", "Amount", "Status", "Date", "Detail"]}
      size={[null, null, null, null]}
      filter={true}
      filterCard={
        <HomeTxsFilterCard
          onChange={(newFilter) => {
            if (JSON.stringify(newFilter) !== JSON.stringify(filter)) {
              setFilter(newFilter);
              setTrasnactionsFetching(true);
            }
          }}
        />
      }
    >
      {trasnactionsFetching === true && <TablePlaceholder config={[10, 5]} />}
      {!trasnactionsFetching &&
        transactions !== undefined &&
        transactions.map((data) => (
          <tr key={data.id}>
            <td>
              <span
                className={`badge ${
                  data.type === "Send" || data.type === "Payout"
                    ? "badge-light-danger"
                    : "badge-light-dark"
                } fw-bold fs-6`}
              >
                {data.type}
              </span>
            </td>
            <td>
              <AmountCurrency
                color="dark"
                amount={data.amount}
                currency={data.currency}
              />
            </td>
            <td>
              <TxStatus
                status={data.status}
                addon={
                  data?.confirm &&
                  data?.confirmRequired &&
                  ` (${data.confirm}/${data.confirmRequired})`
                }
              />
            </td>
            <td>
              <span className="text-gray-600 fw-bold fs-6">
                {DateTime(parseInt(data.date))}
              </span>
            </td>
            <td>
              <button
                onClick={() => {
                  setShowModal(true);
                  setModalWidth(600);
                  setComponent(
                    <div className="px-5">
                      <div className="bg-light-secondary ps-6 py-4 rounded mb-7">
                        {data.type !== "Merchant (Static Wallet)" && (
                          <>
                            <p className="m-0 fs-4 text-dark">Track ID</p>
                            <p className="m-0 fs-6 text-primary">
                              {data.id || "-"}
                            </p>
                            {(data?.receiverAddress || data?.txHash) && (
                              <hr className="border-gray-500 my-3" />
                            )}
                          </>
                        )}
                        {data?.receiverAddress && (
                          <>
                            <p className="m-0 fs-4 text-dark">
                              Receiver Address
                            </p>
                            <p className="m-0 fs-6 text-primary">
                              {data?.receiverAddress}
                            </p>
                            <hr className="border-gray-500 my-3" />
                          </>
                        )}
                        {data?.fee !== undefined &&
                          (data?.type === "Send" ||
                            data?.type === "Payout") && (
                            <>
                              <p className="m-0 fs-4 text-dark">Fee</p>
                              <p className="m-0 fs-6 text-primary">
                                {data.fee === "0"
                                  ? 0
                                  : formatAmount(
                                      parseFloat(data.fee),
                                      coinsListMap[data.currency]
                                        ?.displayPrecision
                                    )}{" "}
                                {data.currency}
                              </p>
                              <hr className="border-gray-500 my-3" />
                            </>
                          )}
                        {data?.txHash && (
                          <>
                            <p className="m-0 fs-4 text-dark">
                              Transaction Hash
                            </p>

                            {data?.txUrl ? (
                              <a
                                href={data?.txUrl}
                                className="m-0 fs-6 text-primary cursor-pointer flex-wrap-overflow"
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {data?.txHash}
                              </a>
                            ) : (
                              <span className="m-0 fs-6 text-primary cursor-pointer flex-wrap-overflow">
                                {data?.txHash || "-"}
                              </span>
                            )}
                            {data?.note && (
                              <hr className="border-gray-500 my-3" />
                            )}
                          </>
                        )}

                        {data?.note && (
                          <>
                            <p className="m-0 fs-4 text-dark">Note</p>
                            <p className="m-0 fs-6 text-primary">
                              {data?.note || "-"}
                            </p>
                          </>
                        )}
                        {(data.type === "Send" ||
                          data.type === "Payout" ||
                          data.type === "Merchant (Static Wallet)" ||
                          data.type === "Commission") &&
                          !data?.txHash &&
                          !data.fee &&
                          !data.note && (
                            <div className="d-flex justify-content-center align-items-center my-20">
                              <img
                                src={toAbsoluteUrl(
                                  "/media/icons/duotune/files/fil012.svg"
                                )}
                                className="w-30px opacity-20 my-10"
                                alt="no-data"
                              />
                              <p className="ms-2 my-0 fw-semibold text-gray-500">
                                No details found
                              </p>
                            </div>
                          )}
                      </div>
                    </div>
                  );
                }}
                className="btn btn-link btn-color-primary btn-active-color-primary me-5 mb-2"
              >
                Details
              </button>
            </td>
          </tr>
        ))}
    </TablesWidget>
  );
};
