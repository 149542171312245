import React, { useState } from "react";
import BalanceProvider from "../_oxapay/layout/core/BlanceContext";
import { ReceiveAsset } from "./ReceiveAsset";
import { SendAsset } from "./SendAsset";
import { WalletAssetsList } from "./WalletAssetsList";
import { WalletTxsList } from "./WalletTxsList";

export const WalletTabs = () => {
  const [activeTab, setActiveTab] = useState<number>(1);
  return (
    <>
      <div className="pb-9 wallet-tabs">
        <ul className="nav nav-pills nav-pills-custom row position-relative mx-0">
          <li className="nav-item col-3 mx-0 p-0" role="presentation">
            <a
              className="nav-link d-flex justify-content-center w-100 border-0 h-100 active"
              data-bs-toggle="pill"
              href="#assets"
              aria-selected="true"
              role="tab"
              onClick={() => setActiveTab(1)}
            >
              <span className="nav-text text-gray-800 fw-bold fs-6 mb-3">
                Assets
              </span>

              <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded"></span>
            </a>
          </li>

          <li className="nav-item col-3 mx-0 px-0" role="presentation">
            <a
              className="nav-link d-flex justify-content-center w-100 border-0 h-100"
              data-bs-toggle="pill"
              href="#send"
              aria-selected="false"
              role="tab"
            >
              <span className="nav-text text-gray-800 fw-bold fs-6 mb-3">
                Send
              </span>

              <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded"></span>
            </a>
          </li>

          <li className="nav-item col-3 mx-0 px-0" role="presentation">
            <a
              className="nav-link d-flex justify-content-center w-100 border-0 h-100"
              data-bs-toggle="pill"
              href="#receive"
              aria-selected="false"
              role="tab"
            >
              <span className="nav-text text-gray-800 fw-bold fs-6 mb-3">
                Receive
              </span>

              <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded"></span>
            </a>
          </li>

          <li className="nav-item col-3 mx-0 px-0" role="presentation">
            <a
              className="nav-link d-flex justify-content-center w-100 border-0 h-100"
              data-bs-toggle="pill"
              href="#transactions"
              aria-selected="false"
              role="tab"
              onClick={() => setActiveTab(4)}
            >
              <span className="nav-text text-gray-800 fw-bold fs-6 mb-3">
                History
              </span>

              <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded"></span>
            </a>
          </li>

          <span className="position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded"></span>
        </ul>
      </div>

      <BalanceProvider>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane show active" id="assets" role="tabpanel">
            {activeTab === 1 && <WalletAssetsList />}
          </div>
          <div className="tab-pane fade" id="send" role="tabpanel">
            <SendAsset />
          </div>
          <div className="tab-pane fade" id="receive" role="tabpanel">
            <ReceiveAsset />
          </div>
          <div className="tab-pane fade" id="transactions" role="tabpanel">
            {activeTab === 4 && <WalletTxsList />}
          </div>
        </div>
      </BalanceProvider>
    </>
  );
};
