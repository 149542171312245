import React, { useContext, useState } from "react";
import { Card } from "../../components/Card";
import { SetEmailForm } from "../../components/forms/SetEmailForm";
import { ChangePasswordForm } from "../../components/forms/ChangePasswordForm";
import { DisableTwoFactorForm } from "../../components/forms/DisableTwoFactorForm";
import { ProfileSettingsForm } from "../../components/forms/ProfileSettingsForm";
import { TwoFactorForm } from "../../components/forms/TwoFactorForm";
import { IconSecureCheck } from "../../components/staticIcons/IconSecureCheck";
import { DisableSidebar, PageTitle } from "../../_oxapay/layout/core";
import { ModalContext } from "../../_oxapay/layout/core/ModalContext";
import { useAuth } from "../modules/auth";
import { ActivityLogs } from "../../components/ActivityLogs";

export const Profile = () => {
  const [setEmail, setSetEmail] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const { setComponent, setModalWidth, setShowModal } =
    useContext(ModalContext);
  const { currentUser } = useAuth();
  return (
    <DisableSidebar>
      <PageTitle breadcrumbs={[]}>Profile</PageTitle>
      <Card cardHeaderTitle="Profile Details">
        <ProfileSettingsForm />
      </Card>
      <Card cardHeaderTitle="Sign-in settings">
        {currentUser?.loginType === "telegram" && !currentUser?.submittedEmail && (
          <>
            {!setEmail && (
              <div className="d-flex flex-wrap align-items-center">
                <div>
                  <div className="fs-6 fw-bold mb-1">
                    Submitted Email Address
                  </div>
                  <div className="fw-semibold text-gray-600">-</div>
                </div>
                <div className="ms-auto">
                  <button
                    type="button"
                    className="btn btn-light btn-active-light-primary"
                    onClick={() => setSetEmail(true)}
                  >
                    Set Email & Password
                  </button>
                </div>
              </div>
            )}
            {setEmail && <SetEmailForm onClick={() => setSetEmail(false)} />}
            <div className="separator separator-dashed my-6"></div>
          </>
        )}

        {currentUser?.loginType === "email" && (
          <>
            {!changePassword && (
              <div className="d-flex flex-wrap align-items-center mb-10">
                <div>
                  <div className="fs-6 fw-bold mb-1">Password</div>
                  <div className="fw-semibold text-gray-600">************</div>
                </div>
                <div className="ms-auto">
                  <button
                    type="button"
                    className="btn btn-light btn-active-light-primary"
                    onClick={() => setChangePassword(true)}
                  >
                    Change Password
                  </button>
                </div>
              </div>
            )}
            {changePassword && (
              <ChangePasswordForm onClick={() => setChangePassword(false)} />
            )}
          </>
        )}
        {currentUser?.twoFa ? (
          <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
            <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
              <IconSecureCheck />
            </span>
            <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
              <div className="mb-3 mb-md-0 fw-semibold">
                <h4 className="text-gray-900 fw-bold">
                  Disable Two-factor authentication
                </h4>
                <div className="fs-6 text-gray-700 pe-7">
                  Two-factor authentication adds an extra layer of security to
                  your account. To log in, in addition you'll need to provide a
                  6 digit code
                </div>
              </div>
              <button
                type="button"
                className="btn btn-warning px-6 align-self-center text-nowrap"
                onClick={() => {
                  setModalWidth(450);
                  setComponent(<DisableTwoFactorForm />);
                  setShowModal(true);
                }}
              >
                Disable
              </button>
            </div>
          </div>
        ) : (
          <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">
            <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
              <IconSecureCheck />
            </span>
            <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
              <div className="mb-3 mb-md-0 fw-semibold">
                <h4 className="text-gray-900 fw-bold">Secure Your Account</h4>
                <div className="fs-6 text-gray-700 pe-7">
                  Two-factor authentication adds an extra layer of security to
                  your account. To log in, in addition you'll need to provide a
                  6 digit code
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary px-6 align-self-center text-nowrap"
                onClick={() => {
                  setModalWidth(650);
                  setComponent(<TwoFactorForm />);
                  setShowModal(true);
                }}
              >
                Enable
              </button>
            </div>
          </div>
        )}
      </Card>
      <ActivityLogs />
    </DisableSidebar>
  );
};
