import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { IconCross } from "../staticIcons/IconCross";
import { IconBackArrow } from "../staticIcons/IconBackArrow";
import { AddressBookList } from "./AddressBookList";

interface props {
  showAddressBook: boolean;
  onClose: (address?: string) => void;
}
export const AddressBookModal = ({ showAddressBook, onClose }: props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onCloseHandler = () => {
    onClose();
    setActiveIndex(0);
  };

  return (
    <Modal
      show={showAddressBook}
      onHide={onCloseHandler}
      size={"md" as any}
      centered
    >
      <Modal.Header className="border-none pb-0">
        <div className="w-100 d-flex justify-content-between align-items-center">
          <div className="d-flex flex-row card-header align-items-center">
            {(activeIndex === 1 || activeIndex === 2) && (
              <button
                className="btn ps-0 pe-2 svg-icon svg-icon-muted svg-icon-2hx backicon"
                onClick={() => setActiveIndex(0)}
              >
                <IconBackArrow />
              </button>
            )}
            <h4 className="modal-title">
              {activeIndex === 0 ? "Address Book" : "Address Book Form"}
            </h4>
          </div>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            onClick={onCloseHandler}
          >
            <IconCross />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <AddressBookList
          onClose={(address) => onClose(address)}
          changePage={activeIndex}
          activePage={(active) => setActiveIndex(active)}
        />
      </Modal.Body>
    </Modal>
  );
};
