import React, { useContext } from "react";
import { formatAmount } from "../app/services/functionServices";
import { toAbsoluteUrl } from "../_oxapay/helpers";
import { AppContext } from "../_oxapay/layout/core/AppContext";

interface props {
  amount: string;
  currency: string;
  color?: string;
}
export const AmountCurrency = ({
  amount,
  currency,
  color = "text-gray-600",
}: props) => {
  const { coinsListMap } = useContext(AppContext);
  return (
    <div className="symbol symbol-35px">
      <img
        className="me-2"
        alt={currency}
        src={toAbsoluteUrl(
          `/media/svg/coins/${coinsListMap[currency]?.slug}.svg`
        )}
      />
      <span className={`fw-bold ${color} fs-6`}>
        {formatAmount(
          parseFloat(amount),
          coinsListMap[currency]?.displayPrecision
        )}{" "}
        {currency}
      </span>
    </div>
  );
};
