import clsx from "clsx";
import React from "react";
import { KTSVG } from "../../_oxapay/helpers";

export const IconDonation = () => {
  return (
    <span className={clsx("symbol-label", `bg-light-warning`)}>
      <KTSVG
        path={`/media/icons/duotune/ecommerce/ecm007.svg`}
        className={`svg-icon-1 svg-icon-warning`}
      />
    </span>
  );
};
