import clsx from "clsx";
import React from "react";
import { KTSVG } from "../../_oxapay/helpers";

export const IconSend = () => {
  return (
    <span className={clsx("symbol-label", `bg-light-danger`)}>
      <KTSVG
        path={`/media/icons/duotune/arrows/arr003.svg`}
        className={`svg-icon-1 svg-icon-danger`}
      />
    </span>
  );
};
