import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import * as Yup from "yup";
import { ipValidator } from "../../app/services/ipValidator";
import { SuccessModalFire } from "../../app/services/successSwalFire";
import { ListMakerWidget } from "../ListMakerWidget";
import { v4 as uuidv4 } from "uuid";
import { WaitButtonSpan } from "../WaitButtonSpan";
import { useRequest } from "../../app/services/userHttpRequests";
import { ModalContext } from "../../_oxapay/layout/core/ModalContext";
import { PayoutApiContext } from "../../app/Core/PayoutApiContext";
import { errorHandler } from "../../app/services/errorHandler";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import {
  alertsMapEditPayoutApi,
  alertsMapNewPayoutApi,
} from "../../app/services/alertsMap";
import { useAuth } from "../../app/modules/auth";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { TwoFactorForm } from "./TwoFactorForm";

const popoverAllowedIps = (
  <Popover>
    <Popover.Header as="h3">Allowed IPs for transfer (Optional)</Popover.Header>
    <Popover.Body>
      You can set some IPs and permit them to transfer. Any request for transfer
      from other IPs will be failed.
    </Popover.Body>
  </Popover>
);

const popoverDailyLimit = (
  <Popover>
    <Popover.Header as="h3">
      Total daily transfer limit (Optional)
    </Popover.Header>
    <Popover.Body>
      This field is for <strong>more security of your API transfers</strong> and
      if your total daily transfers exceed this amount, the remained
      transactions will be failed.
    </Popover.Body>
  </Popover>
);

const popoverTrLimit = (
  <Popover>
    <Popover.Header as="h3">
      Transfer limit per transaction (Optional)
    </Popover.Header>
    <Popover.Body>
      Transactions with more than this amount will be failed.
    </Popover.Body>
  </Popover>
);

interface PayoutApiModel {
  id: string;
  name: string;
  ips?: string[];
  dailyLimit?: string;
  trLimit?: string;
}

interface props {
  payoutApi?: PayoutApiModel;
}

export const PayoutApiForm = ({ payoutApi }: props) => {
  const { setComponent, setModalWidth, setShowModal } =
    useContext(ModalContext);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const [disableUpdate, setDisableUpdate] = useState(true);
  const { newPayoutApi, editPayoutApi } = useRequest();
  const { setPayoutApi, setPayoutApiPage, setPayoutApiSearch } =
    useContext(PayoutApiContext);
  const formik = useFormik({
    initialValues: {
      name: payoutApi?.name || "",
      ips: payoutApi?.ips?.map((ip) => {
        return {
          id: uuidv4(),
          value: ip,
          error: null,
          onBlur: true,
        };
      }) || [{ id: uuidv4(), value: "", error: null, onBlur: true }],
      dailyLimit: payoutApi?.dailyLimit || "",
      trLimit: payoutApi?.trLimit || "",
      twoFa: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Name must be at least 3 characters")
        .max(30, "Name must be less than 30 characters")
        .required("Name is required"),
      ips: Yup.array()
        .nullable()
        .of(
          Yup.object().shape({
            id: Yup.string(),
            value: Yup.string(),
            error: Yup.string()
              .nullable()
              .test(
                "ipValidator",
                "IP address is invalid",
                (error) => error === null
              ),
            onBlur: Yup.boolean(),
          })
        ),
      dailyLimit: Yup.number().min(
        1,
        "Daily transfer limit must be greater than $1"
      ),
      trLimit: Yup.number().min(
        1,
        "Transfer limit per transaction must be greater than $1"
      ),
      twoFa: Yup.string().required("Two-Factor code is required"),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        if (payoutApi) {
          const res = await editPayoutApi(
            payoutApi.id,
            values.name,
            {
              day: values.dailyLimit,
              transaction: values.trLimit,
            },
            values.ips.filter((ip) => ip.value !== "").map((ip) => ip.value),
            values.twoFa
          );
          if (res.status === 200 && res.result === true) {
            setPayoutApiSearch("");
            setPayoutApi(res.data);
            setPayoutApiPage(res.meta);
            SuccessModalFire("Your Payout API was updated successfully");
            setShowModal(false);
          } else {
            FailedModalFire(alertsMapEditPayoutApi[res.errorNum.toString()]);
          }
        } else {
          const res = await newPayoutApi(
            values.name,
            {
              day: values.dailyLimit,
              transaction: values.trLimit,
            },
            values.ips.filter((ip) => ip.value !== "").map((ip) => ip.value),
            values.twoFa
          );
          if (res.status === 200 && res.result === true) {
            setPayoutApiSearch("");
            setPayoutApi(res.data);
            setPayoutApiPage(res.meta);
            SuccessModalFire("Your payout API key was generated successfully");
            resetForm();
          } else {
            FailedModalFire(alertsMapNewPayoutApi[res.errorNum.toString()]);
          }
        }
      } catch (error) {
        errorHandler(error as Error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (payoutApi) {
      formik.setValues({
        name: payoutApi.name,
        ips: payoutApi.ips?.map((ip) => {
          return {
            id: uuidv4(),
            value: ip,
            error: null,
            onBlur: true,
          };
        }) || [{ id: uuidv4(), value: "", error: null, onBlur: true }],
        dailyLimit: payoutApi?.dailyLimit || "",
        trLimit: payoutApi?.trLimit || "",
        twoFa: "",
      });
    }
    // eslint-disable-next-line
  }, [payoutApi]);

  const payoutApiValues = payoutApi;
  useEffect(() => {
    //

    const formikValues = formik.values.ips
      .map((ip) => ip.value)
      .filter((value) => value !== "");
    const payoutParams = (payoutApiValues?.ips || []).filter(
      (value) => value !== ""
    );
    if (
      payoutApi?.name === formik.values.name &&
      formikValues.every((value) => payoutParams.includes(value)) &&
      payoutParams.every((value) => formikValues.includes(value)) &&
      payoutApi?.dailyLimit === formik.values.dailyLimit &&
      payoutApi?.trLimit === formik.values.trLimit
    ) {
      setDisableUpdate(true);
    } else {
      setDisableUpdate(false);
    }
    // eslint-disable-next-line
  }, [payoutApiValues, formik.values]);

  return (
    <>
      <div className="card h-md-100">
        <div className="card-body d-flex flex-column flex-center">
          {currentUser?.twoFa ? (
            <form className="w-100" onSubmit={formik.handleSubmit}>
              {!payoutApi && (
                <div className="mb-2">
                  <h1 className="fw-semibold text-gray-800 text-left fs-3 mt-5 mb-5">
                    Payout <span className="fw-bolder">API</span>
                  </h1>
                  <p className="text-dark">
                    OxaPay Payout API helps you crypto mass transfer to other
                    OxaPay users automatically (Zero fee in internal payouts).
                    Generate an API and check the documentation.
                  </p>
                </div>
              )}
              <div className="w-100 mb-5">
                <h4 className="fs-5 fw-semibold text-gray-800">Name</h4>
                <input
                  type="text"
                  className={`form-control form-control-solid ${
                    formik.errors.name &&
                    formik.touched.name &&
                    "is-invalid border border-danger"
                  }`}
                  autoComplete="off"
                  name="name"
                  maxLength={30}
                  placeholder="Name [3-30 Characters]"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                {formik.errors.name && formik.touched.name && (
                  <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                    {formik.errors.name}
                  </p>
                )}
              </div>
              <div className="w-100 mb-5">
                <ListMakerWidget
                  title={
                    <>
                      Allowed IPs for transfer
                      {
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          placement="bottom"
                          overlay={popoverAllowedIps}
                        >
                          <span className="badge badge-circle badge-sm badge-outline badge-primary cursor-pointer ms-1">
                            ?
                          </span>
                        </OverlayTrigger>
                      }
                    </>
                  }
                  placeholder="127.0.0.1"
                  addBtnTitle="Add more IP"
                  value={formik.values.ips}
                  onChange={(ips) => formik.setFieldValue("ips", ips)}
                  validator={ipValidator}
                />
              </div>
              <div className="w-100 mb-5">
                <h4 className="fs-5 fw-semibold text-gray-800">
                  Total daily transfer limit
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverDailyLimit}
                  >
                    <span className="badge badge-circle badge-sm badge-outline badge-primary cursor-pointer ms-1">
                      ?
                    </span>
                  </OverlayTrigger>
                </h4>
                <div
                  className={`input-group input-group-solid ${
                    formik.errors.dailyLimit &&
                    formik.touched.dailyLimit &&
                    "border border-danger"
                  }`}
                >
                  <input
                    type="number"
                    className="form-control"
                    autoComplete="off"
                    name="dailyLimit"
                    placeholder="Minimum: 1"
                    step={0.01}
                    min={1}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.dailyLimit}
                  />
                  <span className="input-group-text" id="basic-addon2">
                    USD
                  </span>
                </div>
                {formik.errors.dailyLimit && formik.touched.dailyLimit && (
                  <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                    {formik.errors.dailyLimit}
                  </p>
                )}
              </div>
              <div className="w-100 mb-5">
                <h4 className="fs-5 fw-semibold text-gray-800">
                  Transfer limit per transaction
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={popoverTrLimit}
                  >
                    <span className="badge badge-circle badge-sm badge-outline badge-primary cursor-pointer ms-1">
                      ?
                    </span>
                  </OverlayTrigger>
                </h4>
                <div
                  className={`input-group input-group-solid ${
                    formik.errors.trLimit &&
                    formik.touched.trLimit &&
                    "border border-danger"
                  }`}
                >
                  <input
                    type="number"
                    className="form-control"
                    autoComplete="off"
                    name="trLimit"
                    placeholder="Minimum: 1"
                    step={0.01}
                    min={1}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.trLimit}
                  />
                  <span className="input-group-text" id="basic-addon2">
                    USD
                  </span>
                </div>
                {formik.errors.trLimit && formik.touched.trLimit && (
                  <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                    {formik.errors.trLimit}
                  </p>
                )}
              </div>
              <div className="w-100 mb-10">
                <h4 className="fs-5 fw-semibold text-gray-800">
                  2-FA verification code
                </h4>
                <input
                  type="text"
                  className={`form-control form-control-solid me-3 flex-grow-1 ${
                    formik.errors.twoFa &&
                    formik.touched.twoFa &&
                    "is-invalid border border-danger"
                  }`}
                  name="twoFa"
                  placeholder="Code"
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.twoFa}
                />
                {formik.errors.twoFa && formik.touched.twoFa && (
                  <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                    {formik.errors.twoFa}
                  </p>
                )}
              </div>
              <div className="d-flex align-items-end w-100">
                {payoutApi ? (
                  <button
                    type="submit"
                    className="btn btn-primary fs-3 w-100"
                    disabled={disableUpdate || loading}
                  >
                    {loading ? <WaitButtonSpan /> : "Save API changes"}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary fs-3 w-100"
                    disabled={formik.isSubmitting}
                  >
                    {loading ? <WaitButtonSpan /> : "Generate Payout API key"}
                  </button>
                )}
              </div>
            </form>
          ) : (
            <>
              <div className="text-center">
                <img
                  src={toAbsoluteUrl(`/media/svg/misc/security.svg`)}
                  className="w-150px mb-15"
                  alt="twoFa"
                />
              </div>
              <div className="mb-15">
                <h1 className="fw-semibold text-gray-800 text-left fs-3 mt-5 mb-5">
                  Payout <span className="fw-bolder">API</span>
                </h1>
                <p className="text-dark">
                  OxaPay Payout API helps you crypto mass transfer to other
                  OxaPay users automatically (Zero fee in internal payouts).
                  Generate an API key and check the API documentation.
                  <br />
                  <br />
                  <b>
                    For enhanced security, it is required to enable Two-Factor
                    Authentication to {payoutApi ? "edit" : "generate"} a payout
                    API key.
                  </b>
                </p>
              </div>
              <button
                type="button"
                className="btn btn-primary fs-3 w-100"
                onClick={() => {
                  setModalWidth(650);
                  setComponent(<TwoFactorForm />);
                  setShowModal(true);
                }}
              >
                Enable Two-Factor
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};
