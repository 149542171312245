import React, { useContext, useEffect, useState } from "react";
import { toAbsoluteUrl } from "../_oxapay/helpers";
import { AppContext } from "../_oxapay/layout/core/AppContext";
import { ModalContext } from "../_oxapay/layout/core/ModalContext";
import { CoinsListWidget } from "./CoinsListWidget";
import { ModalBodyTitle } from "./modals/ModalBodyTitle";
interface props {
  acceptedCoins: string[];
}

export const TableBodyCoins = ({ acceptedCoins }: props) => {
  const { setComponent, setModalWidth, setShowModal } =
    useContext(ModalContext);
  const { coinsListMap } = useContext(AppContext);
  const [sortedCoinsList, setSortedCoinsList] = useState<
    string[] | undefined
  >();

  useEffect(() => {
    const sortedCoinsList =
      coinsListMap &&
      acceptedCoins
        .filter((coinSymbol) => coinsListMap[coinSymbol]?.status === true)
        .sort((a, b) => {
          const statusA = coinsListMap[a].depositEnable ? 1 : 0;
          const statusB = coinsListMap[b].depositEnable ? 1 : 0;

          return statusB - statusA; // Sort by status in descending order (true first)
        });

    setSortedCoinsList(sortedCoinsList);
  }, [coinsListMap, acceptedCoins]);

  return (
    <>
      {sortedCoinsList &&
        (sortedCoinsList.length ? (
          <div className="symbol-group symbol-hover flex-nowrap">
            {sortedCoinsList.map(
              (coin, index) =>
                index < 2 && (
                  <div
                    key={index}
                    className={`symbol symbol-35px symbol-circle ${
                      coinsListMap[coin]?.depositEnable === false &&
                      "disable filter-grayscale"
                    }`}
                  >
                    <img
                      alt={coin}
                      src={toAbsoluteUrl(
                        `/media/svg/coins/${coinsListMap[coin]?.slug}.svg`
                      )}
                    />
                  </div>
                )
            )}
            {acceptedCoins.length > 2 && (
              <button className="symbol symbol-35px symbol-circle bg-transparent border-transparent p-0">
                <span
                  className="symbol-label bg-light text-gray-400 fs-8 fw-bold"
                  onClick={() => {
                    setModalWidth(500);
                    setComponent(
                      <>
                        <ModalBodyTitle
                          title={`Accepted coins list`}
                          subTitle={`You can check all of accepted coins list here.`}
                          className="mt-8 mb-15"
                        />
                        <CoinsListWidget coinsList={sortedCoinsList} />
                      </>
                    );
                    setShowModal(true);
                  }}
                >
                  +{sortedCoinsList.length - 2}
                </span>
              </button>
            )}
          </div>
        ) : (
          <span className="text-danger fs-8">
            The accepted coin(s) has been removed from the list of supported
            coins on OxaPay.
          </span>
        ))}
    </>
  );
};
