import React from "react";
import { Card } from "../../components/Card";
import { AddressListForm } from "../../components/forms/AddressListForm";
import { GeneralApiKey } from "../../components/GeneralApiKey";
import { DisableSidebar, PageTitle } from "../../_oxapay/layout/core";

export const AccountSettings = () => {
  return (
    <DisableSidebar>
      <PageTitle breadcrumbs={[]}>Settings</PageTitle>
      <GeneralApiKey />
      <Card cardHeaderTitle="Address List">
        <AddressListForm />
      </Card>
    </DisableSidebar>
  );
};
