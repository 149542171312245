import React from "react";
import { KTSVG } from "../_oxapay/helpers";

interface props {
  onClick: () => void;
}

export const TableDeleteButton = ({ onClick }: props) => {
  return (
    <button
      onClick={onClick}
      className="btn btn-icon btn-bg-light text-hover-primary btn-sm mx-1 w-45px h-45px rounded"
    >
      <KTSVG
        path="/media/icons/duotune/general/gen027.svg"
        className="svg-icon-3"
      />
    </button>
  );
};
