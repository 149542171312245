import { errorHandler } from "./errorHandler";

export const generateToken = async (action: string) => {
  try {
    const token = await window?.grecaptcha?.execute(
      process.env.REACT_APP_SITE_KEY,
      {
        action: action,
      }
    );
    return token;
  } catch (error) {
    errorHandler(error as Error);
  }
};
