import axios from "axios";
import React, { useEffect, useState } from "react";
import { errorHandler } from "../../app/services/errorHandler";
import { refStatsChartInterval } from "../../app/services/intervalManage";
import { useRequest } from "../../app/services/userHttpRequests";
import { ChartsWidget2 } from "../charts/ChartWidget2";

export const RefChartStats = () => {
  const { refStatsChart } = useRequest();
  const [refStats, setRefStats] = useState({
    categories: ["-", "-", "-", "-", "-", "-", "-"],
    data: [0, 0, 0, 0, 0, 0, 0],
  });

  useEffect(() => {
    const controller = new AbortController();

    const fetchChart = async () => {
      try {
        const response = await refStatsChart({ signal: controller.signal });
        if (response.status === 200) {
          const chartData = response.chart;
          setRefStats({
            categories: chartData.categories,
            data: chartData.data,
          });
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };
    fetchChart();
    const interval = setInterval(fetchChart, refStatsChartInterval); // Fetch chart every 10 seconds

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval on component unmount
    };
    // eslint-disable-next-line
  }, []);

  return (
    <ChartsWidget2
      className="card card-flush overflow-hidden"
      chartTitle="Referrals statistics"
      chartSubtitle="The last 7 days referrals"
      height={228}
      type="Referrals"
      categories={refStats.categories}
      data={[
        {
          name: "Referrals",
          data: refStats.data.map(Number),
        },
      ]}
    />
  );
};
