import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_oxapay/helpers";
import { alertsMapResendEmailAuth } from "../../../services/alertsMap";
import { errorHandler } from "../../../services/errorHandler";
import { FailedModalFire } from "../../../services/failedSwalFire";
import { generateToken } from "../../../services/ReCaptchaGenerator";
import { SuccessModalFire } from "../../../services/successSwalFire";
import { useAuth } from "../core/Auth";
import { resendEmailAuth } from "../core/_requests";

export const LoginVerifyEmail = () => {
  const [loading, setLoading] = useState(false);
  const { loginInfo } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (loginInfo === undefined) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  const resendEmail = async () => {
    setLoading(true);
    try {
      const resendToken = await generateToken("verify_email_send");
      const { data: res } = await resendEmailAuth(
        loginInfo?.userAuth as string,
        loginInfo?.password as string,
        resendToken
      );
      if (res.status === 200 && res.result === true) {
        SuccessModalFire("New verification email was sent");
      } else if (res.status === 0 && res.errorNum) {
        FailedModalFire(alertsMapResendEmailAuth[res.errorNum.toString()]);
      }
    } catch (error) {
      errorHandler(error as Error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="form w-100 mb-13 p-10">
      <div className="text-center mb-10">
        <img
          alt="Logo"
          className="mh-200px"
          src={toAbsoluteUrl("/media/auth/please-verify-your-email.png")}
        />
      </div>
      <div className="text-center mb-10">
        <h1 className="text-dark mb-1">Your account is not verified</h1>
        <p className="fs-5 fw-semibold text-gray-500 mt-0 mb-7">
          Check your email to verify your account
        </p>
        <div className="fs-6 mb-8">
          <span className="fw-semibold text-gray-500">
            Did’t receive an email?
          </span>
          <button
            className="btn px-1 link-primary fw-bold"
            onClick={resendEmail}
            disabled={loading}
          >
            {loading ? "please wait" : "Resend email"}
          </button>
        </div>
      </div>
    </div>
  );
};
