import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

interface props {
  value: boolean;
  onChange: (value: boolean) => void;
  label: string;
  help?: string;
}

export const SwitchSignleInput = ({ value, onChange, label, help }: props) => {
  const underPaidPopover = (
    <Popover>
      <Popover.Body>{help}</Popover.Body>
    </Popover>
  );
  return (
    <div className="w-100 py-1 ps-5 pe-4">
      <div className="d-flex align-items-center py-1">
        <div className="flex-grow-1 me-2">
          <h4 className="fs-6 fw-semibold text-gray-700 m-0">
            {label}

            {help && (
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="top"
                overlay={underPaidPopover}
              >
                <span className="badge badge-circle badge-sm badge-outline badge-primary cursor-pointer ms-1">
                  ?
                </span>
              </OverlayTrigger>
            )}
          </h4>
        </div>

        <div className="flex-grow-1 me-2">
          <div className="form-check form-switch form-check-custom form-check-solid justify-content-end">
            <input
              className="form-check-input"
              type="checkbox"
              checked={value}
              onChange={() => onChange(!value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
