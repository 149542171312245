import { createContext, useEffect, useState } from "react";
import { useRequest } from "../../../app/services/userHttpRequests";
import { WithChildren } from "../../helpers";

interface RecentAddress {
  name?: string;
  whitelist?: boolean;
  address: string;
  date: string;
}

interface Coin {
  [symbol: string]: {
    symbol: string;
    network: string;
    withdrawMin: number;
    withdrawMax: number;
    withdrawFee: number;
    withdrawEnable: boolean;
    memoEnable: boolean;
    suggest?: string;
  }[];
}

interface StepTwoOptions {
  address: string;
  coins: Coin;
  intenal: boolean;
}

interface StepThreeOptions {
  memo?: string;
  address: string;
  amount: string;
  coin: string;
  fee: number;
  network: string;
  requestToken: string;
  source: string;
  twoFa: boolean;
  emailconfirmation: boolean;
}

export interface WalletWebappSendContextModel {
  stepTwoOptions?: StepTwoOptions;
  setStepTwoOptions: (_stepTwoOptions: StepTwoOptions | undefined) => void;
  stepThreeOptions?: StepThreeOptions | undefined;
  setStepThreeOptions: (
    _stepThreeOptions: StepThreeOptions | undefined
  ) => void;

  recentAddresses: RecentAddress[];
  setRecentAddresses: (_recentAddresses: RecentAddress[]) => void;
}

export const WalletWebappSendContext =
  createContext<WalletWebappSendContextModel>({
    stepTwoOptions: undefined,
    setStepTwoOptions: (_stepTwoOptions?: StepTwoOptions | undefined) => {},
    stepThreeOptions: undefined,
    setStepThreeOptions: (
      _stepThreeOptions?: StepThreeOptions | undefined
    ) => {},
    recentAddresses: [],
    setRecentAddresses: (_recentAddresses: RecentAddress[]) => {},
  });

const WalletWebappSendProvider: React.FC<WithChildren> = ({ children }) => {
  const [stepTwoOptions, setStepTwoOptions] = useState<
    StepTwoOptions | undefined
  >(undefined);
  const [stepThreeOptions, setStepThreeOptions] = useState<
    StepThreeOptions | undefined
  >(undefined);
  const [recentAddresses, setRecentAddresses] = useState<RecentAddress[]>([]);
  const { recentAddress } = useRequest();

  useEffect(() => {
    stepThreeOptions === undefined &&
      recentAddress().then((res) => {
        if (res.status === 200) {
          setRecentAddresses(res.data);
        }
      });
    // eslint-disable-next-line
  }, [stepThreeOptions]);

  const value = {
    stepTwoOptions,
    setStepTwoOptions,
    stepThreeOptions,
    setStepThreeOptions,
    recentAddresses,
    setRecentAddresses,
  };

  return (
    <WalletWebappSendContext.Provider value={value}>
      {children}
    </WalletWebappSendContext.Provider>
  );
};

export default WalletWebappSendProvider;
