import {Item} from '../../components/ListMakerWidget'
import {ListItem} from '../../components/ListMakerWidgetC2'

export const existValidator = (value: string, items: Item[]) => {
  if (value === '') {
    return {result: true, message: null} // Allow empty value to be submitted
  }

  const existingItem = items.find((item) => item.value === value)
  if (existingItem) {
    return {result: false, message: 'Entered value already exists in the list'}
  }

  return {result: true, message: null}
}

export const existPlanValidator = (value: string, valueNumber: number, items: ListItem[]) => {
  if (value === '') {
    return {result: true, message: null} // Allow empty value to be submitted
  }

  const existingItem = items.find((item) => {
    return valueNumber === 1 ? item.value1 === value : item.value2 === value
  })
  if (existingItem) {
    return {
      result: false,
      message: valueNumber === 1 ? 'Entered period already exists' : 'Entered price already exists',
    }
  }

  return {result: true, message: null}
}
