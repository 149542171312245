import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useRequest } from "../app/services/userHttpRequests";
import { SendContext } from "../_oxapay/layout/core/SendContext";
import SelectWithImage from "./SelectWithImage";
import { errorHandler } from "../app/services/errorHandler";
import { FailedModalFire } from "../app/services/failedSwalFire";
import { WaitButtonSpan } from "./WaitButtonSpan";
import { AppContext } from "../_oxapay/layout/core/AppContext";
import { Network } from "./ReceiveAsset";
import { BalanceContext } from "../_oxapay/layout/core/BlanceContext";
import { alertsMapSendSubmit } from "../app/services/alertsMap";
import { formatAmount } from "../app/services/functionServices";
import { WebappContext } from "../_oxapay/layout/core/WebappContext";
import { Collapse } from "react-bootstrap";

interface props {
  onSuccess: () => void;
  onBack: () => void;
}
export const SendStepTwo = ({ onSuccess, onBack }: props) => {
  const { webappStatus } = useContext(WebappContext);
  const isInitialMount = useRef(true);
  const [loading, setLoading] = useState(false);
  const { sendAssetSubmit } = useRequest();
  const {
    stepTwoOptions,
    setStepTwoOptions,
    stepThreeOptions,
    setStepThreeOptions,
  } = useContext(SendContext);
  const { coinsListMap } = useContext(AppContext);
  const [selectedCoin, setSelectedCoin] = useState<string | undefined>(
    stepThreeOptions?.coin || undefined
  );
  const [selectedNetwork, setSelectedNetwork] = useState<string | undefined>(
    stepThreeOptions?.network || undefined
  );
  const [networks, setNetworks] = useState<Network[] | undefined>();
  const { balance } = useContext(BalanceContext);
  const [memoEnable, setMemoEnable] = useState(true);
  const [suggest, setSuggest] = useState("");
  const [amountDetail, setAmountDetail] = useState({
    balance: 0,
    fee: 0,
    min: 0,
    max: 0,
  });

  const allowedCoins = Object.keys(stepTwoOptions?.coins ?? []);
  const optionsCoin = Object.entries(coinsListMap)
    .filter(([_symbol, coin]) => coin.status === true)
    .filter(([symbol]) => allowedCoins.includes(symbol))
    .map(([symbol, coin]) => ({
      value: symbol,
      label: `${coin.name} (${symbol})`,
      image: `/media/svg/coins/${coin?.slug}.svg`,
      disable: !stepTwoOptions?.intenal ? !coin.withdrawEnable : false,
    }));

  useEffect(() => {
    if (stepThreeOptions) {
      setSelectedCoin(stepThreeOptions.coin);
      setSelectedNetwork(stepThreeOptions.network);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedCoin && networks) {
      const coinConfig = stepTwoOptions?.coins[selectedCoin]?.find(
        (coin) => coin.network === (selectedNetwork || networks[0].value)
      );

      if (coinConfig) {
        const Networksuggest = coinConfig.suggest;
        const memoEnable = coinConfig.memoEnable;
        const withdrawFee = coinConfig.withdrawFee;
        const withdrawMin = coinConfig.withdrawMin;
        const withdrawMax = coinConfig.withdrawMax;
        setMemoEnable(memoEnable);
        setSuggest(Networksuggest || "");
        setAmountDetail({
          balance: balance[selectedCoin],
          fee: withdrawFee,
          min: withdrawMin,
          max: withdrawMax,
        });
      }
    }
    // eslint-disable-next-line
  }, [selectedCoin, networks, selectedNetwork, balance]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      formik.resetForm({ values: { amount: "", memo: "" } });
    }

    if (selectedCoin) {
      const Coin = coinsListMap[selectedCoin];
      if (Coin && Coin.networkList) {
        const networks = Coin.networkList;
        const allowedNetworks = stepTwoOptions?.coins[selectedCoin].map(
          (item) => item.network
        );

        const optionsNetwork = Object.entries(networks)
          .filter(([symbol]) => allowedNetworks?.includes(symbol))
          .map(([symbol, network]) => ({
            value: symbol,
            label: network.name,
            image: `/media/svg/coins/${network.slug}.svg`,
            disable: !network.withdrawEnable,
          }));

        setNetworks(optionsNetwork);
      }
    }
    // eslint-disable-next-line
  }, [selectedCoin]);

  const maxValidateHandler = () => {
    const maxAmount = Math.min(
      Math.floor(
        (amountDetail.balance - amountDetail.fee + 0.000000000001) * 100000000
      ) / 100000000,
      amountDetail.max
    );

    const finalAmount = Math.max(0, maxAmount);

    return finalAmount;
  };

  const formik = useFormik({
    initialValues: {
      amount: stepThreeOptions?.amount ? stepThreeOptions.amount : "",
      memo: stepThreeOptions?.memo ? stepThreeOptions.memo : "",
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required("Amount is required")
        .min(amountDetail.min, "Your amount is less than minimum amount")
        .max(maxValidateHandler(), "Your amount is more than maximum amount"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        const res = await sendAssetSubmit(
          stepTwoOptions?.address as string,
          selectedCoin as string,
          stepTwoOptions?.intenal
            ? networks
              ? networks[0].value
              : ""
            : (selectedNetwork as string),
          parseFloat(values.amount),
          values.memo
        );
        if (res.status === 200) {
          setStepThreeOptions({
            address: res.address,
            amount: res.amount,
            coin: res.coin,
            fee: res.fee,
            memo: res.memo,
            network: res.network,
            requestToken: res.requestToken,
            source: res.source,
            twoFa: res.twoFa,
            emailconfirmation: res.emailconfirmation,
          });
          onSuccess();
        } else {
          FailedModalFire(alertsMapSendSubmit[res.errorNum.toString()]);
        }
      } catch (error) {
        errorHandler(error as Error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  const minHandler = () => {
    const minAmount = Math.min(
      Math.floor(
        (amountDetail.balance - amountDetail.fee + 0.000000000001) * 100000000
      ) / 100000000,
      amountDetail.min
    );

    const finalAmount = Math.max(0, minAmount);

    formik.setFieldValue("amount", finalAmount);
  };

  const maxHandler = () => {
    const maxAmount = Math.min(
      Math.floor(
        (amountDetail.balance - amountDetail.fee + 0.000000000001) * 100000000
      ) / 100000000,
      amountDetail.max
    );

    const finalAmount = Math.max(0, maxAmount);

    formik.setFieldValue("amount", finalAmount);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="w-100 mb-5">
        <h4 className="fs-5 fw-semibold text-gray-800">Choose currency</h4>
        <SelectWithImage
          search={!webappStatus}
          defaultValue={"Choose currency"}
          options={optionsCoin}
          onChange={(value) => {
            setSelectedNetwork(undefined);
            setSelectedCoin(value);
          }}
          value={selectedCoin}
        />
      </div>
      {!stepTwoOptions?.intenal && networks && (
        <div className="w-100 mb-5">
          <h4 className="fs-5 fw-semibold text-gray-800">Choose network</h4>
          <SelectWithImage
            search={!webappStatus}
            defaultValue={"Choose network"}
            options={networks}
            onChange={(value) => {
              setSelectedNetwork(value);
            }}
            value={selectedNetwork}
          />
        </div>
      )}

      {selectedCoin && (stepTwoOptions?.intenal || selectedNetwork) && (
        <div className={`w-100 ${memoEnable ? "mb-5" : "mb-10"}`}>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="fs-5 fw-semibold text-gray-800 mb-0">
              Amount{" "}
              <span className="text-muted fs-7">
                (Balance:{" "}
                {formatAmount(
                  amountDetail.balance,
                  coinsListMap[selectedCoin]?.displayPrecision
                )}{" "}
                {selectedCoin})
              </span>
            </h4>
            <div className="d-flex flex-row">
              <span
                className="input-group-text text-primary bg-light-secondary border-0 cursor-pointer px-3 py-1 me-1"
                onClick={minHandler}
              >
                Min
              </span>
              <span
                className="input-group-text text-primary bg-light-secondary border-0 cursor-pointer px-3 py-1"
                onClick={maxHandler}
              >
                Max
              </span>
            </div>
          </div>
          <input
            type="number"
            className={`form-control form-control-solid flex-grow-1 ${
              formik.errors.amount &&
              formik.touched.amount &&
              "is-invalid border border-danger"
            }`}
            name="amount"
            autoComplete="off"
            placeholder={`min: ${amountDetail.min} ${selectedCoin}`}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.amount}
          />
          {formik.errors.amount && formik.touched.amount && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.amount}
            </p>
          )}
          <span className="text-gray-800 fw-semibold">
            Transfer fee: {amountDetail.fee} {selectedCoin}
            <span className="text-primary"> (will be added to the amount)</span>
          </span>

          <Collapse
            in={
              suggest && parseFloat(formik.values.amount) > amountDetail.max
                ? true
                : false
            }
          >
            <div className="bg-light-primary rounded py-4 px-5 mt-4">
              <div className="d-flex align-items-center mb-2">
                <i className="far fa-lightbulb text-primary fs-1 me-1"></i>
                <span className="fs-3 text-primary fw-bold">Tip</span>
              </div>
              <p className="text-gray-900 mb-1">
                The amount you've set exceeds the maximum limit.{" "}
                <b className="text-primary">Consider using the {suggest}</b> for
                transferring this amount.
              </p>
            </div>
          </Collapse>
        </div>
      )}

      {selectedCoin &&
        selectedNetwork &&
        memoEnable &&
        !stepTwoOptions?.intenal && (
          <div className="w-100 mb-10">
            <div className="mb-2">
              <h4 className="fs-5 fw-semibold text-gray-800 mb-0">Memo</h4>
            </div>
            <input
              type="text"
              className={`form-control form-control-solid flex-grow-1 ${
                formik.errors.memo &&
                formik.touched.memo &&
                "is-invalid border border-danger"
              }`}
              name="memo"
              autoComplete="off"
              placeholder="Memo (optional)"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.memo}
            />
            {formik.errors.memo && formik.touched.memo && (
              <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                {formik.errors.memo}
              </p>
            )}
            <p className="text-muted mt-3">
              <span className="badge badge-circle badge-sm badge-outline badge-primary me-1">
                i
              </span>
              Please input any memo you may have here
            </p>
          </div>
        )}

      <div className="d-flex justify-content-between align-items-center">
        <button
          className="btn fs-3 text-muted text-hover-primary mx-5"
          onClick={() => {
            setStepTwoOptions(undefined);
            setStepThreeOptions(undefined);
            onBack();
          }}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn-primary fs-3 w-200px"
          disabled={formik.isSubmitting}
        >
          {loading ? <WaitButtonSpan /> : "Next"}
        </button>
      </div>
    </form>
  );
};
