export const Check = () => {
  return (
    <div className='d-flex justify-content-center'>
      <div className='sa'>
        <div className='sa-success'>
          <div className='sa-success-tip'></div>
          <div className='sa-success-long'></div>
          <div className='sa-success-placeholder'></div>
          <div className='sa-success-fix'></div>
        </div>
      </div>
    </div>
  )
}
