import React from "react";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { useAuth } from "../modules/auth";

export const Connect = () => {
  const { returnPage, currentUser } = useAuth();

  return (
    <div className="d-flex flex-column flex-root justify-content-center align-items-center">
      <div
        className="bot-bg"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/custom/pattern.svg")})`,
        }}
      ></div>
      <div className="d-flex flex-center w-lg-50 w-100 p-lg-10 p-md-10 px-10">
        <div className="card shadow w-md-400px w-100">
          <div className="text-center mt-10">
            <img
              src={toAbsoluteUrl(`/media/logos/bot.jpg`)}
              className="w-90px mt-2"
              style={{ borderRadius: "50%" }}
              alt={"telegram"}
            />
          </div>

          <div className="px-10 pb-0 pt-10">
            <p className="fs-5 fw-semibold mb-10">
              Click on the{" "}
              <a
                href={
                  returnPage === "/connect"
                    ? `https://t.me/OxaPayCHGBot?start=${currentUser?.refCode}`
                    : `https://t.me/OxaPayWalletBot?start=${currentUser?.refCode}`
                }
                className="text-primary fw-bold"
              >
                "Connect to telegram"
              </a>{" "}
              and follow the steps of bot
            </p>
          </div>

          <div className="px-10 mb-10">
            <a
              href={
                returnPage === "/connect"
                  ? `https://t.me/OxaPayCHGBot?start=${currentUser?.refCode}`
                  : `https://t.me/OxaPayWalletBot?start=${currentUser?.refCode}`
              }
              type="button"
              className="btn btn-primary w-100 mb-1 shine"
            >
              Connect to telegram
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
