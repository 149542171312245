import { KTSVG } from "../_oxapay/helpers";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";

interface Props {
  value?: string;
  searchPlaceholder?: string;
  onChange: () => void;
  onDebouncedChange: (value: string) => void;
}

export const TableSearch = ({
  searchPlaceholder = "Search...",
  onChange,
  onDebouncedChange,
  value = "",
}: Props) => {
  const [firstMount, setFirstMount] = useState(true);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (firstMount === true) {
      setFirstMount(false);
    } else {
      const debouncedChange = debounce((value: string) => {
        onDebouncedChange(value);
      }, 1000);

      debouncedChange(inputValue);
      return () => {
        debouncedChange.cancel();
      };
    }
    // eslint-disable-next-line
  }, [inputValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    onChange();
  };

  return (
    <div className="position-relative my-3">
      <span className="svg-icon svg-icon-2 position-absolute top-50 translate-middle-y ms-4">
        <KTSVG
          path="/media/icons/duotune/general/gen004.svg"
          className="svg-icon-3"
        />
      </span>
      <input
        type="text"
        data-kt-table-widget-4="search"
        className="form-control w-250px fs-7 ps-12"
        placeholder={searchPlaceholder}
        value={inputValue}
        onChange={handleInputChange}
      />
    </div>
  );
};
