import clsx from "clsx";
import React from "react";
import { HeaderNotificationsMenu } from "../_oxapay/partials";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarButtonHeightClass = "w-30px h-30px w-md-40px h-md-40px";

interface props {
  newNotifs: number;
  onClick: () => void;
  onShowChange: (show: boolean) => void;
}
export const TopBarNotification = ({
  newNotifs,
  onClick,
  onShowChange,
}: props) => {
  return (
    <div
      className={clsx(
        "d-flex align-items-center position-relative",
        toolbarButtonMarginClass
      )}
    >
      {newNotifs !== 0 && (
        <span
          className="badge badge-circle badge-danger position-absolute"
          style={{ top: "-10px", right: "-10px" }}
        >
          {newNotifs >= 10 ? "+9" : newNotifs?.toString()}
        </span>
      )}

      <div
        className={clsx(
          "btn btn-icon btn-color-gray-700 btn-active-light-primary btn-custom btn-outline w-40px h-40px ms-2",

          toolbarButtonHeightClass
        )}
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
        onClick={onClick}
      >
        <i className="fa-solid fa-bell fs-3"></i>
      </div>
      <HeaderNotificationsMenu onChange={onShowChange} />
    </div>
  );
};
