import axios from "axios";
import React, { useEffect, useState } from "react";
import { errorHandler } from "../app/services/errorHandler";
import { walletTxsInterval } from "../app/services/intervalManage";
import { useRequest } from "../app/services/userHttpRequests";
import { TransactionsList, tx } from "./TransactionsList";

export const WalletTxsList = () => {
  const [trasnactionsFetching, setTrasnactionsFetching] = useState(true);
  const [transactions, setTransactions] = useState<tx[]>([]);
  const { walletTransactions } = useRequest();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchTxs = async () => {
      try {
        const response = await walletTransactions(
          {
            type: "",
            coin: [],
            start: "",
            end: "",
          },
          signal
        );
        if (response.status === 200) {
          const TransactionsData = response;
          setTransactions(TransactionsData.data);
          setTrasnactionsFetching(false);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };

    fetchTxs();
    const interval = setInterval(fetchTxs, walletTxsInterval);

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval on component unmount
    };
    // eslint-disable-next-line
  }, []);

  return (
    <TransactionsList
      transactions={transactions}
      height={450}
      loading={trasnactionsFetching}
      noData={
        transactions?.length === 0 && !trasnactionsFetching ? true : false
      }
    />
  );
};
