import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../app/modules/auth";
import { errorHandler } from "../../app/services/errorHandler";
import { decodedUri, uriParser } from "../../app/services/functionServices";
import { walletPortfoInterval } from "../../app/services/intervalManage";
import { useRequest } from "../../app/services/userHttpRequests";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { ShowAlertContext } from "../../_oxapay/layout/core/ShowAlertContext";
import { WalletAddressInfoButton } from "./WalletAddressInfoButton";
import { WalletAddressQrButton } from "./WalletAddressQrButton";
import { WebappTextCopy } from "./webappTextCopy";

export const WalletWebappHeader = () => {
  const [fetching, setFetching] = useState(true);
  const { setShowAlert, setAlertMessage } = useContext(ShowAlertContext);
  const { currentUser } = useAuth();
  const { userPortfolio } = useRequest();
  const navigate = useNavigate();
  const [portfo, setPortfo] = useState({
    amount: 0,
    changeAmount: 0,
    changePercent: 0,
  });

  useEffect(() => {
    const controller = new AbortController();

    const fetchPortfo = async () => {
      try {
        const response = await userPortfolio({ signal: controller.signal });
        if (response.status === 200) {
          const portfoData = response.data;
          setFetching(false);
          setPortfo(portfoData);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };
    fetchPortfo();
    const interval = setInterval(fetchPortfo, walletPortfoInterval);

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval on component unmount
    };

    // eslint-disable-next-line
  }, []);

  const changeColor = portfo.changePercent < 0 ? "danger" : "success";
  const changeSymbol = portfo.changePercent < 0 ? "-" : "+";

  const qrCodeHandler = () => {
    const qrParams = {
      text: "Find QR",
    };

    // Show the QR code scanning popup
    (window as any).Telegram?.WebApp.showScanQrPopup(
      qrParams,
      (qrText: any) => {
        if (qrText) {
          (window as any).Telegram?.WebApp.closeScanQrPopup();
          const result = decodedUri(uriParser(qrText));
          result === "error"
            ? (window as any).Telegram?.WebApp.showAlert("Invalid Data")
            : navigate(`/webapp-wallet/send/${result}`);
        } else {
          console.log("No QR code text found.");
        }
      }
    );
  };

  return (
    <div className="pt-14 mb-8 d-flex flex-column justify-content-center align-items-center">
      <p className="fs-4 fw-400 mb-0">Total Balance</p>
      <div className="d-flex flex-row justify-content-center align-items-center mb-n">
        {fetching ? (
          <div className="h-45px w-150px bg-secondary rounded shine mt-2"></div>
        ) : (
          <>
            <p className="fm-sfuirounded fs-45 text-gray-600 fw-600 mb-0">$</p>
            <p className="fm-sfuirounded fs-45 text-dark fw-600 letter-spacing-n mb-0">
              {Number(Math.floor(portfo.amount)).toLocaleString()}
              <span className="fs-3x">
                .
                {(portfo.amount - Math.floor(portfo.amount))
                  .toFixed(3)
                  .slice(2)}
              </span>
            </p>
          </>
        )}
      </div>
      {fetching ? (
        <div className="d-flex justify-content-center align-items-center mt-3 mb-3">
          <div className="h-15px w-100px bg-secondary rounded shine mx-auto"></div>
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center mb-6">
          <span className={`fs-5 fw-semibold text-dark align-self-start`}>
            {changeSymbol} $
          </span>
          <span className={`fs-5 fw-semibold text-dark me-2 lh-1`}>
            {portfo.changeAmount}
          </span>
          <span className={`badge badge-${changeColor} fs-8`}>
            {portfo.changePercent}%
          </span>
        </div>
      )}

      <div className="d-flex justify-content-center align-items-center mb-10">
        <WalletAddressInfoButton />
        <WebappTextCopy
          text={currentUser?.wallet || ""}
          onCopy={() => {
            setShowAlert(true);
            setAlertMessage("Address copied to clipoboard");
          }}
        >
          <p className="fm-jetbrain fs-3 m-0">
            <span className="fw-bold">{currentUser?.wallet.slice(0, 3)}</span>
            {currentUser?.wallet.slice(3)}
          </p>
        </WebappTextCopy>

        <WalletAddressQrButton address={currentUser?.wallet as string} />
      </div>
      <div className="w-100 d-flex justify-content-between align-items-center gap-3">
        <Link
          to="/webapp-wallet/send"
          className="btn bg-webapp text-primary fs-7 px-0 fw-400 py-3 flex-grow-1"
        >
          <div className="d-flex flex-column justify-align-center align-items-center">
            <img
              src={toAbsoluteUrl("/media/icons/wallet/send.svg")}
              className={`${
                currentUser?.access?.swap ? "h-15px" : "h-20px"
              } mb-1`}
              alt="send icon"
            />
            <span>send</span>
          </div>
        </Link>
        <Link
          to="/webapp-wallet/receive"
          className="btn bg-webapp text-primary fs-7 px-0 fw-400 py-3 flex-grow-1"
        >
          <div className="d-flex flex-column justify-align-center align-items-center">
            <img
              src={toAbsoluteUrl("/media/icons/wallet/receive.svg")}
              className={`${
                currentUser?.access?.swap ? "h-15px" : "h-20px"
              } mb-1`}
              alt="receive icon"
            />
            <span>receive</span>
          </div>
        </Link>
        {currentUser?.access?.swap && (
          <Link
            to="/webapp-wallet/swap"
            className="btn bg-webapp text-primary fs-7 px-0 fw-400 py-3 flex-grow-1"
          >
            <div className="d-flex flex-column justify-align-center align-items-center">
              <img
                src={toAbsoluteUrl("/media/icons/wallet/swap-wallet.svg")}
                className="h-15px mb-1"
                alt="swap icon"
              />
              <span>swap</span>
            </div>
          </Link>
        )}
        <Link
          to="/webapp-wallet/history"
          className="btn bg-webapp text-primary fs-7 px-0 fw-400 py-3 flex-grow-1"
        >
          <div className="d-flex flex-column justify-align-center align-items-center">
            <img
              src={toAbsoluteUrl("/media/icons/wallet/history.svg")}
              className={`${
                currentUser?.access?.swap ? "h-15px" : "h-20px"
              } mb-1`}
              alt="history icon"
            />
            <span>history</span>
          </div>
        </Link>
        <div
          className="btn bg-webapp text-primary fs-7 px-0 fw-400 py-3 flex-grow-1"
          onClick={qrCodeHandler}
        >
          <div className="d-flex flex-column justify-align-center align-items-center">
            <img
              src={toAbsoluteUrl("/media/icons/wallet/scan-main.svg")}
              className={`${
                currentUser?.access?.swap ? "h-15px" : "h-20px"
              } mb-1`}
              alt="history icon"
            />
            <span>Scan</span>
          </div>
        </div>
      </div>
    </div>
  );
};
