import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { alertsMapSendCheckAddres } from "../../app/services/alertsMap";
import { errorHandler } from "../../app/services/errorHandler";
import { useRequest } from "../../app/services/userHttpRequests";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { WalletWebappSendContext } from "../../_oxapay/layout/core/WalletWebappSendContext";
import { WebappModalContext } from "../../_oxapay/layout/core/WebappModalContext";
import { WebappFailAlert } from "./AlertsModal/WebappFailAlert";

interface Props {
  address: string;
  onSuccess: () => void;
}

export const WalletWebappQrSendStepOne: React.FC<Props> = ({
  address,
  onSuccess,
}) => {
  const navigate = useNavigate();
  const { setStepTwoOptions } = useContext(WalletWebappSendContext);
  const { setComponent, setShowModal } = useContext(WebappModalContext);
  const { sendAsset } = useRequest();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await sendAsset(address);
        if (res.status === 200) {
          const stepTwoOptions = {
            address: res.address,
            coins: res.allowed,
            intenal: res.internal,
          };
          setStepTwoOptions(stepTwoOptions);
          onSuccess();
        } else {
          navigate(`/webapp-wallet`);
          setShowModal(true);
          setComponent(
            <WebappFailAlert
              content={`${alertsMapSendCheckAddres[res.errorNum.toString()]}`}
            />
          );
        }
      } catch (error) {
        errorHandler(error as Error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="splash-screen">
      <div className="loading-container d-flex flex-column">
        <img
          className="infinite-rotate"
          src={toAbsoluteUrl("/media/icons/wallet/waiting.svg")}
          alt="oxapay loading"
        />
      </div>
    </div>
  );
};
