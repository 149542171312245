import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Slide, toast } from "react-toastify";
import { errorHandler } from "../app/services/errorHandler";

interface Props {
  children: string;
  disable?: boolean;
}

export const TableBodyLink = ({ children, disable = false }: Props) => {
  const [copied, setCopied] = useState(false);
  const [qrLoading, setQrLoading] = useState(true);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(children);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      // Clipboard API writeText failed, falling back to document.execCommand for compatibility
      try {
        const textArea = document.createElement("textarea");
        textArea.value = children;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (fallbackError) {
        // Unable to copy using Clipboard API and document.execCommand
        errorHandler(fallbackError as Error);
        toast.info("Clipboard copy failed", {
          toastId: "copyFailed",
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          theme: "dark",
          closeButton: true,
          closeOnClick: true,
          draggable: true,
          transition: Slide,
          icon: false,
        });
      }
    }
  };

  return (
    <div className={`input-group ${disable && "disable"}`}>
      <OverlayTrigger placement="top" overlay={<Tooltip>{children}</Tooltip>}>
        <input
          type="text"
          className="form-control form-control-solid flex-grow-1 pe-0 w-90px"
          value={children}
          onClick={copyToClipboard}
          readOnly
        />
      </OverlayTrigger>
      <OverlayTrigger
        placement="top-end"
        overlay={
          <Tooltip>
            {qrLoading && (
              <div className="h-150px w-150px bg-secondary rounded m-auto shine my-1"></div>
            )}
            <img
              className={`mx-auto d-block my-1 ${qrLoading && "d-none"}`}
              src={`http://api.qrserver.com/v1/create-qr-code/?data=${children}&size=150x150`}
              alt="address-qrcode"
              onLoad={() => {
                setQrLoading(false);
              }}
            />
          </Tooltip>
        }
      >
        <span
          className="input-group-text border-0 cursor-pointer ps-3 pe-2"
          id="basic-addon2"
        >
          <i className="fa-solid fa-qrcode fs-3"></i>
        </span>
      </OverlayTrigger>
      <span
        className="input-group-text border-0 cursor-pointer ps-3"
        id="basic-addon2"
        onClick={copyToClipboard}
      >
        {copied ? (
          <>
            <i className="fa-solid fa-check fs-3 text-success me-2"></i>
            <span className="fs-6 text-success">Copied</span>
          </>
        ) : (
          <OverlayTrigger placement="top" overlay={<Tooltip>Copy</Tooltip>}>
            <i className="fa-regular fa-copy fs-3"></i>
          </OverlayTrigger>
        )}
      </span>
    </div>
  );
};
