import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_oxapay/layout/MasterLayout";
import { Home } from "../pages/Home";
import { PaymentLink } from "../pages/PaymentLink";
import { DonationButton } from "../pages/DonationButton";
import { Merchants } from "../pages/Merchants";
import { PayoutApi } from "../pages/PayoutApi";
import { Referrals } from "../pages/Referrals";
import { Profile } from "../pages/Profile";
import { Lock } from "../pages/Lock";
import { Connect } from "../pages/Connect";
import { PanelSignin } from "../../components/PanelSignin";
import { useEffect, useState } from "react";
import { loadTawkScript } from "../services/loadTawk";
import { Notifications } from "../pages/Notifications";
import { firebaseConfig } from "../../messaging_init_in_sw";
import { useRequest } from "../services/userHttpRequests";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { errorHandler } from "../services/errorHandler";
import { Swap } from "../pages/Swap";
import { useAuth } from "../modules/auth";
import { AccountSettings } from "../pages/AccountSettings";

const PrivateRoutes = () => {
  const { currentUser } = useAuth();
  const { pushNotificationToken, pushNotificationDeny } = useRequest();
  const [showTelegramWidget, setShowTelegramWidget] = useState(true);

  const requestPermission = () => {
    Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          const app = initializeApp(firebaseConfig);

          const messaging = getMessaging(app);
          getToken(messaging, {
            vapidKey:
              "BJCITejqa4U2GCmWsRhqvWeX5QBMFXZ1na3ANKs8VLTN9VC5_WgFLVuPJ4oD71KDlT940vG1IAhORugwpqnvW1M",
          }).then((currentToken) => {
            if (currentToken) {
              if (currentToken) {
                pushNotificationToken(currentToken).catch((err) =>
                  errorHandler(err)
                );
              }
            } else {
              pushNotificationToken("").catch((err) => errorHandler(err));
            }
          });
        } else {
          pushNotificationDeny().catch((err) => errorHandler(err));
        }
      })
      .catch((error) => errorHandler(error));
  };
  const registerServiceWorker = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js", {
          scope: "/firebase-cloud-messaging-push-scope",
        })
        .then(() => {
          requestPermission();
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  };

  useEffect(() => {
    loadTawkScript();
    (window as any).Tawk_API = (window as any).Tawk_API || {};
    (window as any).Tawk_API.onStatusChange = function (status: any) {
      if (status === "offline") {
        setShowTelegramWidget(true);
        (window as any)?.Tawk_API.hideWidget();
      } else {
        setShowTelegramWidget(false);
        (window as any)?.Tawk_API.showWidget();
      }
    };
    registerServiceWorker();
    // eslint-disable-next-line
  }, []);

  return (
    <Routes>
      <Route path="signin" element={<PanelSignin />} />
      <Route path="connect" element={<Connect />} />
      <Route element={<MasterLayout showTelegramWidget={showTelegramWidget} />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="/*" element={<Navigate to="home" />} />
        {/* Pages */}
        <Route path="home" element={<Home />} />
        <Route path="paymentlink" element={<PaymentLink />} />
        <Route path="donations" element={<DonationButton />} />
        <Route path="merchant" element={<Merchants />} />
        <Route path="payout" element={<PayoutApi />} />
        {/* {(currentUser?.access?.hasChannel ?? true) && (
          <Route path="TCHgateway" element={<ChannelGateway />} />
        )} */}
        <Route path="referrals" element={<Referrals />} />
        <Route path="lock" element={<Lock />} />
        <Route path="settings" element={<AccountSettings />} />
        <Route path="profile" element={<Profile />} />
        <Route path="notifications" element={<Notifications />} />
        {currentUser?.access?.swap && <Route path="swap" element={<Swap />} />}

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
