import Swal from "sweetalert2";

export const SuccessModalFire = (message: string, title?: string) => {
  Swal.fire({
    title: title ? title : "Congratulations",
    text: message,
    icon: "success",
    padding: "10px 10px 50px",
    width: "500",
    iconColor: "#4c9c74",
    showCloseButton: true,
    showConfirmButton: false,
    heightAuto: false,
    background: "var(--kt-card-bg)",
    showClass: {
      popup: "animate__animated animate__faster animate__zoomIn",
    },
    hideClass: {
      popup: "animate__animated animate__faster animate__fadeOutUp",
    },
  });
};
