import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { errorHandler } from "../../app/services/errorHandler";
import { DateTime, formatAmount } from "../../app/services/functionServices";
import { commissionTxsInterval } from "../../app/services/intervalManage";
import { useRequest } from "../../app/services/userHttpRequests";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { TableTXsFilterCard } from "../filters/TableTXsFilterCard";
import { TableBodyCurrency } from "../TableBodyCurrency";
import { TablePlaceholder } from "../TablePlaceholder";
import { TablesWidget } from "../TableWidget";

export interface commissionTxs {
  id: string;
  amount: string;
  currency: string;
  date: string;
}

export const CommissionTXsTable = () => {
  const { coinsListMap } = useContext(AppContext);
  const [trasnactionsFetching, setTrasnactionsFetching] = useState(true);
  const [transactions, setTransactions] = useState<commissionTxs[]>();
  const [trasnactionsPage, setTransactionsPage] = useState({
    page: 1,
    pages: 0,
  });
  const [filter, setFilter] = useState({
    coin: [],
    start: "",
    end: "",
  });
  const { commissionTransactions } = useRequest();

  useEffect(() => {
    setTrasnactionsFetching(true);
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchTxs = async () => {
      try {
        const response = await commissionTransactions(
          trasnactionsPage.page,
          filter,
          {
            signal,
          }
        );
        if (response.status === 200) {
          const TransactionsData = response;
          setTransactions(TransactionsData.data);
          setTransactionsPage(TransactionsData.meta);
          setTrasnactionsFetching(false);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };

    if (trasnactionsPage.page === 1) {
      fetchTxs();
      const interval = setInterval(fetchTxs, commissionTxsInterval);

      return () => {
        controller.abort(); // Cancel the fetch request
        clearInterval(interval); // Clean up the interval on component unmount
      };
    } else {
      fetchTxs();

      return () => {
        controller.abort(); // Cancel the fetch request
      };
    }
    // eslint-disable-next-line
  }, [trasnactionsPage.page, filter]);

  return (
    <TablesWidget
      loading={trasnactionsFetching}
      noData={
        transactions?.length === 0 && !trasnactionsFetching ? true : false
      }
      pages={trasnactionsPage.pages}
      pageChange={(page) =>
        setTransactionsPage({ ...trasnactionsPage, page: page })
      }
      title="Referrals commission transactions"
      className="mb-5 mb-xl-10"
      tableHead={["ID", "Amount", "Currency", "Date"]}
      size={[null, null, null, null]}
      filter={true}
      filterCard={
        <TableTXsFilterCard
          onChange={(newFilter) => {
            if (JSON.stringify(newFilter) !== JSON.stringify(filter)) {
              setFilter(newFilter);
              setTrasnactionsFetching(true);
            }
          }}
        />
      }
    >
      {trasnactionsFetching === true && <TablePlaceholder config={[10, 4]} />}
      {!trasnactionsFetching &&
        transactions !== undefined &&
        transactions.map((data) => (
          <tr key={data.id}>
            <td>
              <span className="text-gray-600 fw-bold fs-6">{data.id}</span>
            </td>
            <td>
              <span className="text-dark fw-bold fs-6">
                {formatAmount(
                  parseFloat(data.amount),
                  coinsListMap[data.currency]?.displayPrecision
                )}
              </span>
            </td>
            <td>
              <TableBodyCurrency currency={data.currency} />
            </td>
            <td>
              <span className="text-gray-600 fw-bold fs-6">
                {DateTime(parseInt(data.date))}
              </span>
            </td>
          </tr>
        ))}
    </TablesWidget>
  );
};
