import React, { ReactNode } from "react";

interface props {
  cardHeaderTitle: string;
  children: ReactNode;
  filter?: boolean;
  filterCard?: ReactNode;
}

export const Card = ({
  cardHeaderTitle,
  children,
  filter = false,
  filterCard,
}: props) => {
  return (
    <div className="card mb-10">
      <div className="card-header border-0">
        <div className="card-title m-0">
          <h3 className="fw-bold m-0">{cardHeaderTitle}</h3>
        </div>
        <div className="card-toolbar">
          {filter && (
            <>
              <button
                type="button"
                className="btn btn-sm btn-flex fw-bold bg-body bg-light-secondary btn-color-gray-600 btn-active-color-primary"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="top-end"
                data-kt-menu-flip="top-end"
              >
                <i className="fa-solid fa-filter"></i>
                Filter
              </button>
              {filterCard}
            </>
          )}
        </div>
      </div>
      <div className="card-body border-top p-9">{children}</div>
    </div>
  );
};
