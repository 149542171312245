import React from "react";
import { convertUnixToTimeAgo } from "../../app/services/functionServices";
import { IconDonation } from "../staticIcons/IconDonation";

interface props {
  data: {
    name: string;
    amount: string;
    currency: string;
  };
  date: string;
}

export const NotifDonation = ({ data, date }: props) => {
  return (
    <div className="card card-body mb-5 px-3 px-sm-0 pb-3 pb-sm-0 pt-1">
      <div className="d-flex align-items-center flex-sm-row flex-column">
        <div className="d-flex align-items-center my-4 mx-4">
          <div className="symbol symbol-50px me-4">
            <IconDonation />
          </div>

          <div className="mb-0 me-2">
            <span className="fs-6 text-gray-800 fw-bolder">
              Donation transaction
            </span>
            <div className="text-gray-400 fs-7">
              {convertUnixToTimeAgo(date)}
            </div>
          </div>
        </div>

        <div
          className="d-flex align-items-center justify-content-center bg-light-secondary border border-dashed border-secondary h-100 p-5"
          style={{ borderRadius: "12px" }}
        >
          <p className="m-0 me-2 border-end-dashed border-1 border-secondary pe-3">
            <span className="fw-bold ">Name:</span> {data.name}
          </p>
          <p className="m-0 me-2">
            <span className="fw-bold ">Amount Received:</span> {data.amount}{" "}
            {data.currency}
          </p>
        </div>
      </div>
    </div>
  );
};
