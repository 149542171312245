import React, { useContext, useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import CoinCarousel from "../CoinCarousel";
import { SwapStepTwo } from "../SwapStepTwo";
import { SwapStepOne, ValuesModel } from "../SwapStepOne";
import { useRequest } from "../../app/services/userHttpRequests";
import { errorHandler } from "../../app/services/errorHandler";
import { SwapSkeleton } from "../SwapSkeleton";
import { SwapPairNames } from "../SwapPairNames";
import { SwapContext } from "../../app/Core/SwapContext";

interface PairModel {
  fromCurrency: string | undefined;
  toCurrency: string | undefined;
}

export const SwapForm = () => {
  const [step, setStep] = useState(0);
  const [values, setValues] = useState<ValuesModel>();
  const [pair, setPair] = useState<PairModel | undefined>();
  const [dropdownIsOpen, setDropDownIsOpen] = useState(false);
  const [pairsLoading, setPairsLoading] = useState(true);
  const { pairsData, setPairsData } = useContext(SwapContext);
  const [coinsList, setCoinsList] = useState<string[]>([]);
  const [resetFormTrigger, setResetFormTrigger] = useState(0);
  const { swapPairs } = useRequest();
  const findIndex = (targetCurrency: string) => {
    return coinsList.indexOf(targetCurrency);
  };

  useEffect(() => {
    swapPairs()
      .then((res) => {
        if (res.status === 200) {
          setPairsData(res.data);
          setCoinsList(Object.keys(res.data));
          setPairsLoading(false);
        }
      })
      .catch((error) => {
        errorHandler(error as Error);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="card">
      <div className="card-body d-flex flex-column flex-center">
        <div className="w-100">
          {pair?.fromCurrency && pair.toCurrency ? (
            <>
              <div className="position-relative h-150px mt-5 mb-5">
                <CoinCarousel
                  coinsList={coinsList}
                  activeIndex={findIndex(pair.toCurrency)}
                  className="position-absolute w-150px rotate-90"
                  style={{ left: "40%" }}
                />
                <CoinCarousel
                  coinsList={coinsList}
                  activeIndex={findIndex(pair.fromCurrency)}
                  className="position-absolute w-150px rotate-90"
                  style={{ left: "15%" }}
                />
              </div>
              <SwapPairNames
                fromCurrency={pair.fromCurrency}
                toCurrency={pair.toCurrency}
              />
            </>
          ) : (
            <div className="mb-10">
              <p className="fs-5 mb-3">
                <b>How to Swap?</b>
              </p>
              <p className="text-justify">
                Select the currency you wish to convert, designate the
                destination currency along with your desired amount, then click
                "Swap." Confirm the details, and receive the destination
                currency instantly.
              </p>
            </div>
          )}

          <Carousel
            className={dropdownIsOpen ? "stepper-carousel" : ""}
            activeIndex={step}
            controls={false}
            indicators={false}
          >
            <Carousel.Item>
              {pairsLoading || !pairsData ? (
                <SwapSkeleton />
              ) : (
                <SwapStepOne
                  resetFormTrigger={resetFormTrigger}
                  pairsData={pairsData}
                  showOptionsStatus={(status) => setDropDownIsOpen(status)}
                  fromCurrencyChange={(value) =>
                    setPair((prevPair) => ({
                      ...prevPair,
                      fromCurrency: value,
                      toCurrency: prevPair?.toCurrency,
                    }))
                  }
                  toCurrencyChange={(value) =>
                    setPair((prevPair) => ({
                      ...prevPair,
                      toCurrency: value,
                      fromCurrency: prevPair?.fromCurrency,
                    }))
                  }
                  onConfirm={(values) => {
                    setValues(values);
                    setStep(1);
                  }}
                />
              )}
            </Carousel.Item>
            <Carousel.Item>
              <SwapStepTwo
                fromAmount={values?.fromAmount ?? ""}
                toAmount={values?.toAmount ?? ""}
                fromCurrency={values?.fromCurrency ?? ""}
                toCurrency={values?.toCurrency ?? ""}
                onBack={() => setStep(0)}
                resetForm={() => {
                  setStep(0);
                  setResetFormTrigger(resetFormTrigger + 1);
                }}
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
};
