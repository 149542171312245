import React, { InputHTMLAttributes } from "react";

interface WebappInputProps extends InputHTMLAttributes<HTMLInputElement> {}

export const WebappSwapInput = ({ ...rest }: WebappInputProps) => {
  return (
    <input
      className="webapp-swap-input webapp-custom-input w-100 fs-3x fw-bold text-dark"
      {...rest}
    />
  );
};
