export const transactionTypeOptions = [
  { value: "Send", label: "Send" },
  { value: "Receive", label: "Receive" },
  { value: "Payout", label: "Payout" },
  { value: "Commission", label: "Commission" },
  { value: "Merchant", label: "Merchants" },
  { value: "Pay Link", label: "Payment link" },
  { value: "Donate", label: "Donation" },
  { value: "ManualSwap", label: "Manual Swap" },
  { value: "AutoConvert", label: "Auto Convert" },
  { value: "SwapByApi", label: "Swap By API" },
];

export const defaultCoin = "BTC";

export const activityTypeOptions = [
  { value: "login", label: "Login" },
  { value: "create", label: "Creation" },
  { value: "delete", label: "Delete" },
  { value: "edit", label: "Edit" },
  { value: "twofa", label: "Two-factor" },
  { value: "profile", label: "Profile changes" },
  { value: "passwordChange", label: "Password changes" },
  { value: "setEmailPassword", label: "Set Email & Password" },
];

export const statusMap: Record<string, { color: string; name: string }> = {
  y: { color: "success", name: "Confirmed" },
  w: { color: "warning", name: "Confirming" },
  p: { color: "warning", name: "Proccessing" },
  f: { color: "danger", name: "Failed" },
  r: { color: "danger", name: "Canceled" },
  under: { color: "info", name: "Underpaid" },
  expired: { color: "dark", name: "Expired" },
  refunded: { color: "primary", name: "Refunded" },
  refunding: { color: "primary", name: "Refunding..." },
  manual: { color: "success", name: "Manual Confirmed" },
};

export const statusOptions = [
  { value: "under", label: "Underpaid" },
  { value: "expired", label: "Expired" },
  { value: "refunded", label: "Refunded" },
  { value: "refunding", label: "Refunding" },
  { value: "manual", label: "Manual Confirmed" },
  { value: "y", label: "Confirmed" },
  { value: "w", label: "Confirming" },
  { value: "p", label: "Proccessing" },
];
