import { toAbsoluteUrl } from "../../_oxapay/helpers";

export const loadTelegramWebappScript = (callback: any) => {
  const existingScript = document.getElementById("telegramScript");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = toAbsoluteUrl("/js/telegram-web-app.js");
    script.id = "telegramScript";
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};
