import axios from "axios";
import React, { useEffect, useState } from "react";
import { errorHandler } from "../app/services/errorHandler";
import { homeShareChartInterval } from "../app/services/intervalManage";
import { useRequest } from "../app/services/userHttpRequests";
import { ChartsWidget3 } from "./charts/ChartWidget3";

interface ShareChart {
  labels: string[];
  amounts: number[];
}

export const HomeChartShare = () => {
  const { homePaymentShare } = useRequest();
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState<ShareChart>({
    labels: [],
    amounts: [],
  });

  useEffect(() => {
    const controller = new AbortController();
    const fetchChartData = async () => {
      try {
        const response = await homePaymentShare({
          signal: controller.signal,
        });
        if (response.status === 200) {
          const NewData = response.chart;
          setChartData(NewData);
          setLoading(false);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };
    fetchChartData();
    const interval = setInterval(fetchChartData, homeShareChartInterval); // Fetch summary every 10 seconds

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval on component unmount
    };
    // eslint-disable-next-line
  }, []);

  return (
    <ChartsWidget3
      className="card card-flush overflow-hidden"
      chartTitle="Payment share overview"
      chartSubtitle="All payments statistics"
      colors={["#54b5e5", "#3485f7", "#8087e2", "#3e5499", "#3176b0"]}
      series={chartData.amounts.map(Number)}
      labels={chartData.labels}
      loading={loading}
    />
  );
};
