import React from 'react'

export const ActiveStatus = () => {
  return (
    <div className='d-flex align-items-center fw-bold my-3'>
      <div className='text-gray-400 fs-7 me-2'>Status</div>
      <select
        className='form-select form-select-transparent text-dark fs-7 lh-1 fw-bold py-0 ps-3 w-auto cursor-pointer'
        data-control='select2'
        data-hide-search='true'
        data-dropdown-css-class='w-150px'
        data-kt-table-widget-4='filter_status'
        defaultValue='Show All'
      >
        <option value='Show All'>Show All</option>
        <option value='Active'>Active</option>
        <option value='Inactive'>Inactive</option>
      </select>
    </div>
  )
}
