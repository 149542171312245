/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { getUserByToken, register } from "../core/_requests";
import { useAuth } from "../core/Auth";
import { PasswordMeterComponent } from "../../../../_oxapay/assets/ts/components";
import { generateToken } from "../../../services/ReCaptchaGenerator";
import { WaitButtonSpan } from "../../../../components/WaitButtonSpan";
import { LoginWithGoogle } from "./LoginWithGoogle";
import { alertsMapRegister } from "../../../services/alertsMap";
import { FailedModalFire } from "../../../services/failedSwalFire";
import Cookies from "universal-cookie";
import { SuccessModalFire } from "../../../services/successSwalFire";
import { errorHandler } from "../../../services/errorHandler";
import LoginWithTelegram from "./LoginWithTelegram";
import { PasswordInput } from "../../../../components/PasswordInput";

export function Registration() {
  const [loading, setLoading] = useState(false);

  const { saveAuth, setCurrentUser, setReturnPage } = useAuth();
  const { ref } = useParams();
  const cookies = new Cookies();
  const [searchParams] = useSearchParams();
  const returnQueryString = searchParams.get("return");

  useEffect(() => {
    returnQueryString && setReturnPage(returnQueryString);
    // eslint-disable-next-line
  }, []);

  const initialValues = {
    email: "",
    password: "",
    passwordConfirmation: "",
    ref: ref ? ref : cookies.get("ref"),
    acceptTerms: false,
    referrer: cookies.get("referrer"),
  };

  const registrationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is required")
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/,
        "Password must be more than 8 chracaters and contain at least one number."
      ),
    passwordConfirmation: Yup.string()
      .required("Password confirmation is required")
      .when("password", {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
    acceptTerms: Yup.boolean()
      .oneOf([true], "You must accept the terms and conditions")
      .required("You must accept the terms and conditions"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        const tokenRegister = await generateToken("register");
        const { data: auth } = await register(
          values.email,
          values.password,
          values.passwordConfirmation,
          tokenRegister,
          values.ref,
          values.referrer
        );

        if (auth.status === 200) {
          saveAuth(auth);
          const { data: user } = await getUserByToken(auth.apiToken);

          if (user.status === 200) {
            setCurrentUser(user);
          } else {
            FailedModalFire("Something went wrong to verify, please try again");
          }
        } else if (auth.status === 202) {
          SuccessModalFire(
            "Check your email and verify the account",
            "Verify your account"
          );
          resetForm();
        } else {
          FailedModalFire(alertsMapRegister[auth.errorNum.toString()]);
        }
      } catch (error) {
        errorHandler(error as Error);
        saveAuth(undefined);
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">Sign Up</h1>
        <div className="text-gray-500 fw-semibold fs-6">
          Your Social Campaigns
        </div>
      </div>
      <div className="row g-3 mb-9">
        <div className="col-md-6">
          <LoginWithGoogle setLoading={(status) => setLoading(status)} />
        </div>
        <div className="col-md-6">
          <LoginWithTelegram setLoading={(status) => setLoading(status)} />
        </div>
      </div>
      <div className="separator separator-content my-14">
        <span className="w-125px text-gray-500 fw-semibold fs-7">
          Or with email
        </span>
      </div>

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Email */}
      <div className="fv-row mb-8">
        <input
          placeholder="Email"
          type="email"
          autoComplete="off"
          {...formik.getFieldProps("email")}
          className={clsx("form-control bg-transparent", {
            "is-invalid border border-danger":
              formik.touched.email && formik.errors.email,
          })}
        />
        {formik.errors.email && formik.touched.email && (
          <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
            {formik.errors.email}
          </p>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className="mb-10 fv-row" data-kt-password-meter="true">
        <div className="mb-1">
          <div className="position-relative mb-3">
            <PasswordInput
              placeholder="Password"
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx("form-control bg-transparent rounded", {
                "border border-danger":
                  formik.touched.password && formik.errors.password,
              })}
            />
            {formik.errors.password && formik.touched.password && (
              <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                {formik.errors.password}
              </p>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className="d-flex align-items-center mb-3"
            data-kt-password-meter-control="highlight"
          >
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className="text-muted">
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>

      <div className="fv-row mb-8">
        <PasswordInput
          placeholder="Password confirmation"
          autoComplete="off"
          {...formik.getFieldProps("passwordConfirmation")}
          className={clsx("form-control bg-transparent rounded", {
            "border border-danger":
              formik.touched.passwordConfirmation &&
              formik.errors.passwordConfirmation,
          })}
        />
        {formik.errors.passwordConfirmation &&
          formik.touched.passwordConfirmation && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.passwordConfirmation}
            </p>
          )}
      </div>

      <div className="fv-row mb-8">
        <input
          type="number"
          placeholder="Referral code"
          autoComplete="off"
          {...formik.getFieldProps("ref")}
          className="form-control bg-transparent"
        />
      </div>

      {/* begin::Form group */}
      <div className="fv-row mb-8">
        <div className="form-check form-check-inline d-flex align-items-center">
          <input
            className="form-check-input me-1"
            type="checkbox"
            name="acceptTerms"
            checked={formik.values.acceptTerms}
            onChange={formik.handleChange}
          />
          <label className="form-check-label fw-bold text-gray-700 fs-6">
            <span className="form-check-label fw-semibold text-gray-700 fs-base ms-1">
              I Accept the
              <button
                type="button"
                className="text-primary bg-transparent border-transparent ms-1 link-primary cursor-pointer fw-semibold px-0"
                data-bs-toggle="modal"
                data-bs-target="#terms"
              >
                Terms & conditions
              </button>
            </span>
          </label>
          {formik.errors.acceptTerms && formik.touched.acceptTerms && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.acceptTerms}
            </p>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="d-grid mb-10">
        <button
          type="submit"
          className="btn btn-primary w-100"
          disabled={
            formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms
          }
        >
          {loading ? <WaitButtonSpan /> : "Sign up"}
        </button>
      </div>

      <div className="text-gray-500 text-center fw-semibold fs-6">
        Already have an Account?
        <Link to="/" className="link-primary ms-1">
          Sign in
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  );
}
