import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { WebappContext } from "../../_oxapay/layout/core/WebappContext";
import { IconArrowIOS } from "../staticIcons/IconArrowIOS";

interface Option {
  value: string;
  label: string;
  image: string;
  disable?: boolean;
}

interface Props {
  type?: string;
  value: string;
  options: Option[];
  onChange: (value: string | undefined) => void;
  defaultValue?: string;
}

export const WebappSelectWithImage = ({
  type = "default",
  value,
  options,
  onChange,
  defaultValue = "Select",
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const { webappColors } = useContext(WebappContext);

  const handleSelect = (selectedValue: string) => {
    setShowModal(false);
    selectedValue !== value && onChange(selectedValue);
  };

  useEffect(() => {
    onChange(value);
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    const WebappFn = (window as any).Telegram?.WebApp;
    if (showModal) {
      WebappFn?.setHeaderColor(webappColors.bgBackdrop);
    } else {
      WebappFn?.setHeaderColor(webappColors.bgGray200);
    }
    // eslint-disable-next-line
  }, [webappColors, showModal]);

  return (
    <>
      {type === "default" ? (
        <>
          <p className="fs-6 text-uppercase fw-400 text-gray-600 ms-4 mb-2">
            {defaultValue}
          </p>
          <div className="card rounded-12px" onClick={() => setShowModal(true)}>
            <div
              className={`cursor-pointer d-flex align-items-center justify-content-between ${
                value !== "" ? "ps-5" : "ps-6"
              } pe-7`}
              style={{ height: "60px" }}
            >
              {/* Display selected value */}
              <div className="d-flex align-items-center py-3">
                {/* Render selected value image and label */}
                {value && (
                  <div className="w-40px overflow-hidden me-3">
                    <div className="symbol-label">
                      <img
                        src={toAbsoluteUrl(
                          options.find((opt) => opt.value === value)?.image ||
                            ""
                        )}
                        alt={"send-type"}
                        className="w-100"
                      />
                    </div>
                  </div>
                )}
                <div className="d-flex flex-column">
                  <h4 className="d-flex align-items-center fs-2 fw-400 text-dark lh-1 mb-0 mt-1">
                    {options.find((opt) => opt.value === value)?.label ||
                      defaultValue}
                  </h4>
                </div>
              </div>
              <div className="d-flex flex-column align-items-end">
                <IconArrowIOS />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          className="bg-gray-200 rounded-12px px-3 py-4 cursor-pointer"
          onClick={() => setShowModal(true)}
        >
          <div className="d-flex flex-row justify-content-between align-items-center w-100px ps-1 pe-2">
            {value ? (
              <div className="d-flex flex-row justify-content-center align-items-center">
                <img
                  src={toAbsoluteUrl(
                    options.find((opt) => opt.value === value)?.image || ""
                  )}
                  alt={"send-type"}
                  className="w-25px me-3 "
                />
                <p className="mb-0 fs-5 fw-semibold text-gray-800">
                  {options.find((opt) => opt.value === value)?.label ||
                    defaultValue}
                </p>
              </div>
            ) : (
              <p className="m-0 fs-5 fw-semibold text-gray-800">
                {defaultValue}
              </p>
            )}

            <div className="rotate-90">
              <IconArrowIOS />
            </div>
          </div>
        </div>
      )}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        className="sheet-modal sheet-height-60 gray-modal fadeup-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-1">{defaultValue}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="scrollable-content">
            <div className="card mb-10">
              {/* Render options */}
              {options.map((item, index) => (
                <div key={index} className="cursor-pointer">
                  <div
                    onClick={() => handleSelect(item.value)}
                    className={`d-flex align-items-center justify-content-between ps-5 pe-7 ${
                      item.disable && "disable gray-scale-1"
                    }`}
                  >
                    {/* Render each option's image and label */}
                    <div className="d-flex align-items-center py-3">
                      <div className="w-40px overflow-hidden me-3">
                        <div className="symbol-label">
                          <img
                            src={toAbsoluteUrl(item.image)}
                            alt={"send-type"}
                            className="w-100"
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-column">
                        <h4 className="d-flex align-items-center fs-2 fw-400 text-dark lh-1 mb-0 mt-1">
                          {item.label}
                        </h4>
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-end">
                      {item.disable && (
                        <span className="badge badge-light fs-6">Disabled</span>
                      )}
                      {item.value === value && (
                        <i className="fa-solid fa-check text-primary"></i>
                      )}
                    </div>
                  </div>
                  {index !== options.length - 1 && (
                    <hr
                      className="border-gray-500 w-100 m-0 right-0"
                      style={{ maxWidth: "300px" }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
