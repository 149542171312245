export const loadRecaptchaScript = (callback: any) => {
  const existingScript = document.getElementById("recaptchaScript");

  if (!existingScript) {
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?render=6LcWhkAiAAAAAIAonpuY-qL4xmLNcNUvTJavUSPu";
    script.id = "recaptchaScript";
    script.setAttribute("data-callback", "onSubmit");
    script.onload = () => {
      if (callback) callback();
    };
    document.body.appendChild(script);
  } else if (callback) {
    callback();
  }

  return () => {
    const scriptElement = document.getElementById("recaptchaScript");
    if (scriptElement) {
      scriptElement.remove();
    }
  };
};
