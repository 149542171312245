import React, { useContext } from "react";
import { KTSVG, toAbsoluteUrl } from "../../_oxapay/helpers";
import { DrawerContext } from "../../_oxapay/layout/core/DrawerContext";
import { ChatInner } from "./ChatInner";

export const AccountMessenger = () => {
  const { setShowDrawer, setComponent } = useContext(DrawerContext);

  return (
    <div className="d-flex flex-column vh-100">
      <div className="w-100">
        <div className="card-header pe-5">
          <div className="card-title">
            <div className="d-flex flex-row">
              <div className="symbol symbol-35px symbol-circle ">
                <img
                  alt="Pic"
                  src={toAbsoluteUrl(`/media/logos/mainlogo.svg`)}
                  className="me-2"
                />
              </div>
            </div>
            <div className="d-flex justify-content-center flex-column me-3">
              <p className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-0 lh-1">
                Daniel
              </p>

              <div className="mb-0 lh-1">
                <span className="fs-7 fw-bold text-gray-400">
                  Account manager
                </span>
              </div>
            </div>
          </div>

          <div className="card-toolbar">
            <div
              className="btn btn-sm btn-icon btn-active-light-primary"
              onClick={() => {
                setShowDrawer(false);
                setTimeout(() => {
                  setComponent(<></>);
                }, 300);
              }}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-2"
              />
            </div>
          </div>
        </div>
      </div>
      <ChatInner />
      <a
        href="https://t.me/danielsup"
        className="text-hover-primary fs-6 cursor-pointer"
        target={"_blank"}
        rel={"noreferrer"}
      >
        <div className="w-100 bg-light px-2 pt-2 pb-2">
          <div className="btn btn-primary w-100 rounded shine mb-2">
            Send Message in telegram
          </div>
        </div>
      </a>
    </div>
  );
};
