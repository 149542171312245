import React, { useContext } from "react";
import { AppContext } from "../_oxapay/layout/core/AppContext";

interface props {
  fromCurrency: string;
  toCurrency: string;
}

export const SwapPairNames = ({ fromCurrency, toCurrency }: props) => {
  const { coinsListMap } = useContext(AppContext);
  return (
    <div className="d-flex justify-content-center mb-5">
      <div>
        <p className="fs-4 text-muted fw-semibold m-0">Swapping</p>
        <p className="fs-1 text-gray-800 fw-bold" style={{ marginTop: "-7px" }}>
          {coinsListMap[fromCurrency]?.name} to {coinsListMap[toCurrency]?.name}
        </p>
      </div>
    </div>
  );
};
