import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { PayoutApiContext } from "../../app/Core/PayoutApiContext";
import { alertsMapDeleteItem } from "../../app/services/alertsMap";
import { DeleteSwalFire } from "../../app/services/deleteSwalFire";
import { errorHandler } from "../../app/services/errorHandler";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { SuccessModalFire } from "../../app/services/successSwalFire";
import { useRequest } from "../../app/services/userHttpRequests";
import { ModalContext } from "../../_oxapay/layout/core/ModalContext";
import { PayoutApiForm } from "../forms/PayoutApiForm";
import { ModalBodyTitle } from "../modals/ModalBodyTitle";
import { TableBodyLink } from "../TableBodyLink";
import { TableDeleteButton } from "../TableDeleteButton";
import { TableEditButton } from "../TableEditButton";
import { TablePlaceholder } from "../TablePlaceholder";
import { TableSearch } from "../TableSearch";
import { TablesWidget } from "../TableWidget";
import { TextListWidget } from "../TextListWidget";

export const PayoutApiTable = () => {
  const { setComponent, setModalWidth, setShowModal } =
    useContext(ModalContext);
  const { listPayoutApi, deletePayoutApi } = useRequest();
  const {
    payoutApi,
    setPayoutApi,
    payoutApiPage,
    setPayoutApiPage,
    payoutApiSearch,
    setPayoutApiSearch,
  } = useContext(PayoutApiContext);
  const [payoutApiLoading, setPayoutApiLoading] = useState(true);

  const deleteHandler = async (id: string) => {
    const deletePayoutApiFn = async () => {
      return deletePayoutApi(id)
        .then((res) => {
          if (res.status === 200 && res.result === true) {
            return res;
          } else {
            FailedModalFire(alertsMapDeleteItem[res.errorNum.toString()]);
          }
        })
        .catch((error) => {
          errorHandler(error);
        });
    };

    DeleteSwalFire(deletePayoutApiFn).then((res) => {
      if (res.value !== undefined) {
        setPayoutApiSearch("");
        setPayoutApi((res.value as any).data);
        setPayoutApiPage((res.value as any).meta);
        SuccessModalFire(`The Payout API key was deleted successfully`);
      }
    });
  };

  useEffect(() => {
    setPayoutApiLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    listPayoutApi(payoutApiSearch, payoutApiPage.page, { signal })
      .then((res) => {
        if (res.status === 200) {
          setPayoutApi(res.data);
          setPayoutApiPage(res.meta);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      })
      .finally(() => {
        setPayoutApiLoading(false);
      });

    return () => controller.abort();
    // eslint-disable-next-line
  }, [payoutApiSearch, payoutApiPage.page]);

  return (
    <TablesWidget
      title="Your Payout API keys"
      className="mb-5 mb-xl-10"
      tableHead={[
        "Name",
        "Allowed IPs",
        "Total daily transfer limits",
        "Transfer limit per transaction",
        "Payout API key",
        "Actions",
      ]}
      loading={payoutApiLoading}
      noData={payoutApi?.length === 0 && !payoutApiLoading ? true : false}
      size={[null, null, 120, 120, 250, 100]}
      searchInput={
        <TableSearch
          value={payoutApiSearch}
          onChange={() => setPayoutApiLoading(true)}
          onDebouncedChange={(search) => {
            setPayoutApiSearch(search);
            setPayoutApiPage({ ...payoutApiPage, page: 1 });
          }}
        />
      }
      activePage={payoutApiPage.page}
      pages={payoutApiPage.pages}
      pageChange={(page) => setPayoutApiPage({ ...payoutApiPage, page: page })}
      document={[
        "https://docs.oxapay.com/api-reference/creating-payout",
        "Payout API docs",
      ]}
    >
      {payoutApiLoading === true && <TablePlaceholder config={[10, 6]} />}
      {!payoutApiLoading &&
        payoutApi !== undefined &&
        payoutApi.map((data, index) => (
          <tr key={index}>
            <td>
              <span className="text-dark fw-bold fs-6">{data.name}</span>
            </td>
            <td>
              {data.allowedIps.length !== 0 ? (
                <button
                  className="text-primary fw-semibold bg-transparent border-transparent"
                  onClick={() => {
                    setModalWidth(450);
                    setComponent(
                      <>
                        <ModalBodyTitle
                          title={`Allowed IPs list`}
                          subTitle={`You can check all of allowed IPs list here.`}
                          className="mt-8 mb-15"
                        />
                        <TextListWidget list={data.allowedIps} />
                      </>
                    );
                    setShowModal(true);
                  }}
                >
                  IPs list
                </button>
              ) : (
                <span className="text-gray-600 fw-bold fs-6">All</span>
              )}
            </td>
            <td>
              <span className="text-dark fw-bold fs-6">
                {data.limit.day.toString() === "0" ? "-" : "$" + data.limit.day}
              </span>
            </td>
            <td>
              <span className="text-dark fw-bold fs-6">
                {data.limit.transaction.toString() === "0"
                  ? "-"
                  : "$" + data.limit.transaction}
              </span>
            </td>
            <td>
              <TableBodyLink>{data.apiKey}</TableBodyLink>
            </td>
            <td>
              <div className="d-flex justify-content-start flex-shrink-0">
                <TableEditButton
                  onClick={() => {
                    setModalWidth(400);
                    setComponent(
                      <PayoutApiForm
                        payoutApi={{
                          id: data.id.toString(),
                          name: data.name,
                          ips: data.allowedIps,
                          dailyLimit:
                            data.limit.day === 0
                              ? ""
                              : data.limit.day.toString(),
                          trLimit:
                            data.limit.transaction === 0
                              ? ""
                              : data.limit.transaction.toString(),
                        }}
                      />
                    );
                    setShowModal(true);
                  }}
                />
                <TableDeleteButton
                  onClick={() => {
                    deleteHandler(data.id);
                  }}
                />
              </div>
            </td>
          </tr>
        ))}
    </TablesWidget>
  );
};
