import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PaymentlinkContext } from "../../app/Core/PaymentlinkContext";
import { alertsMapDeleteItem } from "../../app/services/alertsMap";
import { DeleteSwalFire } from "../../app/services/deleteSwalFire";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { SuccessModalFire } from "../../app/services/successSwalFire";
import { useRequest } from "../../app/services/userHttpRequests";
import { TableBodyCoins } from "../TableBodyCoins";
import { TableBodyLink } from "../TableBodyLink";
import { TableDeleteButton } from "../TableDeleteButton";
import { TablePlaceholder } from "../TablePlaceholder";
import { TablesWidget } from "../TableWidget";
import { DateTime } from "../../app/services/functionServices";
import { errorHandler } from "../../app/services/errorHandler";
import { TableSearch } from "../TableSearch";
import { DescriptionTable } from "../DescriptionTable";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { AppContext } from "../../_oxapay/layout/core/AppContext";

export const PaymentLinkTable = () => {
  const intl = useIntl();
  const { deletePaymentLink, listPaymentLink } = useRequest();
  const {
    paymentlinks,
    setPaymentlinks,
    paymentlinksPage,
    setPaymentlinksPage,
    paymentlinksSearch,
    setPaymentlinksSearch,
  } = useContext(PaymentlinkContext);
  const [paymentlinksLoading, setPaymentlinksLoading] = useState(true);
  const { coinsListMap } = useContext(AppContext);

  const deleteHandler = async (id: string) => {
    const deletePaymentlink = async () => {
      return deletePaymentLink(id)
        .then((res) => {
          if (res.status === 200 && res.result === true) {
            return res;
          } else {
            FailedModalFire(alertsMapDeleteItem[res.errorNum.toString()]);
          }
        })
        .catch((error) => {
          errorHandler(error);
        });
    };

    DeleteSwalFire(deletePaymentlink).then((res) => {
      if (res.value !== undefined) {
        setPaymentlinksSearch("");
        setPaymentlinks((res.value as any).data);
        setPaymentlinksPage((res.value as any).meta);
        SuccessModalFire(
          `${intl.formatMessage({ id: "PAYMENTLINK.SUCCESS.SWAL.MESSAGE" })}`
        );
      }
    });
  };

  useEffect(() => {
    setPaymentlinksLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    listPaymentLink(paymentlinksSearch, paymentlinksPage.page, { signal })
      .then((res) => {
        if (res.status === 200) {
          setPaymentlinks(res.data);
          setPaymentlinksPage(res.meta);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      })
      .finally(() => {
        setPaymentlinksLoading(false);
      });

    return () => controller.abort();
    // eslint-disable-next-line
  }, [paymentlinksSearch, paymentlinksPage.page]);

  return (
    <>
      <TablesWidget
        title="Your payment links"
        className="mb-5 mb-xl-10"
        tableHead={[
          "Name",
          "Amount",
          "Accepted coins",
          "Fee paid by payer",
          "Underpaid",
          "Description",
          "Link",
          "Created at",
          "Actions",
        ]}
        size={[100, 50, 100, 50, 50, 50, 250, 150, 50]}
        loading={paymentlinksLoading}
        noData={
          paymentlinks?.length === 0 && !paymentlinksLoading ? true : false
        }
        searchInput={
          <TableSearch
            value={paymentlinksSearch}
            onChange={() => setPaymentlinksLoading(true)}
            onDebouncedChange={(search) => {
              setPaymentlinksSearch(search);
              setPaymentlinksPage({ ...paymentlinksPage, page: 1 });
            }}
          />
        }
        activePage={paymentlinksPage.page}
        pages={paymentlinksPage.pages}
        pageChange={(page) =>
          setPaymentlinksPage({ ...paymentlinksPage, page: page })
        }
      >
        {paymentlinksLoading === true && <TablePlaceholder config={[10, 9]} />}
        {!paymentlinksLoading &&
          paymentlinks !== undefined &&
          paymentlinks.map((data) => (
            <tr key={data.id}>
              <td>
                <span className="text-dark fw-bold fs-6">{data.name}</span>
              </td>
              <td>
                <span className="text-gray-600 fw-bold fs-6">
                  {Number(data.price).toLocaleString()} {data.priceCurrency}
                </span>
              </td>
              <td>
                <TableBodyCoins acceptedCoins={data.coins} />
              </td>
              <td>
                {parseFloat(data.feePaidByPayer) === 1 ? (
                  <img
                    src={toAbsoluteUrl(
                      "/media/icons/duotune/general/gen037.svg"
                    )}
                    className="w-30px"
                    alt="check"
                  />
                ) : (
                  <span className="text-dark fw-bold fs-6 mx-3">-</span>
                )}
              </td>
              <td>
                <span className="text-gray-600 fw-bold fs-6">
                  {data.underPaid}%
                </span>
              </td>
              <td>
                {data.description ? (
                  <DescriptionTable
                    text={data.description}
                    className="text-gray-600 fs-6 cursor-pointer"
                  />
                ) : (
                  "-"
                )}
              </td>
              <td>
                <TableBodyLink
                  disable={
                    data.coins.filter(
                      (coinSymbol) => coinsListMap[coinSymbol]?.status === true
                    ).length
                      ? false
                      : true
                  }
                >
                  {data.url}
                </TableBodyLink>
              </td>
              <td>
                <span className="text-gray-600 fw-bold fs-6">
                  {DateTime(parseInt(data.date))}
                </span>
              </td>
              <td>
                <div className="d-flex justify-content-start flex-shrink-0">
                  <TableDeleteButton onClick={() => deleteHandler(data.id)} />
                </div>
              </td>
            </tr>
          ))}
      </TablesWidget>
    </>
  );
};
