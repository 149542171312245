import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Collapse, OverlayTrigger, Popover } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { MerchantContext } from "../../app/Core/MerchantsContext";
import { useAuth } from "../../app/modules/auth";
import {
  alertsMapEditMerchant,
  alertsMapNewMerchant,
} from "../../app/services/alertsMap";
import { errorHandler } from "../../app/services/errorHandler";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { defaultCoin } from "../../app/services/mapHelper";
import { SuccessModalFire } from "../../app/services/successSwalFire";
import { useRequest } from "../../app/services/userHttpRequests";
import { ModalContext } from "../../_oxapay/layout/core/ModalContext";
import { AcceptedCoinsWidget } from "../AcceptedCoinsWidget";
import { Slider } from "../Slider";
import { SwitchSignleInput } from "../SwitchSignleInput";
import { WaitButtonSpan } from "../WaitButtonSpan";

const popover = (
  <Popover>
    <Popover.Header as="h3">Your merchant logo (optional)</Popover.Header>
    <Popover.Body>
      This logo will be displayed on your merchant payment page. The logo should
      has a
      <strong>
        {" "}
        transparent background and be in dark colors in PNG or SVG
      </strong>{" "}
      format.
    </Popover.Body>
  </Popover>
);

const underPaidPopover = (
  <Popover>
    <Popover.Header as="h3">What is Underpaid cover?</Popover.Header>
    <Popover.Body>
      Underpaid signifies that if the payer submits a payment that is slightly
      less (by an amount equal to or less than the underpaid amount), than the
      invoiced amount, the invoice will still be accepted.
    </Popover.Body>
  </Popover>
);

interface MerchantModel {
  id: string;
  name: string;
  logourl?: string;
  feePaidByPayer: number;
  acceptedCoins: string[];
  underPaid: string;
  toCurrency: string;
  autoWithdraw: boolean;
}

interface props {
  merchant?: MerchantModel;
}

export const MerchantsForm = ({ merchant }: props) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(true);
  const { setShowModal } = useContext(ModalContext);
  const { newMerchant, editMerchant } = useRequest();
  const { setMerchants, setMerchantsPage, setMerchantsSearch } =
    useContext(MerchantContext);

  const formik = useFormik({
    initialValues: {
      name: merchant?.name || "",
      logourl: merchant?.logourl || "",
      feePaidByPayer: merchant?.feePaidByPayer || 0,
      selectedCoins: merchant?.acceptedCoins || [defaultCoin],
      underPaid: merchant?.underPaid || "0",
      toCurrency: merchant?.toCurrency || "",
      autoWithdraw: merchant?.autoWithdraw || false,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Name must be at least 3 characters")
        .max(30, "Name must be less than 30 characters")
        .required("Name is required"),
      selectedCoins: Yup.array().min(1, "You must select at least 1 coin"),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        if (merchant) {
          const res = await editMerchant(
            merchant.id,
            values.name,
            values.selectedCoins,
            values.logourl,
            values.feePaidByPayer,
            values.underPaid,
            values.toCurrency,
            values.autoWithdraw
          );
          if (res.status === 200 && res.result === true) {
            setMerchantsSearch("");
            setMerchants(res.data);
            setMerchantsPage(res.meta);
            SuccessModalFire("Your merchant API key was updated successfully");
            setShowModal(false);
          } else {
            FailedModalFire(alertsMapEditMerchant[res.errorNum.toString()]);
          }
        } else {
          const res = await newMerchant(
            values.name,
            values.selectedCoins,
            values.logourl,
            values.feePaidByPayer,
            values.underPaid,
            values.toCurrency,
            values.autoWithdraw
          );
          if (res.status === 200 && res.result === true) {
            setMerchantsSearch("");
            setMerchants(res.data);
            setMerchantsPage(res.meta);
            SuccessModalFire(
              "Your merchant API key was generated successfully"
            );
            resetForm();
          } else {
            FailedModalFire(alertsMapNewMerchant[res.errorNum.toString()]);
          }
        }
      } catch (error) {
        errorHandler(error as Error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (merchant) {
      formik.setValues({
        name: merchant.name,
        logourl: merchant.logourl ?? "",
        feePaidByPayer: merchant.feePaidByPayer,
        selectedCoins: merchant.acceptedCoins,
        underPaid: merchant.underPaid,
        toCurrency: merchant.toCurrency,
        autoWithdraw: merchant?.autoWithdraw,
      });
    }
    // eslint-disable-next-line
  }, [merchant]);

  const merchantValues = merchant;
  useEffect(() => {
    if (
      merchantValues?.toCurrency === formik.values.toCurrency &&
      merchantValues?.name === formik.values.name &&
      merchantValues?.logourl === formik.values.logourl &&
      merchantValues?.underPaid === formik.values.underPaid &&
      merchantValues?.autoWithdraw === formik.values.autoWithdraw &&
      Number(merchantValues?.feePaidByPayer) ===
        Number(formik.values.feePaidByPayer) &&
      JSON.stringify(merchantValues?.acceptedCoins.sort()) ===
        JSON.stringify(formik.values.selectedCoins.sort())
    ) {
      setDisableUpdate(true);
    } else {
      setDisableUpdate(false);
    }
  }, [merchantValues, formik.values]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="card h-md-100">
          <div className="card-body d-flex flex-column flex-center">
            <div className="w-100 mb-5">
              <h4 className="fs-5 fw-semibold text-gray-800">Name</h4>
              <input
                type="text"
                className={`form-control form-control-solid ${
                  formik.errors.name &&
                  formik.touched.name &&
                  "is-invalid border border-danger"
                }`}
                autoComplete="off"
                name="name"
                maxLength={30}
                placeholder="Name [3-30 Characters]"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {formik.errors.name && formik.touched.name && (
                <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                  {formik.errors.name}
                </p>
              )}
            </div>
            <div className="w-100 mb-5">
              <h4 className="fs-5 fw-semibold text-gray-800">
                Logo URL
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="bottom"
                  overlay={popover}
                >
                  <span className="badge badge-circle badge-sm badge-outline badge-primary cursor-pointer ms-1">
                    ?
                  </span>
                </OverlayTrigger>
              </h4>
              <input
                type="text"
                name="logourl"
                className="form-control form-control-solid"
                autoComplete="off"
                placeholder="Your merchant logo URL"
                onChange={formik.handleChange}
                value={formik.values.logourl}
              />
            </div>

            <div className="w-100 mb-5">
              <AcceptedCoinsWidget
                height={150}
                className={
                  formik.errors.selectedCoins && "border border-danger"
                }
                value={formik.values.selectedCoins}
                onChange={(selectedCoins) => {
                  formik.setFieldValue("selectedCoins", selectedCoins);
                }}
              />
              {formik.errors.selectedCoins && (
                <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                  {formik.errors.selectedCoins}
                </p>
              )}
            </div>
            <div className="w-100 mb-5">
              <h4 className="fs-5 fw-semibold text-gray-800">
                Underpaid cover
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={underPaidPopover}
                >
                  <span className="badge badge-circle badge-sm badge-outline badge-primary cursor-pointer ms-1">
                    ?
                  </span>
                </OverlayTrigger>
              </h4>
              <Slider
                className="mt-4"
                valueLabel={true}
                min="0"
                max="60"
                step="0.1"
                alertValue="10"
                alertMessage={`Warning: Payments that are ${formik.values.underPaid}%
                 less than the invoiced amount will still be accepted.`}
                value={formik.values.underPaid}
                onChange={(value) => formik.setFieldValue("underPaid", value)}
              />
            </div>
            <div className="w-100 bg-light-secondary rounded mb-7 py-1">
              <SwitchSignleInput
                label="Fee paid by payer"
                help="Your customer will cover all the fees for this payment."
                value={
                  Number(formik.values.feePaidByPayer) === 1 ? true : false
                }
                onChange={(value) =>
                  formik.setFieldValue("feePaidByPayer", value ? 1 : 0)
                }
              />

              {currentUser?.access?.swap && (
                <>
                  <div className="separator border-gray-300 my-1" />
                  <SwitchSignleInput
                    label="Auto convert to USDT"
                    help="Your received currency will convert to USDT instantly."
                    value={formik.values.toCurrency !== "" ? true : false}
                    onChange={(value) =>
                      formik.setFieldValue("toCurrency", value ? "USDT" : "")
                    }
                  />
                </>
              )}
              <div className="separator border-gray-300 my-1" />
              <SwitchSignleInput
                label="Auto Withdraw"
                help="Your received currency will be sent to the address specified in your Address List on the Settings page, otherwise your OxaPay balance will be charged."
                value={formik.values.autoWithdraw}
                onChange={(value) =>
                  formik.setFieldValue("autoWithdraw", value)
                }
              />
              <Collapse in={formik.values.autoWithdraw}>
                <div className="px-4 mb-3">
                  <div className="bg-light-warning px-4 py-3 rounded">
                    Your received currency will be sent to the address specified
                    in your <b>Address List</b> on the{" "}
                    <Link to={"/settings"}>Settings page</Link>, otherwise your
                    OxaPay balance will be charged.
                  </div>
                </div>
              </Collapse>
            </div>
            <div className="d-flex align-items-end w-100">
              {merchant ? (
                <button
                  type="submit"
                  className="btn btn-primary fs-3 w-100"
                  disabled={disableUpdate || loading}
                >
                  {loading ? <WaitButtonSpan /> : "Save merchant changes"}
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary fs-3 w-100"
                  disabled={formik.isSubmitting}
                >
                  {loading ? <WaitButtonSpan /> : "Generate merchant API key"}
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
