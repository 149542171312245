import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useAuth } from "../../app/modules/auth";
import { useRequest } from "../../app/services/userHttpRequests";

import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { KTSVG, toAbsoluteUrl } from "../../_oxapay/helpers";
import { AddressListSkeletons } from "../AddressListSkeletons";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ModalContext } from "../../_oxapay/layout/core/ModalContext";
import { TwoFactorForm } from "./TwoFactorForm";
import { SubmitAddressListChanges } from "./SubmitAddressListChanges";
import { AddressBookModal } from "../addressbook/AddressBookModal";

export interface CoinAddress {
  address: string;
  coin: string;
  depositEnable: boolean;
  id: number;
  isMain: boolean;
  memo: string;
  memoEnable: boolean;
  network: string;
  status: boolean;
  universal: boolean;
  withdrawEnable: boolean;
  addressRegex: string;
}

interface FormType {
  id: number;
  coin: string;
  network: string;
  address: string;
  memo: string;
  universal: boolean;
}

export const AddressListForm = () => {
  const [showAddressBook, setShowAddressBook] = useState(false);
  const [currentInputAddressKey, setCurrentInputAddressKey] = useState("");
  const { setShowModal, setModalWidth, setComponent } =
    useContext(ModalContext);
  const { coinsLoading, coinsListMap } = useContext(AppContext);
  const [fetching, setFetching] = useState(true);
  const { currentUser } = useAuth();
  const { addressList } = useRequest();
  const [coinsAddress, setCoinsAddress] = useState<CoinAddress[]>([]);
  const [formInitialValue, setFormInitialValue] = useState<FormType[]>([]);

  useEffect(() => {
    addressList().then((res) => {
      if (res.status === 200) {
        setCoinsAddress(res.data);
        setFetching(false);
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (coinsAddress.length !== 0) {
      const newFormInitialValue = coinsAddress.map((item) => ({
        id: item.id,
        coin: item.coin,
        network: item.network,
        address: item.address,
        memo: item.memo,
        universal: item.universal,
      }));

      setFormInitialValue(newFormInitialValue);
      formik.setValues({ coinsAddress: newFormInitialValue });
    }
    // eslint-disable-next-line
  }, [coinsAddress]);

  const formik = useFormik({
    initialValues: {
      coinsAddress: formInitialValue,
    },
    onSubmit: async (values) => {
      if (
        formik.values.coinsAddress.some((item, index) => {
          const coin = coinsAddress[index];
          return (
            (!item.address && item.universal) ||
            (item.address && !new RegExp(coin.addressRegex).test(item.address))
          );
        })
      ) {
        return; // Halt the process if any address is invalid
      }
      setModalWidth(450);
      setComponent(
        <SubmitAddressListChanges
          coins={coinsAddress.map((coin) => ({
            id: coin.id,
            coin: coin.coin,
            network: coin.network,
            isMain: coin.isMain,
          }))}
          addressValues={values}
          onSuccess={(data) => setCoinsAddress(data)}
        />
      );
      setShowModal(true);
    },
  });

  const addressbookHandker = (key: string) => {
    setShowAddressBook(true);
    setCurrentInputAddressKey(key);
  };

  return currentUser?.twoFa ? (
    <>
      <form className="form" onSubmit={formik.handleSubmit}>
        {coinsLoading || fetching ? (
          <>
            <AddressListSkeletons />
            <AddressListSkeletons />
            <AddressListSkeletons />
            <AddressListSkeletons />
            <AddressListSkeletons />
            <AddressListSkeletons />
            <AddressListSkeletons />
            <AddressListSkeletons />
            <AddressListSkeletons />
            <AddressListSkeletons />
            <AddressListSkeletons />
            <div className="mb-7"></div>
          </>
        ) : (
          coinsAddress.map((item, index) => (
            <div
              className={`d-flex bg-light-secondary flex-column flex-sm-row rounded-12px justify-content-center align-items-center px-5 py-4 ${
                coinsAddress.length - 1 === index ? "mb-7" : "mb-3"
              } ${
                (!(
                  formik.values.coinsAddress[index]?.address &&
                  !new RegExp(item.addressRegex).test(
                    formik.values.coinsAddress[index]?.address
                  )
                ) &&
                  !item.isMain &&
                  formik.values.coinsAddress.some(
                    (itemValues) =>
                      itemValues.network === item.network &&
                      itemValues.universal === true
                  )) ||
                !item.status
                  ? "d-none"
                  : ""
              }`}
              key={item.id}
            >
              <label className="col-md-3 d-flex fw-semibold fs-6 justify-content-start align-items-center nowrap my-3">
                <img
                  src={toAbsoluteUrl(
                    `media/svg/coins/${coinsListMap[item.coin]?.slug}.svg`
                  )}
                  className="h-50px me-2"
                  alt="coin"
                />{" "}
                <span className="fw-bold">{coinsListMap[item.coin]?.name}</span>
                {!item.isMain && (
                  <span className="fw-bold ms-1">
                    (
                    {
                      coinsListMap[item.coin]?.networkList[item.network]
                        ?.network
                    }
                    )
                  </span>
                )}
              </label>
              <div className="flex-grow-1 align-items-center justify-content-center w-100">
                <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center gap-3">
                  <div className={`input-group input-group`}>
                    <input
                      name={`coinsAddress[${index}].address`}
                      value={formik.values.coinsAddress[index]?.address || ""}
                      onChange={formik.handleChange}
                      type="text"
                      className={`form-control fs-5 ${
                        formik.values.coinsAddress[index]?.address &&
                        !new RegExp(item.addressRegex).test(
                          formik.values.coinsAddress[index]?.address
                        )
                          ? "border border-danger"
                          : ""
                      }`}
                      placeholder="Address"
                    />
                    <span
                      className="input-group-text"
                      id="basic-addon2"
                      onClick={() => {
                        addressbookHandker(`coinsAddress[${index}].address`);
                      }}
                    >
                      <KTSVG
                        path="media/icons/duotune/communication/com005.svg"
                        className="svg-icon-primary svg-icon-1"
                      />
                    </span>
                  </div>

                  {item.memoEnable && (
                    <input
                      name={`coinsAddress[${index}].memo`}
                      value={formik.values.coinsAddress[index]?.memo || ""}
                      onChange={formik.handleChange}
                      type="text"
                      className="form-control fs-5"
                      placeholder="Memo"
                    />
                  )}
                </div>
                {formik.values.coinsAddress[index]?.address &&
                  !new RegExp(item.addressRegex).test(
                    formik.values.coinsAddress[index]?.address
                  ) && (
                    <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                      Address format is not valid
                    </p>
                  )}

                {item.isMain && (
                  <div className="mt-2">
                    <div className="form-check form-check-custom form-check-solid">
                      <input
                        className="form-check-input w-20px h-20px"
                        type="checkbox"
                        name={`coinsAddress[${index}].universal`}
                        checked={
                          formik.values.coinsAddress[index]?.universal || false
                        }
                        onChange={formik.handleChange}
                      />
                      <label className="form-check-label">
                        Set as universal address{" "}
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          placement="top"
                          overlay={
                            <Popover>
                              <Popover.Body>
                                A universal address can be used for all coins
                                that match its network with this coin network
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <span className="badge badge-circle badge-sm badge-outline badge-primary cursor-pointer ms-1">
                            ?
                          </span>
                        </OverlayTrigger>
                      </label>
                    </div>
                    {formik.values.coinsAddress[index]?.universal &&
                      !formik.values.coinsAddress[index]?.address && (
                        <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                          When universal is checked, address is required
                        </p>
                      )}
                  </div>
                )}
              </div>
            </div>
          ))
        )}

        <div className="card-footer d-flex justify-content-end pt-6 px-0 pb-0">
          <button
            type="reset"
            className="btn fs-5 text-muted text-hover-primary me-2"
            onClick={() =>
              formik.setFieldValue("coinsAddress", formInitialValue)
            }
          >
            Discard
          </button>
          <button
            type="submit"
            className="btn btn-primary me-2 px-6"
            disabled={
              JSON.stringify(formik.values.coinsAddress) ===
              JSON.stringify(formInitialValue)
            }
          >
            Save Changes
          </button>
        </div>
      </form>
      <AddressBookModal
        showAddressBook={showAddressBook}
        onClose={(value) => {
          value && formik.setFieldValue(currentInputAddressKey, value);
          setShowAddressBook(false);
        }}
      />
    </>
  ) : (
    <div className="row justify-content-center align-items-center my-20">
      <div className="col-md-5 col-12 text-center">
        <img
          src={toAbsoluteUrl(`/media/svg/misc/security.svg`)}
          className="w-150px"
          alt="twoFa"
        />
      </div>
      <div className="col-md-5 col-12 justify-content-center align-items-center">
        <div className="mb-15">
          <h1 className="fw-semibold text-gray-800 text-left fs-3 mt-5 mb-5">
            <span className="fw-bolder">
              Two-Factor Authentication is required
            </span>
          </h1>
          <p className="text-dark">
            For enhanced security, it is required to enable Two-Factor
            Authentication to change the Address List.
          </p>
        </div>
        <button
          type="button"
          className="btn btn-primary fs-3 w-100"
          onClick={() => {
            setModalWidth(650);
            setComponent(<TwoFactorForm />);
            setShowModal(true);
          }}
        >
          Enable Two-Factor
        </button>
      </div>
    </div>
  );
};
