import React, { useState } from "react";
import { toAbsoluteUrl } from "../_oxapay/helpers";
import { CodeBlock } from "../_oxapay/partials";

export const DonationModalWidget = ({ donateLink }: { donateLink: string }) => {
  const [btnTheme, setBtnTheme] = useState("light-btn");

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBtnTheme(event.target.value);
  };

  return (
    <>
      <div className="form px-10 pb-10 pt-5">
        <div className="mb-13 text-center">
          <h1 className="mb-3">Get Donation button code</h1>

          <div className="text-muted fw-semibold fs-5">
            Add the code in your website and enjoy your users donates.
          </div>
        </div>

        <div className="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
          <div className="row mb-7">
            <div className="col-6">
              <label className="form-check-clip text-center">
                <input
                  className="btn-check"
                  type="radio"
                  value="light-btn"
                  checked={btnTheme === "light-btn"}
                  name="option"
                  onChange={handleRadioChange}
                />
                <div className="form-check-wrapper">
                  <div className="form-check-indicator"></div>
                  <img
                    className="form-check-content"
                    alt="light-donate-button"
                    src={toAbsoluteUrl("/media/btn/light-btn.png")}
                  />
                </div>

                <div className="form-check-label">Light theme</div>
              </label>
            </div>

            <div className="col-6">
              <label className="form-check-clip text-center">
                <input
                  className="btn-check"
                  type="radio"
                  value="dark-btn"
                  checked={btnTheme === "dark-btn"}
                  name="option"
                  onChange={handleRadioChange}
                />
                <div className="form-check-wrapper">
                  <div className="form-check-indicator"></div>
                  <img
                    className="form-check-content"
                    alt="dark-donate-button"
                    src={toAbsoluteUrl("/media/btn/dark-btn.png")}
                  />
                </div>

                <div className="form-check-label">Dark theme</div>
              </label>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column">
          <div id="kt_clipboard_4" className="fs-6 fw-semibold me-2">
            Your button code
          </div>

          <CodeBlock
            code={`<a
href="${donateLink}"
target="_blank">
<img src="https://app.oxapay.com/media/btn/${btnTheme}.png"
style="width: 185px">
</a>`}
            language={"markup"}
          />
        </div>
      </div>
    </>
  );
};
