import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useAuth, UserModel } from "../../app/modules/auth";
import { alertsMapDisableTwoFa } from "../../app/services/alertsMap";
import { errorHandler } from "../../app/services/errorHandler";
import { SuccessModalFire } from "../../app/services/successSwalFire";
import { useRequest } from "../../app/services/userHttpRequests";
import { ModalContext } from "../../_oxapay/layout/core/ModalContext";
import { WaitButtonSpan } from "../WaitButtonSpan";

export const DisableTwoFactorForm = () => {
  const [loading, setLoading] = useState(false);
  const { currentUser, setCurrentUser } = useAuth();
  const { setShowModal, setComponent } = useContext(ModalContext);
  const { disableTwoFa } = useRequest();
  const [error, setError] = useState<string>();

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.number()
        .typeError("Authentication code must be a number")
        .required("Authentication code is required"),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        const data = await disableTwoFa(values.code);
        if (data.status === 200) {
          const updatedUser = {
            ...currentUser,
            twoFa: false,
          };
          setCurrentUser(updatedUser as UserModel);
          SuccessModalFire("Your Two-factor authentication was deactivated");
          setShowModal(false);
          resetForm();
        } else if (data.status === 0 && data.errorNum) {
          setError(alertsMapDisableTwoFa[data.errorNum.toString()]);
        }
      } catch (error) {
        errorHandler(error as Error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    setError("");
    // eslint-disable-next-line
  }, [formik.errors.code && formik.touched.code]);

  return (
    <div className="scroll-y pt-10 pb-15 px-lg-17" data-kt-element="apps">
      <h3 className="text-dark fw-bold mb-7">
        Disable Two-factor authentication
      </h3>
      <div className="text-gray-500 fw-semibold fs-6 mb-10">
        Enter 6 digit code to disable your Two-factor authentication.
      </div>
      <form className="form" onSubmit={formik.handleSubmit}>
        <div className="mb-10 fv-row">
          <input
            type="text"
            className={`form-control form-control-solid ${
              ((formik.errors.code && formik.touched.code) || error) &&
              "is-invalid border border-danger"
            }`}
            placeholder="Enter authentication code"
            autoComplete="off"
            name="code"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.code}
          />
          {formik.errors.code && formik.touched.code && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.code}
            </p>
          )}
          {error && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">{error}</p>
          )}
        </div>
        <div className="d-flex flex-center">
          <button
            type="button"
            className="btn btn-light me-3"
            onClick={() => {
              setShowModal(false);
              setComponent(<></>);
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={formik.isSubmitting}
          >
            {loading ? <WaitButtonSpan /> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};
