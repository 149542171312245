import React, { useContext } from "react";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { ModalContext } from "../../_oxapay/layout/core/ModalContext";
import { TwoFactorForm } from "../forms/TwoFactorForm";

interface props {
  message: string;
  className?: string;
}

export const TwoFactorWarning = ({ message, className = "p-10" }: props) => {
  const { setComponent, setModalWidth, setShowModal } =
    useContext(ModalContext);

  return (
    <div className={className}>
      <div className="text-center">
        <img
          src={toAbsoluteUrl(`/media/svg/misc/security.svg`)}
          className="w-150px mb-15"
          alt="twoFa"
        />
      </div>
      <div className="mb-15">
        <h1 className="fw-semibold text-gray-800 text-left fs-3 mt-5 mb-5">
          Enable <span className="fw-bolder">Two-Factor Authentication</span>
        </h1>
        <p className="text-dark">{message}</p>
      </div>
      <button
        type="button"
        className="btn btn-primary fs-3 w-100"
        onClick={() => {
          setModalWidth(650);
          setComponent(<TwoFactorForm />);
          setShowModal(true);
        }}
      >
        Enable Two-Factor
      </button>
    </div>
  );
};
