import React, { useContext } from "react";
import { KTSVG } from "../_oxapay/helpers";
import { DrawerContext } from "../_oxapay/layout/core/DrawerContext";
import { AccountMessenger } from "./Chats/AccountMessenger";

interface props {
  newMessages: Number;
}
export const TopBarAccountMessenger = ({ newMessages }: props) => {
  const { setComponent, setShowDrawer } = useContext(DrawerContext);
  return (
    <div className="position-relative d-flex align-items-center ms-4">
      {newMessages !== 0 && (
        <span
          className="badge badge-circle badge-danger position-absolute"
          style={{ top: "-10px", right: "-10px", zIndex: 1 }}
        >
          {newMessages >= 10 ? "+9" : newMessages?.toString()}
        </span>
      )}
      <div
        className="btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px position-relative"
        onClick={() => {
          setShowDrawer(true);
          setComponent(<AccountMessenger />);
        }}
      >
        <KTSVG
          path="/media/icons/duotune/communication/com003.svg"
          className="svg-icon-1"
        />
      </div>
    </div>
  );
};
