import React, { useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { WebappContext } from "../../_oxapay/layout/core/WebappContext";
import { WebappModalContext } from "../../_oxapay/layout/core/WebappModalContext";

export const WebappModal = () => {
  const { showModal, setShowModal, component, setComponent, dFlex, setDFlex } =
    useContext(WebappModalContext);
  const { webappColors } = useContext(WebappContext);

  useEffect(() => {
    const WebappFn = (window as any)?.Telegram?.WebApp;
    if (showModal) {
      console.log("showmodal");
      WebappFn?.setHeaderColor(webappColors.bgModal);
    } else {
      console.log("hideModalmodal");
      WebappFn?.setHeaderColor(webappColors.bgGray200);
    }
    // eslint-disable-next-line
  }, [webappColors, showModal]);

  return (
    <>
      <Modal
        fullscreen
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setComponent(<></>);
          setDFlex(true);
        }}
        centered
        className="fadeup-modal"
      >
        <Modal.Body className={`${dFlex && "d-flex justify-content-center"}`}>
          {component}
        </Modal.Body>
      </Modal>
    </>
  );
};
