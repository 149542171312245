import React, { useContext, useEffect, useState } from "react";
import { ShowAlertContext } from "../../_oxapay/layout/core/ShowAlertContext";

export const WebappAlert = () => {
  const { alertMessage, setShowAlert, alertIcon } =
    useContext(ShowAlertContext);
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
    const timeOut = setTimeout(() => {
      setShowAlert(false);
    }, 2500);

    return () => clearTimeout(timeOut);
    // eslint-disable-next-line
  }, [alertMessage]);

  return (
    <div
      key={key}
      className="alert tel-alert d-flex p-5 mx-5 animated animatedFadeInUp fadeInUp"
      role="alert"
    >
      <span className="svg-icon svg-icon-2hx svg-icon-white me-3">
        {alertIcon === "check" ? (
          <i className="fa-solid fa-check text-white fs-3"></i>
        ) : (
          <i className="fa-regular fa-copy text-white fs-3"></i>
        )}
      </span>
      <div className="d-flex flex-column">
        <h4 className="fw-semibold text-white m-0">{alertMessage}</h4>
      </div>
    </div>
  );
};
