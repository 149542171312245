import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Lock } from "./Lotties/Lock";

export const WebappSendLock = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const WebappFn = (window as any)?.Telegram?.WebApp;
    const mainButtonFn = () => {
      navigate("/webapp-wallet/enabletwofactor");
    };

    WebappFn?.MainButton?.show()
      .setText("Enable Two-Factor")
      .onClick(mainButtonFn);

    return () => {
      WebappFn?.MainButton?.hide().offClick(mainButtonFn);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center pt-20 pb-8">
      <div className="w-150px h-150px m-auto mt-15 mb-5">
        <Lock />
      </div>
      <p className="fw-600 fs-2 mb-3">
        Enable <b>Two-Factor Authentication</b>
      </p>
      <p className="text-gray-600 fs-6 lh-2 text-center mw-300px">
        For enhanced security, it is required to enable Two-Factor
        Authentication to transfer assets.
      </p>
    </div>
  );
};
