import React, { InputHTMLAttributes, ReactElement, useState } from "react";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { WebappAddressbookList } from "./WebappAddressbookList";

interface WebappInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  labelAddon?: ReactElement;
  qrCode?: boolean;
  addon?: ReactElement;
  error?: boolean;
  clear?: () => void;
  qrScanText?: string;
  onGetData?: (value: string) => void;
  onChangeModalShow?: (value: boolean) => void;
  addressbookWidget?: boolean;
}

export const WebappInput = ({
  label,
  labelAddon,
  qrCode = false,
  addon,
  error = false,
  clear,
  qrScanText = "Find QR that contains address",
  onGetData,
  onChangeModalShow,
  addressbookWidget = false,
  ...rest
}: WebappInputProps) => {
  // const pasteWithApproachTwo = async () => {
  //   const clipboardData = await navigator.clipboard.readText();
  //   onGetData && onGetData(clipboardData);
  // };

  // const handlePaste = async () => {
  //   try {
  //     (window as any).Telegram?.WebApp.readTextFromClipboard((e: any) => {
  //       if (e === null) {
  //         pasteWithApproachTwo();
  //       } else {
  //         e?.data && onGetData && onGetData(e?.data);
  //       }
  //     });
  //   } catch (error) {
  //     errorHandler(error as Error);
  //   }
  // };

  const [showAddressbook, setShowAddressbook] = useState(false);
  const qrCodeHandler = () => {
    const qrParams = {
      text: qrScanText,
    };

    // Show the QR code scanning popup
    (window as any).Telegram?.WebApp.showScanQrPopup(
      qrParams,
      (qrText: any) => {
        if (qrText) {
          (window as any).Telegram?.WebApp.closeScanQrPopup();
          onGetData && onGetData(qrText);
        } else {
          console.log("No QR code text found.");
        }
      }
    );
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-between ps-4 pe-4 mb-2">
        <p className="fs-6 text-uppercase fw-400 text-gray-600 mb-0">{label}</p>
        {labelAddon}
      </div>

      <div
        className={`card rounded-12px h-60px d-flex justify-content-center align-items-center flex-row ps-6 pe-6 ${
          error && "border border-danger"
        }`}
      >
        <input
          className="fs-2 flex-grow-1 text-dark webapp-custom-input w-100"
          {...rest}
        />
        <div className="d-flex flex-row justify-content-center align-items-center ps-2">
          {rest.value === "" ? (
            <>
              {/* <span
                onClick={handlePaste}
                className="text-primary cursor-pointer fs-3 me-2"
              >
                Paste
              </span> */}
              {addressbookWidget && (
                <img
                  onClick={() => {
                    onChangeModalShow && onChangeModalShow(true);
                    setShowAddressbook(true);
                  }}
                  className="h-20px me-2 cursor-pointer"
                  src={toAbsoluteUrl(
                    "/media/icons/wallet/addressbookwebappicon.svg"
                  )}
                  alt="scan"
                />
              )}
              {addon}
              {qrCode && (
                <img
                  onClick={qrCodeHandler}
                  className="h-20px ms-2 cursor-pointer"
                  src={toAbsoluteUrl("/media/icons/wallet/scan.svg")}
                  alt="scan"
                />
              )}
            </>
          ) : (
            <i
              onClick={clear}
              className="cursor-pointer fa-solid fa-xmark fs-3 bg-secondary rounded-12px py-1 px-2"
            ></i>
          )}
        </div>
      </div>

      {addressbookWidget && (
        <WebappAddressbookList
          showAddressbook={showAddressbook}
          onChange={(value) => {
            onChangeModalShow && onChangeModalShow(value);
            setShowAddressbook(value);
          }}
          onGetData={(value) => onGetData && onGetData(value)}
        />
      )}
    </>
  );
};
