export const getPriceInterval = 30000;

//home
export const homeRecentTxs = 10000;
export const homePaymentsSummary = 60000;
export const homeShareChartInterval = 600000;
export const homeStatsChartInterval = 60000;

//wallet
export const walletPortfoInterval = 10000;
export const walletBalanceInterval = 10000;
export const walletTxsInterval = 10000;

//paymentlink
export const paymentlinkSummaryInterval = 60000;
export const paymentLinkChartInterval = 300000;
export const paymentLinkTxsInterval = 10000;

//donationbutton
export const donationSummaryInterval = 60000;
export const donationChartInterval = 300000;
export const donationTxsInterval = 10000;

//channelGateway
export const channelGatewaySummaryInterval = 60000;
export const channelGatewayUsersInterval = 300000;
export const channelGatewayTxChartInterval = 300000;
export const channelGatewayListInterval = 60000;
export const channelGatewayTxsInterval = 10000;

//merchant
export const merchantSummaryInterval = 60000;
export const merchantChartInterval = 300000;
export const merchantTxsInterval = 10000;

//payoutApi
export const payoutApiSummaryInterval = 60000;
export const payoutApiChartInterval = 300000;
export const payoutApiTxInterval = 10000;

//referrals
export const refBalanceInterval = 30000;
export const refSummaryInterval = 60000;
export const refDetailInterval = 60000;
export const refStatsChartInterval = 300000;
export const refCommissionChartInterval = 300000;
export const commissionTxsInterval = 20000;

//activityLog
export const activityInterval = 10000;

//notifications
export const notificationsCountInterval = 10000;
export const notificationsDataInterval = 5000;

//swap
export const swapRateInterval = 10000;

//transfer check
export const checkTransferTxStatus = 5000;
