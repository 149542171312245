import { Slide, toast } from "react-toastify";
import { logError } from "./appRequests";

export const errorHandler = (error: Error) => {
  const pageUrl = window.location.href;
  console.log(pageUrl);
  const errorDetails = {
    message: error.message,
    stack: error.stack,
    // Extract the URL from the error stack
    url: error.stack?.match(/http:\/\/[^\s]*/)?.[0] || "Unknown URL",
    // You can extract more specific details based on your requirements
    // ...
  };
  if (error.message === "Network Error") {
    toast.info("No internet connection", {
      toastId: "networkError",
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      theme: "dark",
      closeButton: false,
      closeOnClick: false,
      draggable: false,
      transition: Slide,
      icon: false,
    });
  } else {
    if (error.message.startsWith("timeout")) {
      toast.info("Somethings went wrong", {
        toastId: "timeoutError",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        theme: "dark",
        closeButton: false,
        closeOnClick: false,
        draggable: false,
        transition: Slide,
        icon: false,
      });
    }
    logError(JSON.stringify(errorDetails));
    console.log("LOG", errorDetails);
  }
};
