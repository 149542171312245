import React from "react";
import { Modal } from "react-bootstrap";
import { IconCross } from "../staticIcons/IconCross";
import { AddressBookForm } from "./AddressBookForm";

interface props {
  showAddressBookForm: boolean;
  onClose: () => void;
  address?: string;
}
export const AddressBookFormModal = ({
  showAddressBookForm,
  onClose,
  address,
}: props) => {
  return (
    <Modal
      show={showAddressBookForm}
      onHide={onClose}
      size={"md" as any}
      centered
    >
      <Modal.Header className="border-none pb-0">
        <div className="w-100 d-flex justify-content-between align-items-center">
          <h4 className="modal-title">Address Book Form</h4>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            onClick={onClose}
          >
            <IconCross />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <AddressBookForm address={address} onSuccess={onClose} />
      </Modal.Body>
    </Modal>
  );
};
