/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { errorHandler } from "../../../../app/services/errorHandler";
import { notificationsDataInterval } from "../../../../app/services/intervalManage";
import { useRequest } from "../../../../app/services/userHttpRequests";
import { HeaderNotifCommission } from "../../../../components/headerNotifications/HeaderNotifCommission";
import { HeaderNotifDonation } from "../../../../components/headerNotifications/HeaderNotifDonation";
import { HeaderNotifMerchant } from "../../../../components/headerNotifications/HeaderNotifMerchant";
import { HeaderNotifPaymentlink } from "../../../../components/headerNotifications/HeaderNotifPaymentlink";
import { HeaderNotifReceive } from "../../../../components/headerNotifications/HeaderNotifReceive";
import { HeaderNotifReferral } from "../../../../components/headerNotifications/HeaderNotifReferral";
import { HeaderNotifSend } from "../../../../components/headerNotifications/HeaderNotifSend";
import { HeaderNotifSkeleton } from "../../../../components/headerNotifications/HeaderNotifSkeleton";
import { HeaderNotifTelegram } from "../../../../components/headerNotifications/HeaderNotifTelegram";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";

interface Notification {
  id: string;
  type: string;
  data?: any;
  date: string;
}

interface props {
  onChange: (show: boolean) => void;
}
export const HeaderNotificationsMenu = ({ onChange }: props) => {
  const notifDropDownRef = useRef<HTMLDivElement>(null);
  const [dropdownShow, setDropdownShow] = useState(false);

  useEffect(() => {
    const notifDropDownDiv = notifDropDownRef.current;

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          const currentClasses = (mutation.target as HTMLDivElement).className;

          if (currentClasses.includes("show")) {
            setDropdownShow(true);
          } else {
            setDropdownShow(false);
          }
        }
      }
    });

    if (notifDropDownDiv) {
      observer.observe(notifDropDownDiv, {
        attributes: true,
        attributeFilter: ["class"],
      });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const { notifications } = useRequest();
  const [loading, setLoading] = useState<boolean>(false);
  const [notificationsList, setNotificationsList] = useState<Notification[]>(
    []
  );

  useEffect(() => {
    onChange(dropdownShow);
    setLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchNotifs = async () => {
      try {
        const response = await notifications(1, {
          signal,
        });
        if (response.status === 200) {
          setNotificationsList(response.data);
          setLoading(false);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };

    if (dropdownShow) {
      fetchNotifs();
      const interval = setInterval(fetchNotifs, notificationsDataInterval);

      return () => {
        controller.abort(); // Cancel the fetch request
        clearInterval(interval); // Clean up the interval on component unmount
      };
    } else {
      return () => {
        controller.abort(); // Cancel the fetch request
      };
    }

    // eslint-disable-next-line
  }, [dropdownShow]);

  const lastTenNotifications = notificationsList.slice(0, 10);

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column w-100 mw-450px mw-lg-450px"
      data-kt-menu="true"
      ref={notifDropDownRef}
    >
      <div
        className="d-flex flex-column bgi-no-repeat rounded-top"
        style={{
          backgroundSize: "cover",
          backgroundImage: `url('${toAbsoluteUrl(
            "/media/patterns/pattern-2.jpg"
          )}')`,
        }}
      >
        <h3 className="text-white fw-bold px-9 mt-10 mb-10">
          Latest Notifications
        </h3>
      </div>
      <div>
        <div className="scroll-y mh-450px my-5 px-8">
          {notificationsList?.length === 0 && !loading && (
            <div className="d-flex justify-content-center align-items-center my-20">
              <img
                src={toAbsoluteUrl("/media/icons/duotune/files/fil012.svg")}
                className="w-30px opacity-20 my-10"
                alt="no-data"
              />
              <p className="ms-2 my-0 fw-semibold text-gray-500">
                No items found
              </p>
            </div>
          )}
          {loading ? (
            <>
              <HeaderNotifSkeleton />
              <HeaderNotifSkeleton />
              <HeaderNotifSkeleton />
              <HeaderNotifSkeleton />
              <HeaderNotifSkeleton />
              <HeaderNotifSkeleton />
              <HeaderNotifSkeleton />
              <HeaderNotifSkeleton />
            </>
          ) : (
            lastTenNotifications.map((item) => {
              if (item.type === "payment link") {
                return (
                  <HeaderNotifPaymentlink
                    key={item.id}
                    data={item.data as any}
                    date={item.date}
                  />
                );
              } else if (item.type === "donation") {
                return (
                  <HeaderNotifDonation
                    key={item.id}
                    data={item.data as any}
                    date={item.date}
                  />
                );
              } else if (item.type === "merchant") {
                return (
                  <HeaderNotifMerchant
                    key={item.id}
                    data={item.data as any}
                    date={item.date}
                  />
                );
              } else if (item.type === "telegram") {
                return (
                  <HeaderNotifTelegram
                    key={item.id}
                    data={item.data as any}
                    date={item.date}
                  />
                );
              } else if (item.type === "new referral") {
                return <HeaderNotifReferral key={item.id} date={item.date} />;
              } else if (item.type === "send") {
                return (
                  <HeaderNotifSend
                    key={item.id}
                    data={item.data as any}
                    date={item.date}
                  />
                );
              } else if (item.type === "receive") {
                return (
                  <HeaderNotifReceive
                    key={item.id}
                    data={item.data as any}
                    date={item.date}
                  />
                );
              } else if (item.type === "referral commission") {
                return (
                  <HeaderNotifCommission
                    key={item.id}
                    data={item.data as any}
                    date={item.date}
                  />
                );
              } else {
                return null; // or remove this block if you don't want to render anything
              }
            })
          )}
        </div>

        <div
          data-kt-menu-dismiss="true"
          className="py-3 text-center border-top"
        >
          <Link
            to="/notifications"
            className="btn btn-color-gray-600 btn-active-color-primary"
          >
            View All{" "}
            <KTSVG
              path="/media/icons/duotune/arrows/arr064.svg"
              className="svg-icon-5"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};
