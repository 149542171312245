import { useEffect, useState } from "react";

interface fields {
  name: {
    show: boolean;
    require: boolean;
  };
  email: {
    show: boolean;
    require: boolean;
  };
}
interface props {
  onChange: (fields: fields) => void;
  value: fields;
}
export const SwitchGroupWidget = ({ onChange, value }: props) => {
  const [fields, setFields] = useState(value);

  useEffect(() => {
    onChange(fields);
    // eslint-disable-next-line
  }, [fields]);

  useEffect(() => {
    setFields(value);
  }, [value]);

  return (
    <>
      <div className="d-flex justify-content-center px-5 pt-5 pb-2 w-100">
        <div className="flex-grow-1 me-2">
          <h4 className="fs-5 fw-semibold text-gray-800">Field</h4>
        </div>

        <div className="flex-grow-1 me-2">
          <h4 className="fs-5 fw-semibold text-gray-800">Show</h4>
        </div>

        <div className="flex-grow-1 me-2">
          <h4 className="fs-5 fw-semibold text-gray-800">Require</h4>
        </div>
      </div>
      <div className="bg-light-secondary rounded px-0 py-1 w-100 mb-7">
        <div className="d-flex align-items-center py-2 px-5">
          <div className="flex-grow-1 me-2">
            <h4 className="fs-5 fw-semibold text-gray-700 m-0">Name</h4>
          </div>

          <div className="flex-grow-1 me-2">
            <div className="form-check form-switch form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                checked={fields.name.show}
                onChange={() => {
                  setFields({
                    ...fields,
                    name: { show: !fields.name.show, require: false },
                  });
                }}
              />
            </div>
          </div>

          <div className="flex-grow-1 me-2">
            <div className="form-check form-switch form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                checked={fields.name.require}
                onChange={() => {
                  setFields({
                    ...fields,
                    name: { ...fields.name, require: !fields.name.require },
                  });
                }}
                disabled={!fields.name.show}
              />
            </div>
          </div>
        </div>
        <div className="separator border-gray-300 my-1" />
        <div className="d-flex align-items-center px-5 py-2">
          <div className="flex-grow-1 me-2">
            <h4 className="fs-5 fw-semibold text-gray-700 m-0">Email</h4>
          </div>

          <div className="flex-grow-1 me-2">
            <div className="form-check form-switch form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                checked={fields.email.show}
                onChange={() => {
                  setFields({
                    ...fields,
                    email: { show: !fields.email.show, require: false },
                  });
                }}
              />
            </div>
          </div>

          <div className="flex-grow-1 me-2">
            <div className="form-check form-switch form-check-custom form-check-solid">
              <input
                className="form-check-input"
                type="checkbox"
                checked={fields.email.require}
                onChange={() => {
                  setFields({
                    ...fields,
                    email: { ...fields.email, require: !fields.email.require },
                  });
                }}
                disabled={!fields.email.show}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
