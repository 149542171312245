import { createContext, useState, ReactNode } from "react";

export interface Merchants {
  id: string;
  name: string;
  logo: string;
  feePaidByPayer: number;
  underPaid: string;
  apiKey: string;
  date: string;
  coins: string[];
  toCurrency: string;
  autoWithdraw: boolean;
}

interface MerchantsPage {
  page: number;
  pages: number;
}

export interface MerchantContextModel {
  merchants?: Merchants[];
  setMerchants: (merchants: Merchants[]) => void;
  merchantsPage: MerchantsPage;
  setMerchantsPage: (MerchantsPage: MerchantsPage) => void;
  merchantsSearch: string;
  setMerchantsSearch: (merchantsSearch: string) => void;
}

export const MerchantContext = createContext<MerchantContextModel>({
  merchants: undefined,
  setMerchants: () => {},
  merchantsPage: {
    page: 1,
    pages: 0,
  },
  setMerchantsPage: () => {},
  merchantsSearch: "",
  setMerchantsSearch: () => {},
});

interface MerchantProviderProps {
  children: ReactNode;
}

const MerchantProvider: React.FC<MerchantProviderProps> = ({ children }) => {
  const [merchants, setMerchants] = useState<Merchants[] | undefined>(
    undefined
  );
  const [merchantsPage, setMerchantsPage] = useState({
    page: 1,
    pages: 0,
  });
  const [merchantsSearch, setMerchantsSearch] = useState("");

  return (
    <MerchantContext.Provider
      value={{
        merchants,
        setMerchants,
        merchantsPage,
        setMerchantsPage,
        merchantsSearch,
        setMerchantsSearch,
      }}
    >
      {children}
    </MerchantContext.Provider>
  );
};

export default MerchantProvider;
