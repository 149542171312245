/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode } from "react";
import { toAbsoluteUrl } from "../_oxapay/helpers";
import { ActiveStatus } from "./ActiveStatus";
import { Pagination } from "./Pagination";
import { Status } from "./Status";

interface Props {
  children: ReactNode;
  className: string;
  title: string;
  tableHead: string[];
  size: (number | null)[];
  status?: boolean;
  activeStatus?: boolean;
  document?: string[];
  activePage?: number;
  pages: number;
  noData?: boolean;
  filter?: boolean;
  filterCard?: ReactNode;
  searchInput?: ReactNode;
  pageChange: (pageNumber: number) => void;
  loading?: boolean;
  filterPlace?: "top" | "bottom";
}

const TablesWidget: React.FC<Props> = ({
  className,
  title,
  tableHead,
  size,
  status = false,
  activeStatus = false,
  children,
  document,
  activePage = 1,
  pages,
  noData = false,
  filter = false,
  filterCard,
  searchInput,
  pageChange,
  loading = false,
  filterPlace = "bottom",
}) => {
  return (
    <div className={`card ${className}`}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column fw-bold">
          {title}
        </h3>
        <div className="card-toolbar">
          {searchInput}
          {filter && (
            <>
              <button
                type="button"
                className="btn btn-sm btn-flex fw-bold bg-body bg-light-secondary btn-color-gray-600 btn-active-color-primary ms-3"
                data-kt-menu-trigger="click"
                data-kt-menu-placement={`${
                  filterPlace === "top" ? "top-end" : "bottom-end"
                }`}
                data-kt-menu-flip={`${
                  filterPlace === "top" ? "top-end" : "bottom-end"
                }`}
              >
                <i className="fa-solid fa-filter"></i>
                Filter
              </button>
              {filterCard}
            </>
          )}

          {status && <Status />}
          {activeStatus && <ActiveStatus />}

          {document && (
            <a
              href={document[0]}
              target="_blank"
              rel="noreferrer"
              className="text-primary fw-semibold ms-4 my-4"
            >
              <i className="fa-regular fa-file text-primary fs-3"></i>
              <span className="ms-2">{document[1]}</span>
            </a>
          )}
        </div>
      </div>
      <div className="card-body py-3">
        <div className="table-responsive">
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bold text-muted">
                {tableHead.map((header, index) => (
                  <th
                    key={index}
                    {...(size[index] !== null && {
                      className: "min-w-" + size[index] + "px",
                    })}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{children}</tbody>
          </table>
        </div>
        {noData && (
          <div className="d-flex justify-content-center align-items-center my-20">
            <img
              src={toAbsoluteUrl("/media/icons/duotune/files/fil012.svg")}
              className="w-30px opacity-20 my-10"
              alt="no-data"
            />
            <p className="ms-2 my-0 fw-semibold text-gray-500">
              No items found
            </p>
          </div>
        )}
      </div>
      {pages > 1 && (
        <Pagination
          loading={loading}
          activePage={activePage}
          pages={pages}
          onChange={(page) => pageChange(page)}
        />
      )}
    </div>
  );
};

export { TablesWidget };
