import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { WalletWebappHistoryList } from "./walletWebappHistoryList";

export const WalletWebappHistory = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const WebappFn = (window as any)?.Telegram?.WebApp;

    const backBtnFn = () => {
      navigate(`/webapp-wallet`);
    };

    WebappFn?.BackButton?.show().onClick(backBtnFn);

    return () => {
      WebappFn?.BackButton?.offClick(backBtnFn).hide();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center pt-15 pb-8">
        <img
          src={toAbsoluteUrl(`/media/icons/wallet/history.png`)}
          alt={"history"}
          className="w-80px mb-8"
        />
        <p className="fw-600 fs-2 mb-1">Transfers history</p>
        <p className="text-gray-600 fs-6 lh-normal text-center">
          For additional information, check your account
          <br />
          on oxapay.com to access further details.
        </p>
      </div>

      <div>
        <p className="fs-6 text-uppercase fw-400 text-gray-600 ms-4 mb-2">
          Transfers history
        </p>

        <WalletWebappHistoryList />
      </div>
    </>
  );
};
