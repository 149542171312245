import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { errorHandler } from "../app/services/errorHandler";
import { walletBalanceInterval } from "../app/services/intervalManage";
import { useRequest } from "../app/services/userHttpRequests";
import usePrice from "../hooks/usePrice";
import { AppContext } from "../_oxapay/layout/core/AppContext";
import { BalanceContext } from "../_oxapay/layout/core/BlanceContext";
import { Asset, AssetsList } from "./AssetsList";

export const WalletAssetsList = () => {
  const { getBalance } = useRequest();
  const { coinsPrice, priceLoading } = usePrice();
  const { coinsListMap } = useContext(AppContext);
  const { balance, setBalance } = useContext(BalanceContext);
  const [assets, setAssets] = useState<Asset[]>([]);
  const [balanceLoading, setBalanceLoading] = useState(true);

  useEffect(() => {
    const balanceAssets = Object.entries(coinsListMap).map(([coin]) => ({
      coin,
      price: 0,
      change: 0,
      amount: balance && balance[coin] ? balance[coin] : 0,
    }));

    const priceAssets = Object.entries(coinsPrice).map(([coin, details]) => ({
      coin,
      price: details.price,
      change: details.changePercent,
    }));

    const mergedAssets = balanceAssets.map((balanceAsset) => {
      const matchingPriceAsset = priceAssets.find(
        (priceAsset) => priceAsset.coin === balanceAsset.coin
      );
      return {
        ...balanceAsset,
        price: matchingPriceAsset?.price || balanceAsset.price,
        change: matchingPriceAsset?.change || balanceAsset.change,
      };
    });

    setAssets(mergedAssets);
  }, [coinsListMap, coinsPrice, balance]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchBalance = async () => {
      try {
        const response = await getBalance({ signal: controller.signal });
        if (response.status === 200) {
          const balanceData = response.data;
          setBalanceLoading(false);
          // Compare previous balance data with new data
          if (JSON.stringify(balance) !== JSON.stringify(balanceData)) {
            setAssets((prevAssets) =>
              prevAssets.map((asset) => ({
                ...asset,
                amount:
                  balanceData[asset.coin as keyof typeof balanceData] ||
                  asset.amount,
              }))
            );

            // Update previous balance data
            setBalance(balanceData);
          }
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };

    fetchBalance();
    const interval = setInterval(fetchBalance, walletBalanceInterval);

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval on component unmount
    };
    // eslint-disable-next-line
  }, []);

  return (
    <AssetsList
      assets={assets}
      height={450}
      priceLoading={priceLoading}
      balanceLoading={balanceLoading}
    />
  );
};
