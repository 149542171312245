import React from "react";

export const AcceptedCoinsPlaceholder = () => {
  return (
    <div className="d-flex align-items-center mb-3">
      <div className="w-checkbox h-20px bg-dark opacity-10 shine-sm rounded-6 me-2"></div>
      <div className="w-25px h-25px bg-dark opacity-10 shine-sm rounded-6 me-2"></div>
      <div className="w-100px h-20px bg-dark opacity-10 shine-sm rounded-6"></div>
    </div>
  );
};
