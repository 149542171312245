import React, { useContext } from "react";
import { toAbsoluteUrl } from "../_oxapay/helpers";
import { AppContext } from "../_oxapay/layout/core/AppContext";

interface props {
  currency: string;
}

export const TableBodyCurrency = ({ currency }: props) => {
  const { coinsListMap } = useContext(AppContext);

  return (
    <div className="symbol symbol-35px">
      <img
        className="me-2"
        alt={currency}
        src={toAbsoluteUrl(
          `/media/svg/coins/${coinsListMap[currency]?.slug}.svg`
        )}
      />
      <span className="fw-bold text-gray-600">
        {coinsListMap[currency]?.name}
      </span>
    </div>
  );
};
