import { createContext, useState, ReactNode } from "react";

export interface ShowAlertContextModel {
  showAlert?: boolean;
  setShowAlert: (showAlert: boolean) => void;
  alertMessage?: string;
  setAlertMessage: (alertMessage: string) => void;
  alertIcon?: string;
  setAlertIcon: (alertIcon: string) => void;
}

export const ShowAlertContext = createContext<ShowAlertContextModel>({
  showAlert: false,
  setShowAlert: () => {},
  alertMessage: "Copied",
  setAlertMessage: () => {},
  alertIcon: "copy",
  setAlertIcon: () => {},
});

interface ShowAlertProviderProps {
  children: ReactNode;
}

const ShowAlertProvider: React.FC<ShowAlertProviderProps> = ({ children }) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>();
  const [alertIcon, setAlertIcon] = useState<string>("copy");

  return (
    <ShowAlertContext.Provider
      value={{
        alertIcon,
        setAlertIcon,
        showAlert,
        setShowAlert,
        alertMessage,
        setAlertMessage,
      }}
    >
      {children}
    </ShowAlertContext.Provider>
  );
};

export default ShowAlertProvider;
