import React, { useContext, useEffect, useState } from "react";
import { Carousel, OverlayTrigger, Popover } from "react-bootstrap";
import { DeleteSwalFire } from "../../app/services/deleteSwalFire";
import { errorHandler } from "../../app/services/errorHandler";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { shortenString } from "../../app/services/functionServices";
import { SuccessModalFire } from "../../app/services/successSwalFire";
import { useRequest } from "../../app/services/userHttpRequests";
import { KTSVG, toAbsoluteUrl } from "../../_oxapay/helpers";
import { AddressBook, AppContext } from "../../_oxapay/layout/core/AppContext";
import { AddressBookForm } from "./AddressBookForm";
import { VerifiedAddress } from "./VerifiedAddress";

interface props {
  changePage: number;
  activePage: (active: number) => void;
  onClose: (address?: string) => void;
}
export const AddressBookList = ({ changePage, activePage, onClose }: props) => {
  const [updateFields, setUpdateFields] = useState<AddressBook>();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [filteredAddressbookList, setFilteredAddressbookList] = useState<
    AddressBook[]
  >([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { addressbook, addressbookLoading, setAddressbook } =
    useContext(AppContext);
  const { deleteAddressbook } = useRequest();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    activePage(activeIndex);
    // eslint-disable-next-line
  }, [activeIndex]);

  useEffect(() => {
    setActiveIndex(changePage);
    // eslint-disable-next-line
  }, [changePage]);

  const deleteHandler = async (id: string) => {
    const deletePaymentlink = async () => {
      return deleteAddressbook(id)
        .then((res) => {
          if (res.status === 200 && res.result === true) {
            return res;
          } else {
            FailedModalFire("Somethings went wrong");
          }
        })
        .catch((error) => {
          errorHandler(error);
        });
    };

    DeleteSwalFire(deletePaymentlink).then((res) => {
      if (res.value !== undefined) {
        setAddressbook((res.value as any).data);
        SuccessModalFire("Address was deleted successfully");
      }
    });
  };

  const addressClickHandler = (address: string) => {
    onClose(address);
  };
  useEffect(() => {
    const filteredList = addressbook.filter((item) => {
      const nameMatches =
        item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase());
      const addressMatches =
        item.address &&
        item.address.toLowerCase().includes(searchTerm.toLowerCase());
      return nameMatches || addressMatches;
    });

    setFilteredAddressbookList(filteredList);
    // eslint-disable-next-line
  }, [addressbook, searchTerm]);

  return (
    <>
      <Carousel activeIndex={activeIndex} controls={false} indicators={false}>
        <Carousel.Item>
          <div className="py-1 mb-5">
            <div className="position-relative">
              <span className="svg-icon svg-icon-2 position-absolute top-50 translate-middle-y ms-4">
                <KTSVG
                  path="/media/icons/duotune/general/gen004.svg"
                  className="svg-icon-3"
                />
              </span>
              <input
                type="text"
                className="form-control form-control-solid w-100 fs-7 ps-12 mt-1 mb-2"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
            <div
              className="d-flex flex-stack py-3 addressbook-container addressbook-hover-card rounded cursor-pointer"
              onClick={() => setActiveIndex(1)}
            >
              <div className="d-flex align-items-center">
                <div className="d-flex w-45px h-45px align-items-center justify-content-center ps-2">
                  <KTSVG
                    path="/media/svg/custom/addaddress.svg"
                    className="svg-icon-2x"
                  />
                </div>
                <div className="ms-3">
                  <p className="fs-6 fw-semibold text-primary mb-0">
                    Add New Address
                  </p>
                </div>
              </div>
            </div>
            {filteredAddressbookList.length !== 0 && (
              <div className="d-flex justify-content-end">
                <hr className="border-gray-500 m-0" style={{ width: "88%" }} />
              </div>
            )}
            {addressbookLoading && (
              <div className="loading-container d-flex flex-column h-300px">
                <img
                  className="infinite-rotate w-40px h-40px"
                  src={toAbsoluteUrl("/media/icons/wallet/waiting.svg")}
                  alt="oxapay loading"
                />
              </div>
            )}
            <div className="overflow-scroll mh-400px">
              {filteredAddressbookList.length === 0 && !addressbookLoading && (
                <div className="d-flex justify-content-center align-items-center my-20">
                  <img
                    src={toAbsoluteUrl("/media/icons/duotune/files/fil012.svg")}
                    className="w-30px opacity-20 my-10"
                    alt="no-data"
                  />
                  <p className="ms-2 my-0 fw-semibold text-gray-500">
                    No Addresses found
                  </p>
                </div>
              )}
              {filteredAddressbookList
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item, index) => (
                  <div key={item?.id}>
                    <div className="d-flex flex-stack py-3 addressbook-container addressbook-hover-card rounded">
                      <div
                        className="d-flex align-items-center cursor-pointer"
                        onClick={() => addressClickHandler(item.address)}
                      >
                        <div className="symbol symbol-45px symbol-circle ps-2">
                          <span className="symbol-label bg-gray text-dark fs-6 fw-bolder">
                            {item.name?.charAt(0)?.toUpperCase()}
                          </span>
                        </div>
                        <div className="ms-3">
                          <p className="fs-6 fw-bolder text-gray-900 mb-0">
                            {item?.name}
                            {item.whitelist && <VerifiedAddress />}
                          </p>
                          {item.address.length > 28 ? (
                            <OverlayTrigger
                              trigger={["hover", "focus"]}
                              placement="top"
                              overlay={
                                <Popover>
                                  <Popover.Body>{item.address}</Popover.Body>
                                </Popover>
                              }
                            >
                              <p className="address fw-semibold fs-7 text-gray-600 mb-0">
                                {shortenString(item.address, 28)}
                              </p>
                            </OverlayTrigger>
                          ) : (
                            <p className="address fw-semibold fs-7 text-gray-600 mb-0">
                              {item.address}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="d-flex flex-row align-items-end ms-1 me-3">
                        <button
                          className="p-2 btn"
                          onClick={() => {
                            setActiveIndex(2);
                            setUpdateFields(item);
                          }}
                        >
                          <KTSVG
                            path="/media/icons/duotune/art/art005.svg"
                            className="svg-icon-3 text-hover-primary m-0"
                          />
                        </button>
                        <button
                          className="p-2 btn"
                          onClick={() => deleteHandler(item.id)}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen027.svg"
                            className="svg-icon-3 text-hover-danger m-0"
                          />
                        </button>
                      </div>
                    </div>
                    {addressbook.length - 1 !== index && (
                      <div className="d-flex justify-content-end">
                        <hr
                          className="border-gray-500 m-0"
                          style={{ width: "88%" }}
                        />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <AddressBookForm onSuccess={() => setActiveIndex(0)} />
        </Carousel.Item>
        <Carousel.Item>
          {activeIndex === 2 && (
            <AddressBookForm
              updateFields={updateFields}
              onSuccess={() => setActiveIndex(0)}
            />
          )}
        </Carousel.Item>
      </Carousel>
    </>
  );
};
