import axios from "axios";
import React, { useEffect, useState } from "react";
import { errorHandler } from "../app/services/errorHandler";
import { homeStatsChartInterval } from "../app/services/intervalManage";
import { useRequest } from "../app/services/userHttpRequests";
import { ChartsWidget2 } from "./charts/ChartWidget2";

export const HomeChartPayment = () => {
  const { homePaymentChart } = useRequest();
  const [paymentStats, setPaymentStats] = useState({
    categories: ["-", "-", "-", "-", "-", "-", "-"],
    data: [0, 0, 0, 0, 0, 0, 0],
  });

  useEffect(() => {
    const controller = new AbortController();

    const fetchChart = async () => {
      try {
        const response = await homePaymentChart({ signal: controller.signal });
        if (response.status === 200) {
          const chartData = response.chart;
          setPaymentStats({
            categories: chartData.categories,
            data: chartData.data,
          });
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };

    fetchChart();
    const interval = setInterval(fetchChart, homeStatsChartInterval);

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval on component unmount
    };
    // eslint-disable-next-line
  }, []);

  return (
    <ChartsWidget2
      className="card card-flush overflow-hidden"
      chartTitle="Payments statistics"
      chartSubtitle="The last 7 days statistics"
      height={228}
      categories={paymentStats.categories}
      data={[
        {
          name: "Amount",
          data: paymentStats.data.map((item) =>
            parseFloat(Number(item).toFixed(3).toLocaleString())
          ),
        },
      ]}
    />
    // <>
    //   {paymentStats.categories.map((item) => (
    //     <p key={item}>{item}</p>
    //   ))}
    // </>
  );
};
