import React from "react";
import { convertUnixToTimeAgo } from "../../app/services/functionServices";
import { IconReferralNotif } from "../staticIcons/IconReferralNotif";

interface props {
  date: string;
}

export const HeaderNotifReferral = ({ date }: props) => {
  return (
    <div className="d-flex flex-stack py-4">
      <div className="d-flex align-items-center">
        <div className="symbol symbol-40px me-4">
          <IconReferralNotif />
        </div>

        <div className="mb-0 me-2">
          <span className="fs-6 text-gray-800 fw-bolder">New referral</span>
          <div className="text-gray-400 fs-7">You got new referral</div>
        </div>
      </div>

      <span className="badge badge-light fs-8">
        {convertUnixToTimeAgo(date)}
      </span>
    </div>
  );
};
