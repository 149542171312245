import { useFormik } from "formik";
import { useContext, useState } from "react";
import * as Yup from "yup";
import { DonationButtonContext } from "../../app/Core/DonationButtonContext";
import { alertsMapNewDonation } from "../../app/services/alertsMap";
import { errorHandler } from "../../app/services/errorHandler";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { defaultCoin } from "../../app/services/mapHelper";
import { SuccessModalFire } from "../../app/services/successSwalFire";
import { useRequest } from "../../app/services/userHttpRequests";
import { AcceptedCoinsWidget } from "../AcceptedCoinsWidget";
import { SwitchGroupWidget } from "../SwitchGroupWidget";
import { WaitButtonSpan } from "../WaitButtonSpan";

export const DonationButtonForm = () => {
  const [loading, setLoading] = useState(false);
  const { newDonationButton } = useRequest();
  const {
    setDonationButton,
    setDonationButtonsPage,
    setDonationButtonsSearch,
  } = useContext(DonationButtonContext);

  const formik = useFormik({
    initialValues: {
      name: "",
      selectedCoins: [defaultCoin],
      fields: {
        name: {
          show: false,
          require: false,
        },
        email: {
          show: false,
          require: false,
        },
      },
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Name must be at least 3 characters")
        .max(30, "Name must be less than 30 characters")
        .required("Name is required"),
      selectedCoins: Yup.array().min(1, "You must select at least 1 coin"),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        const res = await newDonationButton(
          values.name,
          values.selectedCoins,
          values.fields
        );
        if (res.status === 200 && res.result === true) {
          setDonationButtonsSearch("");
          setDonationButton(res.data);
          setDonationButtonsPage(res.meta);
          SuccessModalFire("Your donation button was created successfully");
          resetForm();
        } else {
          FailedModalFire(alertsMapNewDonation[res.errorNum.toString()]);
        }
      } catch (error) {
        errorHandler(error as Error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="card h-md-100">
          <div className="card-body d-flex flex-column flex-center">
            <div className="w-100 mb-5">
              <h4 className="fs-5 fw-semibold text-gray-800">Name</h4>
              <input
                type="text"
                className={`form-control form-control-solid ${
                  formik.errors.name &&
                  formik.touched.name &&
                  "is-invalid border border-danger"
                }`}
                autoComplete="off"
                name="name"
                maxLength={30}
                placeholder="Name [3-30 Characters]"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {formik.errors.name && formik.touched.name && (
                <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                  {formik.errors.name}
                </p>
              )}
            </div>
            <div className="w-100 mb-5">
              <AcceptedCoinsWidget
                className={
                  formik.errors.selectedCoins && "border border-danger"
                }
                value={formik.values.selectedCoins}
                onChange={(selectedCoins) => {
                  formik.setFieldValue("selectedCoins", selectedCoins);
                }}
              />
              {formik.errors.selectedCoins && (
                <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                  {formik.errors.selectedCoins}
                </p>
              )}
            </div>
            <SwitchGroupWidget
              value={formik.values.fields}
              onChange={(fields) => formik.setFieldValue("fields", fields)}
            />
            <div className="d-flex align-items-end w-100">
              <button
                type="submit"
                className="btn btn-primary fs-3 w-100"
                disabled={formik.isSubmitting}
              >
                {loading ? <WaitButtonSpan /> : "Create donation button"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
