import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { requestPassword } from "../core/_requests";
import { generateToken } from "../../../services/ReCaptchaGenerator";
import { SuccessModalFire } from "../../../services/successSwalFire";
import { FailedModalFire } from "../../../services/failedSwalFire";
import { WaitButtonSpan } from "../../../../components/WaitButtonSpan";
import { errorHandler } from "../../../services/errorHandler";
import { emailRegex } from "../../../services/functionServices";

const initialValues = {
  username: "",
};

const forgotPasswordSchema = Yup.object().shape({
  username: Yup.string()
    .test("email", "The email is not valid", (value) => {
      if (!value) {
        return true; // No error for empty value
      }
      return emailRegex.test(value);
    })
    .required("Email is required"),
});

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        const forgotToken = await generateToken("forgot_password");
        const { data: result } = await requestPassword(
          values.username,
          forgotToken
        );
        if (result.status === 200 && result.result === true) {
          SuccessModalFire(
            "If the entered email exists, recovery message was sent to it.",
            "Check your email"
          );
        } else if (result.status === 202 && result.result === false) {
          FailedModalFire(
            "Check your connection and try again.",
            "Cannot contact reCAPTCHA"
          );
        } else {
          FailedModalFire("Email is invalid", "Invalid email");
        }
      } catch (error) {
        errorHandler(error as Error);
        FailedModalFire(
          "Please try again. If you continue to encounter this issue, please contact our online support for assistance.",
          "Something went wrong with your connection"
        );
      } finally {
        resetForm();
        setLoading(false);
      }
    },
  });

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        onSubmit={formik.handleSubmit}
      >
        <div className="text-center mb-10">
          <h1 className="text-dark fw-bolder mb-3">Forgot Password ?</h1>
          <div className="text-gray-500 fw-semibold fs-6">
            Enter your email to reset your password.
          </div>
        </div>

        {/* begin::Form group */}
        <div className="fv-row mb-8">
          <input
            type="text"
            placeholder="Email address"
            autoComplete="off"
            {...formik.getFieldProps("username")}
            className={clsx("form-control bg-transparent", {
              "border border-danger is-invalid":
                formik.touched.username && formik.errors.username,
            })}
          />
          {formik.touched.username && formik.errors.username && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.username}
            </p>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="d-flex flex-wrap justify-content-center pb-lg-0 align-items-center">
          <Link to="/signin">
            <button
              type="button"
              className="btn text-muted text-hover-primary"
              disabled={formik.isSubmitting}
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className="btn btn-primary ms-4"
            disabled={formik.isSubmitting || loading}
          >
            {loading ? <WaitButtonSpan /> : "Submit"}
          </button>
        </div>
        {/* end::Form group */}
      </form>
    </>
  );
}
