/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { useAuth } from "../../../../app/modules/auth";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain() {
  const intl = useIntl();
  const { currentUser } = useAuth();

  return (
    <>
      <AsideMenuItem
        to="/home"
        icon="/media/icons/duotune/arrows/arr001.svg"
        title="Home"
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/paymentlink"
        icon="/media/icons/duotune/arrows/arr001.svg"
        title={intl.formatMessage({ id: "MENU.PAYMENTLINK" })}
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/donations"
        icon="/media/icons/duotune/arrows/arr001.svg"
        title="Donations"
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/merchant"
        icon="/media/icons/duotune/arrows/arr001.svg"
        title="Merchant API"
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/payout"
        icon="/media/icons/duotune/arrows/arr001.svg"
        title="Payout API"
        fontIcon="bi-app-indicator"
      />
      {/* {(currentUser?.access?.hasChannel ?? true) && (
        <AsideMenuItem
          to="/TCHgateway"
          icon="/media/icons/duotune/arrows/arr001.svg"
          title="Telegram channel gateway"
          fontIcon="bi-app-indicator"
        />
      )} */}
      {currentUser?.access?.swap && (
        <AsideMenuItem
          to="/swap"
          icon="/media/icons/duotune/arrows/arr001.svg"
          title="Swap"
          fontIcon="bi-app-indicator"
        />
      )}
      <AsideMenuItem
        to="/settings"
        icon="/media/icons/duotune/arrows/arr001.svg"
        title="Settings"
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/referrals"
        icon="/media/icons/duotune/arrows/arr001.svg"
        title="Referrals"
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/profile"
        icon="/media/icons/duotune/arrows/arr001.svg"
        title="Profile"
        fontIcon="bi-app-indicator"
      />
      <div className="menu-item">
        <div className="menu-content">
          <div className="separator mx-1 my-4"></div>
        </div>
      </div>
    </>
  );
}
