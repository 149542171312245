import React from "react";

export const WalletWebappSwapSkeleton = () => {
  return (
    <>
      <div className="card rounded-12px mb-2 pt-1">
        <div className="rounded px-5 py-4 mb-4">
          <div className="d-flex justify-content-between align-items-center mb-5">
            <h4 className="fs-6 text-uppercase fw-400 text-gray-600 mb-0">
              FROM
            </h4>
            <div className="d-flex flex-row">
              <div className="h-25px w-45px bg-secondary rounded shine me-1"></div>
              <div className="h-25px w-45px bg-secondary rounded shine"></div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div
              className="h-40px w-100 bg-secondary rounded shine"
              style={{ maxWidth: "150px" }}
            ></div>
            <div className="h-40px w-130px bg-secondary rounded shine"></div>
          </div>
        </div>
        <hr className="border-gray-500 w-100 mx-0 my-3" />
        <div>
          <div className="position-relative">
            <span
              className={`position-absolute rounded-12px w-40px h-40px bg-secondary rounded shine `}
              style={{ top: "-29px", left: "44%" }}
            ></span>
          </div>
        </div>
        <div className="rounded px-5 pt-0 pb-4 mb-2">
          <div className="d-flex justify-content-between align-items-center mb-6">
            <h4 className="fs-6 text-uppercase fw-400 text-gray-600 mb-0">
              To
            </h4>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div
              className="h-40px w-100 bg-secondary rounded shine"
              style={{ maxWidth: "150px" }}
            ></div>
            <div className="h-40px w-130px bg-secondary rounded shine"></div>
          </div>
        </div>
      </div>
    </>
  );
};
