import axios from "axios";
import React, { useEffect, useState } from "react";
import { errorHandler } from "../app/services/errorHandler";
import { activityInterval } from "../app/services/intervalManage";
import { useRequest } from "../app/services/userHttpRequests";
import { toAbsoluteUrl } from "../_oxapay/helpers";
import { Card } from "./Card";
import { ActivityFilterCard } from "./filters/ActivityFilterCard";
import { DeleteItem } from "./logs/DeleteItem";
import { EditItem } from "./logs/EditItem";
import { GeneralItem } from "./logs/GeneralItem";
import { LoginItem } from "./logs/LoginItem";
import { NewItem } from "./logs/NewItem";
import { SetEmailPassword } from "./logs/SetEmailPassword";
import { SkeletonItem } from "./logs/SkeletonItem";
import { Pagination } from "./Pagination";

interface Logs {
  id: string;
  type: string;
  data: any;
  date: string;
}

export const ActivityLogs = () => {
  const [filter, setFilter] = useState({
    type: "",
    start: "",
    end: "",
  });
  const { userActivity } = useRequest();
  const [logs, setLogs] = useState<Logs[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [activityPage, setActivityPage] = useState({
    page: 1,
    pages: 0,
  });

  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchTxs = async () => {
      try {
        const response = await userActivity(activityPage.page, filter, {
          signal,
        });
        if (response.status === 200) {
          const TransactionsData = response;
          setLogs(TransactionsData.data);
          setActivityPage(TransactionsData.meta);
          setLoading(false);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };

    if (activityPage.page === 1) {
      fetchTxs();
      const interval = setInterval(fetchTxs, activityInterval);

      return () => {
        controller.abort(); // Cancel the fetch request
        clearInterval(interval); // Clean up the interval on component unmount
      };
    } else {
      fetchTxs();

      return () => {
        controller.abort(); // Cancel the fetch request
      };
    }
    // eslint-disable-next-line
  }, [activityPage.page, filter]);

  return (
    <Card
      cardHeaderTitle="Activity logs"
      filter={true}
      filterCard={
        <ActivityFilterCard
          onChange={(newFilter) => {
            if (JSON.stringify(newFilter) !== JSON.stringify(filter)) {
              setFilter(newFilter);
              setLoading(true);
            }
          }}
        />
      }
    >
      <div className="card-body position-relative">
        <div className="timeline">
          {logs?.length === 0 && !loading && (
            <div className="d-flex justify-content-center align-items-center my-20">
              <img
                src={toAbsoluteUrl("/media/icons/duotune/files/fil012.svg")}
                className="w-30px opacity-20 my-10"
                alt="no-data"
              />
              <p className="ms-2 my-0 fw-semibold text-gray-500">
                No items found
              </p>
            </div>
          )}

          {loading ? (
            <>
              <SkeletonItem />
              <SkeletonItem />
              <SkeletonItem />
              <SkeletonItem />
              <SkeletonItem />
              <SkeletonItem />
              <SkeletonItem />
              <SkeletonItem />
              <SkeletonItem />
            </>
          ) : (
            logs?.map((item) => {
              if (item.type === "login") {
                return (
                  <LoginItem
                    data={item.data as any}
                    date={item.date}
                    key={item.id}
                  />
                );
              } else if (item.type === "create") {
                return (
                  <NewItem
                    data={item.data as any}
                    date={item.date}
                    key={item.id}
                  />
                );
              } else if (item.type === "delete") {
                return (
                  <DeleteItem
                    data={item.data as any}
                    date={item.date}
                    key={item.id}
                  />
                );
              } else if (item.type === "edit") {
                return (
                  <EditItem
                    data={item.data as any}
                    date={item.date}
                    key={item.id}
                  />
                );
              } else if (item.type === "twoFa") {
                return (
                  <GeneralItem
                    title={`Two-factor authentication ${item.data?.status}d`}
                    date={item.date}
                    key={item.date}
                  />
                );
              } else if (item.type === "profile") {
                return (
                  <GeneralItem
                    title={`Profile Detail Changed`}
                    date={item.date}
                    key={item.date}
                  />
                );
              } else if (item.type === "passwordChanged") {
                return (
                  <GeneralItem
                    title={`Password changed`}
                    date={item.date}
                    key={item.date}
                  />
                );
              } else if (item.type === "setEmailPassword") {
                return (
                  <SetEmailPassword
                    data={item.data as any}
                    date={item.date}
                    key={item.date}
                  />
                );
              } else {
                return null;
              }
            })
          )}
        </div>
      </div>
      {activityPage.pages > 1 && (
        <Pagination
          loading={loading}
          pages={activityPage.pages}
          onChange={(page) => setActivityPage({ ...activityPage, page: page })}
          activePage={activityPage.page}
        />
      )}
    </Card>
  );
};
