import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import * as Yup from "yup";
import { ipValidator } from "../../app/services/ipValidator";
import { SuccessModalFire } from "../../app/services/successSwalFire";
import { ListMakerWidget } from "../ListMakerWidget";
import { v4 as uuidv4 } from "uuid";
import { WaitButtonSpan } from "../WaitButtonSpan";
import { useRequest } from "../../app/services/userHttpRequests";
import { ModalContext } from "../../_oxapay/layout/core/ModalContext";
import { errorHandler } from "../../app/services/errorHandler";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { useAuth } from "../../app/modules/auth";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { TwoFactorForm } from "./TwoFactorForm";
import { GeneralApiContext } from "../../app/Core/GeneralApiContext";
import {
  alertsMapEditGeneralApi,
  alertsMapNewGeneralApi,
} from "../../app/services/alertsMap";

const popoverAllowedIps = (
  <Popover>
    <Popover.Header as="h3">Allowed IPs to use (Optional)</Popover.Header>
    <Popover.Body>
      You can set some IPs and permit them to use this API key. Any request from
      other IPs will fail.
    </Popover.Body>
  </Popover>
);

interface GeneralApiModel {
  id: string;
  name: string;
  ips?: string[];
}

interface props {
  generalApi?: GeneralApiModel;
}

export const GeneralApiKeyForm = ({ generalApi }: props) => {
  const { setComponent, setModalWidth, setShowModal } =
    useContext(ModalContext);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const [disableUpdate, setDisableUpdate] = useState(true);
  const { newGeneralApi, editGeneralApi } = useRequest();
  const { setGeneralApi, setGeneralApiPage, setGeneralApiSearch } =
    useContext(GeneralApiContext);
  const formik = useFormik({
    initialValues: {
      name: generalApi?.name || "",
      ips: generalApi?.ips?.map((ip) => {
        return {
          id: uuidv4(),
          value: ip,
          error: null,
          onBlur: true,
        };
      }) || [{ id: uuidv4(), value: "", error: null, onBlur: true }],
      twoFa: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Name must be at least 3 characters")
        .max(30, "Name must be less than 30 characters")
        .required("Name is required"),
      ips: Yup.array()
        .nullable()
        .of(
          Yup.object().shape({
            id: Yup.string(),
            value: Yup.string(),
            error: Yup.string()
              .nullable()
              .test(
                "ipValidator",
                "IP address is invalid",
                (error) => error === null
              ),
            onBlur: Yup.boolean(),
          })
        ),
      twoFa: Yup.string().required("Two-Factor code is required"),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        if (generalApi) {
          const res = await editGeneralApi(
            generalApi.id,
            values.name,
            values.ips.filter((ip) => ip.value !== "").map((ip) => ip.value),
            values.twoFa
          );
          if (res.status === 200 && res.result === true) {
            setGeneralApiSearch("");
            setGeneralApi(res.data);
            console.log(generalApi);
            setGeneralApiPage(res.meta);
            SuccessModalFire("Your General API was updated successfully");
            setShowModal(false);
          } else {
            FailedModalFire(alertsMapEditGeneralApi[res.errorNum.toString()]);
          }
        } else {
          const res = await newGeneralApi(
            values.name,
            values.ips.filter((ip) => ip.value !== "").map((ip) => ip.value),
            values.twoFa
          );
          if (res.status === 200 && res.result === true) {
            setGeneralApiSearch("");
            setGeneralApi(res.data);
            setGeneralApiPage(res.meta);
            SuccessModalFire("Your general API key was generated successfully");
            resetForm();
          } else {
            FailedModalFire(alertsMapNewGeneralApi[res.errorNum.toString()]);
          }
        }
      } catch (error) {
        errorHandler(error as Error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (generalApi) {
      formik.setValues({
        name: generalApi.name,
        ips: generalApi.ips?.map((ip) => {
          return {
            id: uuidv4(),
            value: ip,
            error: null,
            onBlur: true,
          };
        }) || [{ id: uuidv4(), value: "", error: null, onBlur: true }],
        twoFa: "",
      });
    }
    // eslint-disable-next-line
  }, [generalApi]);

  const generalApiValues = generalApi;
  useEffect(() => {
    //

    const formikValues = formik.values.ips
      .map((ip) => ip.value)
      .filter((value) => value !== "");
    const generalParams = (generalApiValues?.ips || []).filter(
      (value) => value !== ""
    );
    if (
      generalApi?.name === formik.values.name &&
      formikValues.every((value) => generalParams.includes(value)) &&
      generalParams.every((value) => formikValues.includes(value))
    ) {
      setDisableUpdate(true);
    } else {
      setDisableUpdate(false);
    }
    // eslint-disable-next-line
  }, [generalApiValues, formik.values]);

  return (
    <>
      <div className="card">
        <div className="card-body d-flex flex-column flex-center">
          {currentUser?.twoFa ? (
            <form className="w-100" onSubmit={formik.handleSubmit}>
              {!generalApi && (
                <div className="mb-2">
                  <h1 className="fw-semibold text-gray-800 text-left fs-3 mt-5 mb-5">
                    General <span className="fw-bolder">API</span>
                  </h1>
                  <p className="text-dark">
                    OxaPay General API helps you to swap with API and make other
                    general API requests. Generate an API and check the
                    documentation.
                  </p>
                </div>
              )}
              <div className="w-100 mb-5">
                <h4 className="fs-5 fw-semibold text-gray-800">Name</h4>
                <input
                  type="text"
                  className={`form-control form-control-solid ${
                    formik.errors.name &&
                    formik.touched.name &&
                    "is-invalid border border-danger"
                  }`}
                  autoComplete="off"
                  name="name"
                  maxLength={30}
                  placeholder="Name [3-30 Characters]"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                {formik.errors.name && formik.touched.name && (
                  <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                    {formik.errors.name}
                  </p>
                )}
              </div>
              <div className="w-100 mb-5">
                <ListMakerWidget
                  title={
                    <>
                      Allowed IPs to use
                      {
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          placement="bottom"
                          overlay={popoverAllowedIps}
                        >
                          <span className="badge badge-circle badge-sm badge-outline badge-primary cursor-pointer ms-1">
                            ?
                          </span>
                        </OverlayTrigger>
                      }
                    </>
                  }
                  placeholder="127.0.0.1"
                  addBtnTitle="Add more IP"
                  value={formik.values.ips}
                  onChange={(ips) => formik.setFieldValue("ips", ips)}
                  validator={ipValidator}
                />
              </div>
              <div className="w-100 mb-10">
                <h4 className="fs-5 fw-semibold text-gray-800">
                  2-FA verification code
                </h4>
                <input
                  type="text"
                  className={`form-control form-control-solid me-3 flex-grow-1 ${
                    formik.errors.twoFa &&
                    formik.touched.twoFa &&
                    "is-invalid border border-danger"
                  }`}
                  name="twoFa"
                  placeholder="Code"
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.twoFa}
                />
                {formik.errors.twoFa && formik.touched.twoFa && (
                  <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                    {formik.errors.twoFa}
                  </p>
                )}
              </div>
              <div className="d-flex align-items-end w-100">
                {generalApi ? (
                  <button
                    type="submit"
                    className="btn btn-primary fs-3 w-100"
                    disabled={disableUpdate || loading}
                  >
                    {loading ? <WaitButtonSpan /> : "Save API changes"}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary fs-3 w-100"
                    disabled={formik.isSubmitting}
                  >
                    {loading ? <WaitButtonSpan /> : "Generate General API key"}
                  </button>
                )}
              </div>
            </form>
          ) : (
            <>
              <div className="text-center">
                <img
                  src={toAbsoluteUrl(`/media/svg/misc/security.svg`)}
                  className="w-150px mb-15"
                  alt="twoFa"
                />
              </div>
              <div className="mb-15">
                <h1 className="fw-semibold text-gray-800 text-left fs-3 mt-5 mb-5">
                  General <span className="fw-bolder">API</span>
                </h1>
                <p className="text-dark">
                  OxaPay General API helps you to swap with API and make other
                  general API requests. Generate an API and check the
                  documentation.
                  <br />
                  <br />
                  <b>
                    For enhanced security, it is required to enable Two-Factor
                    Authentication to {generalApi ? "edit" : "generate"} a
                    general API key.
                  </b>
                </p>
              </div>
              <button
                type="button"
                className="btn btn-primary fs-3 w-100"
                onClick={() => {
                  setModalWidth(650);
                  setComponent(<TwoFactorForm />);
                  setShowModal(true);
                }}
              >
                Enable Two-Factor
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};
