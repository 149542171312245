import { useFormik } from "formik";
import { useContext, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import * as Yup from "yup";
import { PaymentlinkContext } from "../../app/Core/PaymentlinkContext";
import { alertsMapNewPaymentlink } from "../../app/services/alertsMap";
import { errorHandler } from "../../app/services/errorHandler";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { formatAmount } from "../../app/services/functionServices";
import { defaultCoin } from "../../app/services/mapHelper";
import { SuccessModalFire } from "../../app/services/successSwalFire";
import { useRequest } from "../../app/services/userHttpRequests";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { AcceptedCoinsWidget } from "../AcceptedCoinsWidget";
import SelectWithImage from "../SelectWithImage";
import { Slider } from "../Slider";
import { SwitchSignleInput } from "../SwitchSignleInput";
import { TextArea } from "../TextArea";
import { WaitButtonSpan } from "../WaitButtonSpan";

const underPaidPopover = (
  <Popover>
    <Popover.Header as="h3">What is Underpaid cover?</Popover.Header>
    <Popover.Body>
      Underpaid signifies that if the payer submits a payment that is slightly
      less (by an amount equal to or less than the underpaid amount), than the
      invoiced amount, the invoice will still be accepted.
    </Popover.Body>
  </Popover>
);

const defaultSelectedFiat = "USD";

export const PaymentLinkForm = () => {
  const { fiatsListMap, coinsLoading } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { newPaymentLink } = useRequest();
  const { setPaymentlinks, setPaymentlinksPage, setPaymentlinksSearch } =
    useContext(PaymentlinkContext);
  const [selectedFiat, setSelectedFiat] = useState(defaultSelectedFiat);

  const getFiatInfo = (fiat: string) => {
    const minAmount =
      Math.ceil(0.1 * (1 / Number(fiatsListMap[fiat]?.price)) * 1000) / 1000;
    const minShow = formatAmount(minAmount, "0.001");

    return { minShow: minShow, minAmount: minAmount, symbol: fiat };
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      amount: "",
      selectedFiat: defaultSelectedFiat,
      selectedCoins: [defaultCoin],
      underPaid: "0",
      feePaidByPayer: 0,
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Name must be at least 3 characters")
        .max(30, "Name must be less than 30 characters")
        .required("Name is required"),
      amount: Yup.number()
        .min(
          getFiatInfo(selectedFiat).minAmount,
          `Amount must be greater than ${getFiatInfo(selectedFiat).minShow} ${
            getFiatInfo(selectedFiat).symbol
          }`
        )
        .required("Amount is required"),
      selectedCoins: Yup.array().min(1, "You must select at least 1 coin"),
      description: Yup.string().max(
        120,
        "Description must be less than 120 characters"
      ),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        const res = await newPaymentLink(
          values.name,
          values.amount.toString(),
          values.selectedFiat,
          values.selectedCoins,
          values.feePaidByPayer,
          values.underPaid,
          values.description
        );
        if (res.status === 200 && res.result === true) {
          setPaymentlinksSearch("");
          setPaymentlinks(res.data);
          setPaymentlinksPage(res.meta);
          SuccessModalFire("Your payment link was created successfully");
          resetForm();
        } else {
          FailedModalFire(alertsMapNewPaymentlink[res.errorNum.toString()]);
        }
      } catch (error) {
        errorHandler(error as Error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="card h-md-100">
          <div className="card-body d-flex flex-column flex-center">
            <div className="w-100 mb-5">
              <h4 className="fs-5 fw-semibold text-gray-800">Name</h4>
              <input
                type="text"
                className={`form-control form-control-solid ${
                  formik.errors.name &&
                  formik.touched.name &&
                  "is-invalid border border-danger"
                }`}
                autoComplete="off"
                name="name"
                maxLength={40}
                placeholder="Name [3-30 Characters]"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {formik.errors.name && formik.touched.name && (
                <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                  {formik.errors.name}
                </p>
              )}
            </div>

            <div className="w-100 mb-5">
              <h4 className="fs-5 fw-semibold text-gray-800">Amount</h4>
              <div
                className={`input-group input-group-solid ${
                  formik.errors.amount &&
                  formik.touched.amount &&
                  "border border-danger"
                }`}
              >
                <input
                  type="number"
                  className="form-control rounded"
                  autoComplete="off"
                  name="amount"
                  placeholder={`min: ${
                    getFiatInfo(formik.values.selectedFiat).minShow
                  } ${formik.values.selectedFiat}`}
                  step={0.0001}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.amount}
                />
                <div className="w-130px ps-2">
                  {coinsLoading ? (
                    <div
                      className="w-100 bg-secondary rounded shine"
                      style={{ height: "43px" }}
                    />
                  ) : (
                    <SelectWithImage
                      searchIcon={false}
                      defaultValue={"Select"}
                      options={Object.keys(fiatsListMap).map((fiat) => ({
                        value: fiat,
                        label: fiat,
                      }))}
                      onChange={(value) => {
                        value && setSelectedFiat(value);
                        formik.setFieldValue("selectedFiat", value);
                      }}
                      value={formik.values.selectedFiat}
                    />
                  )}
                </div>
              </div>
              {formik.errors.amount && formik.touched.amount && (
                <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                  {formik.errors.amount}
                </p>
              )}
            </div>
            <div className="w-100 mb-7">
              <AcceptedCoinsWidget
                height={150}
                className={
                  formik.errors.selectedCoins && "border border-danger"
                }
                value={formik.values.selectedCoins}
                onChange={(selectedCoins) => {
                  formik.setFieldValue("selectedCoins", selectedCoins);
                }}
              />
              {formik.errors.selectedCoins && (
                <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                  {formik.errors.selectedCoins}
                </p>
              )}
            </div>
            <div className="w-100 mb-5">
              <h4 className="fs-5 fw-semibold text-gray-800">
                Underpaid cover
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={underPaidPopover}
                >
                  <span className="badge badge-circle badge-sm badge-outline badge-primary cursor-pointer ms-1">
                    ?
                  </span>
                </OverlayTrigger>
              </h4>
              <Slider
                className="mt-4"
                valueLabel={true}
                min="0"
                max="60"
                step="0.1"
                alertValue="10"
                alertMessage={`Warning: Payments that are ${formik.values.underPaid}%
                 less than the invoiced amount will still be accepted.`}
                value={formik.values.underPaid}
                onChange={(value) => formik.setFieldValue("underPaid", value)}
              />
            </div>
            <div className="w-100 mb-5 bg-light-secondary py-1 rounded">
              <SwitchSignleInput
                label="Fee paid by payer"
                help="Your customer will cover all the fees for this payment."
                value={formik.values.feePaidByPayer === 1 ? true : false}
                onChange={(value) =>
                  formik.setFieldValue("feePaidByPayer", value ? 1 : 0)
                }
              />
            </div>
            <div className="w-100 mb-7">
              <h4 className="fs-5 fw-semibold text-gray-800">Description</h4>
              <TextArea
                maxLength={120}
                placeholder="Description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
              />
            </div>
            <div className="d-flex align-items-end w-100">
              <button
                type="submit"
                className="btn btn-primary fs-3 w-100"
                disabled={formik.isSubmitting}
              >
                {loading ? <WaitButtonSpan /> : "Create payment link"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
