/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { useThemeMode } from "../../_oxapay/partials/layout/theme-mode/ThemeModeProvider";
import { Placeholder } from "react-bootstrap";

type Props = {
  colors: string[];
  series: number[];
  labels: string[];
  className: string;
  chartTitle: string;
  chartSubtitle: string;
  loading?: boolean;
};

const ChartsWidget3: React.FC<Props> = ({
  colors,
  series,
  labels,
  className,
  chartTitle,
  chartSubtitle,
  loading = false,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();

  const [chartSeries, setChartSeries] = useState<number[]>([]);
  const chartInstanceRef = useRef<ApexCharts | null>(null);

  useEffect(() => {
    if (JSON.stringify(chartSeries) !== JSON.stringify(series)) {
      setChartSeries(series);
    }
  }, [series, chartSeries]);

  useEffect(() => {
    if (series.length !== 0) {
      const refreshMode = () => {
        if (!chartRef.current) {
          return;
        }

        const chart = new ApexCharts(
          chartRef.current,
          getChartOptions(colors, chartSeries, labels)
        );
        if (chart) {
          chart.render();
          chartInstanceRef.current = chart; // Store the chart instance in the ref
        }

        return chart;
      };
      const chart = refreshMode();

      return () => {
        if (chart) {
          chart.destroy();
          chartInstanceRef.current = null; // Reset the stored chart instance
        }
      };
    } else {
      // Update the series data when data or categories change
      if (chartInstanceRef.current && chartSeries) {
        chartInstanceRef.current.updateSeries(chartSeries);
      }
    }
    // eslint-disable-next-line
  }, [chartRef, mode, chartSeries]);

  return (
    <div className={`card ${className}`}>
      <div className="card-header py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">{chartTitle}</span>
          <span className="text-muted fw-semibold fs-7">{chartSubtitle}</span>
        </h3>
      </div>

      {/* begin::Body */}
      <div className="card-body d-flex justify-content-center flex-column pb-5 px-5 pt-0">
        {/* begin::Chart */}
        {loading ? (
          <div className="d-flex justify-content-center my-5">
            <div className="doughnut me-5"></div>
            <div className="d-flex flex-column justify-content-center align-items-start">
              <div className="m-0 w-80px">
                <Placeholder xs={10} bg="secondary" className="rounded shine" />
                <Placeholder xs={8} bg="secondary" className="rounded shine" />
                <Placeholder xs={11} bg="secondary" className="rounded shine" />
                <Placeholder xs={9} bg="secondary" className="rounded shine" />
                <Placeholder xs={12} bg="secondary" className="rounded shine" />
              </div>
            </div>
          </div>
        ) : series.every((value) => value === 0) ? (
          <div className="d-flex justify-content-center my-5">
            <div className="doughnut me-5"></div>
            <div className="d-flex flex-column justify-content-center align-items-start">
              {colors.map((color, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-center align-items-center m-0"
                >
                  <div
                    className="chart-series-labels me-1"
                    style={{ backgroundColor: color }}
                  ></div>
                  <p className="m-0">{labels[index]}</p>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div
            ref={chartRef}
            id="kt_charts_widget_3_chart"
            className="my-3"
          ></div>
        )}
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { ChartsWidget3 };

function getChartOptions(
  colors: string[],
  series: number[],
  labels: string[]
): ApexOptions {
  return {
    series: series,
    labels: labels,
    chart: {
      width: 360,
      type: "donut",
    },
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "right",
      horizontalAlign: "center",
      fontSize: "12px",
      fontWeight: 400,
      offsetX: 0,
      offsetY: 40,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            width: 300,
            position: "bottom",
            offsetY: 0,
            horizontalAlign: "center",
          },
        },
      },
    ],
  };
}
