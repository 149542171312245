import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { AsideDefault } from "./components/aside/AsideDefault";
import { Footer } from "./components/Footer";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { ScrollTop } from "./components/ScrollTop";
import { Content } from "./components/Content";
import { PageDataProvider } from "./core";
import { useLocation } from "react-router-dom";
import { MenuComponent } from "../assets/ts/components";
import { Sidebar } from "./components/Sidebar";
import { ThemeModeProvider } from "../partials/layout/theme-mode/ThemeModeProvider";
import { Modal } from "../../components/modals/Modal";
import AppProvider from "./core/AppContext";
import MerchantProvider from "../../app/Core/MerchantsContext";
import PayoutApiProvider from "../../app/Core/PayoutApiContext";
import ChannelsProvider from "../../app/Core/ChannelGatewayContext";
import { SocialMedias } from "../../components/SocialMedias";
import { Drawer } from "../../components/Chats/Drawer";
import { TelegramChatWidget } from "../../components/TelegramChatWidget";
import GeneralApiProvider from "../../app/Core/GeneralApiContext";

interface props {
  showTelegramWidget?: boolean;
}
const MasterLayout = ({ showTelegramWidget = false }: props) => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);

    const element = document.querySelector(".grecaptcha-badge");

    if (element) {
      element.remove();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  return (
    <AppProvider>
      <PageDataProvider>
        <ChannelsProvider>
          <MerchantProvider>
            <PayoutApiProvider>
              <GeneralApiProvider>
                <ThemeModeProvider>
                  <div className="d-flex flex-column flex-root oxa-panel">
                    <div className="page d-flex flex-row flex-column-fluid">
                      <AsideDefault />
                      <div
                        className="wrapper d-flex flex-column flex-row-fluid"
                        id="kt_wrapper"
                      >
                        <HeaderWrapper />

                        <div
                          id="kt_content"
                          className="content d-flex flex-column flex-column-fluid"
                        >
                          <Content>
                            <Outlet />
                          </Content>
                        </div>
                        <Footer />
                      </div>
                      <Sidebar />
                    </div>
                  </div>
                  {showTelegramWidget && <TelegramChatWidget />}
                  <SocialMedias />
                  {/* begin:: Footer */}
                  {/* <StickyFooter /> */}
                  <Drawer />
                  {/* begin:: Modals */}
                  <Modal />
                  {/* end:: Modals */}
                  <ScrollTop />
                </ThemeModeProvider>
              </GeneralApiProvider>
            </PayoutApiProvider>
          </MerchantProvider>
        </ChannelsProvider>
      </PageDataProvider>
    </AppProvider>
  );
};

export { MasterLayout };
