import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { errorHandler } from "../../app/services/errorHandler";
import {
  getFormattedDateFromUnix,
  getFormattedTimeFromUnix,
} from "../../app/services/functionServices";
import { useRequest } from "../../app/services/userHttpRequests";
import { SendMessage } from "../walletWebapp/Lotties/SendMessage";
import { ChatSkeleton } from "./ChatSkeleton";
import { ReadAllButton } from "./ReadAllButton";

export const ChatInner: React.FC = () => {
  const { getMessages, messagesRead } = useRequest();
  const [gettingMessages, setGettingMessages] = useState(true);
  const [unread, setUnread] = useState<number>(0);
  const [messages, setMessages] = useState<any[]>([]);
  const [readMessages, setReadMessages] = useState<string[]>([]);
  const unreadMessagesRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getMessages()
      .then((res) => {
        if (res?.status === 200) {
          setGettingMessages(false);
          setMessages(res?.data);
          setUnread(res?.meta?.unRead);
        }
      })
      .catch((err) => errorHandler(err));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (unreadMessagesRef.current) {
      unreadMessagesRef.current.scrollIntoView({ behavior: "auto" });
    } else {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    }

    const observer = new IntersectionObserver(
      (entries, obs) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.target.getAttribute("view-key")) {
            setReadMessages((prevKeys) => [
              ...prevKeys,
              entry.target.getAttribute("view-key") || "",
            ]);
            obs.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.2 }
    );

    document.querySelectorAll("[view-key]").forEach((element) => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line
  }, [messages]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (readMessages.length > 0) {
      const maxRead = Math.max(...readMessages.map((read) => Number(read)));

      messagesRead(String(maxRead), { signal }).catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      });
    }

    return () => controller.abort();
    // eslint-disable-next-line
  }, [readMessages]);

  return (
    <div
      className={`w-100 flex-grow-1 tgm-bg scroll-y scroll-hidden ${
        !gettingMessages &&
        messages?.length === 0 &&
        "d-flex align-items-center justify-content-center"
      }`}
      ref={chatContainerRef}
    >
      {gettingMessages ? (
        <ChatSkeleton />
      ) : (
        <>
          {unread > 4 && unread - readMessages?.length > 0 && (
            <ReadAllButton
              count={unread - readMessages?.length}
              onClick={() => {
                if (chatContainerRef.current) {
                  const chatContainer = chatContainerRef.current;
                  const lastMessage = chatContainer.lastElementChild;
                  if (lastMessage) {
                    lastMessage.scrollIntoView({ behavior: "smooth" });
                  }
                }

                setUnread(0);
                messagesRead("all").catch((err) => errorHandler(err));
              }}
            />
          )}
          {messages?.length ? (
            messages.map((message, index) => {
              const currentDate = getFormattedDateFromUnix(message?.date);
              const prevDate =
                index > 0
                  ? getFormattedDateFromUnix(messages[index - 1]?.date)
                  : null;
              const showDate = currentDate !== prevDate;

              return (
                <React.Fragment key={message.id}>
                  {!messages[index - 1]?.unRead && message.unRead && (
                    <div
                      ref={unreadMessagesRef}
                      className="bg-light w-100 text-center"
                    >
                      <p className="m-0 text-gray-600">Unread Messages</p>
                    </div>
                  )}

                  {showDate && (
                    <div className="text-center">
                      <div className="badge badge-blur rounded-16px px-3 my-2">
                        <span className="text-light">{currentDate}</span>
                      </div>
                    </div>
                  )}
                  <div
                    className="bg-light rounded-16px overflow-hidden my-2 me-15 ms-3"
                    {...(message?.unRead ? { "view-key": message.id } : {})}
                  >
                    {message?.image && (
                      <img
                        src={message?.image}
                        className="w-100 rounded-16px border border-light"
                        alt="message-img"
                      />
                    )}
                    <div className="px-5 py-1 mt-2 mb-1">
                      <p
                        className="fs-6 lh-normal mb-0 pe-3"
                        dangerouslySetInnerHTML={{
                          __html: message?.content,
                        }}
                      />
                      <div className="d-flex justify-content-end">
                        <p className="text-muted mb-0 fs-8">
                          {getFormattedTimeFromUnix(message?.date)}
                        </p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })
          ) : (
            <div className="text-center">
              <a
                href="https://t.me/danielsup"
                className="text-hover-primary fs-6 cursor-pointer"
                target={"_blank"}
                rel={"noreferrer"}
              >
                <div className="badge-blur-light d-flex flex-column justify-content-center align-items-center cursor-pointer rounded-16px px-7 my-2 py-5 mw-300px">
                  <p className="text-light fw-bold mt-4 mb-2 fs-6">
                    No messages here yet...
                  </p>
                  <p className="text-light mb-4">
                    If you need any assistance, click here to send a message to
                    your account manager.
                  </p>
                  <div className="w-150px mb-5">
                    <SendMessage loop />
                  </div>
                </div>
              </a>
            </div>
          )}
        </>
      )}
    </div>
  );
};
