import axios from "axios";
import React, { useEffect, useState } from "react";
import { NotifCommission } from "../../components/notificationsComponents/NotifCommission";
import { NotifDonation } from "../../components/notificationsComponents/NotifDonation";
import { NotifMerchant } from "../../components/notificationsComponents/NotifMerchant";
import { NotifPaymentlink } from "../../components/notificationsComponents/NotifPaymentlink";
import { NotifReceive } from "../../components/notificationsComponents/NotifReceive";
import { NotifReferral } from "../../components/notificationsComponents/NotifReferral";
import { NotifSend } from "../../components/notificationsComponents/NotifSend";
import { NotifSkeleton } from "../../components/notificationsComponents/NotifSkeleton";
import { NotifTelegram } from "../../components/notificationsComponents/NotifTelegram";
import { Pagination } from "../../components/Pagination";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { DisableSidebar, PageTitle } from "../../_oxapay/layout/core";
import { errorHandler } from "../services/errorHandler";
import { notificationsDataInterval } from "../services/intervalManage";
import { useRequest } from "../services/userHttpRequests";

interface Notification {
  id: string;
  type: string;
  data?: any;
  date: string;
}

export const Notifications = () => {
  const { notifications } = useRequest();
  const [loading, setLoading] = useState<boolean>(false);
  const [notificationPage, setNotificationPage] = useState({
    page: 1,
    pages: 0,
  });
  const [notificationsList, setNotificationsList] = useState<Notification[]>(
    []
  );

  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchTxs = async () => {
      try {
        const response = await notifications(notificationPage.page, {
          signal,
        });
        if (response.status === 200) {
          setNotificationsList(response.data);
          setNotificationPage(response.meta);
          setLoading(false);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };

    if (notificationPage.page === 1) {
      fetchTxs();
      const interval = setInterval(fetchTxs, notificationsDataInterval);

      return () => {
        controller.abort(); // Cancel the fetch request
        clearInterval(interval); // Clean up the interval on component unmount
      };
    } else {
      fetchTxs();

      return () => {
        controller.abort(); // Cancel the fetch request
      };
    }
    // eslint-disable-next-line
  }, [notificationPage.page]);

  return (
    <DisableSidebar>
      <PageTitle breadcrumbs={[]}>Notifications</PageTitle>
      {notificationsList?.length === 0 && !loading && (
        <div
          className="card d-flex justify-content-center align-items-center"
          style={{ minHeight: "600px" }}
        >
          <div className="d-flex justify-content-center align-items-center my-20">
            <img
              src={toAbsoluteUrl("/media/icons/duotune/files/fil012.svg")}
              className="w-30px opacity-20 my-10"
              alt="no-data"
            />
            <p className="ms-2 my-0 fw-semibold text-gray-500">
              No items found
            </p>
          </div>
        </div>
      )}
      {loading ? (
        <>
          <NotifSkeleton />
          <NotifSkeleton />
          <NotifSkeleton />
          <NotifSkeleton />
          <NotifSkeleton />
          <NotifSkeleton />
          <NotifSkeleton />
          <NotifSkeleton />
          <NotifSkeleton />
          <NotifSkeleton />
        </>
      ) : (
        notificationsList?.map((item) => {
          if (item.type === "payment link") {
            return (
              <NotifPaymentlink
                key={item.id}
                data={item.data as any}
                date={item.date}
              />
            );
          } else if (item.type === "donation") {
            return (
              <NotifDonation
                key={item.id}
                data={item.data as any}
                date={item.date}
              />
            );
          } else if (item.type === "merchant") {
            return (
              <NotifMerchant
                key={item.id}
                data={item.data as any}
                date={item.date}
              />
            );
          } else if (item.type === "telegram") {
            return (
              <NotifTelegram
                key={item.id}
                data={item.data as any}
                date={item.date}
              />
            );
          } else if (item.type === "new referral") {
            return <NotifReferral key={item.id} date={item.date} />;
          } else if (item.type === "send") {
            return (
              <NotifSend
                key={item.id}
                data={item.data as any}
                date={item.date}
              />
            );
          } else if (item.type === "receive") {
            return (
              <NotifReceive
                key={item.id}
                data={item.data as any}
                date={item.date}
              />
            );
          } else if (item.type === "referral commission") {
            return (
              <NotifCommission
                key={item.id}
                data={item.data as any}
                date={item.date}
              />
            );
          } else {
            return null;
          }
        })
      )}
      {notificationPage.pages > 1 && (
        <div className="mt-10">
          <Pagination
            loading={loading}
            pages={notificationPage.pages}
            onChange={(page) =>
              setNotificationPage({ ...notificationPage, page: page })
            }
            activePage={notificationPage.page}
          />
        </div>
      )}
    </DisableSidebar>
  );
};
