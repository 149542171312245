import { Suspense, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import { I18nProvider } from "../_oxapay/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "../_oxapay/layout/core";
import DrawerProvider from "../_oxapay/layout/core/DrawerContext";
import ModalProvider from "../_oxapay/layout/core/ModalContext";
import { MasterInit } from "../_oxapay/layout/MasterInit";
import { AuthInit } from "./modules/auth";
import { getPageTitle } from "./services/pageTitle";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    const pageTitle = getPageTitle(location.pathname);
    document.title = `${pageTitle} | OxaPay`;
  }, [location]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <ErrorBoundary>
        <I18nProvider>
          <DrawerProvider>
            <ModalProvider>
              <LayoutProvider>
                <AuthInit>
                  <Outlet />
                  <MasterInit />
                </AuthInit>
              </LayoutProvider>
            </ModalProvider>
          </DrawerProvider>
        </I18nProvider>
      </ErrorBoundary>
    </Suspense>
  );
};

export { App };
