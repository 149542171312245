import React, { useContext } from "react";
import { DrawerContext } from "../../_oxapay/layout/core/DrawerContext";

export const Drawer = () => {
  const { setComponent, setShowDrawer, showDrawer, component } =
    useContext(DrawerContext);

  return (
    <>
      <div
        onClick={() => {
          setShowDrawer(false);
          setTimeout(() => {
            setComponent(<></>);
          }, 300);
        }}
        className={` animate__animated ${
          showDrawer ? "oxa-backdrop animate__fadeIn" : "animate__fadeOut"
        }`}
        style={{ pointerEvents: showDrawer ? "auto" : "none" }}
      />

      <div className={`oxa-drawer ${showDrawer ? "show" : ""}`}>
        <div className="card w-100 rounded-0">{component}</div>
      </div>
    </>
  );
};
