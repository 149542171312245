import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { alertsMapChangeAddressList } from "../../app/services/alertsMap";
import { errorHandler } from "../../app/services/errorHandler";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { SuccessModalFire } from "../../app/services/successSwalFire";
import { useRequest } from "../../app/services/userHttpRequests";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { ModalContext } from "../../_oxapay/layout/core/ModalContext";
import { WaitButtonSpan } from "../WaitButtonSpan";

interface props {
  addressValues: any;
  coins: { id: number; coin: string; network: string; isMain: boolean }[];
  onSuccess: (data: any) => void;
}
export const SubmitAddressListChanges = ({
  addressValues,
  coins,
  onSuccess,
}: props) => {
  const { setShowModal, setComponent } = useContext(ModalContext);
  const [loading, setLoading] = useState(false);
  const { changeAddressList } = useRequest();
  const [error, setError] = useState<string>();
  const { coinsListMap } = useContext(AppContext);

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.number()
        .typeError("Authentication code must be a number")
        .required("Authentication code is required"),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        const res = await changeAddressList(
          String(values.code),
          addressValues?.coinsAddress
        );
        if (res.status === 200 && res.result === true) {
          setShowModal(false);
          SuccessModalFire("The address list changed successfully");
          onSuccess(res.data);
          resetForm();
        } else if (res.status === 0 && res.errorNum) {
          if (res.errorNum.toString() === "-12") {
            setError(alertsMapChangeAddressList[res.errorNum.toString()]);
          } else if (res.errorNum.toString() === "-4") {
            const foundCoin = coins.find((item) => item.id === res.id);

            FailedModalFire(
              `Address of ${
                foundCoin ? coinsListMap[foundCoin.coin]?.name : ""
              } ${
                foundCoin && !foundCoin?.isMain
                  ? "(" +
                    coinsListMap[foundCoin.coin]?.networkList[foundCoin.network]
                      ?.network +
                    ")"
                  : ""
              } is  invalid`
            );
            setShowModal(false);
            resetForm();
          } else if (res.errorNum.toString() === "-13") {
            const foundCoin = coins.find((item) => item.id === res.id);

            FailedModalFire(
              `Address of ${
                foundCoin ? coinsListMap[foundCoin.coin]?.name : ""
              } ${
                foundCoin && !foundCoin?.isMain
                  ? "(" +
                    coinsListMap[foundCoin.coin]?.networkList[foundCoin.network]
                      ?.network +
                    ")"
                  : ""
              } is OxaPay address, enter external address`
            );
            setShowModal(false);
            resetForm();
          } else if (res.errorNum.toString() === "-7") {
            const foundCoin = coins.find((item) => item.id === res.id);

            FailedModalFire(
              `Memo of ${foundCoin ? coinsListMap[foundCoin.coin]?.name : ""} ${
                foundCoin && !foundCoin?.isMain
                  ? "(" +
                    coinsListMap[foundCoin.coin]?.networkList[foundCoin.network]
                      ?.network +
                    ")"
                  : ""
              } is invalid`
            );
            setShowModal(false);
            resetForm();
          } else {
            setShowModal(false);
            resetForm();
            FailedModalFire(
              alertsMapChangeAddressList[res.errorNum.toString()]
            );
          }
        }
      } catch (error) {
        errorHandler(error as Error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    setError("");
    // eslint-disable-next-line
  }, [formik.errors.code && formik.touched.code]);
  return (
    <div className="scroll-y pt-10 pb-15 px-lg-17" data-kt-element="apps">
      <h3 className="text-dark fw-bold mb-7">Two-factor authentication</h3>
      <div className="text-gray-500 fw-semibold fs-6 mb-10">
        Enter 6 digit code to change your Addresses list.
      </div>
      <form className="form" onSubmit={formik.handleSubmit}>
        <div className="mb-10 fv-row">
          <input
            type="text"
            className={`form-control form-control-solid ${
              ((formik.errors.code && formik.touched.code) || error) &&
              "is-invalid border border-danger"
            }`}
            placeholder="Enter authentication code"
            autoComplete="off"
            name="code"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.code}
          />
          {formik.errors.code && formik.touched.code && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.code}
            </p>
          )}
          {error && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">{error}</p>
          )}
        </div>
        <div className="d-flex flex-center">
          <button
            type="button"
            className="btn btn-light me-3"
            onClick={() => {
              setShowModal(false);
              setComponent(<></>);
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={formik.isSubmitting}
          >
            {loading ? <WaitButtonSpan /> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};
