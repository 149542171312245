/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { useThemeMode } from "../../_oxapay/partials/layout/theme-mode/ThemeModeProvider";
import { getCSS, getCSSVariableValue } from "../../_oxapay/assets/ts/_utils";

interface chartData {
  name: string;
  data: number[];
}

type Props = {
  className: string;
  chartTitle: string;
  chartSubtitle: string;
  height: number;
  categories: string[];
  data: chartData[];
};

const ChartsWidget1: React.FC<Props> = ({
  className,
  chartTitle,
  chartSubtitle,
  height,
  categories,
  data,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const [chartCategories, setChartCategories] = useState<string[]>([]);
  const chartInstanceRef = useRef<ApexCharts | null>(null);

  useEffect(() => {
    if (JSON.stringify(chartCategories) !== JSON.stringify(categories)) {
      setChartCategories(categories);
    }
  }, [categories, chartCategories]);

  useEffect(() => {
    const refreshMode = () => {
      if (!chartRef.current) {
        return;
      }

      const height = parseInt(getCSS(chartRef.current, "height"));

      const chart = new ApexCharts(
        chartRef.current,
        getChartOptions(height, categories, data)
      );
      if (chart) {
        chart.render();
        chartInstanceRef.current = chart; // Store the chart instance in the ref
      }

      return chart;
    };
    const chart = refreshMode();

    return () => {
      if (chart) {
        chart.destroy();
        chartInstanceRef.current = null; // Reset the stored chart instance
      }
    };
    // eslint-disable-next-line
  }, [chartRef, mode, chartCategories]);

  useEffect(() => {
    // Update the series data when data or categories change
    if (chartInstanceRef.current && data) {
      chartInstanceRef.current.updateSeries(data);
    }
  }, [data]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">{chartTitle}</span>
          <span className="text-muted fw-semibold fs-7">{chartSubtitle}</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body d-flex justify-content-between flex-column pb-1 px-5 pt-0">
        {/* begin::Chart */}
        <div
          ref={chartRef}
          id="kt_charts_widget_3_chart"
          style={{ height: `${height}px` }}
        ></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { ChartsWidget1 };

function getChartOptions(
  height: number,
  categories: string[],
  data: chartData[]
): ApexOptions {
  const labelColor = getCSSVariableValue("--kt-gray-500");
  const borderColor = getCSSVariableValue("--kt-gray-200");
  const baseColor = getCSSVariableValue("--kt-primary");
  const lightColor = getCSSVariableValue("--kt-primary-light");

  return {
    series: data,
    chart: {
      fontFamily: "inherit",
      type: "area",
      height: `${height}`,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 4,
      colors: [baseColor],
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
      crosshairs: {
        position: "front",
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return "$" + val;
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  };
}
