import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { countriesList } from "../../app/services/countriesList";
import SelectWithImage from "../SelectWithImage";
import { WaitButtonSpan } from "../WaitButtonSpan";
import { useAuth, UserModel } from "../../app/modules/auth";
import { useRequest } from "../../app/services/userHttpRequests";
import { SuccessModalFire } from "../../app/services/successSwalFire";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { alertsMapProfileUpdate } from "../../app/services/alertsMap";
import { phoneRegex } from "../../app/services/functionServices";

export const ProfileSettingsForm = () => {
  const [loading, setLoading] = useState(false);
  const [same, setSame] = useState(true);
  const { currentUser, setCurrentUser } = useAuth();
  const { updateProfile } = useRequest();

  const formik = useFormik({
    initialValues: {
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      company: currentUser?.companyName,
      phone: currentUser?.phone,
      site: currentUser?.site,
      country: currentUser?.country,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(3, "First Name must be greater than 3 characters")
        .max(30, "First name must be less than 30 characters"),
      lastName: Yup.string()
        .min(3, "Last Name must be greater than 3 characters")
        .max(30, "Last name must be less than 30 characters"),
      company: Yup.string()
        .min(3, "Company Name must be greater than 3 characters")
        .max(30, "Company name must be less than 30 characters"),
      phone: Yup.string().test("phone", "The phone is not valid", (value) => {
        if (!value) {
          return true; // No error for empty value
        }
        return phoneRegex.test(value);
      }),
      site: Yup.string()
        .min(3, "Website address must be greater than 3 characters")
        .max(30, "Website address must be less than 30 characters"),
      country: Yup.string(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);

      try {
        const data = await updateProfile(
          values.firstName as string,
          values.lastName as string,
          values.company as string,
          values.phone as string,
          values.site as string,
          values.country as string
        );
        if (data && data.status === 200) {
          const updatedUser = {
            ...currentUser,
            firstName: data.firstName,
            lastName: data.lastName,
            companyName: data.companyName,
            phone: data.phone,
            site: data.site,
            country: data.country,
          };

          setCurrentUser(updatedUser as UserModel);
          SuccessModalFire("Your profile was updated successfully");
        } else {
          FailedModalFire(alertsMapProfileUpdate[data.errorNum.toString()]);
        }
      } catch (error) {
        FailedModalFire(
          "Check your internet connection and try again",
          "Connection error"
        );
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const profile = {
      firstName: currentUser?.firstName,
      lastName: currentUser?.lastName,
      company: currentUser?.companyName,
      phone: currentUser?.phone,
      site: currentUser?.site,
      country: currentUser?.country,
    };
    JSON.stringify(profile) === JSON.stringify(formik.values)
      ? setSame(true)
      : setSame(false);
  }, [formik.values, currentUser]);

  return (
    <form className="form" onSubmit={formik.handleSubmit}>
      <div className="row mb-6">
        <label className="col-lg-4 col-form-label fw-semibold fs-6">
          Full Name
        </label>
        <div className="col-lg-8">
          <div className="row">
            <div className="col-lg-6 fv-row">
              <input
                type="text"
                name="firstName"
                className={`form-control form-control-solid mb-3 mb-lg-0 ${
                  formik.errors.firstName &&
                  formik.touched.firstName &&
                  "is-invalid border border-danger"
                }`}
                placeholder="First name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.firstName}
                autoComplete="off"
              />
              {formik.errors.firstName && formik.touched.firstName && (
                <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                  {formik.errors.firstName}
                </p>
              )}
            </div>

            <div className="col-lg-6 fv-row">
              <input
                type="text"
                name="lastName"
                className={`form-control form-control-solid ${
                  formik.errors.lastName &&
                  formik.touched.lastName &&
                  "is-invalid border border-danger"
                }`}
                placeholder="Last name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.lastName}
                autoComplete="off"
              />
              {formik.errors.lastName && formik.touched.lastName && (
                <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                  {formik.errors.lastName}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-6">
        <label className="col-lg-4 col-form-label fw-semibold fs-6">
          Company
        </label>
        <div className="col-lg-8 fv-row">
          <input
            type="text"
            name="company"
            className={`form-control form-control-solid ${
              formik.errors.company &&
              formik.touched.company &&
              "is-invalid border border-danger"
            }`}
            placeholder="Company name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.company}
            autoComplete="off"
          />
          {formik.errors.company && formik.touched.company && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.company}
            </p>
          )}
        </div>
      </div>

      <div className="row mb-6">
        <label className="col-lg-4 col-form-label fw-semibold fs-6">
          <span>Contact Phone</span>
        </label>

        <div className="col-lg-8 fv-row">
          <input
            type="tel"
            name="phone"
            className={`form-control form-control-solid ${
              formik.errors.phone &&
              formik.touched.phone &&
              "is-invalid border border-danger"
            }`}
            placeholder="Phone number"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.phone}
            autoComplete="off"
          />
          {formik.errors.phone && formik.touched.phone && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.phone}
            </p>
          )}
        </div>
      </div>
      <div className="row mb-6">
        <label className="col-lg-4 col-form-label fw-semibold fs-6">
          Company Site
        </label>
        <div className="col-lg-8 fv-row">
          <input
            type="text"
            name="site"
            className={`form-control form-control-solid ${
              formik.errors.site &&
              formik.touched.site &&
              "is-invalid border border-danger"
            }`}
            placeholder="Company site"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.site}
            autoComplete="off"
          />
          {formik.errors.site && formik.touched.site && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.site}
            </p>
          )}
        </div>
      </div>
      <div className="row mb-6">
        <label className="col-lg-4 col-form-label fw-semibold fs-6">
          <span>Country</span>
        </label>
        <div className="col-lg-8 fv-row">
          <SelectWithImage
            hasImage={false}
            options={countriesList}
            value={formik.values.country}
            onChange={(value) => formik.setFieldValue("country", value)}
            defaultValue={"Choose country"}
          />
          {formik.errors.country && formik.touched.country && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.country}
            </p>
          )}
        </div>
      </div>
      <div className="card-footer d-flex justify-content-end pt-6 px-0 pb-0">
        <button
          type="reset"
          className="btn fs-5 text-muted text-hover-primary me-2"
          onClick={() =>
            formik.setValues({
              firstName: currentUser?.firstName,
              lastName: currentUser?.lastName,
              company: currentUser?.companyName,
              phone: currentUser?.phone,
              site: currentUser?.site,
              country: currentUser?.country,
            })
          }
        >
          Discard
        </button>
        <button
          type="submit"
          className="btn btn-primary me-2 px-6"
          disabled={formik.isSubmitting || same}
        >
          {loading ? <WaitButtonSpan /> : "Save Changes"}
        </button>
      </div>
    </form>
  );
};
