import React from "react";
import { CommissionChartStats } from "../../components/pageCharts/CommissionChartStats";
import { RefChartStats } from "../../components/pageCharts/RefChartStats";
import { ReferralsAssetsList } from "../../components/ReferralsAssetsList";
import { ReferralsCommissionSummary } from "../../components/ReferralsCommissionSummary";
import { ReferralsDetail } from "../../components/ReferralsDetail";
import { TableBodyLink } from "../../components/TableBodyLink";
import { CommissionTXsTable } from "../../components/tables/CommissionTXsTable";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { DisableSidebar, PageTitle } from "../../_oxapay/layout/core";
import { useAuth } from "../modules/auth";

export const Referrals = () => {
  const { currentUser } = useAuth();
  const refLink = `https://oxapay.com/?ref=${currentUser?.refCode}`;
  return (
    <DisableSidebar>
      <PageTitle breadcrumbs={[]}>Referrals</PageTitle>
      <div className="row gy-5 g-xl-10">
        <div className="col-xl-4 mb-5">
          <div className="card">
            <div className="card-body d-flex flex-column flex-start">
              <ReferralsDetail />
              <div className="separator mt-5 mb-0 w-100"></div>
              <div className="mb-2 w-100">
                <h1 className="fw-semibold text-gray-800 text-center lh-lg mt-10 mb-10">
                  Share your referral link and
                  <br />
                  <span className="fw-bolder">
                    get 30% referrals commission
                  </span>
                </h1>
                <p className="fs-5 mb-3">
                  <b>What is Referral Plus? </b>
                </p>
                <p className="text-justify">
                  If you generate an invoice (whether it's a payment link,
                  donation, merchant transaction, etc.), and the recipient of
                  your invoice becomes a registered user on OxaPay, they will be
                  counted as your referral.
                </p>
                <div className="py-2 text-center">
                  <img
                    src={toAbsoluteUrl(`/media/illustrations/dozzy-1/20.png`)}
                    className="theme-light-show w-100"
                    alt=""
                    style={{ maxWidth: "250px" }}
                  />
                  <img
                    src={toAbsoluteUrl(
                      `/media/illustrations/dozzy-1/20-dark.png`
                    )}
                    className="theme-dark-show w-100"
                    alt=""
                    style={{ maxWidth: "250px" }}
                  />
                </div>
              </div>
              <label className="form-label text-dark mt-2">
                Your referral link
              </label>
              <div className="d-flex w-100 mb-3">
                <TableBodyLink>{refLink}</TableBodyLink>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-8 mb-5">
          <ReferralsCommissionSummary />
          <div className="row mt-5">
            <div className="col-xl-6">
              <div className="card">
                <div className="d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3">
                  <div className="me-2 mb-5">
                    <span className="fw-bold text-gray-800 d-block fs-3">
                      Commissions assets
                    </span>
                    <span className="text-gray-400 fw-bold">
                      All received assets as commission
                    </span>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <ReferralsAssetsList />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="mb-5">
                <RefChartStats />
              </div>
              <CommissionChartStats />
            </div>
          </div>
        </div>
      </div>
      <CommissionTXsTable />
    </DisableSidebar>
  );
};
