import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { DonationButtonContext } from "../../app/Core/DonationButtonContext";
import { alertsMapDeleteItem } from "../../app/services/alertsMap";
import { DeleteSwalFire } from "../../app/services/deleteSwalFire";
import { errorHandler } from "../../app/services/errorHandler";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { DateTime } from "../../app/services/functionServices";
import { SuccessModalFire } from "../../app/services/successSwalFire";
import { useRequest } from "../../app/services/userHttpRequests";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { ModalContext } from "../../_oxapay/layout/core/ModalContext";
import { DonationModalWidget } from "../DonationModalWidget";
import { TableBodyCoins } from "../TableBodyCoins";
import { TableBodyLink } from "../TableBodyLink";
import { TableDeleteButton } from "../TableDeleteButton";
import { TableGetCodeButton } from "../TableGetCodeButton";
import { TablePlaceholder } from "../TablePlaceholder";
import { TableSearch } from "../TableSearch";
import { TablesWidget } from "../TableWidget";

export const DonationButtonTable = () => {
  const { setComponent, setModalWidth, setShowModal } =
    useContext(ModalContext);
  const { listDonationButton, deleteDonationButton } = useRequest();
  const {
    donationButton,
    setDonationButton,
    donationButtonsPage,
    setDonationButtonsPage,
    donationButtonsSearch,
    setDonationButtonsSearch,
  } = useContext(DonationButtonContext);

  const [donationButtonsLoading, setDonationButtonsLoading] = useState(true);
  const { coinsListMap } = useContext(AppContext);

  const deleteHandler = async (id: string) => {
    const deleteDonationbutton = async () => {
      return deleteDonationButton(id)
        .then((res) => {
          if (res.status === 200 && res.result === true) {
            return res;
          } else {
            FailedModalFire(alertsMapDeleteItem[res.errorNum.toString()]);
          }
        })
        .catch((error) => {
          errorHandler(error as Error);
        });
    };

    DeleteSwalFire(deleteDonationbutton).then((res) => {
      if (res.value !== undefined) {
        setDonationButtonsSearch("");
        setDonationButton((res.value as any).data);
        setDonationButtonsPage((res.value as any).meta);
        SuccessModalFire(`The donation was deleted successfully`);
      }
    });
  };

  useEffect(() => {
    setDonationButtonsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    listDonationButton(donationButtonsSearch, donationButtonsPage.page, {
      signal,
    })
      .then((res) => {
        if (res.status === 200) {
          setDonationButton(res.data);
          setDonationButtonsPage(res.meta);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      })
      .finally(() => {
        setDonationButtonsLoading(false);
      });

    return () => controller.abort();
    // eslint-disable-next-line
  }, [donationButtonsSearch, donationButtonsPage.page]);

  return (
    <TablesWidget
      title="Your donations list"
      className="mb-5 mb-xl-10"
      tableHead={[
        "Name",
        "Fields",
        "Accepted coins",
        "Link",
        "Created at",
        "Actions",
      ]}
      size={[100, 200, 100, 250, 100, 100]}
      loading={donationButtonsLoading}
      noData={
        donationButton?.length === 0 && !donationButtonsLoading ? true : false
      }
      searchInput={
        <TableSearch
          value={donationButtonsSearch}
          onChange={() => setDonationButtonsLoading(true)}
          onDebouncedChange={(search) => {
            setDonationButtonsSearch(search);
            setDonationButtonsPage({ ...donationButtonsPage, page: 1 });
          }}
        />
      }
      activePage={donationButtonsPage.page}
      pages={donationButtonsPage.pages}
      pageChange={(page) =>
        setDonationButtonsPage({ ...donationButtonsPage, page: page })
      }
    >
      {donationButtonsLoading === true && <TablePlaceholder config={[10, 6]} />}
      {!donationButtonsLoading &&
        donationButton !== undefined &&
        donationButton.map((data, index) => (
          <tr key={index}>
            <td>
              <span className="text-dark fw-bold fs-6">{data.name}</span>
            </td>
            <td>
              <span className="text-gray-700 fs-6">
                {data.fields.name.show && "Name"}
                {data.fields.name.require && (
                  <span className="text-danger">*</span>
                )}
                {data.fields.name.show && data.fields.email.show && ", "}
                {data.fields.email.show && "Email"}
                {data.fields.email.require && (
                  <span className="text-danger">*</span>
                )}
                {!data.fields.name.show && !data.fields.email.show && "-"}
              </span>
            </td>
            <td>
              <TableBodyCoins acceptedCoins={data.coins} />
            </td>
            <td>
              <TableBodyLink
                disable={
                  data.coins.filter(
                    (coinSymbol) => coinsListMap[coinSymbol]?.status === true
                  ).length
                    ? false
                    : true
                }
              >
                {data.url}
              </TableBodyLink>
            </td>
            <td>
              <span className="text-gray-600 fw-bold fs-6">
                {DateTime(parseInt(data.date))}
              </span>
            </td>
            <td>
              <div className="d-flex justify-content-start flex-shrink-0">
                <TableGetCodeButton
                  disable={
                    data.coins.filter(
                      (coinSymbol) => coinsListMap[coinSymbol]?.status === true
                    ).length
                      ? false
                      : true
                  }
                  onClick={() => {
                    setModalWidth(600);
                    setComponent(<DonationModalWidget donateLink={data.url} />);
                    setShowModal(true);
                  }}
                />
                <TableDeleteButton
                  onClick={() => {
                    deleteHandler(data.id);
                  }}
                />
              </div>
            </td>
          </tr>
        ))}
    </TablesWidget>
  );
};
