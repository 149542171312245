import React from "react";

interface props {
  title?: string;
  loading?: boolean;
  paymentSummary: {
    today: number;
    week: number;
    month: number;
    all: number;
  };
}

export const PaymentsSummary = ({
  paymentSummary,
  loading = false,
  title = "payments",
}: props) => {
  return (
    <div className="card card-flush mb-7">
      <div className="card-body d-flex justify-content-between flex-column px-5 py-10">
        <div className="d-flex flex-wrap d-grid justify-content-center text-center gap-5 px-5">
          <div className="px-5">
            <div className="d-flex mb-2">
              {loading ? (
                <div className="h-25px w-100 bg-secondary rounded m-auto shine"></div>
              ) : (
                <>
                  <span className="fs-4 fw-semibold text-primary me-1">$</span>
                  <span className="fs-2x fw-bold text-primary me-2 lh-1 ls-n2">
                    {Number(paymentSummary.today).toLocaleString()}
                  </span>
                </>
              )}
            </div>
            <span className="fs-6 fw-semibold text-primary">Today {title}</span>
          </div>
          <div className="border-start-dashed border-1 border-gray-300 px-5">
            <div className="d-flex mb-2">
              {loading ? (
                <div className="h-25px w-100 bg-secondary rounded m-auto shine"></div>
              ) : (
                <>
                  <span className="fs-4 fw-semibold text-gray-500 me-1">$</span>
                  <span className="fs-2x fw-bold text-dark me-2 lh-1 ls-n2">
                    {Number(paymentSummary.week).toLocaleString()}
                  </span>
                </>
              )}
            </div>
            <span className="fs-6 fw-semibold text-gray-400">This week</span>
          </div>
          <div className="border-start-dashed border-1 border-gray-300 px-5">
            <div className="d-flex mb-2">
              {loading ? (
                <div className="h-25px w-100 bg-secondary rounded m-auto shine"></div>
              ) : (
                <>
                  <span className="fs-4 fw-semibold text-gray-500 me-1">$</span>
                  <span className="fs-2x fw-bold text-dark me-2 lh-1 ls-n2">
                    {Number(paymentSummary.month).toLocaleString()}
                  </span>
                </>
              )}
            </div>
            <span className="fs-6 fw-semibold text-gray-400">This month</span>
          </div>
          <div className="border-start-dashed border-1 border-gray-300 px-5">
            <div className="d-flex mb-2">
              {loading ? (
                <div className="h-25px w-100 bg-secondary rounded m-auto shine"></div>
              ) : (
                <>
                  <span className="fs-4 fw-semibold text-gray-500 me-1">$</span>
                  <span className="fs-2x fw-bold text-dark me-2 lh-1 ls-n2">
                    {Number(paymentSummary.all).toLocaleString()}
                  </span>
                </>
              )}
            </div>
            <span className="fs-6 fw-semibold text-gray-400">All time</span>
          </div>
        </div>
      </div>
    </div>
  );
};
