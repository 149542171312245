/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { useThemeMode } from "../../_oxapay/partials/layout/theme-mode/ThemeModeProvider";
import { getCSS, getCSSVariableValue } from "../../_oxapay/assets/ts/_utils";

interface chartData {
  name: string;
  data: number[];
}

type props = {
  className: string;
  chartTitle: string;
  chartSubtitle: string;
  height: number;
  categories: string[];
  data: chartData[];
  type?: string;
};

const ChartsWidget2 = ({
  className,
  chartTitle,
  chartSubtitle,
  height,
  categories,
  type = "USD",
  data,
}: props) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();
  const [chartCategories, setChartCategories] = useState<string[]>([]);
  const chartInstanceRef = useRef<ApexCharts | null>(null);

  useEffect(() => {
    if (JSON.stringify(chartCategories) !== JSON.stringify(categories)) {
      setChartCategories(categories);
    }
  }, [categories, chartCategories]);

  useEffect(() => {
    const refreshMode = () => {
      if (!chartRef.current) {
        return;
      }

      const height = parseInt(getCSS(chartRef.current, "height"));

      const chart = new ApexCharts(
        chartRef.current,
        getChartOptions(height, categories, type, data)
      );
      if (chart) {
        chart.render();
        chartInstanceRef.current = chart; // Store the chart instance in the ref
      }

      return chart;
    };
    const chart = refreshMode();

    return () => {
      if (chart) {
        chart.destroy();
        chartInstanceRef.current = null; // Reset the stored chart instance
      }
    };

    // eslint-disable-next-line
  }, [chartRef, mode, chartCategories]);

  useEffect(() => {
    // Update the series data when data or categories change
    if (chartInstanceRef.current && data) {
      chartInstanceRef.current.updateSeries(data);
    }
  }, [data]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      {/* begin::Header */}
      <div className="card-header py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">{chartTitle}</span>
          <span className="text-muted fw-semibold fs-7">{chartSubtitle}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body d-flex justify-content-between flex-column pb-1 px-5 pt-0">
        {/* begin::Chart */}
        <div
          ref={chartRef}
          id="kt_charts_widget_1_chart"
          style={{ height: `${height}px` }}
        />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { ChartsWidget2 };

function getChartOptions(
  height: number,
  categories: string[],
  type: string,
  data: chartData[]
): ApexOptions {
  const labelColor = getCSSVariableValue("--kt-gray-500");
  const borderColor = getCSSVariableValue("--kt-gray-200");
  const baseColor = getCSSVariableValue("--kt-primary");
  const secondaryColor = getCSSVariableValue("--kt-gray-300");

  return {
    series: data,
    chart: {
      fontFamily: "inherit",
      type: "bar",
      height: `${height}`,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
        borderRadius: 8,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return val + " " + type;
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
}
