import { useGoogleLogin } from "@react-oauth/google";
import { toAbsoluteUrl } from "../../../../_oxapay/helpers";
import { FailedModalFire } from "../../../services/failedSwalFire";
import Cookies from "universal-cookie";
import { getUserByToken, googleLogin } from "../core/_requests";
import { useAuth } from "../core/Auth";
import { useNavigate } from "react-router-dom";
import { alertsMapGoogleLogin } from "../../../services/alertsMap";
import { errorHandler } from "../../../services/errorHandler";

interface props {
  setLoading: (status: boolean) => void;
}
export const LoginWithGoogle = ({ setLoading }: props) => {
  const {
    saveAuth,
    setCurrentUser,
    setLoginInfo,
    setGoogleInfo,
    setTelegramInfo,
    returnPage,
  } = useAuth();
  const navigate = useNavigate();

  const loginWithGoogle = async (result: any) => {
    setLoading(true);
    const cookies = new Cookies();
    try {
      const refCode = cookies.get("ref");
      const referrer = cookies.get("referrer");
      const { data: auth } = await googleLogin(
        result.code,
        refCode,
        referrer,
        ""
      );
      saveAuth(auth);
      if (auth.status === 200 && auth.apiToken) {
        const { data: user } = await getUserByToken(auth.apiToken);
        setCurrentUser(user);
        returnPage && navigate("/connect");
      } else if (auth.status === 0 && auth.errorNum === 5) {
        setGoogleInfo(auth.requestToken);
        setLoginInfo(undefined);
        setTelegramInfo(undefined);
        navigate("/twofactor");
      } else if (auth.errorNum) {
        FailedModalFire(alertsMapGoogleLogin[auth.errorNum.toString()]);
      }
    } catch (error) {
      errorHandler(error as Error);
      FailedModalFire(
        "Please try again.",
        "Something went wrong with your connection"
      );
      saveAuth(undefined);
    } finally {
      setLoading(false);
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => loginWithGoogle(codeResponse),
    scope:
      "email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    flow: "auth-code",
  });

  return (
    <button
      type="button"
      onClick={() => login()}
      className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
    >
      <img
        alt="Logo"
        src={toAbsoluteUrl("/media/svg/brand-logos/google-icon.svg")}
        className="h-15px me-3"
      />
      Continue with Google
    </button>
  );
};
