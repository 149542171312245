/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { unix } from "../../app/services/functionServices";
import { activityTypeOptions } from "../../app/services/mapHelper";
import DateTimePicker from "../DatePicker";

interface props {
  onChange: (filter: any) => void;
}

export function ActivityFilterCard({ onChange }: props) {
  useEffect(() => {
    formik.setValues({
      type: "",
      start: null,
      end: null,
    });
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      type: "",
      start: null,
      end: null,
    },
    validationSchema: Yup.object({
      coins: Yup.array().min(1, "You must select at least 1 coin"),
    }),
    onSubmit: async (values) => {
      const filter = {
        type: values.type,
        start: values.start !== null ? unix(values.start) : "",
        end: values.end !== null ? unix(values.end) : "",
      };
      onChange(filter);
    },
  });

  const handleReset = () => {
    const initValues = {
      type: "",
      start: null,
      end: null,
    };
    if (JSON.stringify(formik.values) !== JSON.stringify(initValues)) {
      formik.setValues(initValues);
    }
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
      data-kt-menu="true"
    >
      <div className="px-7 py-5">
        <div className="fs-5 text-dark fw-bolder">Filter Options</div>
      </div>
      <div className="separator border-gray-200"></div>
      <form className="px-7 py-5" onSubmit={formik.handleSubmit}>
        <div className="mb-10">
          <label className="form-label fw-bold">Type:</label>

          <div>
            <select
              className="form-select form-select-solid"
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
            >
              <option value="">All</option>
              {activityTypeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mb-5">
          <label className="form-label fw-bold">Start date & time:</label>
          <DateTimePicker
            value={formik.values.start}
            placeholder="Start Date & Time"
            onchange={(date) => formik.setFieldValue("start", date)}
          />
        </div>

        <div className="mb-10">
          <label className="form-label fw-bold">End date & time:</label>
          <DateTimePicker
            value={formik.values.end}
            placeholder="End Date & Time"
            onchange={(date) => formik.setFieldValue("end", date)}
          />
        </div>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-sm btn-light btn-active-light-primary me-2"
            onClick={handleReset}
          >
            Reset
          </button>

          <button
            type="submit"
            className="btn btn-sm btn-primary"
            {...{ "data-kt-menu-dismiss": "true" }}
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  );
}
