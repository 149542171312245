import React from "react";

export const AddressListSkeletons = () => {
  return (
    <div
      className={`d-flex bg-light-secondary flex-column flex-sm-row rounded justify-content-center align-items-center mb-2 px-4 py-2`}
    >
      <label className="col-md-3 d-flex fw-semibold fs-6 justify-content-start align-items-center nowrap my-3">
        <div className="h-40px w-40px bg-secondary rounded shine"></div>
        <span className="ms-2 h-25px w-100px bg-secondary rounded shine"></span>
      </label>
      <div className="flex-grow-1 align-items-center justify-content-center w-100">
        <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center gap-3">
          <div className="h-50px w-100 bg-secondary rounded shine"></div>
        </div>
      </div>
    </div>
  );
};
