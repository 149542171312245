import React, { useContext, useEffect, useState } from "react";
import { WalletWebappSwapContext } from "../../app/Core/WalletWebappSwapContext";
import { alertsMapSwap } from "../../app/services/alertsMap";
import { errorHandler } from "../../app/services/errorHandler";
import { useRequest } from "../../app/services/userHttpRequests";
import { WebappModalContext } from "../../_oxapay/layout/core/WebappModalContext";
import { WebappFailAlert } from "./AlertsModal/WebappFailAlert";
import { WebappSuccessAlert } from "./AlertsModal/WebappSuccessAlert";

interface props {
  fromAmount: string;
  toAmount: string;
  fromCurrency: string;
  toCurrency: string;
  onBack: () => void;
  resetForm: () => void;
}
export const WalletWebappSwapStepTwo = ({
  fromAmount,
  toAmount,
  fromCurrency,
  toCurrency,
  onBack,
  resetForm,
}: props) => {
  const [loading, setLoading] = useState(false);
  const { swap } = useRequest();
  const { setSwaps } = useContext(WalletWebappSwapContext);
  const { showModal, setShowModal, setComponent } =
    useContext(WebappModalContext);

  const submit = async () => {
    setLoading(true);
    try {
      const res = await swap(fromCurrency, fromAmount, toCurrency, toAmount);
      if (res.status === 200 && res.result === true) {
        setSwaps(res.data);
        setShowModal(true);
        setComponent(
          <WebappSuccessAlert content="Your swap has been successfully completed" />
        );
        resetForm();
        onBack();
      } else {
        setShowModal(true);
        setComponent(
          <WebappFailAlert content={alertsMapSwap[res.errorNum.toString()]} />
        );
        onBack();
      }
    } catch (error) {
      errorHandler(error as Error);
    } finally {
      setLoading(false);
    }
  };

  const WebappFn = (window as any)?.Telegram?.WebApp;

  useEffect(() => {
    const backBtnFn = () => {
      onBack();
    };

    WebappFn?.BackButton?.onClick(backBtnFn).show();

    return () => {
      WebappFn?.BackButton?.offClick(backBtnFn).hide();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!showModal) {
      if (loading) {
        WebappFn.MainButton.show()
          .setText("Please wait")
          .offClick(submit)
          .showProgress(true);
      } else {
        WebappFn.MainButton.show()
          .setText("Confirm")
          .onClick(submit)
          .hideProgress();
      }
    }

    return () => {
      WebappFn.MainButton.hide().offClick(submit).hideProgress();
    };
    // eslint-disable-next-line
  }, [loading, showModal]);

  return (
    <>
      <p className="fs-6 text-uppercase fw-400 text-gray-600 mb-2 ps-4">
        Swap details
      </p>
      <div className="card rounded-12px ps-6 py-4 mb-15">
        <div>
          <p className="text-dark fs-2 mb-0">From</p>
          <p className="text-primary fs-5 fw-400 mb-0 pe-3">
            {fromAmount} {fromCurrency}
          </p>
        </div>
        <hr className="border-gray-500 w-100 mx-0 my-3" />
        <div>
          <p className="text-dark fs-2 mb-0">To</p>
          <p className="text-primary fs-5 fw-400 mb-0 pe-3">
            {toAmount} {toCurrency}
          </p>
        </div>
      </div>
    </>
  );
};
