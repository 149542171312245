import React from "react";

export const WalletTxsListPlaceholder = () => {
  return (
    <div className="w-100 d-flex flex-row justify-content-between align-items-center mb-5">
      <div className="d-flex flex-column align-items-start">
        <div className="w-150px h-20px rounded bg-secondary shine-sm mb-1"></div>
        <div className="w-70px h-20px rounded bg-secondary shine-sm"></div>
      </div>
      <div className="d-flex flex-column align-items-end">
        <div className="w-90px h-20px rounded bg-secondary shine-sm mb-1"></div>
        <div className="w-40px h-20px rounded bg-secondary shine-sm"></div>
      </div>
    </div>
  );
};
