/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { unix } from "../../app/services/functionServices";
import { statusOptions } from "../../app/services/mapHelper";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { AcceptedCoinsWidget } from "../AcceptedCoinsWidget";
import DateTimePicker from "../DatePicker";

interface props {
  statusShow?: boolean;
  onChange: (filter: any) => void;
}

export function TableTXsFilterCard({ onChange, statusShow = false }: props) {
  const { coinsListMap } = useContext(AppContext);
  const [allAssets, setAllAssets] = useState<string[]>([]);

  useEffect(() => {
    if (Object.keys(coinsListMap).length !== 0) {
      const coinsList = Object.keys(coinsListMap);
      setAllAssets(coinsList);
    }
  }, [coinsListMap]);

  useEffect(() => {
    formik.setValues({
      status: "",
      coins: allAssets,
      start: null,
      end: null,
    });
    // eslint-disable-next-line
  }, [allAssets]);

  const formik = useFormik({
    initialValues: { status: "", coins: allAssets, start: null, end: null },
    validationSchema: Yup.object({
      coins: Yup.array().min(1, "You must select at least 1 coin"),
    }),
    onSubmit: async (values) => {
      const filter = {
        ...(statusShow && {
          status: values.status === "" ? [] : [values.status],
        }),
        coin:
          JSON.stringify(values.coins) === JSON.stringify(allAssets)
            ? []
            : values.coins,
        start: values.start !== null ? unix(values.start) : "",
        end: values.end !== null ? unix(values.end) : "",
      };
      onChange(filter);
    },
  });

  const handleReset = () => {
    const initValues = {
      status: "",
      coins: allAssets,
      start: null,
      end: null,
    };
    if (JSON.stringify(formik.values) !== JSON.stringify(initValues)) {
      formik.setValues(initValues);
    }
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
      data-kt-menu="true"
    >
      <div className="px-7 py-5">
        <div className="fs-5 text-dark fw-bolder">Filter Options</div>
      </div>
      <div className="separator border-gray-200"></div>
      <form className="px-7 py-5" onSubmit={formik.handleSubmit}>
        {statusShow && (
          <div className="mb-10">
            <label className="form-label fw-bold">Status:</label>
            <select
              className="form-select form-select-solid"
              name="status"
              value={formik.values.status}
              onChange={formik.handleChange}
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
            >
              <option value="">All</option>
              {statusOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="mb-10">
          <AcceptedCoinsWidget
            type="filter"
            className={formik.errors.coins && "border-red"}
            height={150}
            label="Currency:"
            labelClassName="form-label fw-bold"
            value={formik.values.coins}
            onChange={(selectedCoins) => {
              formik.setFieldValue("coins", selectedCoins);
            }}
          />
          {formik.errors.coins && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.coins}
            </p>
          )}
        </div>

        <div className="mb-5">
          <label className="form-label fw-bold">Start date & time:</label>
          <DateTimePicker
            value={formik.values.start}
            placeholder="Start Date & Time"
            onchange={(date) => formik.setFieldValue("start", date)}
          />
        </div>

        <div className="mb-10">
          <label className="form-label fw-bold">End date & time:</label>
          <DateTimePicker
            value={formik.values.end}
            placeholder="End Date & Time"
            onchange={(date) => formik.setFieldValue("end", date)}
          />
        </div>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-sm btn-light btn-active-light-primary me-2"
            onClick={handleReset}
          >
            Reset
          </button>

          <button
            type="submit"
            className="btn btn-sm btn-primary"
            {...(!formik.errors.coins && { "data-kt-menu-dismiss": "true" })}
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  );
}
