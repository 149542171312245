import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../app/modules/auth";
import { errorHandler } from "../../../../app/services/errorHandler";
import { notificationsCountInterval } from "../../../../app/services/intervalManage";
import { useRequest } from "../../../../app/services/userHttpRequests";
import { TopBarAccountMessenger } from "../../../../components/TopBarAccountMessenger";
import { TopBarNotification } from "../../../../components/TopBarNotification";
import { ThemeModeSwitcher } from "../../../partials";

const Topbar = () => {
  const { notificationsCount } = useRequest();
  const [notifCount, setNotifCount] = useState<number>(0);
  const [newMessagesCount, setNewMessagesCount] = useState<number>(0);
  const [dropdownShow, setDropdownShow] = useState(false);
  const { logout } = useAuth();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchNotifCount = async () => {
      try {
        const response = await notificationsCount({
          signal,
        });
        if (response.status === 200) {
          const Notifications = response;
          setNewMessagesCount(Notifications.messages);
          !dropdownShow && notifCount < 10 && setNotifCount(Notifications.new);
        } else if (response.status === 0 && response.errorNum === -1) {
          logout();
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };

    fetchNotifCount();
    const interval = setInterval(fetchNotifCount, notificationsCountInterval);

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval on component unmount
    };

    // eslint-disable-next-line
  }, [dropdownShow, notifCount]);

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      <TopBarNotification
        newNotifs={notifCount}
        onClick={() => setNotifCount(0)}
        onShowChange={setDropdownShow}
      />
      <TopBarAccountMessenger newMessages={newMessagesCount} />
      <div className="d-flex align-items-center flex-shrink-0">
        {/* begin::Theme mode */}
        <div className={"d-flex align-items-center ms-4"}>
          <ThemeModeSwitcher toggleBtnClass="btn-color-gray-700 btn-active-light-primary btn-custom btn-outline w-40px h-40px" />
        </div>
        {/* end::Theme mode */}
      </div>
    </div>
  );
};

export { Topbar };
