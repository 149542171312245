import { PaymentLinkForm } from "../../components/forms/PaymentLinkForm";
import { DisableSidebar, PageTitle } from "../../_oxapay/layout/core";
import { useIntl } from "react-intl";
import { PaymentLinkTable } from "../../components/tables/PaymentLinkTable";
import PaymentlinkProvider from "../Core/PaymentlinkContext";
import { PaymentlinkSummary } from "../../components/pageSummary/PaymentlinkSummary";
import { PaymentlinkChart } from "../../components/pageCharts/PaymentlinkChart";
import { PaymentlinkTxsTable } from "../../components/tables/PaymentlinkTxsTable";

export function PaymentLink() {
  const intl = useIntl();

  return (
    <DisableSidebar>
      <PaymentlinkProvider>
        <PageTitle breadcrumbs={[]}>
          {intl.formatMessage({ id: "PAYMENTLINK.TITLE" })}
        </PageTitle>
        <div className="row gy-5 g-xl-10">
          <div className="col-xl-4 mb-10">
            <PaymentLinkForm />
          </div>

          <div className="col-xl-8 mb-5">
            <PaymentlinkSummary />
            <PaymentlinkChart />
          </div>
        </div>

        <PaymentLinkTable />
        <PaymentlinkTxsTable />
      </PaymentlinkProvider>
    </DisableSidebar>
  );
}
