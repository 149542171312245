import React from "react";
import WalletWebappSwapProvider from "../../app/Core/WalletWebappSwapContext";
import { WalletWebappSwapContent } from "./WalletWebappSwapContent";

export const WalletWebappSwap = () => {
  return (
    <WalletWebappSwapProvider>
      <WalletWebappSwapContent />
    </WalletWebappSwapProvider>
  );
};
