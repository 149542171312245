import React, { useContext } from "react";
import { AssetsContext } from "../../_oxapay/layout/core/AssetsContext";
import { WalletWebappAssetsList } from "./WalletWebappAssetsList";
import { WalletWebappHeader } from "./WalletWebappHeader";

export const WalletWebappMain = () => {
  const { assets, assetPriceLoading, balanceLoading } =
    useContext(AssetsContext);

  return (
    <>
      <WalletWebappHeader />
      <div>
        <p className="fs-2x fw-semibold ms-4 mb-2">Assets</p>
        <WalletWebappAssetsList
          assets={assets}
          priceLoading={assetPriceLoading}
          balanceLoading={balanceLoading}
        />
      </div>
    </>
  );
};
