import React, { InputHTMLAttributes, useState } from "react";

interface PasswordInputProps extends InputHTMLAttributes<HTMLInputElement> {}

export const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  return (
    <div className="input-group">
      <input type={passwordVisible ? "text" : "password"} {...props} />

      <span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2">
        {passwordVisible ? (
          <i
            className="bi bi-eye-slash fs-2"
            onClick={() => setPasswordVisible(false)}
          ></i>
        ) : (
          <i
            className="bi bi-eye fs-2"
            onClick={() => setPasswordVisible(true)}
          ></i>
        )}
      </span>
    </div>
  );
};
