import React from "react";
import ShowAlertProvider from "../../_oxapay/layout/core/ShowAlertContext";
import WebappProvider from "../../_oxapay/layout/core/WebappContext";
import WebappModalProvider from "../../_oxapay/layout/core/WebappModalContext";
import { WalletWebappHighLevel } from "./WalletWebappHighLevel";

export const WalletWebappLayout = () => {
  return (
    <WebappProvider>
      <ShowAlertProvider>
        <WebappModalProvider>
          <WalletWebappHighLevel />
        </WebappModalProvider>
      </ShowAlertProvider>
    </WebappProvider>
  );
};
