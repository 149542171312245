import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Card, Placeholder } from "react-bootstrap";
import { errorHandler } from "../app/services/errorHandler";
import { useRequest } from "../app/services/userHttpRequests";
import { AppContext } from "../_oxapay/layout/core/AppContext";
import { WebappContext } from "../_oxapay/layout/core/WebappContext";
import SelectWithImage from "./SelectWithImage";
import { TableBodyLink } from "./TableBodyLink";

export interface Network {
  label: string;
  value: string;
  image: string;
}

export const ReceiveAsset = () => {
  const { webappStatus } = useContext(WebappContext);
  const { coinsListMap } = useContext(AppContext);
  const [gettingAddress, setGettingAddress] = useState(false);
  const [qrLoading, setQrLoading] = useState(true);
  const [selectedCoin, setSelectedCoin] = useState<string | undefined>();
  const [selectedNetwork, setSelectedNetwork] = useState<string | undefined>();
  const [networks, setNetworks] = useState<Network[] | undefined>();
  const [address, setAddress] = useState<string | undefined>();
  const { getAddress } = useRequest();

  useEffect(() => {
    setQrLoading(true);
  }, [address]);

  const optionsCoin = Object.entries(coinsListMap)
    .filter(([_symbol, coin]) => coin.status === true)
    .map(([symbol, coin]) => ({
      value: symbol,
      label: `${coin.name} (${symbol})`,
      image: `/media/svg/coins/${coin?.slug}.svg`,
      disable: !coin.depositEnable,
    }));

  useEffect(() => {
    if (selectedCoin) {
      setAddress(undefined);
      const Coin = coinsListMap[selectedCoin];
      if (Coin && Coin.networkList) {
        const networks = Coin.networkList;
        const optionsNetwork = Object.entries(networks).map(
          ([symbol, network]) => ({
            value: symbol,
            label: network.name,
            image: `/media/svg/coins/${network.slug}.svg`,
            disable: !network.depositEnable,
          })
        );
        setNetworks(optionsNetwork);
      }
    }
    // eslint-disable-next-line
  }, [selectedCoin]);

  useEffect(() => {
    if (selectedCoin && selectedNetwork) {
      setGettingAddress(true);
      const controller = new AbortController();
      const signal = controller.signal;
      getAddress(selectedCoin, selectedNetwork, { signal })
        .then((res) => {
          if (res.status === 200) {
            setAddress(res.address);
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            return;
          }
          errorHandler(error as Error);
        })
        .finally(() => {
          setGettingAddress(false);
        });

      return () => controller.abort();
    }
    // eslint-disable-next-line
  }, [selectedCoin, selectedNetwork]);

  return (
    <>
      <div className="w-100 mb-5">
        <h4 className="fs-5 fw-semibold text-gray-800">Choose currency</h4>
        <SelectWithImage
          search={!webappStatus}
          defaultValue={"Choose currency"}
          options={optionsCoin}
          onChange={(value) => {
            setSelectedNetwork(undefined);
            setSelectedCoin(value);
          }}
        />
      </div>
      {networks && (
        <div className="w-100">
          <h4 className="fs-5 fw-semibold text-gray-800">Choose network</h4>
          <SelectWithImage
            search={!webappStatus}
            defaultValue={"Choose network"}
            options={networks}
            onChange={(value) => setSelectedNetwork(value)}
            value={selectedNetwork}
          />
        </div>
      )}
      {gettingAddress && (
        <>
          <div className="h-150px w-150px bg-secondary rounded m-auto shine my-10"></div>
          <div className="w-100">
            <h4 className="fs-5 fw-semibold text-gray-800">Receiver address</h4>
            <Placeholder.Button xs={12} variant="secondary" className="shine" />
            <Placeholder as={Card.Text} animation="wave" className="mt-3">
              <span className="badge badge-circle badge-sm badge-outline badge-primary me-1">
                i
              </span>
              <Placeholder xs={7} bg="secondary" />{" "}
              <Placeholder xs={4} bg="secondary" />
              <Placeholder xs={4} bg="secondary" />{" "}
              <Placeholder xs={6} bg="secondary" />
            </Placeholder>
          </div>
        </>
      )}
      {address && (
        <>
          {qrLoading && (
            <div className="h-150px w-150px bg-secondary rounded m-auto shine my-10"></div>
          )}
          <img
            className={`mx-auto d-block my-10 ${qrLoading && "d-none"}`}
            src={`http://api.qrserver.com/v1/create-qr-code/?data=${address}&size=150x150`}
            alt="address-qrcode"
            onLoad={() => {
              setQrLoading(false);
            }}
          />

          <div className="w-100">
            <h4 className="fs-5 fw-semibold text-gray-800">Receiver address</h4>
            <TableBodyLink>{address}</TableBodyLink>
            <p className="text-muted mt-3">
              <span className="badge badge-circle badge-sm badge-outline badge-primary me-1">
                i
              </span>
              Send only{" "}
              <b className="text-primary me-1">
                {selectedCoin && coinsListMap[selectedCoin].name} (
                {selectedCoin})
              </b>
              to this address. Sending other coins may result in permanent loss.
            </p>
          </div>
        </>
      )}
    </>
  );
};
