import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { errorHandler } from "../../app/services/errorHandler";
import { DateTime, formatAmount } from "../../app/services/functionServices";
import { donationTxsInterval } from "../../app/services/intervalManage";
import { useRequest } from "../../app/services/userHttpRequests";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { ModalContext } from "../../_oxapay/layout/core/ModalContext";
import { AmountCurrency } from "../AmountCurrency";
import { BadgeLight } from "../BadgeLight";
import { TableTXsFilterCard } from "../filters/TableTXsFilterCard";
import { TablePlaceholder } from "../TablePlaceholder";
import { TableSearch } from "../TableSearch";
import { TablesWidget } from "../TableWidget";

export interface TxsType {
  id: string;
  userName: string;
  userEmail: string;
  buttonName: string;
  price: string;
  amountSent: string;
  amountReceived: string;
  currency: string;
  status: string;
  confirm?: string;
  confirmRequired?: string;
  date: string;
  txHash?: string;
  txUrl?: string;
}

export const DonationTxsTable = () => {
  const [trasnactionsFetching, setTrasnactionsFetching] = useState(true);
  const [transactions, setTransactions] = useState<TxsType[]>();
  const { coinsListMap } = useContext(AppContext);
  const { setComponent, setShowModal, setModalWidth } =
    useContext(ModalContext);
  const [search, setSearch] = useState("");
  const [trasnactionsPage, setTransactionsPage] = useState({
    page: 1,
    pages: 0,
  });
  const [filter, setFilter] = useState({
    coin: [],
    start: "",
    end: "",
  });
  const { donationTransactions } = useRequest();

  useEffect(() => {
    setTrasnactionsFetching(true);
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchTxs = async () => {
      try {
        const response = await donationTransactions(
          search,
          trasnactionsPage.page,
          filter,
          {
            signal,
          }
        );
        if (response.status === 200) {
          const TransactionsData = response;
          setTransactions(TransactionsData.data);
          setTransactionsPage(TransactionsData.meta);
          setTrasnactionsFetching(false);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };

    if (trasnactionsPage.page === 1) {
      fetchTxs();
      const interval = setInterval(fetchTxs, donationTxsInterval);

      return () => {
        controller.abort(); // Cancel the fetch request
        clearInterval(interval); // Clean up the interval on component unmount
      };
    } else {
      fetchTxs();

      return () => {
        controller.abort(); // Cancel the fetch request
      };
    }
    // eslint-disable-next-line
  }, [, search, trasnactionsPage.page, filter]);

  return (
    <TablesWidget
      loading={trasnactionsFetching}
      noData={
        transactions?.length === 0 && !trasnactionsFetching ? true : false
      }
      searchInput={
        <TableSearch
          value={search}
          onChange={() => setTrasnactionsFetching(true)}
          onDebouncedChange={(searchContent) => {
            setSearch(searchContent);
            setTransactionsPage({ ...trasnactionsPage, page: 1 });
          }}
          searchPlaceholder={"Search (Track ID, Hash)"}
        />
      }
      activePage={trasnactionsPage.page}
      pages={trasnactionsPage.pages}
      pageChange={(page) =>
        setTransactionsPage({ ...trasnactionsPage, page: page })
      }
      title="Your donations transactions"
      className="mb-5 mb-xl-10"
      tableHead={[
        "Donation name",
        "Amount received",
        "Status",
        "Date",
        "Detail",
      ]}
      size={[null, null, 150, null, null]}
      filter={true}
      filterCard={
        <TableTXsFilterCard
          onChange={(newFilter) => {
            if (JSON.stringify(newFilter) !== JSON.stringify(filter)) {
              setFilter(newFilter);
              setTrasnactionsFetching(true);
            }
          }}
        />
      }
    >
      {trasnactionsFetching === true && <TablePlaceholder config={[10, 5]} />}
      {!trasnactionsFetching &&
        transactions !== undefined &&
        transactions.map((data) => (
          <tr key={data.id}>
            <td>
              <span className="text-dark fw-bold fs-6">{data.buttonName}</span>
            </td>

            <td>
              <AmountCurrency
                amount={data.amountReceived}
                currency={data.currency}
              />
            </td>
            <td>
              <BadgeLight
                bg={
                  data.status === "y"
                    ? "success"
                    : data.status === "f"
                    ? "danger"
                    : "warning"
                }
              >
                {data.status === "y"
                  ? "Confirmed"
                  : data.status === "w"
                  ? data?.confirm && data?.confirmRequired
                    ? `Confirming (${data.confirm}/${data.confirmRequired})`
                    : "Confirming"
                  : data.status === "p"
                  ? "Processing"
                  : data.status === "f"
                  ? "Failed"
                  : "Processing"}
              </BadgeLight>
            </td>
            <td>
              <span className="text-gray-600 fw-bold fs-6">
                {DateTime(parseInt(data.date))}
              </span>
            </td>
            <td>
              <button
                onClick={() => {
                  setShowModal(true);
                  setModalWidth(600);
                  setComponent(
                    <div className="px-5">
                      <div className="bg-light-secondary ps-6 py-4 rounded mb-7">
                        <p className="m-0 fs-4 text-dark">Track ID</p>
                        <p className="m-0 fs-6 text-primary">
                          {data.id || "-"}
                        </p>
                        <hr className="border-gray-500 my-3" />
                        <p className="m-0 fs-4 text-dark">User's name</p>
                        <p className="m-0 fs-6 text-primary">
                          {data.userName || "-"}
                        </p>
                        <hr className="border-gray-500 my-3" />
                        <p className="m-0 fs-4 text-dark">User's email</p>
                        <p className="m-0 fs-6 text-primary">
                          {data.userEmail || "-"}
                        </p>
                        <hr className="border-gray-500 my-3" />
                        <p className="m-0 fs-4 text-dark">Amount Sent</p>
                        <p className="m-0 fs-6 text-primary">
                          {formatAmount(
                            parseFloat(data.amountSent),
                            coinsListMap[data.currency]?.displayPrecision
                          )}{" "}
                          {data.currency}
                        </p>
                        {data?.txHash && (
                          <>
                            <hr className="border-gray-500 my-3" />
                            <p className="m-0 fs-4 text-dark">
                              Transaction Hash
                            </p>
                            {data?.txUrl ? (
                              <a
                                href={data?.txUrl}
                                className="m-0 fs-6 text-primary cursor-pointer flex-wrap-overflow"
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {data?.txHash}
                              </a>
                            ) : (
                              <span className="m-0 fs-6 text-primary cursor-pointer flex-wrap-overflow">
                                {data?.txHash || "-"}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  );
                }}
                className="btn btn-link btn-color-primary btn-active-color-primary me-5 mb-2"
              >
                Details
              </button>
            </td>
          </tr>
        ))}
    </TablesWidget>
  );
};
