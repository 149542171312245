import React, { createContext, useEffect, useState } from "react";
import { getCoinsList } from "../../../app/services/appRequests";
import { errorHandler } from "../../../app/services/errorHandler";
import { useRequest } from "../../../app/services/userHttpRequests";
import { WithChildren } from "../../helpers";

interface Network {
  coin: string;
  slug: string;
  network: string;
  name: string;
  depositEnable: boolean;
  withdrawEnable: boolean;
}

interface NetworkList {
  [network: string]: Network;
}

export interface CoinsList {
  [symbol: string]: {
    symbol: string;
    status: boolean;
    name: string;
    slug: string;
    displayPrecision: string;
    networkList: NetworkList;
    depositEnable: boolean;
    withdrawEnable: boolean;
  };
}

export interface FiatsList {
  [symbol: string]: {
    symbol: string;
    name: string;
    price: string;
  };
}

export interface AppContextModel {
  fiatsListMap: FiatsList;
  coinsListMap: CoinsList;
  coinsLoading: boolean;
  addressbookLoading: boolean;
  addressbook: AddressBook[];
  setAddressbook: (_addressbook: AddressBook[]) => void;
}

export interface AddressBook {
  id: string;
  name: string;
  address: string;
  whitelist: boolean;
}

export const AppContext = createContext<AppContextModel>({
  fiatsListMap: {},
  coinsListMap: {},
  coinsLoading: true,
  addressbookLoading: true,
  addressbook: [],
  setAddressbook: (_addressbook: AddressBook[]) => {},
});

const AppProvider: React.FC<WithChildren> = ({ children }) => {
  const { addressbookList } = useRequest();

  const [coinsListMap, setCoinsListMap] = useState<CoinsList>({});
  const [fiatsListMap, setFiatsListMap] = useState<FiatsList>({});
  const [coinsLoading, setCoinsLoading] = useState(true);

  const [addressbook, setAddressbook] = useState<AddressBook[]>([]);
  const [addressbookLoading, setAddressbookLoading] = useState(true);

  const value: AppContextModel = {
    fiatsListMap,
    coinsListMap,
    coinsLoading,
    addressbookLoading,
    addressbook,
    setAddressbook,
  };

  const updateCoinsListMap = (response: any) => {
    const updatedCoinsListMap: CoinsList = {};

    for (const symbol in response.data.coins) {
      const coinData = response.data.coins[symbol];

      // Check if all networkList entries have depositEnable set to false
      const allNetworksDepositDisabled = Object.values(
        coinData.networkList
      ).every((network) => !(network as any).depositEnable);

      // Check if all networkList entries have withdrawEnable set to false
      const allNetworksWithdrawDisabled = Object.values(
        coinData.networkList
      ).every((network) => !(network as any).withdrawEnable);

      // Update depositEnable based on the condition
      coinData.depositEnable = !allNetworksDepositDisabled;

      // Update withdrawEnable based on the condition
      coinData.withdrawEnable = !allNetworksWithdrawDisabled;

      updatedCoinsListMap[symbol] = coinData;
    }

    setCoinsListMap(updatedCoinsListMap);
  };

  useEffect(() => {
    getCoinsList()
      .then((response) => {
        updateCoinsListMap(response);
        setFiatsListMap(response.data.fiats);
        setCoinsLoading(false);
      })
      .catch((error) => {
        errorHandler(error as Error);
      });

    addressbookList()
      .then((response) => {
        if (response.status === 200) {
          setAddressbookLoading(false);
          setAddressbook(response.data);
        }
      })
      .catch((error) => {
        errorHandler(error as Error);
      });
    // eslint-disable-next-line
  }, []);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
