import React, { useEffect, useState } from "react";
import { ConnectWidget } from "../../components/ConnectWidget";
import { HomeChartPayment } from "../../components/HomeChartPayment";
import { HomeChartShare } from "../../components/HomeChartShare";
import { HomePaymentSummary } from "../../components/HomePaymentSummary";
import { HomeTxsTable } from "../../components/tables/HomeTxsTable";
import { WalletHeader } from "../../components/WalletHeader";
import { WalletTabs } from "../../components/WalletTabs";
import { DisableSidebar, PageTitle } from "../../_oxapay/layout/core";
import { useAuth } from "../modules/auth";
import { errorHandler } from "../services/errorHandler";
import { useRequest } from "../services/userHttpRequests";

export const Home = () => {
  const [newPaymentsStats, setNewPaymentsStats] = useState(0);
  const { newPayments } = useRequest();
  const { currentUser } = useAuth();
  useEffect(() => {
    newPayments()
      .then((res) => {
        if (res.status === 200) {
          setNewPaymentsStats(res.payments);
        }
      })
      .catch((error) => errorHandler(error));
    // eslint-disable-next-line
  }, []);
  return (
    <DisableSidebar>
      <PageTitle
        description={`You’ve got ${newPaymentsStats} new payment transactions`}
        breadcrumbs={[]}
      >
        {currentUser?.firstName !== ""
          ? `Hello, ${currentUser?.firstName}`
          : `Welcome to OxaPay`}
      </PageTitle>
      <div className="row gy-5 g-xl-10">
        <div className="col-xl-8 mb-5">
          <HomePaymentSummary />
          <div className="row mt-5">
            <div className="col-xl-6 mb-5">
              <HomeChartShare />
            </div>
            <div className="col-xl-6 mb-5">
              <HomeChartPayment />
            </div>
          </div>
          <HomeTxsTable />
        </div>

        <div className="col-xl-4 mb-5 order-first order-xl-2">
          <ConnectWidget
            href={`https://t.me/OxaPayWalletBot?start=${currentUser?.connectCode}`}
          />
          <div className="card">
            <div className="card-body px-7">
              <WalletHeader />
              <WalletTabs />
            </div>
          </div>
        </div>
      </div>
    </DisableSidebar>
  );
};
