import { DisableSidebar, PageTitle } from "../../_oxapay/layout/core";
import { SwapForm } from "../../components/forms/SwapForm";
import { SwapHistoryTable } from "../../components/tables/SwapHistoryTable";
import SwapProvider from "../Core/SwapContext";

export function Swap() {
  return (
    <DisableSidebar>
      <SwapProvider>
        <PageTitle breadcrumbs={[]}>Swap</PageTitle>
        <div className="row gy-5 g-xl-10">
          <div className="col-xl-4 mb-10">
            <SwapForm />
          </div>

          <div className="col-xl-8 mb-5">
            <SwapHistoryTable />
          </div>
        </div>
      </SwapProvider>
    </DisableSidebar>
  );
}
