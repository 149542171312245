import React, { useContext, useState } from "react";
import { SwapContext } from "../app/Core/SwapContext";
import { alertsMapSwap } from "../app/services/alertsMap";
import { errorHandler } from "../app/services/errorHandler";
import { FailedModalFire } from "../app/services/failedSwalFire";
import { SuccessModalFire } from "../app/services/successSwalFire";
import { useRequest } from "../app/services/userHttpRequests";
import { WaitButtonSpan } from "./WaitButtonSpan";

interface props {
  fromAmount: string;
  toAmount: string;
  fromCurrency: string;
  toCurrency: string;
  onBack: () => void;
  resetForm: () => void;
}
export const SwapStepTwo = ({
  fromAmount,
  toAmount,
  fromCurrency,
  toCurrency,
  onBack,
  resetForm,
}: props) => {
  const [submitting, setSubmitting] = useState(false);
  const { swap } = useRequest();
  const { setSwaps, setSwapsPage } = useContext(SwapContext);

  const submit = async () => {
    setSubmitting(true);
    try {
      const res = await swap(fromCurrency, fromAmount, toCurrency, toAmount);
      if (res.status === 200 && res.result === true) {
        setSwaps(res.data);
        setSwapsPage(res.meta);
        SuccessModalFire("Your swap has been successfully completed");
        resetForm();
        onBack();
      } else {
        FailedModalFire(alertsMapSwap[res.errorNum.toString()]);
        onBack();
      }
    } catch (error) {
      errorHandler(error as Error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <p className="fs-5 text-gray-700 ms-5 mb-2">Swap details</p>
      <div className="bg-light-secondary ps-6 py-4 rounded mb-7">
        <p className="m-0 fs-4 text-dark">From</p>
        <p className="m-0 fs-7 text-primary">
          {fromAmount} {fromCurrency}
        </p>
        <hr className="border-gray-500 my-3" />
        <p className="m-0 fs-4 text-dark">To</p>
        <p className="m-0 fs-7 text-primary">
          {toAmount} {toCurrency}
        </p>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <button
          type="button"
          className="btn fs-3 text-muted text-hover-primary mx-5"
          disabled={submitting}
          onClick={onBack}
        >
          Back
        </button>
        <button
          type="button"
          onClick={submit}
          className="btn btn-primary fs-3 w-200px"
          disabled={submitting}
        >
          {submitting ? <WaitButtonSpan /> : "Confirm"}
        </button>
      </div>
    </>
  );
};
