import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { errorHandler } from "../../app/services/errorHandler";
import { useRequest } from "../../app/services/userHttpRequests";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { ShowAlertContext } from "../../_oxapay/layout/core/ShowAlertContext";
import { WebappSelectWithImage } from "./WebappSelectWithImage";
import { WebappTextCopy } from "./webappTextCopy";

export interface Network {
  label: string;
  value: string;
  image: string;
}

export const WalletWebappReceive = () => {
  const { symbol } = useParams();
  const navigate = useNavigate();
  const { coinsListMap } = useContext(AppContext);
  const [gettingAddress, setGettingAddress] = useState(false);
  const [qrLoading, setQrLoading] = useState(true);
  const [selectedCoin, setSelectedCoin] = useState<string | undefined>(
    symbol || ""
  );
  const [selectedNetwork, setSelectedNetwork] = useState<string | undefined>();
  const [networks, setNetworks] = useState<Network[] | undefined>();
  const [address, setAddress] = useState<string | undefined>();
  const { setShowAlert, setAlertMessage } = useContext(ShowAlertContext);
  const { getAddress } = useRequest();

  useEffect(() => {
    setQrLoading(true);
  }, [address]);

  const optionsCoin = Object.entries(coinsListMap)
    .filter(([_symbol, coin]) => coin.status === true)
    .map(([symbol, coin]) => ({
      value: symbol,
      label: `${coin.name} (${symbol})`,
      image: `/media/svg/coins/${coin?.slug}.svg`,
      disable: !coin.depositEnable,
    }));

  useEffect(() => {
    if (selectedCoin) {
      setAddress(undefined);
      const Coin = coinsListMap[selectedCoin];
      if (Coin && Coin.networkList) {
        const networks = Coin.networkList;
        const optionsNetwork = Object.entries(networks).map(
          ([symbol, network]) => ({
            value: symbol,
            label: network.name,
            image: `/media/svg/coins/${network.slug}.svg`,
            disable: !network.depositEnable,
          })
        );
        setNetworks(optionsNetwork);
      }
    }
    // eslint-disable-next-line
  }, [selectedCoin]);

  useEffect(() => {
    if (selectedCoin && selectedNetwork) {
      setGettingAddress(true);
      const controller = new AbortController();
      const signal = controller.signal;
      getAddress(selectedCoin, selectedNetwork, { signal })
        .then((res) => {
          if (res.status === 200) {
            setAddress(res.address);
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            return;
          }
          errorHandler(error as Error);
        })
        .finally(() => {
          setGettingAddress(false);
        });

      return () => controller.abort();
    }
    // eslint-disable-next-line
  }, [selectedCoin, selectedNetwork]);

  useEffect(() => {
    const WebappFn = (window as any)?.Telegram?.WebApp;

    const backBtnFunc = () => {
      symbol
        ? navigate(`/webapp-wallet/coin/${symbol}`)
        : navigate(`/webapp-wallet`);
    };
    WebappFn?.BackButton?.onClick(backBtnFunc).show();

    return () => {
      WebappFn?.BackButton?.offClick(backBtnFunc).hide();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="pt-15 mb-7">
        <WebappSelectWithImage
          defaultValue="Choose currency"
          value={selectedCoin || ""}
          options={optionsCoin}
          onChange={(value) => {
            setSelectedNetwork(undefined);
            setSelectedCoin(value);
          }}
        />
      </div>
      {networks && (
        <WebappSelectWithImage
          defaultValue="Choose network"
          options={networks}
          onChange={(value) => setSelectedNetwork(value)}
          value={selectedNetwork || ""}
        />
      )}
      {gettingAddress && (
        <>
          <div className="d-flex justify-content-center align-items-center mt-15 mb-5">
            <div className="card p-4 rounded-12px">
              <div className="h-150px w-150px bg-secondary rounded m-auto shine"></div>
            </div>
          </div>
          <div className="text-center mb-9">
            <div className="h-20px w-200px bg-secondary rounded m-auto shine mb-1"></div>
            <div className="h-20px w-200px bg-secondary rounded m-auto shine"></div>
          </div>
          <div className="card py-8 px-10">
            <div className="h-20px w-100 bg-secondary rounded m-auto shine mb-1"></div>
            <div className="h-20px w-100 bg-secondary rounded m-auto shine mb-1"></div>
          </div>
        </>
      )}
      {address && (
        <>
          <div className="d-flex justify-content-center align-items-center mt-15 mb-5">
            <div className="card p-4 rounded-12px">
              {qrLoading && (
                <div className="h-150px w-150px bg-secondary rounded m-auto shine"></div>
              )}
              <img
                className={`mx-auto d-block ${qrLoading && "d-none"}`}
                src={`https://api.qrserver.com/v1/create-qr-code/?data=${address}&size=150x150`}
                alt="address-qrcode"
                onLoad={() => {
                  setQrLoading(false);
                }}
              />
            </div>
          </div>
          <div className="text-center mb-9">
            <WebappTextCopy
              text={address}
              onCopy={() => {
                setShowAlert(true);
                setAlertMessage("Address copied to clipoboard");
              }}
            >
              <p className="fs-2 lh-normal fm-jetbrain">
                {address.slice(0, Math.floor(address.length / 2))}
                <br />
                {address.slice(Math.floor(address.length / 2))}
              </p>
            </WebappTextCopy>
          </div>
          <div className="card py-8 px-10">
            <p className="fs-5 lh-normal m-0">
              Send only{" "}
              <span className="text-primary">
                {selectedCoin && coinsListMap[selectedCoin].name} (
                {selectedCoin})
              </span>{" "}
              to this address. Sending other coins may result in permanent loss.
            </p>
          </div>
        </>
      )}
    </>
  );
};
