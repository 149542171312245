import React from "react";
interface props {
  children: string;
  bg: string;
}

export const BadgeLight = ({ children, bg }: props) => {
  return (
    <span className={`badge py-3 px-4 fs-8 badge-light-${bg}`}>{children}</span>
  );
};
