import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { KTSVG } from "../../_oxapay/helpers";

export const VerifiedAddress = () => {
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="top"
      overlay={
        <Popover>
          <Popover.Body>
            This address has been verified by you as whitelist. Once an address
            is whitelisted in your address book, you can transfer assets to it
            without the need for entering any confirmation or two-factor
            authentication codes.
          </Popover.Body>
        </Popover>
      }
    >
      <span className="cursor-pointer">
        <KTSVG
          path="/media/icons/duotune/general/gen026.svg"
          className="svg-icon-primary svg-icon-2 ms-1"
        />
      </span>
    </OverlayTrigger>
  );
};
