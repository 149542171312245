import { createContext, useState, ReactNode } from "react";

export interface PayoutApi {
  id: string;
  name: string;
  limit: {
    day: number;
    transaction: number;
  };
  allowedIps: string[];
  apiKey: string;
  date: string;
}

interface PayoutApiPage {
  page: number;
  pages: number;
}

export interface PayoutApiContextModel {
  payoutApi?: PayoutApi[];
  setPayoutApi: (payoutApi: PayoutApi[]) => void;
  payoutApiPage: PayoutApiPage;
  setPayoutApiPage: (payoutApiPage: PayoutApiPage) => void;
  payoutApiSearch: string;
  setPayoutApiSearch: (payoutApiSearch: string) => void;
}

export const PayoutApiContext = createContext<PayoutApiContextModel>({
  payoutApi: undefined,
  setPayoutApi: () => {},
  payoutApiPage: {
    page: 1,
    pages: 0,
  },
  setPayoutApiPage: () => {},
  payoutApiSearch: "",
  setPayoutApiSearch: () => {},
});

interface PayoutApiProviderProps {
  children: ReactNode;
}

const PayoutApiProvider: React.FC<PayoutApiProviderProps> = ({ children }) => {
  const [payoutApi, setPayoutApi] = useState<PayoutApi[] | undefined>(
    undefined
  );
  const [payoutApiPage, setPayoutApiPage] = useState({
    page: 1,
    pages: 0,
  });
  const [payoutApiSearch, setPayoutApiSearch] = useState("");

  return (
    <PayoutApiContext.Provider
      value={{
        payoutApi,
        setPayoutApi,
        payoutApiPage,
        setPayoutApiPage,
        payoutApiSearch,
        setPayoutApiSearch,
      }}
    >
      {children}
    </PayoutApiContext.Provider>
  );
};

export default PayoutApiProvider;
