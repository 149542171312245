import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface props {
  placeholder?: string;
  onchange: (date: any) => void;
  value: any;
}
const DateTimePicker = ({
  placeholder = "Date & Time",
  onchange,
  value,
}: props) => {
  const [selectedDate, setSelectedDate] = useState(value);

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  useEffect(() => {
    onchange(selectedDate);
    // eslint-disable-next-line
  }, [selectedDate]);

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={15}
      dateFormat="MMMM d, yyyy h:mm aa"
      className="form-control form-control-solid flex-grow-1 pe-0"
      placeholderText={placeholder}
      shouldCloseOnSelect={false} // Set shouldCloseOnSelect to false
    />
  );
};

export default DateTimePicker;
