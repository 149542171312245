import React from "react";
import { toAbsoluteUrl } from "../_oxapay/helpers";

export const LoadingComponent = () => {
  return (
    <div className="splash-screen">
      <div className="loading-container d-flex flex-column">
        <img
          className="logo-loading"
          src={toAbsoluteUrl("/media/logos/gray.svg")}
          alt="oxapay loading"
        />
      </div>
    </div>
  );
};
