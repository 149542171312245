import React, { useContext, useState } from "react";
import { alertsMapTxAction } from "../../app/services/alertsMap";
import { errorHandler } from "../../app/services/errorHandler";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { SuccessModalFire } from "../../app/services/successSwalFire";
import { useRequest } from "../../app/services/userHttpRequests";
import { ModalContext } from "../../_oxapay/layout/core/ModalContext";
import { WaitButtonSpan } from "../WaitButtonSpan";

interface props {
  status: string;
  type: string;
  depositId: string;
  onSuccess: (data: any) => void;
  search?: string;
  page?: number;
  filter?: {
    status?: string[];
    coin: string[];
    start: string;
    end: string;
  };
}

export const TxAcceptForm = ({
  status,
  type,
  depositId,
  onSuccess,
  search,
  page,
  filter,
}: props) => {
  const [loading, setLoading] = useState(false);
  const { merchantTxAction, paymentlinkTxAction, channelTxAction } =
    useRequest();
  const { setShowModal } = useContext(ModalContext);

  const AcceptConfirm = () => {
    setLoading(true);

    const handleTxAction = async (
      txAction: (
        depositId: string,
        action: string,
        search?: string,
        page?: number,
        filter?: {
          status?: string[];
          coin: string[];
          start: string;
          end: string;
        }
      ) => Promise<any>
    ) => {
      try {
        const res = await txAction(depositId, "confirm", search, page, filter);

        if (res.status === 200 && res.result === true) {
          onSuccess(res);
          SuccessModalFire("The transaction was accepted successfully");
          setShowModal(false);
        } else if (res.status === 0 && res.errorNum) {
          FailedModalFire(alertsMapTxAction[res.errorNum.toString()]);
        }
      } catch (error) {
        errorHandler(error as Error);
      } finally {
        setLoading(false);
      }
    };

    switch (type) {
      case "merchant":
        handleTxAction(merchantTxAction);
        break;
      case "paymentlink":
        handleTxAction(paymentlinkTxAction);
        break;
      case "channelgateway":
        handleTxAction(channelTxAction);
        break;
      default:
      // Handle other cases if needed
    }
  };

  return (
    <div className="px-10 pb-10 pt-5 d-flex flex-column justify-content-center align-items-center">
      <p className="fs-2 fw-bold mb-8">Are you sure to accept?</p>
      <div className="bg-light-warning rounded p-10 mb-10">
        <p className="fs-6 text-gray-900 m-0">
          <i className="fa-solid fa-circle-info fs-4 text-warning"></i>{" "}
          {status === "under"
            ? "The invoice status indicates it's underpaid. By accepting it, you acknowledge receiving an amount lower than the invoice amount."
            : "The invoice status indicates it's expired. By accepting it, you acknowledge receiving an amount lower than the invoice amount."}
        </p>
      </div>
      <div className="d-flex justify-content-between align-items-center mw-400px">
        <button
          type="button"
          className="btn fs-3 text-muted text-hover-primary mx-5"
          disabled={loading}
          onClick={() => {
            setShowModal(false);
          }}
        >
          No, Cancel
        </button>
        <button
          type="button"
          onClick={AcceptConfirm}
          className="btn btn-primary fs-3 w-200px"
          disabled={loading}
        >
          {loading ? <WaitButtonSpan /> : "Yes, Accept"}
        </button>
      </div>
    </div>
  );
};
