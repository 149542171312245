import React from "react";
import { statusMap } from "../app/services/mapHelper";
import { BadgeLight } from "./BadgeLight";

interface props {
  status: string;
  addon?: string;
}
export const TxStatus = ({ status, addon = "" }: props) => {
  const statusConverter = (status: string) => {
    const mappedStatus = statusMap[status];

    if (mappedStatus) {
      return { color: mappedStatus.color, name: mappedStatus.name };
    } else {
      return { color: "warning", name: "Processing" };
    }
  };

  return (
    <BadgeLight bg={statusConverter(status).color}>
      {statusConverter(status).name + addon}
    </BadgeLight>
  );
};
