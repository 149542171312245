import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { errorHandler } from "../../app/services/errorHandler";
import { formatAmountNumber } from "../../app/services/functionServices";
import { swapRateInterval } from "../../app/services/intervalManage";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { OptionModel, ValuesModel } from "../SwapStepOne";
import { WebappSelectWithImage } from "./WebappSelectWithImage";
import { WebappSwapInput } from "./WebappSwapInput";
import * as Yup from "yup";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import usePrice from "../../hooks/usePrice";
import { useRequest } from "../../app/services/userHttpRequests";
import { useFormik } from "formik";
import { BalanceContext } from "../../_oxapay/layout/core/BlanceContext";
import { AssetsContext } from "../../_oxapay/layout/core/AssetsContext";
import { WebappModalContext } from "../../_oxapay/layout/core/WebappModalContext";
import { useNavigate } from "react-router-dom";

interface props {
  values: ValuesModel | undefined;
  pairsData: Record<string, { min: string; pair: string }[]>;
  onConfirm: (values: ValuesModel) => void;
  fromCurrencyChange: (fromCurrency: string | undefined) => void;
  toCurrencyChange: (toCurrency: string | undefined) => void;
  resetFormTrigger: number;
}

export const WalletWebappSwapStepOne = ({
  values,
  pairsData,
  onConfirm,
  fromCurrencyChange,
  toCurrencyChange,
  resetFormTrigger,
}: props) => {
  const { coinsPrice } = usePrice();
  const { coinsListMap } = useContext(AppContext);
  const { getSwapRate } = useRequest();
  const { balance } = useContext(BalanceContext);
  const { balanceLoading } = useContext(AssetsContext);
  const [min, setMin] = useState(0);
  const [swapRate, setSwapRate] = useState<number | undefined>(undefined);
  const [showError, setShowError] = useState(false);
  const [swapRateLoading, setSwapRateLoading] = useState(false);
  const [rotate, setRotate] = useState(false);
  const [fromOptions, setFromOptions] = useState<OptionModel[]>([]);
  const [toOptions, setToOptions] = useState<OptionModel[]>([]);
  const [selectedFrom, setSelectedFrom] = useState<string | undefined>();
  const [selectedTo, setSelectedTo] = useState<string | undefined>();
  const { showModal } = useContext(WebappModalContext);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: values ?? {
      fromAmount: "",
      toAmount: "",
      fromCurrency: "",
      toCurrency: "",
    },
    validationSchema: Yup.object({
      fromAmount: Yup.number()
        .min(min, `Minimum amount is ${min}`)
        .max(
          balance && selectedFrom ? Number(balance[selectedFrom]) : Infinity,
          "Your balance is insufficient"
        )
        .required("Amount is required"),
      toAmount: Yup.number().required(),
      fromCurrency: Yup.string().required("Select currency"),
      toCurrency: Yup.string().required("Select currency"),
    }),
    onSubmit: async (values) => {
      onConfirm(values);
    },
  });

  const getOptions = (pairs: string[]): OptionModel[] => {
    return pairs.map((pair) => ({
      label: pair,
      value: pair,
      image: `/media/svg/coins/${coinsListMap[pair]?.slug}.svg`,
    }));
  };

  const getMinimumValue = (selectedFrom: string, selectedTo: string) => {
    const pairInfo = pairsData[selectedFrom]?.find(
      (pair) => pair.pair === selectedTo
    );

    if (pairInfo) {
      return pairInfo.min;
    } else {
      return "Default"; // or any default value
    }
  };

  const minHandler = () => {
    if (selectedTo && swapRate && balance && selectedFrom) {
      formik.setFieldValue(
        "toAmount",
        formatAmountNumber(
          min * swapRate,
          coinsListMap[selectedTo]?.displayPrecision
        )
      );
    }
    min && formik.setFieldValue("fromAmount", min);
  };

  const maxHandler = () => {
    if (balance && selectedFrom) {
      if (selectedTo && swapRate) {
        formik.setFieldValue(
          "toAmount",
          formatAmountNumber(
            parseFloat(balance[selectedFrom]) * swapRate,
            coinsListMap[selectedTo]?.displayPrecision
          )
        );
      }
      formik.setFieldValue("fromAmount", balance[selectedFrom]);
    }
  };

  useEffect(() => {
    if (resetFormTrigger > 0) {
      setSelectedFrom(undefined);
      setSelectedTo(undefined);
      const coins = Object.keys(pairsData);
      setFromOptions(getOptions(coins));
      setToOptions(getOptions(coins));
      formik.resetForm();
      setShowError(false);
    }
    // eslint-disable-next-line
  }, [resetFormTrigger]);

  useEffect(() => {
    const coins = Object.keys(pairsData);
    setFromOptions(getOptions(coins));
    setToOptions(getOptions(coins));
    // eslint-disable-next-line
  }, [coinsListMap]);

  useEffect(() => {
    //props need
    fromCurrencyChange(selectedFrom);
    toCurrencyChange(selectedTo);

    //define minimum from amount
    selectedFrom && selectedTo
      ? setMin(Number(getMinimumValue(selectedFrom, selectedTo)))
      : setMin(0);

    //update toCurrency options when fromCurrency change
    if (selectedFrom) {
      const selectedPairs = pairsData[selectedFrom]?.map(
        (details) => details.pair
      );
      setToOptions(getOptions(selectedPairs));
    }

    //update fromCurrency options when toCurrency change
    if (selectedTo) {
      const selectedPairs = pairsData[selectedTo]?.map(
        (details) => details.pair
      );
      setFromOptions(getOptions(selectedPairs));
    }

    // eslint-disable-next-line
  }, [selectedFrom, selectedTo, coinsListMap]);

  useEffect(() => {
    if (selectedFrom && selectedTo) {
      setSwapRateLoading(true);
    }
    const controller = new AbortController();
    const fetchRate = () => {
      if (selectedFrom && selectedTo) {
        getSwapRate(selectedFrom, selectedTo, { signal: controller.signal })
          .then((res) => {
            if (res.status === 200) {
              setSwapRateLoading(false);
              setSwapRate(res.data.rate);
            }
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              return;
            }
            errorHandler(error as Error);
          });
      } else {
        setSwapRate(undefined);
      }
    };

    fetchRate();
    const interval = setInterval(fetchRate, swapRateInterval);

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval on component unmount
    };
    // eslint-disable-next-line
  }, [selectedFrom, selectedTo]);

  useEffect(() => {
    formik.values.fromAmount !== "" &&
      swapRate &&
      selectedTo &&
      formik.setFieldValue(
        "toAmount",
        formatAmountNumber(
          Number(formik.values.fromAmount) * swapRate,
          coinsListMap[selectedTo]?.displayPrecision
        )
      );
    // eslint-disable-next-line
  }, [swapRate]);

  useEffect(() => {
    if (values) {
      setSelectedFrom(values.fromCurrency);
      setSelectedTo(values.toCurrency);
    }
    // eslint-disable-next-line
  }, []);

  const WebappFn = (window as any)?.Telegram?.WebApp;

  useEffect(() => {
    const backBtnFn = () => {
      navigate(`/webapp-wallet`);
    };

    WebappFn?.BackButton?.onClick(backBtnFn).show();

    return () => {
      WebappFn?.BackButton?.offClick(backBtnFn).hide();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const mainButtonFn = () => {
      formik.handleSubmit();
      setShowError(true);
    };

    if (!showModal) {
      WebappFn?.MainButton?.show().setText("Swap").onClick(mainButtonFn);
    }

    return () => {
      WebappFn?.MainButton?.hide().offClick(mainButtonFn);
    };
    // eslint-disable-next-line
  }, [showModal]);

  return (
    <form>
      <div className="card rounded-12px mb-2">
        <div className="px-6 py-5">
          <div className="d-flex justify-content-between align-items-center mb-5">
            <h4 className="fs-6 text-uppercase fw-400 text-gray-600 mb-0">
              FROM
            </h4>
            <div className="d-flex flex-row">
              <span
                className="text-primary cursor-pointer fs-3 me-4"
                onClick={minHandler}
              >
                Min
              </span>
              <span
                className="text-primary cursor-pointer fs-3"
                onClick={maxHandler}
              >
                Max
              </span>
            </div>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div className="flex-grow-1 pe-2">
              <WebappSwapInput
                type="number"
                name="fromAmount"
                inputMode="decimal"
                autoComplete="off"
                onBlur={formik.handleBlur}
                value={formik.values.fromAmount}
                onChange={(e) => {
                  if (swapRate) {
                    e.currentTarget.value !== "" && selectedTo
                      ? formik.setFieldValue(
                          "toAmount",
                          formatAmountNumber(
                            parseFloat(e.currentTarget.value) * swapRate,
                            coinsListMap[selectedTo]?.displayPrecision
                          )
                        )
                      : formik.setFieldValue("toAmount", "");
                  }
                  formik.handleChange(e);
                }}
                placeholder={min !== 0 ? `min: ${min}` : "Amount"}
              />
            </div>
            <WebappSelectWithImage
              type="swap"
              defaultValue={"Select"}
              options={fromOptions}
              value={selectedFrom || ""}
              onChange={(value) => {
                setSelectedFrom(value);
                formik.setFieldValue("fromCurrency", value);
              }}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p className="fs-6 text-gray-600 m-0">
              {coinsPrice &&
              selectedFrom &&
              Number(formik.values.fromAmount) &&
              !isNaN(
                coinsPrice[selectedFrom]?.price *
                  Number(formik.values.fromAmount)
              )
                ? `=$${(
                    coinsPrice[selectedFrom]?.price *
                    Number(formik.values.fromAmount)
                  ).toLocaleString()}`
                : ""}
            </p>

            <p className="fs-6 text-gray-600 m-0">
              {selectedFrom && balanceLoading ? (
                <div className="h-15px w-100px bg-secondary rounded shine"></div>
              ) : (
                <span className="fs-6 text-gray-600 m-0">
                  {balance &&
                    selectedFrom &&
                    `Balance: ${balance[selectedFrom]}`}
                </span>
              )}
            </p>
          </div>
          {formik.errors.fromAmount && formik.touched.fromAmount && (
            <p className="text-danger fs-5 fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.fromAmount}
            </p>
          )}
          {formik.errors.fromCurrency && showError && (
            <p className="text-danger fs-5 fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.fromCurrency}
            </p>
          )}
        </div>
        <hr className="border-gray-500 w-100 mx-0 my-3" />
        <div>
          <div className="position-relative">
            <span
              className={`position-absolute rounded-12px w-40px h-40px input-group-text bg-primary border-0 cursor-pointer px-3 d-flex justify-content-center align-items-center ${
                rotate ? "rotate-180-animate" : "rotate-0-animate"
              }`}
              onClick={() => {
                if (selectedFrom && selectedTo) {
                  setSelectedFrom(selectedTo);
                  setSelectedTo(selectedFrom);
                  setFromOptions(toOptions);
                  setToOptions(fromOptions);
                }
                setRotate((prevRotate) => !prevRotate);
              }}
              style={{ top: "-29px", left: "44%" }}
            >
              <img
                src={toAbsoluteUrl("/media/icons/wallet/toggle-swap.svg")}
                alt={"swap icon"}
              />
            </span>
          </div>
        </div>
        <div className="px-6 pt-0 pb-5">
          <div className="d-flex justify-content-between align-items-center mb-5">
            <h4 className="fs-6 text-uppercase fw-400 text-gray-600 mb-0">
              To
            </h4>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div className="flex-grow-1 pe-2">
              {swapRateLoading ? (
                <div className="h-40px w-130px bg-secondary rounded shine"></div>
              ) : (
                <WebappSwapInput
                  name="toAmount"
                  type="number"
                  inputMode="decimal"
                  autoComplete="off"
                  value={formik.values.toAmount}
                  onChange={(e) => {
                    if (swapRate) {
                      e.currentTarget.value !== "" && selectedFrom
                        ? formik.setFieldValue(
                            "fromAmount",

                            formatAmountNumber(
                              parseFloat(e.currentTarget.value) / swapRate,
                              coinsListMap[selectedFrom]?.displayPrecision
                            )
                          )
                        : formik.setFieldValue("fromAmount", "");
                    }
                    formik.handleChange(e);
                  }}
                  placeholder={"To Amount"}
                />
              )}
            </div>
            <WebappSelectWithImage
              type="swap"
              defaultValue={"Select"}
              options={toOptions}
              value={selectedTo || ""}
              onChange={(value) => {
                setSelectedTo(value);
                formik.setFieldValue("toCurrency", value);
              }}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p className="fs-6 text-gray-600 m-0">
              {coinsPrice &&
              selectedTo &&
              formik.values.toAmount &&
              !isNaN(
                coinsPrice[selectedTo]?.price *
                  parseFloat(formik.values.toAmount)
              )
                ? `=$${(
                    coinsPrice[selectedTo]?.price *
                    parseFloat(formik.values.toAmount)
                  ).toLocaleString()}`
                : ""}
            </p>

            <p className="fs-6 text-gray-600 m-0">
              {selectedTo && balanceLoading ? (
                <div className="h-15px w-100px bg-secondary rounded shine"></div>
              ) : (
                <span className="fs-6 text-gray-600 m-0">
                  {balance && selectedTo && `Balance: ${balance[selectedTo]}`}
                </span>
              )}
            </p>
          </div>
          {formik.errors.toCurrency && showError && (
            <p className="text-danger fw-semibold fs-5 ms-3 mt-1 mb-0">
              {formik.errors.toCurrency}
            </p>
          )}
        </div>
      </div>
      {swapRateLoading ? (
        <div className="h-20px w-100px bg-secondary rounded shine mb-2 ms-5"></div>
      ) : (
        swapRate && (
          <p className="fs-7 fw-semibold text-gray-600 mb-2 h-20px ms-5">
            1 {selectedFrom} = {swapRate} {selectedTo}
          </p>
        )
      )}
    </form>
  );
};
