import React from "react";
import { Empty } from "./Lotties/Empty";

export const WebappNoData = () => {
  return (
    <div className="mt-17 mb-20">
      <div className="w-150px h-150px m-auto mb-5">
        <Empty loop />
      </div>
      <p className="fs-5 fw-400 text-center mb-0">Nothings found</p>
    </div>
  );
};
