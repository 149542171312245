import { createContext, useState, ReactNode } from "react";

export interface Swap {
  id: string;
  fromCurrency: string;
  fromAmount: string;
  toCurrency: string;
  toAmount: string;
  rate: string;
  date: string;
}

export interface WalletWebappSwapContextModel {
  swaps?: Swap[];
  setSwaps: (swaps: Swap[]) => void;
  pairsData: Record<string, { min: string; pair: string }[]> | undefined;
  setPairsData: (
    pairsData: Record<string, { min: string; pair: string }[]>
  ) => void;
}

export const WalletWebappSwapContext =
  createContext<WalletWebappSwapContextModel>({
    swaps: undefined,
    setSwaps: () => {},
    pairsData: undefined,
    setPairsData: () => {},
  });

interface WalletWebappSwapProviderProps {
  children: ReactNode;
}

const WalletWebappSwapProvider: React.FC<WalletWebappSwapProviderProps> = ({
  children,
}) => {
  const [swaps, setSwaps] = useState<Swap[] | undefined>(undefined);

  const [pairsData, setPairsData] = useState<
    Record<string, { min: string; pair: string }[]> | undefined
  >();

  return (
    <WalletWebappSwapContext.Provider
      value={{
        swaps,
        setSwaps,
        pairsData,
        setPairsData,
      }}
    >
      {children}
    </WalletWebappSwapContext.Provider>
  );
};

export default WalletWebappSwapProvider;
