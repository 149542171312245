import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useAuth } from "../../app/modules/auth";
import { errorHandler } from "../../app/services/errorHandler";
import { DateTime, formatAmount } from "../../app/services/functionServices";
import { merchantTxsInterval } from "../../app/services/intervalManage";
import { useRequest } from "../../app/services/userHttpRequests";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { ModalContext } from "../../_oxapay/layout/core/ModalContext";
import { AmountCurrency } from "../AmountCurrency";
import { TableTXsFilterCard } from "../filters/TableTXsFilterCard";
import { TxAcceptForm } from "../forms/TxAcceptForm";
import { TxRefundForm } from "../forms/TxRefundForm";
import { TablePlaceholder } from "../TablePlaceholder";
import { TableSearch } from "../TableSearch";
import { TablesWidget } from "../TableWidget";
import { TxStatus } from "../TxStatus";

export interface TxsType {
  id: string;
  name: string;
  price: string;
  invoiceAmount: string;
  invoiceCurrency: string;
  amountSent: string;
  amountReceived: string;
  currency: string;
  type: string;
  status: string;
  confirm?: string;
  confirmRequired?: string;
  date: string;
  convertedAmount: number;
  convertedCurrency: "";
  depositId: string;
  refundAmount?: number;
  fromWallet?: string;
  txHash?: string;
  txUrl?: string;
  orderId: string;
  refundTx?: {
    url: string;
    txHash: string;
  };
}

export const MerchantTxsTable = () => {
  const { currentUser } = useAuth();
  const { setShowModal, setComponent, setModalWidth } =
    useContext(ModalContext);
  const { coinsListMap } = useContext(AppContext);
  const [search, setSearch] = useState("");
  const [trasnactionsFetching, setTrasnactionsFetching] = useState(true);
  const [transactions, setTransactions] = useState<TxsType[]>();
  const [trasnactionsPage, setTransactionsPage] = useState({
    page: 1,
    pages: 0,
  });
  const [filter, setFilter] = useState({
    ...(currentUser?.access?.txCheck && { status: [] }),
    coin: [],
    start: "",
    end: "",
  });
  const { merchantTransactions } = useRequest();

  useEffect(() => {
    setTrasnactionsFetching(true);
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchTxs = async () => {
      try {
        const response = await merchantTransactions(
          search,
          trasnactionsPage.page,
          filter,
          {
            signal,
          }
        );
        if (response.status === 200) {
          const TransactionsData = response;
          setTransactions(TransactionsData.data);
          setTransactionsPage(TransactionsData.meta);
          setTrasnactionsFetching(false);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };

    if (trasnactionsPage.page === 1) {
      fetchTxs();
      const interval = setInterval(fetchTxs, merchantTxsInterval);

      return () => {
        controller.abort(); // Cancel the fetch request
        clearInterval(interval); // Clean up the interval on component unmount
      };
    } else {
      fetchTxs();

      return () => {
        controller.abort(); // Cancel the fetch request
      };
    }
    // eslint-disable-next-line
  }, [search, trasnactionsPage.page, filter]);

  const convertedToHeader = currentUser?.access?.swap ? ["Converted to"] : [];
  const convertedToSize = currentUser?.access?.swap ? [null] : [];

  const ActionHeader = currentUser?.access?.txCheck ? ["Action"] : [];
  const ActionSize = currentUser?.access?.txCheck ? [null] : [];

  const swapPlaceholder = currentUser?.access?.swap ? 1 : 0;
  const txCheckPlaceholder = currentUser?.access?.txCheck ? 1 : 0;

  return (
    <TablesWidget
      loading={trasnactionsFetching}
      noData={
        transactions?.length === 0 && !trasnactionsFetching ? true : false
      }
      searchInput={
        <TableSearch
          value={search}
          onChange={() => setTrasnactionsFetching(true)}
          onDebouncedChange={(searchContent) => {
            setSearch(searchContent);
            setTransactionsPage({ ...trasnactionsPage, page: 1 });
          }}
          searchPlaceholder={"Search (Track ID, Hash)"}
        />
      }
      activePage={trasnactionsPage.page}
      pages={trasnactionsPage.pages}
      pageChange={(page) =>
        setTransactionsPage({ ...trasnactionsPage, page: page })
      }
      title="Your merchants transactions"
      className="mb-5 mb-xl-10"
      tableHead={[
        "Merchant name",
        "Type",
        "Amount received",
        ...convertedToHeader,
        "Status",
        ...ActionHeader,
        "Date",
        "Detail",
      ]}
      size={[
        null,
        null,
        100,
        ...convertedToSize,
        100,
        ...ActionSize,
        150,
        null,
      ]}
      filter={true}
      filterCard={
        <TableTXsFilterCard
          statusShow={currentUser?.access?.txCheck}
          onChange={(newFilter) => {
            if (JSON.stringify(newFilter) !== JSON.stringify(filter)) {
              setFilter(newFilter);
              setTrasnactionsFetching(true);
            }
          }}
        />
      }
    >
      {trasnactionsFetching === true && (
        <TablePlaceholder
          config={[10, 6 + swapPlaceholder + txCheckPlaceholder]}
        />
      )}
      {!trasnactionsFetching &&
        transactions !== undefined &&
        transactions.map((data, index) => (
          <tr key={index}>
            <td>
              <span className="text-dark fw-bold fs-6">{data.name}</span>
            </td>
            <td>
              <span className=" badge badge-light-primary fw-bold fs-6">
                {data.type}
              </span>
            </td>
            <td>
              {data.status === "y" || data.status === "manual" ? (
                <AmountCurrency
                  amount={data.amountReceived}
                  currency={data.currency}
                />
              ) : (
                "-"
              )}
            </td>
            {currentUser?.access?.swap && (
              <td>
                {(data.status === "y" || data.status === "manual") &&
                data.convertedCurrency !== "" ? (
                  <AmountCurrency
                    amount={String(data.convertedAmount)}
                    currency={data.convertedCurrency}
                  />
                ) : (
                  <span className="text-gray-600 fw-bold fs-6">-</span>
                )}
              </td>
            )}
            <td>
              <div className="d-flex align-items-center">
                <TxStatus
                  status={data.status}
                  addon={
                    data?.confirm &&
                    data?.confirmRequired &&
                    ` (${data.confirm}/${data.confirmRequired})`
                  }
                />
                {(data.status === "expired" || data.status === "under") && (
                  <span
                    className="ms-2 cursor-pointer"
                    onClick={() => {
                      setShowModal(true);
                      setModalWidth(400);
                      setComponent(
                        <div className="px-5">
                          <div className="bg-light-secondary ps-6 py-4 rounded mb-7">
                            <p className="m-0 fs-4 text-dark">Invoice Amount</p>
                            <p className="m-0 fs-6 text-primary">
                              {data.invoiceAmount
                                ? data.invoiceCurrency
                                  ? formatAmount(
                                      parseFloat(data.invoiceAmount),
                                      coinsListMap[data.currency]
                                        ?.displayPrecision
                                    ) +
                                    " " +
                                    data.invoiceCurrency
                                  : data.invoiceAmount + "USD"
                                : "-"}
                            </p>
                            <hr className="border-gray-500 my-3" />
                            <p className="m-0 fs-4 text-dark">Amount Sent</p>
                            <p className="m-0 fs-6 text-primary">
                              {formatAmount(
                                parseFloat(data.amountSent),
                                coinsListMap[data.currency]?.displayPrecision
                              )}{" "}
                              {data.currency}
                            </p>
                          </div>
                        </div>
                      );
                    }}
                  >
                    <i className="fa-solid fa-circle-info text-primary fs-2"></i>
                  </span>
                )}
              </div>
            </td>
            {currentUser?.access?.txCheck && (
              <td>
                {data.status === "under" || data.status === "expired" ? (
                  <div className="d-flex">
                    <button
                      onClick={() => {
                        setShowModal(true);
                        setModalWidth(500);
                        setComponent(
                          <TxAcceptForm
                            status={data.status}
                            type="merchant"
                            depositId={data.depositId}
                            onSuccess={(newData) => {
                              setTransactions(newData.data);
                              setTransactionsPage(newData.meta);
                            }}
                            search={search}
                            page={trasnactionsPage.page}
                            filter={filter}
                          />
                        );
                      }}
                      className="btn btn-icon btn-success btn-sm mx-1 w-60px h-35px rounded"
                    >
                      Accept
                    </button>
                    {data.refundAmount !== undefined &&
                    data.refundAmount <= 0 ? (
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        overlay={
                          <Popover>
                            <Popover.Body>
                              The refund cannot be processed because the refund
                              amount is less than the network and proccess fee.
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <button className="btn btn-icon btn-primary btn-sm mx-1 w-60px h-35px rounded disable-opacity">
                          Refund
                        </button>
                      </OverlayTrigger>
                    ) : (
                      <button
                        onClick={() => {
                          setShowModal(true);
                          setModalWidth(600);
                          setComponent(
                            <TxRefundForm
                              address={data.fromWallet}
                              refundAmount={
                                data.refundAmount + " " + data.currency
                              }
                              type="merchant"
                              depositId={data.depositId}
                              onSuccess={(newData) => {
                                setTransactions(newData.data);
                                setTransactionsPage(newData.meta);
                              }}
                              search={search}
                              page={trasnactionsPage.page}
                              filter={filter}
                            />
                          );
                        }}
                        className="btn btn-icon btn-primary btn-sm mx-1 w-60px h-35px rounded"
                      >
                        Refund
                      </button>
                    )}
                  </div>
                ) : (
                  <span className="text-gray-600 fw-bold fs-6">-</span>
                )}
              </td>
            )}
            <td>
              <span className="text-gray-600 fw-bold fs-7">
                {DateTime(parseInt(data.date))}
              </span>
            </td>
            <td>
              <button
                onClick={() => {
                  setShowModal(true);
                  setModalWidth(600);
                  setComponent(
                    <div className="px-5">
                      <div className="bg-light-secondary ps-6 py-4 rounded mb-7">
                        {data.type !== "Static Wallet" && (
                          <>
                            <p className="m-0 fs-4 text-dark">Track ID</p>
                            <p className="m-0 fs-6 text-primary">
                              {data.id || "-"}
                            </p>
                            <hr className="border-gray-500 my-3" />
                          </>
                        )}
                        {data.orderId && (
                          <>
                            <p className="m-0 fs-4 text-dark">Order ID</p>
                            <p className="m-0 fs-6 text-primary">
                              {data.orderId}
                            </p>
                            <hr className="border-gray-500 my-3" />
                          </>
                        )}
                        {data.type !== "Static Wallet" && (
                          <>
                            <p className="m-0 fs-4 text-dark">Invoice Amount</p>
                            <p className="m-0 fs-6 text-primary">
                              {data?.invoiceAmount
                                ? data.invoiceCurrency
                                  ? formatAmount(
                                      parseFloat(data.invoiceAmount),
                                      coinsListMap[data.currency]
                                        ?.displayPrecision
                                    ) +
                                    " " +
                                    data.invoiceCurrency
                                  : data.invoiceAmount + "USD"
                                : "-"}
                            </p>
                            <hr className="border-gray-500 my-3" />
                          </>
                        )}
                        <p className="m-0 fs-4 text-dark">Amount Sent</p>
                        <p className="m-0 fs-6 text-primary">
                          {formatAmount(
                            parseFloat(data.amountSent),
                            coinsListMap[data.currency]?.displayPrecision
                          )}{" "}
                          {data.currency}
                        </p>
                        {data?.txHash && (
                          <>
                            <hr className="border-gray-500 my-3" />
                            <p className="m-0 fs-4 text-dark">
                              Transaction Hash
                            </p>
                            {data?.txUrl ? (
                              <a
                                href={data?.txUrl}
                                className="m-0 fs-6 text-primary cursor-pointer flex-wrap-overflow"
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {data?.txHash}
                              </a>
                            ) : (
                              <span className="m-0 fs-6 text-primary cursor-pointer flex-wrap-overflow">
                                {data?.txHash || "-"}
                              </span>
                            )}
                          </>
                        )}
                        {data.status === "refunded" && data.refundTx?.txHash && (
                          <>
                            <hr className="border-gray-500 my-3" />
                            <p className="m-0 fs-4 text-dark">
                              Refunded Transaction Hash
                            </p>
                            <a
                              href={data.refundTx.url}
                              className="m-0 fs-6 text-primary cursor-pointer flex-wrap-overflow"
                              target={"_blank"}
                              rel="noreferrer"
                            >
                              {data.refundTx.txHash}
                            </a>
                          </>
                        )}
                      </div>
                    </div>
                  );
                }}
                className="btn btn-link btn-color-primary btn-active-color-primary me-5 mb-2"
              >
                Details
              </button>
            </td>
          </tr>
        ))}
    </TablesWidget>
  );
};
