import axios from "axios";
import React, { useEffect, useState } from "react";
import { errorHandler } from "../../app/services/errorHandler";
import { paymentlinkSummaryInterval } from "../../app/services/intervalManage";
import { useRequest } from "../../app/services/userHttpRequests";
import { PaymentsSummary } from "../PaymentsSummary";

export const PaymentlinkSummary = () => {
  const [loading, setLoading] = useState(true);
  const { paymentLinkSummary } = useRequest();
  const [paymentSummary, setPaymentSummary] = useState({
    today: 0,
    week: 0,
    month: 0,
    all: 0,
  });

  useEffect(() => {
    const controller = new AbortController();
    const fetchSummary = async () => {
      try {
        const response = await paymentLinkSummary({
          signal: controller.signal,
        });

        if (response.status === 200) {
          const summaryNewData = response.data;
          setPaymentSummary(summaryNewData);
          setLoading(false);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };
    fetchSummary();
    const interval = setInterval(fetchSummary, paymentlinkSummaryInterval); // Fetch balance every 10 seconds

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval on component unmount
    };
    // eslint-disable-next-line
  }, []);

  return <PaymentsSummary paymentSummary={paymentSummary} loading={loading} />;
};
