// Helper function to get the page title based on the current route
export const getPageTitle = (pathname: any) => {
  switch (pathname) {
    case "/":
      return "Sign In";
    case "/signup":
      return "Sign Up";
    case "/forgot-password":
      return "Forgot password";
    case "/twofactor":
      return "Two Factor authentication";
    case "/home":
      return "Home";
    case "/paymentlink":
      return "Payment Link";
    case "/donations":
      return "Donation";
    case "/merchants":
      return "Merchants";
    case "/payout":
      return "Payout";
    case "/TCHgateway":
      return "Telegram Channel Gateway";
    case "/referrals":
      return "Referrals";
    case "/profile":
      return "Profile";
    case "/swap":
      return "Swap";
    case "/settings":
      return "Settings";
    case "/webapp-wallet":
      return "Wallet";
    default:
      return "Useful crypto for all";
  }
};
