import { createContext, useState, ReactNode } from "react";

export interface Swap {
  id: string;
  fromCurrency: string;
  fromAmount: string;
  toCurrency: string;
  toAmount: string;
  type: string;
  apiName?: string;
  rate: string;
  date: string;
}

interface SwapPage {
  page: number;
  pages: number;
}

interface SwapsFilter {
  fromCurrency: string;
  toCurrency: string;
  type: string;
  start: string;
  end: string;
}

export interface SwapContextModel {
  swaps?: Swap[];
  setSwaps: (swaps: Swap[]) => void;
  swapsPage: SwapPage;
  setSwapsPage: (swapsPage: SwapPage) => void;
  swapsFilter: SwapsFilter;
  setSwapsFilter: (swapsFilter: SwapsFilter) => void;
  pairsData: Record<string, { min: string; pair: string }[]> | undefined;
  setPairsData: (
    pairsData: Record<string, { min: string; pair: string }[]>
  ) => void;
}

export const SwapContext = createContext<SwapContextModel>({
  swaps: undefined,
  setSwaps: () => {},
  swapsPage: {
    page: 1,
    pages: 0,
  },
  setSwapsPage: () => {},
  swapsFilter: {
    fromCurrency: "",
    toCurrency: "",
    type: "",
    start: "",
    end: "",
  },
  setSwapsFilter: () => {},
  pairsData: undefined,
  setPairsData: () => {},
});

interface SwapProviderProps {
  children: ReactNode;
}

const SwapProvider: React.FC<SwapProviderProps> = ({ children }) => {
  const [swaps, setSwaps] = useState<Swap[] | undefined>(undefined);
  const [swapsPage, setSwapsPage] = useState({
    page: 1,
    pages: 0,
  });
  const [swapsFilter, setSwapsFilter] = useState({
    fromCurrency: "",
    toCurrency: "",
    type: "",
    start: "",
    end: "",
  });
  const [pairsData, setPairsData] = useState<
    Record<string, { min: string; pair: string }[]> | undefined
  >();

  return (
    <SwapContext.Provider
      value={{
        swaps,
        setSwaps,
        swapsPage,
        setSwapsPage,
        swapsFilter,
        setSwapsFilter,
        pairsData,
        setPairsData,
      }}
    >
      {children}
    </SwapContext.Provider>
  );
};

export default SwapProvider;
