import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { WebappContext } from "../../_oxapay/layout/core/WebappContext";

export const WalletAddressInfoButton = () => {
  const [showModal, setShowModal] = useState(false);
  const { webappColors } = useContext(WebappContext);

  useEffect(() => {
    const WebappFn = (window as any)?.Telegram?.WebApp;
    if (showModal) {
      WebappFn?.setHeaderColor(webappColors.bgBackdrop);
      WebappFn.MainButton.setText("Got it")
        .setParams({ color: "#3478f6" })
        .show()
        .onClick(() => {
          setShowModal(false);
        });
    } else {
      WebappFn?.setHeaderColor(webappColors.bgGray200);
      WebappFn.MainButton.hide();
    }

    // eslint-disable-next-line
  }, [showModal]);

  return (
    <>
      <span
        onClick={() => setShowModal(true)}
        className={`cursor-pointer badge badge-primary justify-content-center w-25px h-25px fs-5 mx-2`}
      >
        i
      </span>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        className="sheet-modal fadeup-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="scrollable-content">
            <div className="px-7">
              <div className="d-flex align-items-center mb-7">
                <span
                  className={`badge badge-primary justify-content-center w-20px h-20px fs-5 me-2`}
                >
                  i
                </span>
                <p className="fs-1 fw-bold m-0">OxaPay Address</p>
              </div>

              <p className="fs-6 fw-400 mb-10">
                You can use OxaPay wallet address to transfer all crypto assets
                with zero fee. Use this address for OxaPay to OxaPay transfers
                to protect your funds from potential losses.
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
