/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { Logout, AuthPage, useAuth, AuthLayout } from "../modules/auth";
import { App } from "../App";
import { ConfirmSetEmailPassword } from "../modules/auth/components/ConfirmSetEmailPassword";
import { RecoveryPassword } from "../modules/auth/components/RecoveryPassword";
import { VerifyEmail } from "../modules/auth/components/VerifyEmail";
import { WalletWebappMain } from "../../components/walletWebapp/WalletWebappMain";
import { WalletWebappCoin } from "../../components/walletWebapp/WalletWebappCoin";
import { WalletWebappHistory } from "../../components/walletWebapp/WalletWebappHistory";
import { WalletWebappReceive } from "../../components/walletWebapp/WalletWebappReceive";
import { WalletWebAppSend } from "../../components/walletWebapp/WalletWebAppSend";
import { WalletWebappSwap } from "../../components/walletWebapp/WalletWebappSwap";
import { WalletWebappLayout } from "../../components/walletWebapp/WalletWebappLayout";
import { WalletWebappEnableTwoFa } from "../../components/walletWebapp/WalletWebappEnableTwoFa";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { currentUser } = useAuth();
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route element={<WalletWebappLayout />}>
            <Route path="/webapp/wallet/*" element={<WalletWebappMain />} />
            <Route path="/webapp-wallet/*" element={<WalletWebappMain />} />
            <Route path="/webapp-wallet/send" element={<WalletWebAppSend />} />

            <Route
              path="/webapp-wallet/receive"
              element={<WalletWebappReceive />}
            />

            <Route
              path="/webapp-wallet/coin/:symbol"
              element={<WalletWebappCoin />}
            />
            <Route
              path="/webapp-wallet/send/:symbol"
              element={<WalletWebAppSend />}
            />
            <Route
              path="/webapp-wallet/receive/:symbol"
              element={<WalletWebappReceive />}
            />

            <Route
              path="/webapp-wallet/coin/:symbol"
              element={<WalletWebappCoin />}
            />
            {currentUser?.access?.swap && (
              <Route
                path="/webapp-wallet/swap"
                element={<WalletWebappSwap />}
              />
            )}
            <Route
              path="/webapp-wallet/history"
              element={<WalletWebappHistory />}
            />
            <Route
              path="/webapp-wallet/enabletwofactor"
              element={<WalletWebappEnableTwoFa />}
            />
          </Route>

          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          <Route element={<AuthLayout />}>
            <Route
              path="set-email-password/confirm/:token/*"
              element={<ConfirmSetEmailPassword />}
            />
            <Route
              path="new-password/:token/*"
              element={<RecoveryPassword />}
            />
            <Route path="verify-email/:token/*" element={<VerifyEmail />} />
          </Route>

          {currentUser ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/home" />} />
            </>
          ) : (
            <>
              <Route path="/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
