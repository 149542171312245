import React from "react";
import { convertUnixToTimeAgo } from "../../app/services/functionServices";
import { IconSend } from "../staticIcons/IconSend";

interface props {
  data: {
    amount: string;
    currency: string;
  };
  date: string;
}

export const NotifSend = ({ data, date }: props) => {
  return (
    <div className="card card-body mb-5 px-3 px-sm-0 pb-3 pb-sm-0 pt-1">
      <div className="d-flex align-items-center flex-sm-row flex-column">
        <div className="d-flex align-items-center my-4 mx-4">
          <div className="symbol symbol-50px me-4">
            <IconSend />
          </div>

          <div className="mb-0 me-2">
            <span className="fs-6 text-gray-800 fw-bolder">
              Send request confirmed successfully
            </span>
            <div className="text-gray-400 fs-7">
              {convertUnixToTimeAgo(date)}
            </div>
          </div>
        </div>

        <div
          className="d-flex align-items-center justify-content-center bg-light-secondary border border-dashed border-secondary h-100 p-5"
          style={{ borderRadius: "12px" }}
        >
          <p className="m-0 me-2">
            <span className="fw-bold ">Amount Received:</span> {data.amount}{" "}
            {data.currency}
          </p>
        </div>
      </div>
    </div>
  );
};
