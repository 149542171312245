import axios from "axios";
import { useAuth } from "../../app/modules/auth";

export function useRequest() {
  const { auth } = useAuth();

  const headers = {
    Authorization: "Bearer " + auth?.apiToken,
  };

  const API_URL = process.env.REACT_APP_API_URL;

  const WALLET_WEBAPP = `${API_URL}/wallets/webapp`;
  const CHANGE_PASSWORD = `${API_URL}/user/profile/password`;
  const UPDATE_PROFILE = `${API_URL}/user/profile/update`;
  const TWOFACTOR = `${API_URL}/user/profile/twoFa`;
  const ENABLE_TWOFACTOR = `${API_URL}/user/profile/twoFa/enable`;
  const DISABLE_TWOFACTOR = `${API_URL}/user/profile/twoFa/disable`;
  const SET_EMAIL_PASSWORD = `${API_URL}/user/profile/setEmailPassword`;
  const NEW_PAYMENTLINK = `${API_URL}/paymentlink/new`;
  const LIST_PAYMENTLINK = `${API_URL}/paymentlink/list`;
  const DELETE_PAYMENTLINK = `${API_URL}/paymentlink/delete`;
  const SUMMARY_PAYMENTLINK = `${API_URL}/paymentlink/summary`;
  const USER_BALANCE = `${API_URL}/wallets/balance`;
  const HOME_NEW_PAYMENTS = `${API_URL}/home/newpayment`;
  const HOME_PAYMENT_SUMMARY = `${API_URL}/home/paymentSummary`;
  const HOME_PAYMENT_SHARE = `${API_URL}/home/chart/payment/share`;
  const HOME_PAYMENT_STATS = `${API_URL}/home/chart/payment/statistics`;
  const HOME_TRANSACTIONS = `${API_URL}/home/recentTransactions`;
  const USER_PORTFOLIO = `${API_URL}/home/portfolio`;
  const GET_ADDRESS = `${API_URL}/wallets/getAddress`;
  const NEW_DONATION = `${API_URL}/donation/new`;
  const LIST_DONATION = `${API_URL}/donation/list`;
  const DELETE_DONATION = `${API_URL}/donation/delete`;
  const SUMMARY_DONATION = `${API_URL}/donation/summary`;
  const NEW_MERCHANT = `${API_URL}/merchant/new`;
  const LIST_MERCHANT = `${API_URL}/merchant/list`;
  const DELETE_MERCHANT = `${API_URL}/merchant/delete`;
  const EDIT_MERCHANT = `${API_URL}/merchant/edit`;
  const SUMMARY_MERCHANT = `${API_URL}/merchant/summary`;
  const NEW_PAYOUTAPI = `${API_URL}/payout/new`;
  const LIST_PAYOUTAPI = `${API_URL}/payout/list`;
  const DELETE_PAYOUTAPI = `${API_URL}/payout/delete`;
  const EDIT_PAYOUTAPI = `${API_URL}/payout/edit`;
  const NEW_GENERALAPI = `${API_URL}/user/generalapis/new`;
  const LIST_GENERALAPI = `${API_URL}/user/generalapis/list`;
  const DELETE_GENERALAPI = `${API_URL}/user/generalapis/delete`;
  const EDIT_GENERALAPI = `${API_URL}/user/generalapis/edit`;
  const SUMMARY_PAYOUTAPI = `${API_URL}/payout/summary`;
  const PAYOUTAPI_CHART = `${API_URL}/payout/chart/statistics`;
  const PAYOUTAPI_TRANSACTIONS = `${API_URL}/payout/transactions`;
  const SEND_ASSET = `${API_URL}/wallets/send`;
  const SEND_ASSET_SUBMIT = `${API_URL}/wallets/send/submit`;
  const SEND_ASSET_SIGN = `${API_URL}/wallets/send/confirm`;
  const REFERRALS_ASSETS_EARNING = `${API_URL}/referrals/earnings`;
  const REFERRALS_COMMISSION_SUMMARY = `${API_URL}/referrals/summary`;
  const REFERRALS_DETAIL = `${API_URL}/referrals/details`;
  const REFERRALS_STATS_CHART = `${API_URL}/referrals/chart/referrals`;
  const REFERRALS_COMMISSION_CHART = `${API_URL}/referrals/chart/commission`;
  const COMMISSION_TRANSACTIONS = `${API_URL}/referrals/transactions`;
  const PAYMENTLINK_CHART = `${API_URL}/paymentlink/chart/statistics`;
  const DONATION_CHART = `${API_URL}/donation/chart/statistics`;
  const MERCHANT_CHART = `${API_URL}/merchant/chart/statistics`;
  const WALLET_TRANSACTIONS = `${API_URL}/home/walletTransactions`;
  const PAYMENTLINK_TRANSACTIONS = `${API_URL}/paymentlink/transactions`;
  const DONATION_TRANSACTIONS = `${API_URL}/donation/transactions`;
  const MERCHANT_TRANSACTIONS = `${API_URL}/merchant/transactions`;
  const CHANNEL_GATEWAY_TRANSACTIONS = `${API_URL}/tchgateway/transactions`;
  const CHANNEL_GATEWAY_SUMMARY = `${API_URL}/tchgateway/summary`;
  const CHANNEL_GATEWAY_USERS_CHART = `${API_URL}/tchgateway/chart/users`;
  const CHANNEL_GATEWAY_TXS_CHART = `${API_URL}/tchgateway/chart/transactions`;
  const LIST_CHANNELS = `${API_URL}/tchgateway/channels`;
  const EDIT_CHANNELS = `${API_URL}/tchgateway/channel/edit`;
  const DELETE_CHANNELS = `${API_URL}/tchgateway/channel/delete`;
  const USER_ACTIVITY = `${API_URL}/user/activity`;
  const NOTIFICATIONS_COUNT = `${API_URL}/user/notifications/new`;
  const NOTIFICATIONS = `${API_URL}/user/notifications`;
  const GET_MESSAGES = `${API_URL}/user/messages`;
  const READ_MESSAGES = `${API_URL}/user/messages/read`;
  const PUSHNOTIFICATION_TOKEN = `${API_URL}/user/webpush`;
  const PUSHNOTIFICATION_DENY = `${API_URL}/user/webpush/deny`;
  const SWAP_PAIRS = `${API_URL}/swap/pairs`;
  const SWAP_HISTORY = `${API_URL}/swap/history`;
  const SWAP_RATE = `${API_URL}/swap/rate`;
  const SWAP = `${API_URL}/swap/new`;
  const ADDRESS_LIST = `${API_URL}/user/autoWithdraw/list`;
  const CHANGE_ADDRESS_LIST = `${API_URL}/user/autoWithdraw/address`;
  const PAYMENTLINK_TXACTION = `${API_URL}/paymentlink/txActions`;
  const CHANNEL_TXACTION = `${API_URL}/tchgateway/txActions`;
  const MERCHANT_TXACTION = `${API_URL}/merchant/txActions`;
  const ADDRESSBOOK_CREATE = `${API_URL}/wallets/addressbook/create`;
  const ADDRESSBOOK_LIST = `${API_URL}/wallets/addressbook/list`;
  const ADDRESSBOOK_DELETE = `${API_URL}/wallets/addressbook/delete`;
  const ADDRESSBOOK_EDIT = `${API_URL}/wallets/addressbook/edit`;
  const RECENTE_ADDRESS = `${API_URL}/wallets/withdrawals/recent`;
  const CHECK_TRANSFER_TX = `${API_URL}/wallets/withdrawals/check`;

  const fetchData = async (endpoint: string, options?: any) => {
    const config = {
      headers,
      ...options,
    };
    const response = await axios.post(endpoint, null, config);
    return response.data;
  };

  const addressList = async (options?: any) => {
    return fetchData(ADDRESS_LIST, options);
  };

  const newPayments = async (options?: any) => {
    return fetchData(HOME_NEW_PAYMENTS, options);
  };

  const paymentlinkChart = async (options?: any) => {
    return fetchData(PAYMENTLINK_CHART, options);
  };

  const donationChart = async (options?: any) => {
    return fetchData(DONATION_CHART, options);
  };

  const TchgUsersChart = async (options?: any) => {
    return fetchData(CHANNEL_GATEWAY_USERS_CHART, options);
  };

  const TchgTxsChart = async (options?: any) => {
    return fetchData(CHANNEL_GATEWAY_TXS_CHART, options);
  };

  const merchantChart = async (options?: any) => {
    return fetchData(MERCHANT_CHART, options);
  };

  const payoutApiChart = async (options?: any) => {
    return fetchData(PAYOUTAPI_CHART, options);
  };

  const paymentLinkSummary = async (options?: any) => {
    return fetchData(SUMMARY_PAYMENTLINK, options);
  };

  const donationSummary = async (options?: any) => {
    return fetchData(SUMMARY_DONATION, options);
  };

  const TchgSummary = async (options?: any) => {
    return fetchData(CHANNEL_GATEWAY_SUMMARY, options);
  };

  const merchantSummary = async (options?: any) => {
    return fetchData(SUMMARY_MERCHANT, options);
  };

  const payoutApiSummary = async (options?: any) => {
    return fetchData(SUMMARY_PAYOUTAPI, options);
  };

  const referralsDetail = async (options?: any) => {
    return fetchData(REFERRALS_DETAIL, options);
  };

  const userReferralsEarning = async (options?: any) => {
    return fetchData(REFERRALS_ASSETS_EARNING, options);
  };

  const referralsCommissionSummary = async (options?: any) => {
    return fetchData(REFERRALS_COMMISSION_SUMMARY, options);
  };

  const refStatsChart = async (options?: any) => {
    return fetchData(REFERRALS_STATS_CHART, options);
  };

  const refCommissionChart = async (options?: any) => {
    return fetchData(REFERRALS_COMMISSION_CHART, options);
  };

  const userPortfolio = async (options?: any) => {
    return fetchData(USER_PORTFOLIO, options);
  };

  const homePaymentChart = async (options?: any) => {
    return fetchData(HOME_PAYMENT_STATS, options);
  };

  const homePaymentSummary = async (options?: any) => {
    return fetchData(HOME_PAYMENT_SUMMARY, options);
  };

  const homePaymentShare = async (options?: any) => {
    return fetchData(HOME_PAYMENT_SHARE, options);
  };

  const getBalance = async (options?: any) => {
    return fetchData(USER_BALANCE, options);
  };

  const addressbookList = async (options?: any) => {
    return fetchData(ADDRESSBOOK_LIST, options);
  };

  const recentAddress = async (options?: any) => {
    return fetchData(RECENTE_ADDRESS, options);
  };

  const addAddressbook = async (
    name: string,
    address: string,
    whitelist: boolean,
    twoFa?: string
  ) => {
    const response = await axios.post(ADDRESSBOOK_CREATE, {
      name,
      address,
      whitelist,
      twoFa,
    });
    return response.data;
  };

  const editAddressbook = async (
    id: string,
    name: string,
    address: string,
    whitelist: boolean,
    twoFa?: string
  ) => {
    const response = await axios.post(ADDRESSBOOK_EDIT, {
      id,
      name,
      address,
      whitelist,
      twoFa,
    });
    return response.data;
  };

  const deleteAddressbook = async (id: string) => {
    const response = await axios.post(ADDRESSBOOK_DELETE, {
      id,
    });
    return response.data;
  };

  const webappToken = async (authData: any) => {
    const response = await axios.post(WALLET_WEBAPP, {
      authData,
    });
    return response.data;
  };

  const listChannels = async (
    search?: string,
    page?: number,
    options?: any
  ) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      LIST_CHANNELS,
      {
        search,
        page,
      },
      config
    );
    return response.data;
  };

  interface Plan {
    id: string;
    period: string;
    price: string;
    currency: string;
  }

  const editChannels = async (id: string, plans: Plan[], coins: string[]) => {
    const response = await axios.post(EDIT_CHANNELS, {
      id,
      plans,
      coins,
    });
    return response.data;
  };

  const deleteChannel = async (id: string) => {
    const response = await axios.post(DELETE_CHANNELS, {
      id,
    });
    return response.data;
  };

  const paymentlinkTransactions = async (
    search?: string,
    page?: number,
    filter?: {
      coin: string[];
      start: string;
      end: string;
    },
    options?: any
  ) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      PAYMENTLINK_TRANSACTIONS,
      { search, page, filter },
      config
    );
    return response.data;
  };

  const donationTransactions = async (
    search?: string,
    page?: number,
    filter?: {
      status?: string[];
      coin: string[];
      start: string;
      end: string;
    },
    options?: any
  ) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      DONATION_TRANSACTIONS,
      {
        search,
        page,
        filter,
      },
      config
    );
    return response.data;
  };

  const merchantTransactions = async (
    search?: string,
    page?: number,
    filter?: {
      status?: string[];
      coin: string[];
      start: string;
      end: string;
    },
    options?: any
  ) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      MERCHANT_TRANSACTIONS,
      {
        search,
        page,
        filter,
      },
      config
    );
    return response.data;
  };

  const payoutApiTransactions = async (
    search?: string,
    page?: number,
    filter?: {
      coin: string[];
      start: string;
      end: string;
    },
    options?: any
  ) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      PAYOUTAPI_TRANSACTIONS,
      { search, page, filter },
      config
    );
    return response.data;
  };

  const channelsTransactions = async (
    search?: string,
    page?: number,
    filter?: {
      status?: string[];
      coin: string[];
      start: string;
      end: string;
    },
    options?: any
  ) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      CHANNEL_GATEWAY_TRANSACTIONS,
      { search, page, filter },
      config
    );
    return response.data;
  };

  const commissionTransactions = async (
    page?: number,
    filter?: {
      coin: string[];
      start: string;
      end: string;
    },
    options?: any
  ) => {
    const config = {
      headers,
      ...options,
    };

    const response = await axios.post(
      COMMISSION_TRANSACTIONS,
      {
        page,
        filter,
      },
      config
    );
    return response.data;
  };

  const sendAsset = async (address: string) => {
    const response = await axios.post(SEND_ASSET, { address });
    return response.data;
  };

  const sendAssetSubmit = async (
    address: string,
    coin: string,
    network: string,
    amount: number,
    memo?: string
  ) => {
    const response = await axios.post(SEND_ASSET_SUBMIT, {
      address,
      coin,
      network,
      amount,
      memo,
    });
    return response.data;
  };

  const sendAssetSign = async (
    requestToken: string,
    code: string,
    twoFa?: string,
    note?: string
  ) => {
    const response = await axios.post(SEND_ASSET_SIGN, {
      requestToken,
      code,
      twoFa,
      note,
    });
    return response.data;
  };

  const getAddress = async (coin: string, network: string, options?: any) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      GET_ADDRESS,
      {
        coin,
        network,
      },
      config
    );
    return response.data;
  };

  const walletTransactions = async (
    filter?: {
      type: string;
      coin: string[];
      start: string;
      end: string;
    },
    options?: any
  ) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      WALLET_TRANSACTIONS,
      {
        filter,
      },
      config
    );
    return response.data;
  };

  const homeTransactions = async (
    search?: string,
    page?: number,
    filter?: {
      type: string;
      coin: string[];
      start: string;
      end: string;
    },
    options?: any
  ) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      HOME_TRANSACTIONS,
      {
        search,
        page,
        filter,
      },
      config
    );
    return response.data;
  };

  const changePassword = async (
    password: string,
    newPassword: string,
    passwordConfirmation: string
  ) => {
    const response = await axios.post(CHANGE_PASSWORD, {
      password,
      newPassword,
      passwordConfirmation,
    });
    return response.data;
  };

  const updateProfile = async (
    firstName: string,
    lastName: string,
    companyName: string,
    phone: string,
    site: string,
    country: string
  ) => {
    const response = await axios.post(UPDATE_PROFILE, {
      firstName,
      lastName,
      companyName,
      phone,
      site,
      country,
    });
    return response.data;
  };

  const twoFactor = async () => {
    return fetchData(TWOFACTOR);
  };

  const enableTwoFa = async (code: string) => {
    const response = await axios.post(ENABLE_TWOFACTOR, { code });
    return response.data;
  };

  const disableTwoFa = async (code: string) => {
    const response = await axios.post(DISABLE_TWOFACTOR, { code });
    return response.data;
  };

  const setEmailPassword = async (
    email: string,
    password: string,
    passwordConfirmation: string
  ) => {
    const response = await axios.post(SET_EMAIL_PASSWORD, {
      email,
      password,
      passwordConfirmation,
    });
    return response.data;
  };

  const newPaymentLink = async (
    name: string,
    price: string,
    currency: string,
    coins: string[],
    feePaidByPayer: number,
    underPaid: string,
    desc: string
  ) => {
    const response = await axios.post(NEW_PAYMENTLINK, {
      name,
      price,
      currency,
      coins,
      feePaidByPayer,
      underPaid,
      desc,
    });
    return response.data;
  };

  const deletePaymentLink = async (id: string) => {
    const response = await axios.post(DELETE_PAYMENTLINK, {
      id,
    });
    return response.data;
  };

  const listPaymentLink = async (
    search?: string,
    page?: number,
    options?: any
  ) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      LIST_PAYMENTLINK,
      {
        search,
        page,
      },
      config
    );
    return response.data;
  };

  interface DonationFields {
    name: {
      show: boolean;
      require: boolean;
    };
    email: {
      show: boolean;
      require: boolean;
    };
  }

  const newDonationButton = async (
    name: string,
    coins: string[],
    fields: DonationFields
  ) => {
    const response = await axios.post(NEW_DONATION, {
      name,
      coins,
      fields,
    });
    return response.data;
  };

  const listDonationButton = async (
    search?: string,
    page?: number,
    options?: any
  ) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      LIST_DONATION,
      {
        search,
        page,
      },
      config
    );
    return response.data;
  };

  const deleteDonationButton = async (id: string) => {
    const response = await axios.post(DELETE_DONATION, {
      id,
    });
    return response.data;
  };

  const newMerchant = async (
    name: string,
    coins: string[],
    logo: string,
    feePaidByPayer: number,
    underPaid: string,
    toCurrency?: string,
    autoWithdraw?: boolean
  ) => {
    const response = await axios.post(NEW_MERCHANT, {
      name,
      coins,
      logo,
      feePaidByPayer,
      underPaid,
      toCurrency,
      autoWithdraw,
    });
    return response.data;
  };

  const listMerchants = async (
    search?: string,
    page?: number,
    options?: any
  ) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      LIST_MERCHANT,
      {
        search,
        page,
      },
      config
    );
    return response.data;
  };

  const deleteMerchant = async (id: string) => {
    const response = await axios.post(DELETE_MERCHANT, {
      id,
    });
    return response.data;
  };

  const editMerchant = async (
    id: string,
    name: string,
    coins: string[],
    logo: string,
    feePaidByPayer: number,
    underPaid: string,
    toCurrency?: string,
    autoWithdraw?: boolean
  ) => {
    const response = await axios.post(EDIT_MERCHANT, {
      id,
      name,
      coins,
      logo,
      feePaidByPayer,
      underPaid,
      toCurrency,
      autoWithdraw,
    });
    return response.data;
  };

  const newPayoutApi = async (
    name: string,
    limit: {
      day: string;
      transaction: string;
    },
    allowedIps: string[],
    twoFa: string
  ) => {
    const response = await axios.post(NEW_PAYOUTAPI, {
      name,
      limit,
      allowedIps,
      twoFa,
    });
    return response.data;
  };

  const listPayoutApi = async (
    search?: string,
    page?: number,
    options?: any
  ) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      LIST_PAYOUTAPI,
      {
        search,
        page,
      },
      config
    );
    return response.data;
  };

  const deletePayoutApi = async (id: string) => {
    const response = await axios.post(DELETE_PAYOUTAPI, {
      id,
    });
    return response.data;
  };

  const editPayoutApi = async (
    id: string,
    name: string,
    limit: {
      day: string;
      transaction: string;
    },
    allowedIps: string[],
    twoFa: string
  ) => {
    const response = await axios.post(EDIT_PAYOUTAPI, {
      id,
      name,
      limit,
      allowedIps,
      twoFa,
    });
    return response.data;
  };

  const userActivity = async (
    page?: number,
    filter?: {
      type: string;
      start: string;
      end: string;
    },
    options?: any
  ) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      USER_ACTIVITY,
      {
        page,
        filter,
      },
      config
    );
    return response.data;
  };

  const getMessages = async (options?: any) => {
    return fetchData(GET_MESSAGES, options);
  };

  const messagesRead = async (lastId: string, options?: any) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      READ_MESSAGES,
      {
        lastId,
      },
      config
    );
    return response.data;
  };

  const notificationsCount = async (options?: any) => {
    return fetchData(NOTIFICATIONS_COUNT, options);
  };

  const notifications = async (
    page?: number,

    options?: any
  ) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      NOTIFICATIONS,
      {
        page,
      },
      config
    );
    return response.data;
  };

  const pushNotificationToken = async (token: string) => {
    const response = await axios.post(PUSHNOTIFICATION_TOKEN, {
      token,
    });
    return response.data;
  };

  const pushNotificationDeny = async (options?: any) => {
    return fetchData(PUSHNOTIFICATION_DENY, options);
  };

  const swapPairs = async (options?: any) => {
    return fetchData(SWAP_PAIRS, options);
  };

  const swapHistory = async (
    filter?: {
      fromCurrency: string;
      toCurrency: string;
      type: string;
      start: string;
      end: string;
    },
    page?: number,
    options?: any
  ) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      SWAP_HISTORY,
      {
        filter,
        page,
      },
      config
    );
    return response.data;
  };

  const getSwapRate = async (from: string, to: string, options?: any) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      SWAP_RATE,
      {
        from,
        to,
      },
      config
    );
    return response.data;
  };

  const swap = async (
    fromCurrency: string,
    fromAmount: string,
    toCurrency: string,
    toAmount: string
  ) => {
    const response = await axios.post(SWAP, {
      fromCurrency,
      fromAmount,
      toCurrency,
      toAmount,
    });
    return response.data;
  };

  const changeAddressList = async (code: string, addressList: any) => {
    const response = await axios.post(CHANGE_ADDRESS_LIST, {
      code,
      addressList,
    });
    return response.data;
  };

  const paymentlinkTxAction = async (
    depositId: string,
    act: string,
    search?: string,
    page?: number,
    filter?: {
      status?: string[];
      coin: string[];
      start: string;
      end: string;
    }
  ) => {
    const response = await axios.post(PAYMENTLINK_TXACTION, {
      depositId,
      act,
      search,
      page,
      filter,
    });
    return response.data;
  };

  const channelTxAction = async (
    depositId: string,
    act: string,
    search?: string,
    page?: number,
    filter?: {
      status?: string[];
      coin: string[];
      start: string;
      end: string;
    }
  ) => {
    const response = await axios.post(CHANNEL_TXACTION, {
      depositId,
      act,
      search,
      page,
      filter,
    });
    return response.data;
  };

  const merchantTxAction = async (
    depositId: string,
    act: string,
    search?: string,
    page?: number,
    filter?: {
      status?: string[];
      coin: string[];
      start: string;
      end: string;
    }
  ) => {
    const response = await axios.post(MERCHANT_TXACTION, {
      depositId,
      act,
      search,
      page,
      filter,
    });
    return response.data;
  };

  const checkTransferTx = async (id: string, options?: any) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(CHECK_TRANSFER_TX, { id }, config);
    return response.data;
  };

  const newGeneralApi = async (
    name: string,
    allowedIps: string[],
    twoFa: string
  ) => {
    const response = await axios.post(NEW_GENERALAPI, {
      name,
      allowedIps,
      twoFa,
    });
    return response.data;
  };

  const listGeneralApi = async (
    search?: string,
    page?: number,
    options?: any
  ) => {
    const config = {
      headers,
      ...options, // Merge the additional options with the default config
    };

    const response = await axios.post(
      LIST_GENERALAPI,
      {
        search,
        page,
      },
      config
    );
    return response.data;
  };

  const deleteGeneralApi = async (id: string) => {
    const response = await axios.post(DELETE_GENERALAPI, {
      id,
    });
    return response.data;
  };

  const editGeneralApi = async (
    id: string,
    name: string,
    allowedIps: string[],
    twoFa: string
  ) => {
    const response = await axios.post(EDIT_GENERALAPI, {
      id,
      name,
      allowedIps,
      twoFa,
    });
    return response.data;
  };

  return {
    webappToken,
    newPayments,
    sendAsset,
    sendAssetSubmit,
    sendAssetSign,
    getAddress,
    userPortfolio,
    homePaymentChart,
    homePaymentSummary,
    homePaymentShare,
    homeTransactions,
    getBalance,
    changePassword,
    updateProfile,
    twoFactor,
    enableTwoFa,
    disableTwoFa,
    setEmailPassword,
    newPaymentLink,
    listPaymentLink,
    deletePaymentLink,
    newDonationButton,
    listDonationButton,
    deleteDonationButton,
    newMerchant,
    listMerchants,
    deleteMerchant,
    editMerchant,
    newPayoutApi,
    listPayoutApi,
    deletePayoutApi,
    editPayoutApi,
    userReferralsEarning,
    referralsCommissionSummary,
    referralsDetail,
    refStatsChart,
    refCommissionChart,
    commissionTransactions,
    paymentLinkSummary,
    donationSummary,
    merchantSummary,
    payoutApiSummary,
    paymentlinkChart,
    donationChart,
    merchantChart,
    payoutApiChart,
    merchantTransactions,
    payoutApiTransactions,
    donationTransactions,
    paymentlinkTransactions,
    walletTransactions,
    TchgUsersChart,
    TchgTxsChart,
    TchgSummary,
    listChannels,
    editChannels,
    deleteChannel,
    channelsTransactions,
    userActivity,
    notificationsCount,
    notifications,
    pushNotificationToken,
    pushNotificationDeny,
    swapPairs,
    swapHistory,
    getSwapRate,
    swap,
    getMessages,
    messagesRead,
    addressList,
    changeAddressList,
    paymentlinkTxAction,
    channelTxAction,
    merchantTxAction,
    addressbookList,
    addAddressbook,
    editAddressbook,
    deleteAddressbook,
    recentAddress,
    checkTransferTx,
    newGeneralApi,
    listGeneralApi,
    deleteGeneralApi,
    editGeneralApi,
  };
}

export {};
