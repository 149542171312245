import React from "react";

export const SwapSkeleton = () => {
  return (
    <>
      <div className="border border-gray-300 rounded px-5 py-4 mb-4">
        <div className="d-flex justify-content-between align-items-center mb-5">
          <h4 className="fs-7 fw-semibold text-gray-800 mb-0">FROM</h4>
          <div className="d-flex flex-row">
            <div className="h-25px w-45px bg-secondary rounded shine me-1"></div>
            <div className="h-25px w-45px bg-secondary rounded shine"></div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-1">
          <div
            className="h-40px w-100 bg-secondary rounded shine"
            style={{ maxWidth: "150px" }}
          ></div>
          <div className="h-40px w-130px bg-secondary rounded shine"></div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="h-15px w-50px bg-secondary rounded shine"></div>
          <div className="h-15px w-70px bg-secondary rounded shine"></div>
        </div>
      </div>
      <div className="position-relative">
        <div
          className={
            "position-absolute w-40px h-40px input-group-text bg-secondary rounded shine border-0 cursor-pointer px-3 d-flex justify-content-center align-items-center"
          }
          style={{ top: "-25px", left: "44%" }}
        ></div>
      </div>
      <div className="border border-gray-300 rounded px-5 py-4 mb-7">
        <div className="d-flex justify-content-between align-items-center mb-5">
          <h4 className="fs-7 fw-semibold text-gray-800 mb-0">To</h4>
          <div className="d-flex flex-row">
            <div className="h-25px w-45px bg-secondary rounded shine me-1"></div>
            <div className="h-25px w-45px bg-secondary rounded shine"></div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-1">
          <div
            className="h-40px w-100 bg-secondary rounded shine"
            style={{ maxWidth: "150px" }}
          ></div>
          <div className="h-40px w-130px bg-secondary rounded shine"></div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="h-15px w-50px bg-secondary rounded shine"></div>
          <div className="h-15px w-70px bg-secondary rounded shine"></div>
        </div>
      </div>

      <div className="d-flex align-items-end w-100">
        <div className="h-50px w-100 bg-secondary rounded shine"></div>
      </div>
    </>
  );
};
