import { createContext, useEffect, useState } from "react";
import { WithChildren } from "../../helpers";

export interface ModalContextModel {
  component?: JSX.Element;
  setComponent: (_component: JSX.Element) => void;
  modalWidth: number;
  setModalWidth: (_modalWidth: number) => void;
  showModal: boolean;
  setShowModal: (_showModal: boolean) => void;
}

export const ModalContext = createContext<ModalContextModel>({
  component: undefined,
  setComponent: (_component?: JSX.Element) => {},
  modalWidth: 400,
  setModalWidth: (_modalWidth: number) => {},
  showModal: false,
  setShowModal: (_showModal: boolean) => {},
});

const ModalProvider: React.FC<WithChildren> = ({ children }) => {
  const [component, setComponent] = useState<JSX.Element | undefined>(
    undefined
  );
  const [modalWidth, setModalWidth] = useState(400);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    console.log("SHOW MODAL", showModal);
  }, [showModal]);

  const value = {
    component,
    setComponent,
    modalWidth,
    setModalWidth,
    showModal,
    setShowModal,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export default ModalProvider;
