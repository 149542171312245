/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from "react";
import { KTSVG } from "../../_oxapay/helpers";
import { ModalContext } from "../../_oxapay/layout/core/ModalContext";

export const Modal = () => {
  const { component, modalWidth, showModal, setShowModal, setComponent } =
    useContext(ModalContext);
  return (
    <>
      <div
        className={`modal fade ${
          showModal && "show d-block"
        } animate__animated animate__faster animate__zoomIn`}
      >
        <div
          className={`modal-dialog mw-${modalWidth}px modal-dialog-centered`}
        >
          <div className="modal-content rounded-modal">
            <div className="modal-header pb-0 border-0 justify-content-end">
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                onClick={() => {
                  setShowModal(false);
                  setComponent(<></>);
                }}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon-1"
                />
              </div>
            </div>
            <div className="modal-body py-0 px-0">{component}</div>
          </div>
        </div>
      </div>
      {showModal && <div className="modal-backdrop fade show"></div>}
    </>
  );
};
