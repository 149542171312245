import React, { useContext, useEffect, useState } from "react";
import { formatAmount } from "../app/services/functionServices";
import { KTSVG, toAbsoluteUrl } from "../_oxapay/helpers";
import { AppContext } from "../_oxapay/layout/core/AppContext";
import { WebappContext } from "../_oxapay/layout/core/WebappContext";
import { WalletPlaceholder } from "./WalletPlaceholder";

export interface Asset {
  coin: string;
  price: number;
  change: number;
  amount: number;
}

interface Props {
  assets: Asset[];
  balanceLoading: boolean;
  priceLoading: boolean;
  height?: number;
}

export const AssetsList = ({
  assets,
  balanceLoading,
  priceLoading,
  height = 450,
}: Props) => {
  const { webappStatus } = useContext(WebappContext);
  const [searchTerm, setSearchTerm] = useState("");
  const { coinsListMap, coinsLoading } = useContext(AppContext);
  const [assetsList, setAssetsList] = useState(assets);

  useEffect(() => {
    setAssetsList(assets);
  }, [assets]);

  const filteredAssets = assetsList.filter((asset) =>
    `${coinsListMap[asset.coin]?.name} ${asset.coin}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const assetValueSort = (a: Asset, b: Asset) =>
    b.amount * b.price - a.amount * a.price;
  const sortedAssets = [...filteredAssets].sort(assetValueSort);

  return (
    <>
      <div className="pb-2 searchBox-section1">
        <div className="position-relative mb-1">
          <span className="svg-icon svg-icon-2 position-absolute top-50 translate-middle-y ms-4">
            <KTSVG
              path="/media/icons/duotune/general/gen004.svg"
              className="svg-icon-3"
            />
          </span>
          <input
            type="text"
            className="form-control w-100 fs-7 ps-12 mb-2"
            placeholder="Search asset..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      <div
        className={`${!webappStatus && "overflow-scroll h-" + height + "px"}`}
      >
        {coinsLoading || priceLoading || balanceLoading ? (
          <div className="mt-4">
            <WalletPlaceholder />
            <WalletPlaceholder />
            <WalletPlaceholder />
            <WalletPlaceholder />
            <WalletPlaceholder />
            <WalletPlaceholder />
            <WalletPlaceholder />
            <WalletPlaceholder />
          </div>
        ) : (
          <div className="table-responsive">
            <table className="table">
              <tbody>
                {sortedAssets
                  .filter(
                    (asset) =>
                      coinsListMap[asset.coin]?.status === true ||
                      Number(asset.amount) !== 0
                  )
                  .map((asset, index) => (
                    <tr
                      key={index}
                      className="d-flex align-items-center justify-content-between"
                    >
                      <td className="d-flex align-items-center">
                        <div className="w-45px overflow-hidden me-3">
                          <div className="symbol-label">
                            <img
                              src={toAbsoluteUrl(
                                `/media/svg/coins/${
                                  coinsListMap[asset.coin]?.slug
                                }.svg`
                              )}
                              alt={coinsListMap[asset.coin]?.name}
                              className="w-100"
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          <h4 className="d-flex align-items-center fw-bold text-dark lh-1 mb-0">
                            <span>{coinsListMap[asset.coin]?.name}</span>
                            <span className="fs-8 fw-semibold text-gray-600 ms-2">
                              ({asset.coin.toUpperCase()})
                            </span>
                          </h4>
                          <span className="d-flex align-items-center text-gray-600 fw-semibold fs-7 mt-1">
                            $
                            {asset.price.toLocaleString(undefined, {
                              maximumFractionDigits: 10,
                            })}
                            <span
                              className={`badge ${
                                asset.change < 0
                                  ? "badge-light-danger"
                                  : "badge-light-success"
                              } fs-9 fw-semibold ms-1`}
                            >
                              {asset.change > 0 && "+"}
                              {asset.change}%
                            </span>
                          </span>
                        </div>
                      </td>
                      <td className="d-flex flex-column align-items-end">
                        <h5 className="fw-500 text-dark lh-1 mb-0">
                          {formatAmount(
                            asset.amount,
                            coinsListMap[asset.coin]?.displayPrecision
                          )}
                        </h5>
                        <span className="text-gray-600 fw-semibold fs-6">
                          ${Number(asset.amount * asset.price).toLocaleString()}
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};
