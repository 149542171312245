import React, { useContext, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { DateTime, shortenString } from "../../app/services/functionServices";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { WalletWebappSendContext } from "../../_oxapay/layout/core/WalletWebappSendContext";
import { VerifiedAddress } from "../addressbook/VerifiedAddress";
import { WebappAddressbookFormModal } from "./WebappAddressbookFormModal";

interface props {
  onSelect: (address: string) => void;
  onChangeModalShow?: (value: boolean) => void;
}

export const WalletWebappRecentAddress = ({
  onSelect,
  onChangeModalShow,
}: props) => {
  const [showAddressBookForm, setShowAddressBookForm] = useState(false);
  const { addressbook } = useContext(AppContext);
  const { recentAddresses } = useContext(WalletWebappSendContext);
  const [address, setAddress] = useState("");

  return recentAddresses.length !== 0 ? (
    <>
      <p className="fs-6 text-uppercase fw-400 text-gray-600 mb-2 ps-4">
        Recents
      </p>
      <div className="card rounded-12px pb-1 pt-1 mb-5 overflow-scroll">
        {recentAddresses.map((item, index) => (
          <div key={index}>
            <div className="d-flex flex-stack rounded py-2">
              <div className="d-flex align-items-center cursor-pointer">
                <div className="ms-5">
                  {addressbook.find(
                    (addressItem) => addressItem.address === item.address
                  ) ? (
                    <p
                      className="fs-5 fw-semibold text-gray-900 text-hover-primary cursor-pointer mb-0"
                      onClick={() => onSelect(item.address)}
                    >
                      {
                        addressbook.find(
                          (addressItem) => addressItem.address === item.address
                        )?.name
                      }
                      {addressbook.find(
                        (addressItem) => addressItem.address === item.address
                      )?.whitelist && <VerifiedAddress />}
                    </p>
                  ) : (
                    <p
                      className="d-inline-block fs-5 text-primary mb-0"
                      onClick={() => {
                        setAddress(item.address);
                        setShowAddressBookForm(true);
                        onChangeModalShow && onChangeModalShow(true);
                      }}
                    >
                      Add to address book
                    </p>
                  )}
                  {item?.address.length < 28 ? (
                    <p
                      className="address fw-semibold fs-6 text-gray-600 text-hover-primary cursor-pointer mb-0"
                      onClick={() => onSelect(item.address)}
                    >
                      {shortenString(item.address, 28)}
                    </p>
                  ) : (
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="top"
                      overlay={
                        <Popover>
                          <Popover.Body>{item.address}</Popover.Body>
                        </Popover>
                      }
                    >
                      <p
                        className="address fw-semibold fs-6 text-gray-600 text-hover-primary cursor-pointer mb-0"
                        onClick={() => onSelect(item.address)}
                      >
                        {shortenString(item.address, 28)}
                      </p>
                    </OverlayTrigger>
                  )}

                  <p className="text-muted fs-7 mb-0">
                    {DateTime(parseInt(item.date))}
                  </p>
                </div>
              </div>
            </div>
            {recentAddresses.length - 1 !== index && (
              <hr className="border-gray-500 m-0" />
            )}
          </div>
        ))}
      </div>

      <WebappAddressbookFormModal
        address={address}
        showAddressBookForm={showAddressBookForm}
        onClose={() => {
          onChangeModalShow && onChangeModalShow(false);
          setShowAddressBookForm(false);
        }}
      />
    </>
  ) : (
    <></>
  );
};
