import React from "react";

interface Props {
  count: number;
}

export const WebappAssetsListSkeleton: React.FC<Props> = ({ count }) => {
  const skeletonItems = Array.from({ length: count }, (_, index) => (
    <React.Fragment key={index}>
      <div className="card ps-4 pe-5 py-3 mb-2">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="w-45px overflow-hidden me-3">
              <div className="symbol-label">
                <div className="h-45px w-45px bg-secondary rounded shine"></div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="h-20px w-100px bg-secondary rounded shine mb-1"></div>
              <span className="d-flex align-items-center text-gray-600 fs-5">
                <div className="h-20px w-100px bg-secondary rounded shine mb-1"></div>
              </span>
            </div>
          </div>
          <div className="d-flex flex-column align-items-end">
            <div className="h-20px w-90px bg-secondary rounded shine mb-1"></div>
            <div className="h-20px w-90px bg-secondary rounded shine mb-1"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ));

  return <>{skeletonItems}</>;
};
