import { createContext, useState } from "react";
import { WithChildren } from "../../helpers";

export interface BalanceContextModel {
  balance?: any;
  setBalance: (_balance: any) => void;
}

export const BalanceContext = createContext<BalanceContextModel>({
  balance: undefined,
  setBalance: (_balance?: any) => {},
});

const BalanceProvider: React.FC<WithChildren> = ({ children }) => {
  const [balance, setBalance] = useState<any>({});
  const value = {
    balance,
    setBalance,
  };

  return (
    <BalanceContext.Provider value={value}>{children}</BalanceContext.Provider>
  );
};

export default BalanceProvider;
