import React, { useContext, useEffect } from "react";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

import { WaitButtonSpan } from "../WaitButtonSpan";
import { Collapse, OverlayTrigger, Popover } from "react-bootstrap";
import { useRequest } from "../../app/services/userHttpRequests";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import {
  alertsMapAddAddressbook,
  alertsMapEditAddressbook,
} from "../../app/services/alertsMap";
import { SuccessModalFire } from "../../app/services/successSwalFire";

interface props {
  address?: string;
  onSuccess?: () => void;
  updateFields?: {
    id: string;
    name: string;
    address: string;
    whitelist: boolean;
  };
}
export const AddressBookForm = ({
  onSuccess,
  address,
  updateFields,
}: props) => {
  const [disableUpdate, setDisableUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requireTwoFa, setRequireTwoFa] = useState(false);
  const { addAddressbook, editAddressbook } = useRequest();
  const { setAddressbook } = useContext(AppContext);

  const formik = useFormik({
    initialValues: {
      name: updateFields ? updateFields.name : "",
      address: updateFields ? updateFields.address : "",
      whitelist: updateFields ? updateFields.whitelist : false,
      twoFa: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Name must be at least 3 characters")
        .max(30, "Name must be less than 30 characters")
        .required("Name is required"),
      address: Yup.string()
        .min(3, "Address must be at least 10 characters")
        .required("Address is required"),
      twoFa: requireTwoFa
        ? Yup.number()
            .typeError("Authentication code must be a number")
            .required("Two-factor authentication code is required")
        : Yup.number().typeError("Authentication code must be a number"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      if (updateFields) {
        editAddressbook(
          updateFields.id,
          values.name,
          values.address,
          values.whitelist,
          values.twoFa
        ).then((res) => {
          if (res.status === 200 && res.result === true) {
            setAddressbook(res.data);
            SuccessModalFire("Address was edited successfully");
            onSuccess && onSuccess();
            resetForm();
            setLoading(false);
          } else if (res.status === 0) {
            FailedModalFire(alertsMapEditAddressbook[res.errorNum.toString()]);
            setLoading(false);
          }
        });
      } else {
        addAddressbook(
          values.name,
          values.address,
          values.whitelist,
          values.twoFa
        ).then((res) => {
          if (res.status === 200 && res.result === true) {
            setAddressbook(res.data);
            SuccessModalFire("Address was added successfully");
            onSuccess && onSuccess();
            resetForm();
            setLoading(false);
          } else if (res.status === 0) {
            FailedModalFire(alertsMapAddAddressbook[res.errorNum.toString()]);
            setLoading(false);
          }
        });
      }
    },
  });

  useEffect(() => {
    if (address !== undefined) {
      formik.setFieldValue("address", address);
    }
    // eslint-disable-next-line
  }, [address]);

  useEffect(() => {
    setRequireTwoFa(formik.values.whitelist);
    // eslint-disable-next-line
  }, [formik.values.whitelist]);

  useEffect(() => {
    if (
      updateFields &&
      updateFields?.name === formik.values.name &&
      updateFields?.address === formik.values.address &&
      updateFields?.whitelist === formik.values.whitelist
    ) {
      setDisableUpdate(true);
    } else {
      setDisableUpdate(false);
    }
  }, [updateFields, formik.values]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="card h-md-100">
          <div className="card-body d-flex flex-column flex-center p-0">
            <div className="w-100 mb-5">
              <h4 className="fs-5 fw-semibold text-gray-800">Name</h4>
              <input
                type="text"
                className={`form-control form-control-solid ${
                  formik.errors.name &&
                  formik.touched.name &&
                  "is-invalid border border-danger"
                }`}
                autoComplete="off"
                name="name"
                maxLength={40}
                placeholder="Name [3-30 Characters]"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {formik.errors.name && formik.touched.name && (
                <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                  {formik.errors.name}
                </p>
              )}
            </div>
            <div
              className={`w-100 ${formik.values.whitelist ? "mb-5" : "mb-10"}`}
            >
              <h4 className="fs-5 fw-semibold text-gray-800">Address</h4>
              <input
                type="text"
                className={`form-control form-control-solid ${
                  formik.errors.address &&
                  formik.touched.address &&
                  "is-invalid border border-danger"
                }`}
                autoComplete="off"
                name="address"
                minLength={10}
                placeholder="Address"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.address}
              />
              {formik.errors.address && formik.touched.address && (
                <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                  {formik.errors.address}
                </p>
              )}
              <div className="mt-2">
                <div className="form-check form-check-custom form-check-solid">
                  <input
                    className="form-check-input w-20px h-20px"
                    type="checkbox"
                    name={`whitelist`}
                    checked={formik.values.whitelist}
                    onChange={formik.handleChange}
                  />
                  <label className="form-check-label">
                    Set as whitelist address{" "}
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="top"
                      overlay={
                        <Popover>
                          <Popover.Body>
                            Once an address is verfied as whitelist, you can
                            transfer assets to it without the need for entering
                            any confirmation or two-factor authentication codes.
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <span className="badge badge-circle badge-sm badge-outline badge-primary cursor-pointer ms-1">
                        ?
                      </span>
                    </OverlayTrigger>
                  </label>
                </div>
              </div>
            </div>
            <Collapse in={formik.values.whitelist}>
              <div className="w-100 mb-10">
                <h4 className="fs-5 fw-semibold text-gray-800">
                  2-FA verification code
                </h4>
                <input
                  type="text"
                  className={`form-control form-control-solid me-3 flex-grow-1 ${
                    formik.errors.twoFa &&
                    formik.touched.twoFa &&
                    "is-invalid border border-danger"
                  }`}
                  name="twoFa"
                  placeholder="Code"
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.twoFa}
                />
                {formik.errors.twoFa && formik.touched.twoFa && (
                  <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                    {formik.errors.twoFa}
                  </p>
                )}
              </div>
            </Collapse>

            <div className="d-flex align-items-end w-100">
              <button
                type="submit"
                className="btn btn-primary fs-3 w-100"
                disabled={loading || disableUpdate}
              >
                {loading ? (
                  <WaitButtonSpan />
                ) : updateFields ? (
                  "Update"
                ) : (
                  "Add Address"
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
