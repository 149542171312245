import React from "react";
import { convertUnixToTimeAgo } from "../../app/services/functionServices";
import { IconReferralNotif } from "../staticIcons/IconReferralNotif";

interface props {
  date: string;
}

export const NotifReferral = ({ date }: props) => {
  return (
    <div className="card card-body mb-5 px-3 px-sm-0 pb-3 pb-sm-0 pt-1">
      <div className="d-flex align-items-center flex-sm-row flex-column">
        <div className="d-flex align-items-center my-4 mx-4">
          <div className="symbol symbol-50px me-4">
            <IconReferralNotif />
          </div>

          <div className="mb-0 me-2">
            <span className="fs-6 text-gray-800 fw-bolder">New referral</span>
            <div className="text-gray-400 fs-7">
              {convertUnixToTimeAgo(date)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
