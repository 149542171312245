import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { MerchantContext } from "../../app/Core/MerchantsContext";
import { useAuth } from "../../app/modules/auth";
import { alertsMapDeleteItem } from "../../app/services/alertsMap";
import { DeleteSwalFire } from "../../app/services/deleteSwalFire";
import { errorHandler } from "../../app/services/errorHandler";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { SuccessModalFire } from "../../app/services/successSwalFire";
import { useRequest } from "../../app/services/userHttpRequests";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { ModalContext } from "../../_oxapay/layout/core/ModalContext";
import { MerchantsForm } from "../forms/MerchantsForm";
import { TableBodyCoins } from "../TableBodyCoins";
import { TableBodyLink } from "../TableBodyLink";
import { TableDeleteButton } from "../TableDeleteButton";
import { TableEditButton } from "../TableEditButton";
import { TablePlaceholder } from "../TablePlaceholder";
import { TableSearch } from "../TableSearch";
import { TablesWidget } from "../TableWidget";

export const MerchantsTable = () => {
  const { currentUser } = useAuth();
  const { setComponent, setModalWidth, setShowModal } =
    useContext(ModalContext);
  const { listMerchants, deleteMerchant } = useRequest();
  const {
    merchants,
    setMerchants,
    merchantsPage,
    setMerchantsPage,
    merchantsSearch,
    setMerchantsSearch,
  } = useContext(MerchantContext);

  const [merchantsLoading, setMerchantsLoading] = useState(true);
  const { coinsListMap } = useContext(AppContext);
  const deleteHandler = async (id: string) => {
    const deletemerchant = async () => {
      return deleteMerchant(id)
        .then((res) => {
          if (res.status === 200 && res.result === true) {
            return res;
          } else {
            FailedModalFire(alertsMapDeleteItem[res.errorNum.toString()]);
          }
        })
        .catch((error) => {
          errorHandler(error);
        });
    };

    DeleteSwalFire(deletemerchant).then((res) => {
      if (res.value !== undefined) {
        setMerchantsSearch("");
        setMerchants((res.value as any).data);
        setMerchantsPage((res.value as any).meta);
        SuccessModalFire(`The merchant was deleted successfully`);
      }
    });
  };

  useEffect(() => {
    setMerchantsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    listMerchants(merchantsSearch, merchantsPage.page, { signal })
      .then((res) => {
        if (res.status === 200) {
          setMerchants(res.data);
          setMerchantsPage(res.meta);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      })
      .finally(() => {
        setMerchantsLoading(false);
      });

    return () => controller.abort();
    // eslint-disable-next-line
  }, [merchantsSearch, merchantsPage.page]);

  return (
    <TablesWidget
      title="Your merchant API keys"
      className="mb-5 mb-xl-10"
      tableHead={
        currentUser?.access?.swap
          ? [
              "Name",
              "Merchant logo",
              "Fee paid by payer",
              "Underpaid",
              "Accepted coins",
              "Auto convert to USDT",
              "Auto withdraw",
              "Merchant API key",
              "Actions",
            ]
          : [
              "Name",
              "Merchant logo",
              "Fee paid by payer",
              "Underpaid",
              "Accepted coins",
              "Auto withdraw",
              "Merchant API key",
              "Actions",
            ]
      }
      size={
        currentUser?.access?.swap
          ? [100, 100, 100, 100, 150, null, 100, 250, 100]
          : [100, 100, 100, 100, 150, 100, 250, 100]
      }
      loading={merchantsLoading}
      noData={merchants?.length === 0 && !merchantsLoading ? true : false}
      searchInput={
        <TableSearch
          value={merchantsSearch}
          onChange={() => setMerchantsLoading(true)}
          onDebouncedChange={(search) => {
            setMerchantsSearch(search);
            setMerchantsPage({ ...merchantsPage, page: 1 });
          }}
        />
      }
      activePage={merchantsPage.page}
      pages={merchantsPage.pages}
      pageChange={(page) => setMerchantsPage({ ...merchantsPage, page: page })}
      document={[
        "https://docs.oxapay.com/api-reference/creating-an-invoice",
        "Merchant docs",
      ]}
    >
      {merchantsLoading === true && (
        <TablePlaceholder
          config={currentUser?.access?.swap ? [10, 9] : [10, 8]}
        />
      )}
      {!merchantsLoading &&
        merchants !== undefined &&
        merchants.map((data, index) => (
          <tr key={index}>
            <td>
              <span className="text-dark fw-bold fs-6">{data.name}</span>
            </td>
            <td>
              {data.logo ? (
                <img src={toAbsoluteUrl(`${data.logo}`)} alt="" height="20px" />
              ) : (
                <span className="text-gray-600 fw-bold fs-6">-</span>
              )}
            </td>
            <td>
              {Number(data.feePaidByPayer) === 0 ? (
                <span className="text-dark fw-bold fs-6 mx-3">-</span>
              ) : (
                <img
                  src={toAbsoluteUrl("/media/icons/duotune/general/gen037.svg")}
                  className="w-30px"
                  alt="check"
                />
              )}
            </td>
            <td>
              <span className="text-gray-600 fw-bold fs-6">
                {data.underPaid}%
              </span>
            </td>
            <td>
              <TableBodyCoins acceptedCoins={data.coins} />
            </td>
            {currentUser?.access?.swap && (
              <td>
                {data.toCurrency !== "" ? (
                  <img
                    src={toAbsoluteUrl(
                      "/media/icons/duotune/general/gen037.svg"
                    )}
                    className="w-30px"
                    alt="check"
                  />
                ) : (
                  <span className="text-dark fw-bold fs-6 mx-3">-</span>
                )}
              </td>
            )}
            <td>
              {data.autoWithdraw ? (
                <img
                  src={toAbsoluteUrl("/media/icons/duotune/general/gen037.svg")}
                  className="w-30px"
                  alt="check"
                />
              ) : (
                <span className="text-dark fw-bold fs-6 mx-3">-</span>
              )}
            </td>
            <td>
              <TableBodyLink
                disable={
                  data.coins.filter(
                    (coinSymbol) => coinsListMap[coinSymbol]?.status === true
                  ).length
                    ? false
                    : true
                }
              >
                {data.apiKey}
              </TableBodyLink>
            </td>
            <td>
              <div className="d-flex justify-content-start flex-shrink-0">
                <TableEditButton
                  onClick={() => {
                    setModalWidth(400);
                    setComponent(
                      <>
                        <MerchantsForm
                          merchant={{
                            id: data.id,
                            name: data.name,
                            logourl: data.logo,
                            feePaidByPayer: data.feePaidByPayer,
                            underPaid: data.underPaid,
                            acceptedCoins: data.coins.filter(
                              (coinSymbol) =>
                                coinsListMap[coinSymbol]?.status === true
                            ),
                            toCurrency: data.toCurrency,
                            autoWithdraw: data?.autoWithdraw,
                          }}
                        />
                      </>
                    );
                    setShowModal(true);
                  }}
                />
                <TableDeleteButton onClick={() => deleteHandler(data.id)} />
              </div>
            </td>
          </tr>
        ))}
    </TablesWidget>
  );
};
