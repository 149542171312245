import React from "react";

interface Props {
  config: [number, number];
}

export const TablePlaceholder: React.FC<Props> = ({ config }) => {
  const renderTableRows = () => {
    const tableRows = [];

    for (let i = 0; i < config[0]; i++) {
      const tableData = [];

      for (let j = 0; j < config[1]; j++) {
        tableData.push(
          <td key={j}>
            <div className="h-35px w-100 bg-secondary rounded m-auto shine"></div>
          </td>
        );
      }

      tableRows.push(<tr key={i}>{tableData}</tr>);
    }

    return tableRows;
  };

  return <>{renderTableRows()}</>;
};
