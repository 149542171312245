import clsx from "clsx";
import React from "react";
import { KTSVG } from "../../_oxapay/helpers";

export const IconPaymentlink = () => {
  return (
    <span className={clsx("symbol-label", `bg-light-dark`)}>
      <KTSVG
        path={`/media/icons/duotune/ecommerce/ecm008.svg`}
        className={`svg-icon-1 svg-icon-dark`}
      />
    </span>
  );
};
