import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { toAbsoluteUrl } from "../../../../_oxapay/helpers";
import { alertsMapTelegramLogin } from "../../../services/alertsMap";
import { errorHandler } from "../../../services/errorHandler";
import { FailedModalFire } from "../../../services/failedSwalFire";
import { loadTelegramWidgetScript } from "../../../services/loadTelegramScript";
import { useAuth } from "../core/Auth";
import { getUserByToken, telegramLogin } from "../core/_requests";

interface props {
  setLoading: (status: boolean) => void;
}

function TelegramWidget({ setLoading }: props) {
  const [telegramLoading, setTelegramLoading] = useState(true);
  const {
    saveAuth,
    setCurrentUser,
    setLoginInfo,
    setTelegramInfo,
    setGoogleInfo,
    returnPage,
  } = useAuth();
  const navigate = useNavigate();

  const loginWithTelegram = async (result: any) => {
    setLoading(true);
    const cookies = new Cookies();
    try {
      const refCode = cookies.get("ref");
      const referrer = cookies.get("referrer");
      const { data: auth } = await telegramLogin(result, refCode, referrer);
      saveAuth(auth);
      if (auth.status === 200 && auth.apiToken) {
        const { data: user } = await getUserByToken(auth.apiToken);
        setCurrentUser(user);
        returnPage && navigate("/connect");
      } else if (auth.status === 0 && auth.errorNum === 5) {
        setTelegramInfo(auth.requestToken);
        setLoginInfo(undefined);
        setGoogleInfo(undefined);
        navigate("/twofactor");
      } else if (auth.errorNum) {
        FailedModalFire(alertsMapTelegramLogin[auth.errorNum.toString()]);
      }
    } catch (error) {
      errorHandler(error as Error);
      FailedModalFire(
        "Please try again.",
        "Something went wrong with your connection"
      );
      saveAuth(undefined);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const cleanup = loadTelegramWidgetScript((user: any) => {
      loginWithTelegram(user);
    });

    return () => {
      cleanup();
      const iframeElement = document.getElementById(
        "telegram-login-newapioxapay_bot"
      );
      if (iframeElement) {
        iframeElement.remove();
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const targetElement = document.querySelector("#widget_container");
    const targetButton = document.querySelector("#telegram-login-btn");

    if (targetElement && targetButton) {
      setTelegramLoading(false);
      const handleHover = () => {
        targetButton.classList.add("btn-text-primary", "bg-light-secondary");
        targetButton.classList.remove("btn-text-gray-700", "bg-state-light");
      };

      const handleLeave = () => {
        targetButton.classList.add("btn-text-gray-700", "bg-state-light");
        targetButton.classList.remove("btn-text-primary", "bg-light-secondary");
      };

      targetElement.addEventListener("mouseenter", handleHover);
      targetElement.addEventListener("mouseleave", handleLeave);
    }
  }, []);

  return (
    <div className="position-relative">
      <div id="widget_container" className="telegramWidget"></div>
      <button
        id="telegram-login-btn"
        type="button"
        className="btn btn-flex btn-outline btn-text-gray-700 bg-state-light flex-center text-nowrap w-100 telegram-button"
        disabled={telegramLoading ? true : false}
      >
        <img
          alt="Logo"
          src={toAbsoluteUrl("/media/svg/brand-logos/telegram.svg")}
          className="h-15px me-3"
        />
        Sign in with Telegram
      </button>
    </div>
  );
}

export default TelegramWidget;
