import React from "react";
import { convertUnixToTimeAgo } from "../../app/services/functionServices";
import { IconSend } from "../staticIcons/IconSend";

interface props {
  data: {
    name: string;
    amount: string;
    currency: string;
  };
  date: string;
}

export const HeaderNotifSend = ({ data, date }: props) => {
  return (
    <div className="d-flex flex-stack py-4">
      <div className="d-flex align-items-center">
        <div className="symbol symbol-40px me-4">
          <IconSend />
        </div>

        <div className="mb-0 me-2">
          <span className="fs-6 text-gray-800 fw-bolder">
            Send request confirmed successfully
          </span>
          <div className="text-gray-400 fs-7">
            {data.amount} {data.currency}
          </div>
        </div>
      </div>

      <span className="badge badge-light fs-8">
        {convertUnixToTimeAgo(date)}
      </span>
    </div>
  );
};
