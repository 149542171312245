import React from "react";

export const NotifSkeleton = () => {
  return (
    <div className="card card-body mb-5 px-3 px-sm-0 pb-3 pb-sm-0 pt-1">
      <div className="d-flex align-items-center flex-sm-row flex-column">
        <div className="d-flex align-items-center my-4 mx-4">
          <div className="symbol symbol-50px me-4">
            <div className="h-50px w-50px bg-secondary rounded shine"></div>
          </div>

          <div className="mb-0 me-2">
            <div className="h-20px w-100px bg-secondary rounded shine mb-1"></div>
            <div className="text-gray-400 fs-7">
              <div className="h-20px w-100px bg-secondary rounded shine"></div>
            </div>
          </div>
        </div>

        <div
          className="d-flex align-items-center justify-content-center bg-secondary shine h-40px p-5 mw-300px w-100"
          style={{ borderRadius: "12px" }}
        ></div>
      </div>
    </div>
  );
};
