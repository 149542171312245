import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { errorHandler } from "../app/services/errorHandler";
import { refBalanceInterval } from "../app/services/intervalManage";
import { useRequest } from "../app/services/userHttpRequests";
import { AppContext } from "../_oxapay/layout/core/AppContext";
import { CommissionList } from "./CommissionList";

export interface Asset {
  coin: string;
  amount: number;
}

export const ReferralsAssetsList = () => {
  const [assets, setAssets] = useState<Asset[]>([]);
  const { userReferralsEarning } = useRequest();
  const [refBalance, setRefBalance] = useState();
  const [balanceLoading, setBalanceLoading] = useState(true);
  const { coinsListMap } = useContext(AppContext);

  useEffect(() => {
    const balanceAssets = Object.entries(coinsListMap).map(([coin]) => ({
      coin,
      amount: refBalance && refBalance[coin] ? refBalance[coin] : 10,
    }));

    setAssets(balanceAssets);
  }, [coinsListMap, refBalance]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchBalance = async () => {
      try {
        const response = await userReferralsEarning({
          signal: controller.signal,
        });
        if (response.status === 200) {
          const balanceData = response.data;
          // Compare previous balance data with new data
          if (JSON.stringify(refBalance) !== JSON.stringify(balanceData)) {
            setAssets((prevAssets) =>
              prevAssets.map((asset) => ({
                ...asset,
                amount:
                  balanceData[asset.coin as keyof typeof balanceData] ||
                  asset.amount,
              }))
            );

            // Update previous balance data
            setRefBalance(balanceData);
            setBalanceLoading(false);
          }
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };
    fetchBalance();
    const interval = setInterval(fetchBalance, refBalanceInterval); // Fetch balance every 5 seconds

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval on component unmount
    };
    // eslint-disable-next-line
  }, []);

  return (
    <CommissionList
      assets={assets}
      height={500}
      balanceLoading={balanceLoading}
    />
  );
};
