/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import { useAuth } from "../../../../app/modules/auth";
import { toAbsoluteUrl } from "../../../helpers";

const UserMenu = () => {
  const { currentUser, logout } = useAuth();
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      {/* begin::Menu item */}
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          {/* begin::Avatar */}
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={toAbsoluteUrl("/media/avatars/blank.png")} />
          </div>
          {/* end::Avatar */}

          {/* begin::Username */}
          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {currentUser?.firstName}
            </div>
            <p className="fw-bold text-muted text-hover-primary fs-7 m-0">
              {currentUser?.username}
            </p>
          </div>
          {/* end::Username */}
        </div>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu separator */}
      <div className="separator my-2"></div>
      {/* end::Menu separator */}

      {/* <Languages languageMenuPlacement="right-end" /> */}

      {/* begin::Menu item */}
      <div className="menu-item px-5 my-1">
        <Link to="/profile" className="menu-link px-5">
          Profile
        </Link>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu item */}
      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          Sign Out
        </a>
      </div>
    </div>
  );
};

export { UserMenu };
