import React, {ReactNode} from 'react'

interface props {
  title: ReactNode
  subTitle: ReactNode
  className?: string
}

export const ModalBodyTitle = ({title, subTitle, className}: props) => {
  return (
    <div className={`text-center ${className}`}>
      <h1 className='mb-3'>{title}</h1>
      <div className='text-muted fw-semibold fs-5'>{subTitle}</div>
    </div>
  )
}
