import { createContext, useState, ReactNode } from "react";

interface Plan {
  id: string;
  month: string;
  price: string;
  priceCurrency: string;
  status: string;
}

export interface Channels {
  id: string;
  name: string;
  inviteLink: string;
  currency: string[];

  plans: Plan[];
  date: string;
  status: string;
}

interface ChannelsPage {
  page: number;
  pages: number;
}

export interface ChannelsContextModel {
  channels?: Channels[];
  setChannels: (channels: Channels[]) => void;
  channelsPage: ChannelsPage;
  setChannelsPage: (channelsPage: ChannelsPage) => void;
  channelsSearch: string;
  setChannelsSearch: (channelsSearch: string) => void;
}

export const ChannelsContext = createContext<ChannelsContextModel>({
  channels: undefined,
  setChannels: () => {},
  channelsPage: { page: 1, pages: 0 },
  setChannelsPage: () => {},
  channelsSearch: "",
  setChannelsSearch: () => {},
});

interface ChannelsProviderProps {
  children: ReactNode;
}

const ChannelsProvider: React.FC<ChannelsProviderProps> = ({ children }) => {
  const [channels, setChannels] = useState<Channels[] | undefined>(undefined);
  const [channelsPage, setChannelsPage] = useState({
    page: 1,
    pages: 0,
  });
  const [channelsSearch, setChannelsSearch] = useState("");

  return (
    <ChannelsContext.Provider
      value={{
        channels,
        setChannels,
        channelsPage,
        setChannelsPage,
        channelsSearch,
        setChannelsSearch,
      }}
    >
      {children}
    </ChannelsContext.Provider>
  );
};

export default ChannelsProvider;
