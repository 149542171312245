import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { alertsMapChangePassword } from "../../app/services/alertsMap";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { SuccessModalFire } from "../../app/services/successSwalFire";
import { useRequest } from "../../app/services/userHttpRequests";
import { WaitButtonSpan } from "../WaitButtonSpan";

interface props {
  onClick: () => void;
}

export const ChangePasswordForm = ({ onClick }: props) => {
  const [loading, setLoading] = useState(false);
  const { changePassword } = useRequest();

  const formik = useFormik({
    initialValues: {
      currentpassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      currentpassword: Yup.string()
        .required("Current password is required")
        .matches(
          /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/,
          "Password must be more than 8 chracaters and contain at least one number."
        ),
      newPassword: Yup.string()
        .required("New password is required")
        .matches(
          /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/,
          "Password must be more than 8 chracaters and contain at least one number."
        ),
      confirmPassword: Yup.string()
        .required("New password confirmation is required")
        .when("newPassword", {
          is: (val: string) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("newPassword")],
            "Password and Confirm Password aren't match"
          ),
        }),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setLoading(true);
      try {
        const data = await changePassword(
          values.currentpassword,
          values.newPassword,
          values.confirmPassword
        );
        if (data.status === 200) {
          SuccessModalFire("Your password was changed successfully.");
        } else if (data.status === 0 && data.errorNum) {
          FailedModalFire(alertsMapChangePassword[data.errorNum.toString()]);
        }
      } catch (error) {
        FailedModalFire(
          "Check your internet connection and try again",
          "Connection error"
        );
      } finally {
        setSubmitting(false);
        setLoading(false);
        resetForm();
        onClick();
      }
    },
  });

  return (
    <div className="flex-row-fluid mb-10">
      <form className="form" onSubmit={formik.handleSubmit}>
        <div className="row mb-1">
          <div className="col-lg-4">
            <div className="fv-row mb-0">
              <label className="form-label fs-6 fw-bold mb-3">
                Current Password
              </label>
              <input
                type="password"
                placeholder="Current password"
                className={`form-control form-control-solid ${
                  formik.errors.currentpassword &&
                  formik.touched.currentpassword &&
                  "is-invalid border border-danger"
                }`}
                name="currentpassword"
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.currentpassword}
              />
              {formik.errors.currentpassword &&
                formik.touched.currentpassword && (
                  <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                    {formik.errors.currentpassword}
                  </p>
                )}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="fv-row mb-0">
              <label className="form-label fs-6 fw-bold mb-3">
                New Password
              </label>
              <input
                type="password"
                placeholder="New password"
                className={`form-control form-control-solid ${
                  formik.errors.newPassword &&
                  formik.touched.newPassword &&
                  "is-invalid border border-danger"
                }`}
                name="newPassword"
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.newPassword}
              />
              {formik.errors.newPassword && formik.touched.newPassword && (
                <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                  {formik.errors.newPassword}
                </p>
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="fv-row mb-0">
              <label className="form-label fs-6 fw-bold mb-3">
                Confirm New Password
              </label>
              <input
                type="password"
                placeholder="Confirm new password"
                className={`form-control form-control-solid ${
                  formik.errors.confirmPassword &&
                  formik.touched.confirmPassword &&
                  "is-invalid border border-danger"
                }`}
                name="confirmPassword"
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
              />
              {formik.errors.confirmPassword &&
                formik.touched.confirmPassword && (
                  <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                    {formik.errors.confirmPassword}
                  </p>
                )}
            </div>
          </div>
        </div>
        <div className="form-text mb-5">
          Password must be at least 8 character and contain number
        </div>
        <div className="d-flex">
          <button
            type="submit"
            className="btn btn-primary me-2 px-6"
            disabled={formik.isSubmitting || loading || !formik.isValid}
          >
            {loading ? <WaitButtonSpan /> : "Update Password"}
          </button>
          <button
            type="button"
            className="btn btn-color-gray-400 btn-active-light-primary px-6"
            onClick={onClick}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};
