export const countriesList = [
  {
    value: 'AF',
    label: 'Afghanistan',
    image: '/media/flags/afghanistan.svg',
  },
  {
    value: 'AX',
    label: 'Aland Islands',
    image: '/media/flags/aland-islands.svg',
  },
  {
    value: 'AL',
    label: 'Albania',
    image: '/media/flags/albania.svg',
  },
  {
    value: 'DZ',
    label: 'Algeria',
    image: '/media/flags/algeria.svg',
  },
  {
    value: 'AS',
    label: 'American Samoa',
    image: '/media/flags/american-samoa.svg',
  },
  {
    value: 'AD',
    label: 'Andorra',
    image: '/media/flags/andorra.svg',
  },
  {
    value: 'AO',
    label: 'Angola',
    image: '/media/flags/angola.svg',
  },
  {
    value: 'AI',
    label: 'Anguilla',
    image: '/media/flags/anguilla.svg',
  },
  {
    value: 'AG',
    label: 'Antigua and Barbuda',
    image: '/media/flags/antigua-and-barbuda.svg',
  },
  {
    value: 'AR',
    label: 'Argentina',
    image: '/media/flags/argentina.svg',
  },
  {
    value: 'AM',
    label: 'Armenia',
    image: '/media/flags/armenia.svg',
  },
  {
    value: 'AW',
    label: 'Aruba',
    image: '/media/flags/aruba.svg',
  },
  {
    value: 'AU',
    label: 'Australia',
    image: '/media/flags/australia.svg',
  },
  {
    value: 'AT',
    label: 'Austria',
    image: '/media/flags/austria.svg',
  },
  {
    value: 'AZ',
    label: 'Azerbaijan',
    image: '/media/flags/azerbaijan.svg',
  },
  {
    value: 'BS',
    label: 'Bahamas',
    image: '/media/flags/bahamas.svg',
  },
  {
    value: 'BH',
    label: 'Bahrain',
    image: '/media/flags/bahrain.svg',
  },
  {
    value: 'BD',
    label: 'Bangladesh',
    image: '/media/flags/bangladesh.svg',
  },
  {
    value: 'BB',
    label: 'Barbados',
    image: '/media/flags/barbados.svg',
  },
  {
    value: 'BY',
    label: 'Belarus',
    image: '/media/flags/belarus.svg',
  },
  {
    value: 'BE',
    label: 'Belgium',
    image: '/media/flags/belgium.svg',
  },
  {
    value: 'BZ',
    label: 'Belize',
    image: '/media/flags/belize.svg',
  },
  {
    value: 'BJ',
    label: 'Benin',
    image: '/media/flags/benin.svg',
  },
  {
    value: 'BM',
    label: 'Bermuda',
    image: '/media/flags/bermuda.svg',
  },
  {
    value: 'BT',
    label: 'Bhutan',
    image: '/media/flags/bhutan.svg',
  },
  {
    value: 'BO',
    label: 'Bolivia, Plurinational State of',
    image: '/media/flags/bolivia.svg',
  },
  {
    value: 'BQ',
    label: 'Bonaire, Sint Eustatius and Saba',
    image: '/media/flags/bonaire.svg',
  },
  {
    value: 'BA',
    label: 'Bosnia and Herzegovina',
    image: '/media/flags/bosnia-and-herzegovina.svg',
  },
  {
    value: 'BW',
    label: 'Botswana',
    image: '/media/flags/botswana.svg',
  },
  {
    value: 'BR',
    label: 'Brazil',
    image: '/media/flags/brazil.svg',
  },
  {
    value: 'IO',
    label: 'British Indian Ocean Territory',
    image: '/media/flags/british-indian-ocean-territory.svg',
  },
  {
    value: 'BN',
    label: 'Brunei Darussalam',
    image: '/media/flags/brunei.svg',
  },
  {
    value: 'BG',
    label: 'Bulgaria',
    image: '/media/flags/bulgaria.svg',
  },
  {
    value: 'BF',
    label: 'Burkina Faso',
    image: '/media/flags/burkina-faso.svg',
  },
  {
    value: 'BI',
    label: 'Burundi',
    image: '/media/flags/burundi.svg',
  },
  {
    value: 'KH',
    label: 'Cambodia',
    image: '/media/flags/cambodia.svg',
  },
  {
    value: 'CM',
    label: 'Cameroon',
    image: '/media/flags/cameroon.svg',
  },
  {
    value: 'CA',
    label: 'Canada',
    image: '/media/flags/canada.svg',
  },
  {
    value: 'CV',
    label: 'Cape Verde',
    image: '/media/flags/cape-verde.svg',
  },
  {
    value: 'KY',
    label: 'Cayman Islands',
    image: '/media/flags/cayman-islands.svg',
  },
  {
    value: 'CF',
    label: 'Central African Republic',
    image: '/media/flags/central-african-republic.svg',
  },
  {
    value: 'TD',
    label: 'Chad',
    image: '/media/flags/chad.svg',
  },
  {
    value: 'CL',
    label: 'Chile',
    image: '/media/flags/chile.svg',
  },
  {
    value: 'CN',
    label: 'China',
    image: '/media/flags/china.svg',
  },
  {
    value: 'CX',
    label: 'Christmas Island',
    image: '/media/flags/christmas-island.svg',
  },
  {
    value: 'CC',
    label: 'Cocos (Keeling) Islands',
    image: '/media/flags/cocos-island.svg',
  },
  {
    value: 'CO',
    label: 'Colombia',
    image: '/media/flags/colombia.svg',
  },
  {
    value: 'KM',
    label: 'Comoros',
    image: '/media/flags/comoros.svg',
  },
  {
    value: 'CK',
    label: 'Cook Islands',
    image: '/media/flags/cook-islands.svg',
  },
  {
    value: 'CR',
    label: 'Costa Rica',
    image: '/media/flags/costa-rica.svg',
  },
  {
    value: 'CI',
    label: "Côte d'Ivoire",
    image: '/media/flags/ivory-coast.svg',
  },
  {
    value: 'HR',
    label: 'Croatia',
    image: '/media/flags/croatia.svg',
  },
  {
    value: 'CU',
    label: 'Cuba',
    image: '/media/flags/cuba.svg',
  },
  {
    value: 'CW',
    label: 'Curaçao',
    image: '/media/flags/curacao.svg',
  },
  {
    value: 'CZ',
    label: 'Czech Republic',
    image: '/media/flags/czech-republic.svg',
  },
  {
    value: 'DK',
    label: 'Denmark',
    image: '/media/flags/denmark.svg',
  },
  {
    value: 'DJ',
    label: 'Djibouti',
    image: '/media/flags/djibouti.svg',
  },
  {
    value: 'DM',
    label: 'Dominica',
    image: '/media/flags/dominica.svg',
  },
  {
    value: 'DO',
    label: 'Dominican Republic',
    image: '/media/flags/dominican-republic.svg',
  },
  {
    value: 'EC',
    label: 'Ecuador',
    image: '/media/flags/ecuador.svg',
  },
  {
    value: 'EG',
    label: 'Egypt',
    image: '/media/flags/egypt.svg',
  },
  {
    value: 'SV',
    label: 'El Salvador',
    image: '/media/flags/el-salvador.svg',
  },
  {
    value: 'GQ',
    label: 'Equatorial Guinea',
    image: '/media/flags/equatorial-guinea.svg',
  },
  {
    value: 'ER',
    label: 'Eritrea',
    image: '/media/flags/eritrea.svg',
  },
  {
    value: 'EE',
    label: 'Estonia',
    image: '/media/flags/estonia.svg',
  },
  {
    value: 'ET',
    label: 'Ethiopia',
    image: '/media/flags/ethiopia.svg',
  },
  {
    value: 'FK',
    label: 'Falkland Islands (Malvinas)',
    image: '/media/flags/falkland-islands.svg',
  },
  {
    value: 'FJ',
    label: 'Fiji',
    image: '/media/flags/fiji.svg',
  },
  {
    value: 'FI',
    label: 'Finland',
    image: '/media/flags/finland.svg',
  },
  {
    value: 'FR',
    label: 'France',
    image: '/media/flags/france.svg',
  },
  {
    value: 'PF',
    label: 'French Polynesia',
    image: '/media/flags/french-polynesia.svg',
  },
  {
    value: 'GA',
    label: 'Gabon',
    image: '/media/flags/gabon.svg',
  },
  {
    value: 'GM',
    label: 'Gambia',
    image: '/media/flags/gambia.svg',
  },
  {
    value: 'GE',
    label: 'Georgia',
    image: '/media/flags/georgia.svg',
  },
  {
    value: 'DE',
    label: 'Germany',
    image: '/media/flags/germany.svg',
  },
  {
    value: 'GH',
    label: 'Ghana',
    image: '/media/flags/ghana.svg',
  },
  {
    value: 'GI',
    label: 'Gibraltar',
    image: '/media/flags/gibraltar.svg',
  },
  {
    value: 'GR',
    label: 'Greece',
    image: '/media/flags/greece.svg',
  },
  {
    value: 'GL',
    label: 'Greenland',
    image: '/media/flags/greenland.svg',
  },
  {
    value: 'GD',
    label: 'Grenada',
    image: '/media/flags/grenada.svg',
  },
  {
    value: 'GU',
    label: 'Guam',
    image: '/media/flags/guam.svg',
  },
  {
    value: 'GT',
    label: 'Guatemala',
    image: '/media/flags/guatemala.svg',
  },
  {
    value: 'GG',
    label: 'Guernsey',
    image: '/media/flags/guernsey.svg',
  },
  {
    value: 'GN',
    label: 'Guinea',
    image: '/media/flags/guinea.svg',
  },
  {
    value: 'GW',
    label: 'Guinea-Bissau',
    image: '/media/flags/guinea-bissau.svg',
  },
  {
    value: 'HT',
    label: 'Haiti',
    image: '/media/flags/haiti.svg',
  },
  {
    value: 'VA',
    label: 'Holy See (Vatican City State)',
    image: '/media/flags/vatican-city.svg',
  },
  {
    value: 'HN',
    label: 'Honduras',
    image: '/media/flags/honduras.svg',
  },
  {
    value: 'HK',
    label: 'Hong Kong',
    image: '/media/flags/hong-kong.svg',
  },
  {
    value: 'HU',
    label: 'Hungary',
    image: '/media/flags/hungary.svg',
  },
  {
    value: 'IS',
    label: 'Iceland',
    image: '/media/flags/iceland.svg',
  },
  {
    value: 'IN',
    label: 'India',
    image: '/media/flags/india.svg',
  },
  {
    value: 'ID',
    label: 'Indonesia',
    image: '/media/flags/indonesia.svg',
  },
  {
    value: 'IR',
    label: 'Iran, Islamic Republic of',
    image: '/media/flags/iran.svg',
  },
  {
    value: 'IQ',
    label: 'Iraq',
    image: '/media/flags/iraq.svg',
  },
  {
    value: 'IE',
    label: 'Ireland',
    image: '/media/flags/ireland.svg',
  },
  {
    value: 'IM',
    label: 'Isle of Man',
    image: '/media/flags/isle-of-man.svg',
  },
  {
    value: 'IL',
    label: 'Israel',
    image: '/media/flags/israel.svg',
  },
  {
    value: 'IT',
    label: 'Italy',
    image: '/media/flags/italy.svg',
  },
  {
    value: 'JM',
    label: 'Jamaica',
    image: '/media/flags/jamaica.svg',
  },
  {
    value: 'JP',
    label: 'Japan',
    image: '/media/flags/japan.svg',
  },
  {
    value: 'JE',
    label: 'Jersey',
    image: '/media/flags/jersey.svg',
  },
  {
    value: 'JO',
    label: 'Jordan',
    image: '/media/flags/jordan.svg',
  },
  {
    value: 'KZ',
    label: 'Kazakhstan',
    image: '/media/flags/kazakhstan.svg',
  },
  {
    value: 'KE',
    label: 'Kenya',
    image: '/media/flags/kenya.svg',
  },
  {
    value: 'KI',
    label: 'Kiribati',
    image: '/media/flags/kiribati.svg',
  },
  {
    value: 'KP',
    label: "Korea, Democratic People's Republic of",
    image: '/media/flags/north-korea.svg',
  },
  {
    value: 'KW',
    label: 'Kuwait',
    image: '/media/flags/kuwait.svg',
  },
  {
    value: 'KG',
    label: 'Kyrgyzstan',
    image: '/media/flags/kyrgyzstan.svg',
  },
  {
    value: 'LA',
    label: "Lao People's Democratic Republic",
    image: '/media/flags/laos.svg',
  },
  {
    value: 'LV',
    label: 'Latvia',
    image: '/media/flags/latvia.svg',
  },
  {
    value: 'LB',
    label: 'Lebanon',
    image: '/media/flags/lebanon.svg',
  },
  {
    value: 'LS',
    label: 'Lesotho',
    image: '/media/flags/lesotho.svg',
  },
  {
    value: 'LR',
    label: 'Liberia',
    image: '/media/flags/liberia.svg',
  },
  {
    value: 'LY',
    label: 'Libya',
    image: '/media/flags/libya.svg',
  },
  {
    value: 'LI',
    label: 'Liechtenstein',
    image: '/media/flags/liechtenstein.svg',
  },
  {
    value: 'LT',
    label: 'Lithuania',
    image: '/media/flags/lithuania.svg',
  },
  {
    value: 'LU',
    label: 'Luxembourg',
    image: '/media/flags/luxembourg.svg',
  },
  {
    value: 'MO',
    label: 'Macao',
    image: '/media/flags/macao.svg',
  },
  {
    value: 'MG',
    label: 'Madagascar',
    image: '/media/flags/madagascar.svg',
  },
  {
    value: 'MW',
    label: 'Malawi',
    image: '/media/flags/malawi.svg',
  },
  {
    value: 'MY',
    label: 'Malaysia',
    image: '/media/flags/malaysia.svg',
  },
  {
    value: 'MV',
    label: 'Maldives',
    image: '/media/flags/maldives.svg',
  },
  {
    value: 'ML',
    label: 'Mali',
    image: '/media/flags/mali.svg',
  },
  {
    value: 'MT',
    label: 'Malta',
    image: '/media/flags/malta.svg',
  },
  {
    value: 'MH',
    label: 'Marshall Islands',
    image: '/media/flags/marshall-island.svg',
  },
  {
    value: 'MQ',
    label: 'Martinique',
    image: '/media/flags/martinique.svg',
  },
  {
    value: 'MR',
    label: 'Mauritania',
    image: '/media/flags/mauritania.svg',
  },
  {
    value: 'MU',
    label: 'Mauritius',
    image: '/media/flags/mauritius.svg',
  },
  {
    value: 'MX',
    label: 'Mexico',
    image: '/media/flags/mexico.svg',
  },
  {
    value: 'FM',
    label: 'Micronesia, Federated States of',
    image: '/media/flags/micronesia.svg',
  },
  {
    value: 'MD',
    label: 'Moldova, Republic of',
    image: '/media/flags/moldova.svg',
  },
  {
    value: 'MC',
    label: 'Monaco',
    image: '/media/flags/monaco.svg',
  },
  {
    value: 'MN',
    label: 'Mongolia',
    image: '/media/flags/mongolia.svg',
  },
  {
    value: 'ME',
    label: 'Montenegro',
    image: '/media/flags/montenegro.svg',
  },
  {
    value: 'MS',
    label: 'Montserrat',
    image: '/media/flags/montserrat.svg',
  },
  {
    value: 'MA',
    label: 'Morocco',
    image: '/media/flags/morocco.svg',
  },
  {
    value: 'MZ',
    label: 'Mozambique',
    image: '/media/flags/mozambique.svg',
  },
  {
    value: 'MM',
    label: 'Myanmar',
    image: '/media/flags/myanmar.svg',
  },
  {
    value: 'NA',
    label: 'Namibia',
    image: '/media/flags/namibia.svg',
  },
  {
    value: 'NR',
    label: 'Nauru',
    image: '/media/flags/nauru.svg',
  },
  {
    value: 'NP',
    label: 'Nepal',
    image: '/media/flags/nepal.svg',
  },
  {
    value: 'NL',
    label: 'Netherlands',
    image: '/media/flags/netherlands.svg',
  },
  {
    value: 'NZ',
    label: 'New Zealand',
    image: '/media/flags/new-zealand.svg',
  },
  {
    value: 'NI',
    label: 'Nicaragua',
    image: '/media/flags/nicaragua.svg',
  },
  {
    value: 'NE',
    label: 'Niger',
    image: '/media/flags/niger.svg',
  },
  {
    value: 'NG',
    label: 'Nigeria',
    image: '/media/flags/nigeria.svg',
  },
  {
    value: 'NU',
    label: 'Niue',
    image: '/media/flags/niue.svg',
  },
  {
    value: 'NF',
    label: 'Norfolk Island',
    image: '/media/flags/norfolk-island.svg',
  },
  {
    value: 'MP',
    label: 'Northern Mariana Islands',
    image: '/media/flags/northern-mariana-islands.svg',
  },
  {
    value: 'NO',
    label: 'Norway',
    image: '/media/flags/norway.svg',
  },
  {
    value: 'OM',
    label: 'Oman',
    image: '/media/flags/oman.svg',
  },
  {
    value: 'PK',
    label: 'Pakistan',
    image: '/media/flags/pakistan.svg',
  },
  {
    value: 'PW',
    label: 'Palau',
    image: '/media/flags/palau.svg',
  },
  {
    value: 'PS',
    label: 'Palestinian Territory, Occupied',
    image: '/media/flags/palestine.svg',
  },
  {
    value: 'PA',
    label: 'Panama',
    image: '/media/flags/panama.svg',
  },
  {
    value: 'PG',
    label: 'Papua New Guinea',
    image: '/media/flags/papua-new-guinea.svg',
  },
  {
    value: 'PY',
    label: 'Paraguay',
    image: '/media/flags/paraguay.svg',
  },
  {
    value: 'PE',
    label: 'Peru',
    image: '/media/flags/peru.svg',
  },
  {
    value: 'PH',
    label: 'Philippines',
    image: '/media/flags/philippines.svg',
  },
  {
    value: 'PL',
    label: 'Poland',
    image: '/media/flags/poland.svg',
  },
  {
    value: 'PT',
    label: 'Portugal',
    image: '/media/flags/portugal.svg',
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
    image: '/media/flags/puerto-rico.svg',
  },
  {
    value: 'QA',
    label: 'Qatar',
    image: '/media/flags/qatar.svg',
  },
  {
    value: 'RO',
    label: 'Romania',
    image: '/media/flags/romania.svg',
  },
  {
    value: 'RU',
    label: 'Russian Federation',
    image: '/media/flags/russia.svg',
  },
  {
    value: 'RW',
    label: 'Rwanda',
    image: '/media/flags/rwanda.svg',
  },
  {
    value: 'BL',
    label: 'Saint Barthélemy',
    image: '/media/flags/st-barts.svg',
  },
  {
    value: 'KN',
    label: 'Saint Kitts and Nevis',
    image: '/media/flags/saint-kitts-and-nevis.svg',
  },
  {
    value: 'LC',
    label: 'Saint Lucia',
    image: '/media/flags/st-lucia.svg',
  },
  {
    value: 'MF',
    label: 'Saint Martin (French part)',
    image: '/media/flags/sint-maarten.svg',
  },
  {
    value: 'VC',
    label: 'Saint Vincent and the Grenadines',
    image: '/media/flags/st-vincent-and-the-grenadines.svg',
  },
  {
    value: 'WS',
    label: 'Samoa',
    image: '/media/flags/samoa.svg',
  },
  {
    value: 'SM',
    label: 'San Marino',
    image: '/media/flags/san-marino.svg',
  },
  {
    value: 'ST',
    label: 'Sao Tome and Principe',
    image: '/media/flags/sao-tome-and-prince.svg',
  },
  {
    value: 'SA',
    label: 'Saudi Arabia',
    image: '/media/flags/saudi-arabia.svg',
  },
  {
    value: 'SN',
    label: 'Senegal',
    image: '/media/flags/senegal.svg',
  },
  {
    value: 'RS',
    label: 'Serbia',
    image: '/media/flags/serbia.svg',
  },
  {
    value: 'SC',
    label: 'Seychelles',
    image: '/media/flags/seychelles.svg',
  },
  {
    value: 'SL',
    label: 'Sierra Leone',
    image: '/media/flags/sierra-leone.svg',
  },
  {
    value: 'SG',
    label: 'Singapore',
    image: '/media/flags/singapore.svg',
  },
  {
    value: 'SX',
    label: 'Sint Maarten (Dutch part)',
    image: '/media/flags/sint-maarten.svg',
  },
  {
    value: 'SK',
    label: 'Slovakia',
    image: '/media/flags/slovakia.svg',
  },
  {
    value: 'SI',
    label: 'Slovenia',
    image: '/media/flags/slovenia.svg',
  },
  {
    value: 'SB',
    label: 'Solomon Islands',
    image: '/media/flags/solomon-islands.svg',
  },
  {
    value: 'SO',
    label: 'Somalia',
    image: '/media/flags/somalia.svg',
  },
  {
    value: 'ZA',
    label: 'South Africa',
    image: '/media/flags/south-africa.svg',
  },
  {
    value: 'KR',
    label: 'South Korea',
    image: '/media/flags/south-korea.svg',
  },
  {
    value: 'SS',
    label: 'South Sudan',
    image: '/media/flags/south-sudan.svg',
  },
  {
    value: 'ES',
    label: 'Spain',
    image: '/media/flags/spain.svg',
  },
  {
    value: 'LK',
    label: 'Sri Lanka',
    image: '/media/flags/sri-lanka.svg',
  },
  {
    value: 'SD',
    label: 'Sudan',
    image: '/media/flags/sudan.svg',
  },
  {
    value: 'SR',
    label: 'Suriname',
    image: '/media/flags/suriname.svg',
  },
  {
    value: 'SZ',
    label: 'Swaziland',
    image: '/media/flags/swaziland.svg',
  },
  {
    value: 'SE',
    label: 'Sweden',
    image: '/media/flags/sweden.svg',
  },
  {
    value: 'CH',
    label: 'Switzerland',
    image: '/media/flags/switzerland.svg',
  },
  {
    value: 'SY',
    label: 'Syrian Arab Republic',
    image: '/media/flags/syria.svg',
  },
  {
    value: 'TW',
    label: 'Taiwan, Province of China',
    image: '/media/flags/taiwan.svg',
  },
  {
    value: 'TJ',
    label: 'Tajikistan',
    image: '/media/flags/tajikistan.svg',
  },
  {
    value: 'TZ',
    label: 'Tanzania, United Republic of',
    image: '/media/flags/tanzania.svg',
  },
  {
    value: 'TH',
    label: 'Thailand',
    image: '/media/flags/thailand.svg',
  },
  {
    value: 'TG',
    label: 'Togo',
    image: '/media/flags/togo.svg',
  },
  {
    value: 'TK',
    label: 'Tokelau',
    image: '/media/flags/tokelau.svg',
  },
  {
    value: 'TO',
    label: 'Tonga',
    image: '/media/flags/tonga.svg',
  },
  {
    value: 'TT',
    label: 'Trinidad and Tobago',
    image: '/media/flags/trinidad-and-tobago.svg',
  },
  {
    value: 'TN',
    label: 'Tunisia',
    image: '/media/flags/tunisia.svg',
  },
  {
    value: 'TR',
    label: 'Turkey',
    image: '/media/flags/turkey.svg',
  },
  {
    value: 'TM',
    label: 'Turkmenistan',
    image: '/media/flags/turkmenistan.svg',
  },
  {
    value: 'TC',
    label: 'Turks and Caicos Islands',
    image: '/media/flags/turks-and-caicos.svg',
  },
  {
    value: 'TV',
    label: 'Tuvalu',
    image: '/media/flags/tuvalu.svg',
  },
  {
    value: 'UG',
    label: 'Uganda',
    image: '/media/flags/uganda.svg',
  },
  {
    value: 'UA',
    label: 'Ukraine',
    image: '/media/flags/ukraine.svg',
  },
  {
    value: 'AE',
    label: 'United Arab Emirates',
    image: '/media/flags/united-arab-emirates.svg',
  },
  {
    value: 'GB',
    label: 'United Kingdom',
    image: '/media/flags/united-kingdom.svg',
  },
  {
    value: 'US',
    label: 'United States',
    image: '/media/flags/united-states.svg',
  },
  {
    value: 'UY',
    label: 'Uruguay',
    image: '/media/flags/uruguay.svg',
  },
  {
    value: 'UZ',
    label: 'Uzbekistan',
    image: '/media/flags/uzbekistan.svg',
  },
  {
    value: 'VU',
    label: 'Vanuatu',
    image: '/media/flags/vanuatu.svg',
  },
  {
    value: 'VE',
    label: 'Venezuela, Bolivarian Republic of',
    image: '/media/flags/venezuela.svg',
  },
  {
    value: 'VN',
    label: 'Vietnam',
    image: '/media/flags/vietnam.svg',
  },
  {
    value: 'VI',
    label: 'Virgin Islands',
    image: '/media/flags/virgin-islands.svg',
  },
  {
    value: 'YE',
    label: 'Yemen',
    image: '/media/flags/yemen.svg',
  },
  {
    value: 'ZM',
    label: 'Zambia',
    image: '/media/flags/zambia.svg',
  },
  {
    value: 'ZW',
    label: 'Zimbabwe',
    image: '/media/flags/zimbabwe.svg',
  },
]
