import React, { useEffect, useState } from "react";
import { WaitButtonSpan } from "../../../../components/WaitButtonSpan";
import { toAbsoluteUrl } from "../../../../_oxapay/helpers";
import { useFormik } from "formik";
import { FailedModalFire } from "../../../services/failedSwalFire";
import { useAuth } from "../core/Auth";
import { generateToken } from "../../../services/ReCaptchaGenerator";
import {
  getUserByToken,
  resendEmailByToken,
  verifyRegisterEmail,
} from "../core/_requests";
import { useNavigate, useParams } from "react-router-dom";
import {
  alertsMapResendEmailByToken,
  alertsMapVerifyEmail,
} from "../../../services/alertsMap";
import { errorHandler } from "../../../services/errorHandler";
import { SuccessModalFire } from "../../../services/successSwalFire";

export const VerifyEmail = () => {
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const { saveAuth, setCurrentUser, logout } = useAuth();
  const { token } = useParams();
  const navigate = useNavigate();

  const resendEmail = async () => {
    setLoadingResend(true);
    try {
      const resendToken = await generateToken("verify_email_send_byToken");
      const { data: res } = await resendEmailByToken(
        token as string,
        resendToken
      );
      if (res.status === 200 && res.result === true) {
        SuccessModalFire("New verification email was sent");
      } else if (res.status === 0 && res.errorNum) {
        FailedModalFire(alertsMapResendEmailByToken[res.errorNum.toString()]);
      }
    } catch (error) {
      errorHandler(error as Error);
    } finally {
      setLoadingResend(false);
    }
  };

  const verfiyEmailFunction = async () => {
    logout();
    setLoading(true);
    try {
      const tokenRegister = await generateToken("verify_email");
      const { data: auth } = await verifyRegisterEmail(
        token as string,
        tokenRegister
      );

      if (auth.status === 200) {
        saveAuth(auth);
        const { data: user } = await getUserByToken(auth.apiToken);

        if (user.status === 200) {
          setCurrentUser(user);
          navigate("/");
        } else {
          FailedModalFire("Something went wrong to verify, please try again");
        }
      } else {
        FailedModalFire(alertsMapVerifyEmail[auth.errorNum.toString()]);
      }
    } catch (error) {
      errorHandler(error as Error);
      saveAuth(undefined);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    verfiyEmailFunction();
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      verfiyEmailFunction();
    },
  });

  return (
    <form className="form w-100 mb-13 p-10" onSubmit={formik.handleSubmit}>
      <div className="text-center mb-10">
        <img
          alt="Logo"
          className="mh-200px"
          src={toAbsoluteUrl("/media/auth/please-verify-your-email.png")}
        />
      </div>
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Verify your email</h1>
        <div className="fs-6 mb-8">
          <span className="fw-semibold text-gray-500">
            Did’t receive an email?
          </span>
          <button
            className="btn px-1 link-primary fw-bold"
            onClick={resendEmail}
            disabled={loadingResend}
          >
            {loadingResend ? "Please wait" : "Resend Email"}
          </button>
        </div>
      </div>
      <div className="d-grid mb-10">
        <button
          type="submit"
          className="btn btn-primary w-100"
          disabled={loading}
        >
          {loading ? <WaitButtonSpan /> : "Verify Email"}
        </button>
      </div>
    </form>
  );
};
