import { createContext, useState, ReactNode } from "react";

export interface Paymentlink {
  id: string;
  name: string;
  price: string;
  priceCurrency: string;
  feePaidByPayer: string;
  underPaid: string;
  description: string;
  url: string;
  date: string;
  coins: string[];
}

interface PaymentlinksPage {
  page: number;
  pages: number;
}

export interface PaymentlinkContextModel {
  paymentlinks?: Paymentlink[];
  setPaymentlinks: (paymentlinks: Paymentlink[]) => void;
  paymentlinksPage: PaymentlinksPage;
  setPaymentlinksPage: (paymentlinksPage: PaymentlinksPage) => void;
  paymentlinksSearch: string;
  setPaymentlinksSearch: (paymentlinksSearch: string) => void;
}

export const PaymentlinkContext = createContext<PaymentlinkContextModel>({
  paymentlinks: undefined,
  setPaymentlinks: () => {},
  paymentlinksPage: {
    page: 1,
    pages: 0,
  },
  setPaymentlinksPage: () => {},
  paymentlinksSearch: "",
  setPaymentlinksSearch: () => {},
});

interface PaymentlinkProviderProps {
  children: ReactNode;
}

const PaymentlinkProvider: React.FC<PaymentlinkProviderProps> = ({
  children,
}) => {
  const [paymentlinks, setPaymentlinks] = useState<Paymentlink[] | undefined>(
    undefined
  );
  const [paymentlinksPage, setPaymentlinksPage] = useState({
    page: 1,
    pages: 0,
  });
  const [paymentlinksSearch, setPaymentlinksSearch] = useState("");

  return (
    <PaymentlinkContext.Provider
      value={{
        paymentlinks,
        setPaymentlinks,
        paymentlinksPage,
        setPaymentlinksPage,
        paymentlinksSearch,
        setPaymentlinksSearch,
      }}
    >
      {children}
    </PaymentlinkContext.Provider>
  );
};

export default PaymentlinkProvider;
