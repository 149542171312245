import axios from "axios";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useAuth } from "../app/modules/auth";
import { errorHandler } from "../app/services/errorHandler";
import { walletPortfoInterval } from "../app/services/intervalManage";
import { useRequest } from "../app/services/userHttpRequests";
import { IconArrow } from "./staticIcons/IconArrow";
import { TextCopyWidget } from "./TextCopyWidget";

const popover = (
  <Popover>
    <Popover.Header as="h3">Your OxaPay wallet address</Popover.Header>
    <Popover.Body>
      You can use OxaPay wallet address to
      <strong> transfer crypto assets with zero fee</strong>.
    </Popover.Body>
  </Popover>
);

export const WalletHeader = () => {
  const [fetching, setFetching] = useState(true);
  const { currentUser } = useAuth();
  const { userPortfolio } = useRequest();
  const [portfo, setPortfo] = useState({
    amount: 0,
    changeAmount: 0,
    changePercent: 0,
  });

  useEffect(() => {
    const controller = new AbortController();

    const fetchPortfo = async () => {
      try {
        const response = await userPortfolio({ signal: controller.signal });
        if (response.status === 200) {
          const portfoData = response.data;
          setFetching(false);
          setPortfo(portfoData);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };
    fetchPortfo();
    const interval = setInterval(fetchPortfo, walletPortfoInterval);

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval on component unmount
    };

    // eslint-disable-next-line
  }, []);

  const changeColor = portfo.changePercent < 0 ? "danger" : "success";
  const changeSymbol = portfo.changePercent < 0 ? "-" : "+";

  return (
    <div className="d-flex flex-column flex-center wallet-header">
      <div className="mt-10 mb-12">
        <div className="card-title d-flex flex-column">
          <div className="d-flex justify-content-center align-items-center">
            {fetching ? (
              <div className="h-35px w-100px bg-secondary rounded shine"></div>
            ) : (
              <>
                <span className="fs-4 fw-semibold text-dark-400 me-1 align-self-start">
                  $
                </span>
                <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                  {Number(portfo.amount).toLocaleString()}
                </span>
              </>
            )}
          </div>
          <span className="text-gray-400 text-center pt-1 fw-semibold fs-6">
            Portfolio value
          </span>
          {fetching ? (
            <div className="d-flex justify-content-center align-items-center mt-3">
              <div className="h-20px w-80px bg-secondary rounded shine mx-auto"></div>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center mt-3">
              <span
                className={`fs-4 fw-semibold text-${changeColor} align-self-start`}
              >
                {changeSymbol} $
              </span>
              <span
                className={`fs-4 fw-fw-semibold text-${changeColor} me-2 lh-1`}
              >
                {portfo.changeAmount}
              </span>
              <span className={`badge badge-light-${changeColor} fs-base`}>
                <span
                  className={`svg-icon svg-icon-5 svg-icon-${changeColor} ms-n1`}
                >
                  <IconArrow
                    className={portfo.changePercent < 0 ? "rotate-180" : ""}
                  />
                </span>
                {portfo.changePercent}%
              </span>
            </div>
          )}

          <p className="text-gray-700 mt-5 text-center fw-semibold fs-6 py-2 rounded m-0 cursor-pointer">
            <OverlayTrigger placement="top-start" overlay={popover}>
              <span className="badge badge-circle badge-sm badge-outline badge-primary cursor-pointer me-1">
                i
              </span>
            </OverlayTrigger>
            <TextCopyWidget
              oxaAddress={true}
              qrCode={true}
              text={`${currentUser?.wallet as string}`}
            />
          </p>
        </div>
      </div>
    </div>
  );
};
