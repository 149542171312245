import React from "react";
import { toAbsoluteUrl } from "../_oxapay/helpers";

export const SocialMedias = () => {
  return (
    <div className="app-engage bg-primary rounded me-3 p-3" id="kt_app_engage">
      <a
        href="https://x.com/OxaPay"
        target="_blank"
        rel="noreferrer"
        className="app-engage-btn text-white py-1 m-0"
      >
        <span className="svg-icon svg-icon-1 pt-1">
          <img
            src={toAbsoluteUrl(`/media/svg/brand-logos/x-white.svg`)}
            className="w-25px mb-2"
            alt="X Logo"
          />
        </span>
      </a>
      <hr className="w-100 text-white my-2" />
      <a
        href="https://www.telegram.me/OxaPay"
        target="_blank"
        rel="noreferrer"
        className="app-engage-btn text-white py-1 m-0"
      >
        <span className="svg-icon svg-icon-1 pt-1">
          <img
            src={toAbsoluteUrl(`/media/svg/brand-logos/telegram-white.svg`)}
            className="w-25px mb-1"
            alt="OxaPay telegram"
          />
        </span>
      </a>
      <hr className="w-100 text-white my-2" />
      <a
        href="https://medium.com/@oxapay"
        target="_blank"
        rel="noreferrer"
        className="app-engage-btn text-white py-1 m-0"
      >
        <span className="svg-icon svg-icon-1 pt-1">
          <img
            src={toAbsoluteUrl(`/media/svg/brand-logos/medium-white.svg`)}
            className="w-25px mb-1"
            alt="OxaPay medium"
          />
        </span>
      </a>
      <hr className="w-100 text-white my-2" />
      <a
        href="https://oxapay.com/blog"
        target="_blank"
        rel="noreferrer"
        className="app-engage-btn text-white py-1 m-0"
      >
        <span className="svg-icon svg-icon-1 pt-1">
          <img
            src={toAbsoluteUrl(`/media/svg/brand-logos/blog.svg?v=1`)}
            className="w-25px mb-1"
            alt="OxaPay blog"
          />
        </span>
      </a>
    </div>
  );
};
