import Swal from "sweetalert2";

type PreConfirmFunction = () => Promise<void>;

export const DeleteSwalFire = async (preConfirm?: PreConfirmFunction) => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-light-danger",
      cancelButton: "btn btn-light",
    },
    buttonsStyling: false,
  });

  const result = await swalWithBootstrapButtons.fire({
    title: "Are you sure to delete?",
    icon: "question",
    padding: "10px 10px 20px",
    width: "500",
    iconColor: "#4c9c74",
    showCancelButton: true,
    showCloseButton: false, // Hide the close button
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete",
    cancelButtonText: "No, cancel",
    reverseButtons: true,
    showLoaderOnConfirm: true,
    backdrop: true,
    background: "var(--kt-card-bg)",
    preConfirm: preConfirm,
    allowOutsideClick: () => !Swal.isLoading(),
  });

  return result;
};
