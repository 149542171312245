import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { errorHandler } from "../app/services/errorHandler";
import { formatAmountNumber } from "../app/services/functionServices";
import {
  swapRateInterval,
  walletBalanceInterval,
} from "../app/services/intervalManage";
import { useRequest } from "../app/services/userHttpRequests";
import usePrice from "../hooks/usePrice";
import { toAbsoluteUrl } from "../_oxapay/helpers";
import { AppContext } from "../_oxapay/layout/core/AppContext";
import SelectWithImage from "./SelectWithImage";

export interface OptionModel {
  label: string;
  value: string;
  image: string;
}

export interface ValuesModel {
  fromAmount: string;
  toAmount: string;
  fromCurrency: string;
  toCurrency: string;
}

interface props {
  pairsData: Record<string, { min: string; pair: string }[]>;
  onConfirm: (values: ValuesModel) => void;
  fromCurrencyChange: (fromCurrency: string | undefined) => void;
  toCurrencyChange: (toCurrency: string | undefined) => void;
  showOptionsStatus: (status: boolean) => void;
  resetFormTrigger: number;
}

export const SwapStepOne = ({
  pairsData,
  onConfirm,
  fromCurrencyChange,
  toCurrencyChange,
  showOptionsStatus,
  resetFormTrigger,
}: props) => {
  const { coinsPrice } = usePrice();
  const [toIsOpen, setToIsOpen] = useState<boolean>(false);
  const [fromIsOpen, setFromIsOpen] = useState<boolean>(false);
  const { coinsListMap } = useContext(AppContext);
  const { getBalance, getSwapRate } = useRequest();
  const [min, setMin] = useState(0);
  const [swapRate, setSwapRate] = useState<number | undefined>(undefined);
  const [showError, setShowError] = useState(false);
  const [balance, setBalance] = useState<Record<string, string>>();
  const [balanceLoading, setBalanceLoading] = useState(true);
  const [swapRateLoading, setSwapRateLoading] = useState(false);
  const [rotate, setRotate] = useState(false);
  const [fromOptions, setFromOptions] = useState<OptionModel[]>([]);
  const [toOptions, setToOptions] = useState<OptionModel[]>([]);
  const [selectedFrom, setSelectedFrom] = useState<string | undefined>();
  const [selectedTo, setSelectedTo] = useState<string | undefined>();

  const formik = useFormik({
    initialValues: {
      fromAmount: "",
      toAmount: "",
      fromCurrency: "",
      toCurrency: "",
    },
    validationSchema: Yup.object({
      fromAmount: Yup.number()
        .min(min, `Minimum amount is ${min}`)
        .max(
          balance && selectedFrom ? Number(balance[selectedFrom]) : Infinity,
          "Your balance is insufficient"
        )
        .required("Amount is required"),
      toAmount: Yup.number().required(),
      fromCurrency: Yup.string().required("Select currency"),
      toCurrency: Yup.string().required("Select currency"),
    }),
    onSubmit: async (values) => {
      onConfirm(values);
    },
  });

  const getOptions = (pairs: string[]): OptionModel[] => {
    return pairs.map((pair) => ({
      label: pair,
      value: pair,
      image: `/media/svg/coins/${coinsListMap[pair]?.slug}.svg`,
    }));
  };

  const getMinimumValue = (selectedFrom: string, selectedTo: string) => {
    const pairInfo = pairsData[selectedFrom]?.find(
      (pair) => pair.pair === selectedTo
    );

    if (pairInfo) {
      return pairInfo.min;
    } else {
      return "Default"; // or any default value
    }
  };

  useEffect(() => {
    if (resetFormTrigger > 0) {
      setSelectedFrom(undefined);
      setSelectedTo(undefined);
      const coins = Object.keys(pairsData);
      setFromOptions(getOptions(coins));
      setToOptions(getOptions(coins));
      formik.resetForm();
      setShowError(false);
    }
    // eslint-disable-next-line
  }, [resetFormTrigger]);

  useEffect(() => {
    fromIsOpen || toIsOpen ? showOptionsStatus(true) : showOptionsStatus(false);
    // eslint-disable-next-line
  }, [fromIsOpen, toIsOpen]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchBalance = async () => {
      try {
        const response = await getBalance({ signal: controller.signal });
        if (response.status === 200) {
          const balanceData = response.data;
          setBalanceLoading(false);
          setBalance(balanceData);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };

    fetchBalance();
    const interval = setInterval(fetchBalance, walletBalanceInterval);

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval on component unmount
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const coins = Object.keys(pairsData);
    setFromOptions(getOptions(coins));
    setToOptions(getOptions(coins));
    // eslint-disable-next-line
  }, [coinsListMap]);

  useEffect(() => {
    //props need
    fromCurrencyChange(selectedFrom);
    toCurrencyChange(selectedTo);

    //define minimum from amount
    selectedFrom && selectedTo
      ? setMin(Number(getMinimumValue(selectedFrom, selectedTo)))
      : setMin(0);

    //update toCurrency options when fromCurrency change
    if (selectedFrom) {
      const selectedPairs = pairsData[selectedFrom]?.map(
        (details) => details.pair
      );
      setToOptions(getOptions(selectedPairs));
    }

    //update fromCurrency options when toCurrency change
    if (selectedTo) {
      const selectedPairs = pairsData[selectedTo]?.map(
        (details) => details.pair
      );
      setFromOptions(getOptions(selectedPairs));
    }

    // eslint-disable-next-line
  }, [selectedFrom, selectedTo, coinsListMap]);

  useEffect(() => {
    if (selectedFrom && selectedTo) {
      setSwapRateLoading(true);
    }
    const controller = new AbortController();
    const fetchRate = () => {
      if (selectedFrom && selectedTo) {
        getSwapRate(selectedFrom, selectedTo, { signal: controller.signal })
          .then((res) => {
            if (res.status === 200) {
              setSwapRateLoading(false);
              setSwapRate(res.data.rate);
            }
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              return;
            }
            errorHandler(error as Error);
          });
      } else {
        setSwapRate(undefined);
      }
    };

    fetchRate();
    const interval = setInterval(fetchRate, swapRateInterval);

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval on component unmount
    };
    // eslint-disable-next-line
  }, [selectedFrom, selectedTo]);

  useEffect(() => {
    formik.values.fromAmount !== "" &&
      swapRate &&
      selectedTo &&
      formik.setFieldValue(
        "toAmount",
        formatAmountNumber(
          Number(formik.values.fromAmount) * swapRate,
          coinsListMap[selectedTo]?.displayPrecision
        )
      );
    // eslint-disable-next-line
  }, [swapRate]);

  const minHandler = () => {
    if (selectedTo && swapRate && balance && selectedFrom) {
      formik.setFieldValue(
        "toAmount",
        formatAmountNumber(
          min * swapRate,
          coinsListMap[selectedTo]?.displayPrecision
        )
      );
    }
    min && formik.setFieldValue("fromAmount", min);
  };

  const maxHandler = () => {
    if (balance && selectedFrom) {
      if (selectedTo && swapRate) {
        formik.setFieldValue(
          "toAmount",
          formatAmountNumber(
            parseFloat(balance[selectedFrom]) * swapRate,
            coinsListMap[selectedTo]?.displayPrecision
          )
        );
      }
      formik.setFieldValue("fromAmount", balance[selectedFrom]);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault(); // Prevent default form submission
        setShowError(true); // Set showError to true
        formik.handleSubmit(); // Handle Formik submission
      }}
    >
      <div className="border border-gray-300 rounded px-5 py-4 mb-4">
        <div className="d-flex justify-content-between align-items-center mb-5">
          <h4 className="fs-7 fw-semibold text-gray-800 mb-0">FROM</h4>
          <div className="d-flex flex-row">
            <span
              className="input-group-text text-primary bg-light-secondary border-0 cursor-pointer px-3 py-1 me-1"
              onClick={minHandler}
            >
              Min
            </span>
            <span
              className="input-group-text text-primary bg-light-secondary border-0 cursor-pointer px-3 py-1"
              onClick={maxHandler}
            >
              Max
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-1">
          <input
            className="fs-1 swap-input w-100 text-gray-700 fw-semibold swap-input-width"
            type="number"
            name="fromAmount"
            autoComplete="off"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              if (swapRate) {
                e.currentTarget.value !== "" && selectedTo
                  ? formik.setFieldValue(
                      "toAmount",
                      formatAmountNumber(
                        parseFloat(e.currentTarget.value) * swapRate,
                        coinsListMap[selectedTo]?.displayPrecision
                      )
                    )
                  : formik.setFieldValue("toAmount", "");
              }
              formik.handleChange(e);
            }}
            value={formik.values.fromAmount}
            placeholder={min !== 0 ? `min: ${min}` : "Amount"}
          />
          <div className="w-130px">
            <SelectWithImage
              showOptionsStatus={(status) => setFromIsOpen(status)}
              search={false}
              defaultValue={"Select"}
              options={fromOptions}
              onChange={(value) => {
                setSelectedFrom(value);
                formik.setFieldValue("fromCurrency", value);
              }}
              value={selectedFrom}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="fs-8 text-gray-600 m-0">
            {(coinsPrice &&
              selectedFrom &&
              Number(formik.values.fromAmount) &&
              `=$${(
                coinsPrice[selectedFrom].price *
                Number(formik.values.fromAmount)
              ).toLocaleString()}`) ||
              ""}
          </p>
          {selectedFrom && balanceLoading ? (
            <div className="h-15px w-100px bg-secondary rounded shine"></div>
          ) : (
            <p className="fs-8 text-gray-600 m-0">
              {balance && selectedFrom && `Balance: ${balance[selectedFrom]}`}
            </p>
          )}
        </div>

        {formik.errors.fromAmount && formik.touched.fromAmount && (
          <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
            {formik.errors.fromAmount}
          </p>
        )}
        {formik.errors.fromCurrency && showError && showError && (
          <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
            {formik.errors.fromCurrency}
          </p>
        )}
      </div>
      <div className="position-relative">
        <span
          className={`position-absolute w-40px h-40px input-group-text text-primary bg-light-secondary border-0 cursor-pointer px-3 d-flex justify-content-center align-items-center ${
            rotate ? "rotate-180-animate" : "rotate-0-animate"
          }`}
          onClick={() => {
            if (selectedFrom && selectedTo) {
              setSelectedFrom(selectedTo);
              setSelectedTo(selectedFrom);
              setFromOptions(toOptions);
              setToOptions(fromOptions);
            }
            setRotate((prevRotate) => !prevRotate);
          }}
          style={{ top: "-25px", left: "44%" }}
        >
          <img
            src={toAbsoluteUrl("/media/svg/icons/swap-light.svg")}
            alt={"swap icon"}
          />
        </span>
      </div>
      <div className="border border-gray-300 rounded px-5 py-4 mb-2">
        <h4 className="fs-7 fw-semibold text-gray-800 mb-5">TO</h4>
        <div className="d-flex justify-content-between align-items-center mb-1">
          {swapRateLoading ? (
            <div className="h-40px w-130px bg-secondary rounded shine"></div>
          ) : (
            <input
              className="fs-1 swap-input w-100 text-gray-700 fw-semibold swap-input-width"
              name="toAmount"
              type={"number"}
              autoComplete="off"
              onChange={(e) => {
                if (swapRate) {
                  e.currentTarget.value !== "" && selectedFrom
                    ? formik.setFieldValue(
                        "fromAmount",

                        formatAmountNumber(
                          parseFloat(e.currentTarget.value) / swapRate,
                          coinsListMap[selectedFrom]?.displayPrecision
                        )
                      )
                    : formik.setFieldValue("fromAmount", "");
                }
                formik.handleChange(e);
              }}
              value={formik.values.toAmount}
              placeholder={"To Amount"}
            />
          )}

          <div className="w-130px">
            <SelectWithImage
              showOptionsStatus={(status) => setToIsOpen(status)}
              search={false}
              defaultValue={"Select"}
              options={toOptions}
              onChange={(value) => {
                setSelectedTo(value);
                formik.setFieldValue("toCurrency", value);
              }}
              value={selectedTo}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="fs-8 text-gray-600 m-0">
            {(coinsPrice &&
              selectedTo &&
              formik.values.toAmount &&
              `=$${(
                coinsPrice[selectedTo].price *
                parseFloat(formik.values.toAmount)
              ).toLocaleString()}`) ||
              ""}
          </p>
          {selectedTo && balanceLoading ? (
            <div className="h-15px w-100px bg-secondary rounded shine"></div>
          ) : (
            <p className="fs-8 text-gray-600 m-0">
              {balance && selectedTo && `Balance: ${balance[selectedTo]}`}
            </p>
          )}
        </div>
        {formik.errors.toCurrency && showError && (
          <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
            {formik.errors.toCurrency}
          </p>
        )}
      </div>
      {swapRateLoading ? (
        <div className="h-20px w-100px bg-secondary rounded shine mb-2"></div>
      ) : (
        swapRate && (
          <p className="fs-7 fw-semibold text-gray-600 mb-2 h-20px">
            1 {selectedFrom} = {swapRate} {selectedTo}
          </p>
        )
      )}

      <div className="d-flex align-items-end w-100 mt-5">
        <button type="submit" className="btn btn-primary fs-3 w-100">
          Swap
        </button>
      </div>
    </form>
  );
};
