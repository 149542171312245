import { createContext, useState } from "react";
import { TransferInvoiceReceipt } from "../../../components/modals/TransferInvoiceReceipt";
import { WithChildren } from "../../helpers";

export interface TxData {
  address: string;
  amount: string;
  coin: string;
  date: string;
  fee: string;
  id: string;
  network: string;
  note: string;
  internal?: boolean;
  status: string;
  txId: string;
  txUrl: string;
}

interface Coin {
  [symbol: string]: {
    symbol: string;
    network: string;
    withdrawMin: number;
    withdrawMax: number;
    withdrawFee: number;
    withdrawEnable: boolean;
    memoEnable: boolean;
    suggest?: string;
  }[];
}

interface StepTwoOptions {
  address: string;
  coins: Coin;
  intenal: boolean;
}

interface StepThreeOptions {
  memo?: string;
  address: string;
  amount: string;
  coin: string;
  fee: number;
  network: string;
  requestToken: string;
  source: string;
  twoFa: boolean;
  emailconfirmation: boolean;
}

export interface SendContextModel {
  stepTwoOptions?: StepTwoOptions;
  setStepTwoOptions: (_stepTwoOptions: StepTwoOptions | undefined) => void;
  stepThreeOptions?: StepThreeOptions | undefined;
  setStepThreeOptions: (
    _stepThreeOptions: StepThreeOptions | undefined
  ) => void;
  setShowSuccessModal: (_showSuccessModal: boolean) => void;
  setTxData: (_txData: TxData | undefined) => void;
}

export const SendContext = createContext<SendContextModel>({
  stepTwoOptions: undefined,
  setStepTwoOptions: (_stepTwoOptions?: StepTwoOptions | undefined) => {},
  stepThreeOptions: undefined,
  setStepThreeOptions: (_stepThreeOptions?: StepThreeOptions | undefined) => {},
  setShowSuccessModal: (_showSuccessModal: boolean) => {},
  setTxData: (_txData?: TxData | undefined) => {},
});

const SendProvider: React.FC<WithChildren> = ({ children }) => {
  const [stepTwoOptions, setStepTwoOptions] = useState<
    StepTwoOptions | undefined
  >(undefined);
  const [stepThreeOptions, setStepThreeOptions] = useState<
    StepThreeOptions | undefined
  >(undefined);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [txData, setTxData] = useState<TxData | undefined>(undefined);

  const value = {
    setTxData,
    setShowSuccessModal,
    stepTwoOptions,
    setStepTwoOptions,
    stepThreeOptions,
    setStepThreeOptions,
  };

  return (
    <SendContext.Provider value={value}>
      {children}
      {showSuccessModal && (
        <TransferInvoiceReceipt txData={txData} show={showSuccessModal} />
      )}
    </SendContext.Provider>
  );
};

export default SendProvider;
