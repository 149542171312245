export const loadTelegramWidgetScript = (callback: any) => {
  const existingScript = document.getElementById("telegramWidgetScript");

  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js?22";
    script.id = "telegramWidgetScript";
    script.setAttribute("data-telegram-login", "OxaPayWalletBot");
    script.setAttribute("data-size", "large");
    script.setAttribute("data-userpic", "true");
    script.setAttribute("data-radius", "5");
    script.setAttribute("data-width", "100");
    script.setAttribute("data-onauth", "onTelegramAuth(user)");
    script.setAttribute("data-request-access", "write");
    const onTelegramAuth = (user: any) => {
      callback(user);
    };
    script.onload = () => {
      (window as any).onTelegramAuth = onTelegramAuth;
    };
    const divElement = document.getElementById("widget_container");
    if (divElement) {
      divElement.appendChild(script);
    } else {
      document.body.appendChild(script);
    }
  } else if (callback) {
    callback();
  }

  return () => {
    const scriptElement = document.getElementById("telegramWidgetScript");
    if (scriptElement) {
      scriptElement.remove();
      delete (window as any).onTelegramAuth;
    }
  };
};
