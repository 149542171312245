import { createContext, useState, ReactNode } from "react";

export interface DonationButton {
  id: string;
  name: string;
  fields: {
    name: { show: boolean; require: boolean };
    email: { show: boolean; require: boolean };
  };
  coins: string[];
  url: string;
  date: string;
}

interface DonationButtonsPage {
  page: number;
  pages: number;
}

export interface DonationButtonContextModel {
  donationButton?: DonationButton[];
  setDonationButton: (donationButton: DonationButton[]) => void;
  donationButtonsPage: DonationButtonsPage;
  setDonationButtonsPage: (donationButtonsPage: DonationButtonsPage) => void;
  donationButtonsSearch: string;
  setDonationButtonsSearch: (donationButtonsSearch: string) => void;
}

export const DonationButtonContext = createContext<DonationButtonContextModel>({
  donationButton: undefined,
  setDonationButton: () => {},
  donationButtonsPage: { page: 1, pages: 0 },
  setDonationButtonsPage: () => {},
  donationButtonsSearch: "",
  setDonationButtonsSearch: () => {},
});

interface DonationButtonProviderProps {
  children: ReactNode;
}

const DonationButtonProvider: React.FC<DonationButtonProviderProps> = ({
  children,
}) => {
  const [donationButton, setDonationButton] = useState<
    DonationButton[] | undefined
  >(undefined);
  const [donationButtonsPage, setDonationButtonsPage] = useState({
    page: 1,
    pages: 0,
  });
  const [donationButtonsSearch, setDonationButtonsSearch] = useState("");

  return (
    <DonationButtonContext.Provider
      value={{
        donationButton,
        setDonationButton,
        donationButtonsPage,
        setDonationButtonsPage,
        donationButtonsSearch,
        setDonationButtonsSearch,
      }}
    >
      {children}
    </DonationButtonContext.Provider>
  );
};

export default DonationButtonProvider;
