import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { SwapContext } from "../../app/Core/SwapContext";
import { errorHandler } from "../../app/services/errorHandler";
import { DateTime, formatAmount } from "../../app/services/functionServices";
import { useRequest } from "../../app/services/userHttpRequests";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { AmountCurrency } from "../AmountCurrency";
import { SwapHistoryFilterCard } from "../filters/SwapHistoryFilterCard";
import { TablePlaceholder } from "../TablePlaceholder";
import { TablesWidget } from "../TableWidget";

export const SwapHistoryTable = () => {
  const { swapHistory } = useRequest();
  const {
    swaps,
    setSwaps,
    swapsPage,
    setSwapsPage,
    swapsFilter,
    setSwapsFilter,
  } = useContext(SwapContext);
  const { coinsListMap } = useContext(AppContext);
  const [swapsLoading, setSwapsLoading] = useState(true);

  useEffect(() => {
    setSwapsLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    swapHistory(swapsFilter, swapsPage.page, { signal })
      .then((res) => {
        if (res.status === 200) {
          setSwaps(res.data);
          setSwapsPage(res.meta);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      })
      .finally(() => {
        setSwapsLoading(false);
      });

    return () => controller.abort();
    // eslint-disable-next-line
  }, [swapsFilter, swapsPage.page]);

  return (
    <>
      <TablesWidget
        title="Your swap history"
        className="mb-5 mb-xl-10"
        tableHead={["From amount", "Received amount", "Type", "Rate", "Date"]}
        size={[150, 150, 100, 150, null]}
        loading={swapsLoading}
        noData={swaps?.length === 0 && !swapsLoading ? true : false}
        activePage={swapsPage.page}
        pages={swapsPage.pages}
        pageChange={(page) => setSwapsPage({ ...swapsPage, page: page })}
        filter
        filterPlace="bottom"
        filterCard={
          <SwapHistoryFilterCard
            onChange={(newFilter) => {
              if (JSON.stringify(newFilter) !== JSON.stringify(swapsFilter)) {
                setSwapsFilter(newFilter);
                setSwapsLoading(true);
              }
            }}
          />
        }
      >
        {swapsLoading === true && <TablePlaceholder config={[10, 5]} />}
        {!swapsLoading &&
          swaps !== undefined &&
          swaps.map((data) => (
            <tr key={data.id}>
              <td>
                <AmountCurrency
                  amount={data.fromAmount}
                  currency={data.fromCurrency}
                />
              </td>
              <td>
                <AmountCurrency
                  amount={data.toAmount}
                  currency={data.toCurrency}
                  color="text-dark"
                />
              </td>
              <td>
                {data.type === "swapByApi" ? (
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="top"
                    overlay={
                      <Popover>
                        <Popover.Body>API Name: {data?.apiName}</Popover.Body>
                      </Popover>
                    }
                  >
                    <span className=" badge badge-light-primary fw-bold fs-6">
                      Swap By API
                    </span>
                  </OverlayTrigger>
                ) : (
                  <span className=" badge badge-light-primary fw-bold fs-6">
                    {data.type === "autoConvert"
                      ? "Auto Convert"
                      : data.type === "manualSwap"
                      ? "Manual Swap"
                      : ""}
                  </span>
                )}
              </td>
              <td>
                <span className="text-gray-600 fw-bold fs-6">
                  1 {data.fromCurrency} ={" "}
                  {formatAmount(
                    parseFloat(data.rate),
                    coinsListMap[data.toCurrency]?.displayPrecision
                  )}{" "}
                  {data.toCurrency}
                </span>
              </td>
              <td>
                <span className="text-gray-600 fw-bold fs-6">
                  {DateTime(parseInt(data.date))}
                </span>
              </td>
            </tr>
          ))}
      </TablesWidget>
    </>
  );
};
