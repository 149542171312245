import React from "react";

export const SkeletonItem = () => {
  return (
    <div className="timeline-item">
      <div className="timeline-line w-40px"></div>

      <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
        <div className="symbol-label bg-secondary shine"></div>
      </div>

      <div className="mb-10 mt-n1 w-100">
        <div className="pe-3 mb-5">
          <div className="h-25px w-100px bg-secondary rounded shine mb-2"></div>

          <div className="d-flex align-items-center mt-1 fs-6">
            <div className="h-25px w-150px bg-secondary rounded shine"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
