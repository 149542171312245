/* eslint-disable jsx-a11y/anchor-is-valid */
import { DateTime } from "../../app/services/functionServices";
import { KTSVG } from "../../_oxapay/helpers";

interface props {
  data: {
    email: string;
  };
  date: string;
}

const SetEmailPassword = ({ data, date }: props) => {
  return (
    <div className="timeline-item">
      <div className="timeline-line w-40px"></div>

      <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
        <div className="symbol-label bg-light-warning">
          <KTSVG
            path="/media/icons/duotune/coding/cod001.svg"
            className="svg-icon-1 svg-icon-warning"
          />
        </div>
      </div>

      <div className="mb-10 mt-n1 w-100">
        <div className="pe-3 mb-5">
          <div className="fs-5 fw-bold mb-2">
            Email and pasword was set for the account
          </div>
          <div className="d-flex align-items-center mt-1 fs-6">
            <div className="text-muted me-2 fs-7">
              {DateTime(parseInt(date))}
            </div>
          </div>
          <div className="d-flex align-items-center mt-1 fs-6">
            <div className="text-muted me-2 fs-6">{data.email}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SetEmailPassword };
