import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { GeneralApiContext } from "../../app/Core/GeneralApiContext";
import { alertsMapDeleteItem } from "../../app/services/alertsMap";
import { DeleteSwalFire } from "../../app/services/deleteSwalFire";
import { errorHandler } from "../../app/services/errorHandler";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { SuccessModalFire } from "../../app/services/successSwalFire";
import { useRequest } from "../../app/services/userHttpRequests";
import { ModalContext } from "../../_oxapay/layout/core/ModalContext";
import { GeneralApiKeyForm } from "../forms/GeneralApiKeyForm";
import { ModalBodyTitle } from "../modals/ModalBodyTitle";
import { TableBodyLink } from "../TableBodyLink";
import { TableDeleteButton } from "../TableDeleteButton";
import { TableEditButton } from "../TableEditButton";
import { TablePlaceholder } from "../TablePlaceholder";
import { TableSearch } from "../TableSearch";
import { TablesWidget } from "../TableWidget";
import { TextListWidget } from "../TextListWidget";

export const GeneralApiTable = () => {
  const { setComponent, setModalWidth, setShowModal } =
    useContext(ModalContext);
  const { listGeneralApi, deleteGeneralApi } = useRequest();
  const {
    generalApi,
    setGeneralApi,
    generalApiPage,
    setGeneralApiPage,
    generalApiSearch,
    setGeneralApiSearch,
  } = useContext(GeneralApiContext);
  const [generalApiLoading, setGeneralApiLoading] = useState(true);

  const deleteHandler = async (id: string) => {
    const deleteGeneralApiFn = async () => {
      return deleteGeneralApi(id)
        .then((res) => {
          if (res.status === 200 && res.result === true) {
            return res;
          } else {
            FailedModalFire(alertsMapDeleteItem[res.errorNum.toString()]);
          }
        })
        .catch((error) => {
          errorHandler(error);
        });
    };

    DeleteSwalFire(deleteGeneralApiFn).then((res) => {
      if (res.value !== undefined) {
        setGeneralApiSearch("");
        setGeneralApi((res.value as any).data);
        setGeneralApiPage((res.value as any).meta);
        SuccessModalFire(`The General API key was deleted successfully`);
      }
    });
  };

  useEffect(() => {
    setGeneralApiLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    listGeneralApi(generalApiSearch, generalApiPage.page, { signal })
      .then((res) => {
        if (res.status === 200) {
          setGeneralApi(res.data);
          setGeneralApiPage(res.meta);
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      })
      .finally(() => {
        setGeneralApiLoading(false);
      });

    return () => controller.abort();
    // eslint-disable-next-line
  }, [generalApiSearch, generalApiPage.page]);

  return (
    <TablesWidget
      title="Your General API keys"
      className="mb-5 mb-xl-10"
      tableHead={["Name", "Allowed IPs", "General API key", "Actions"]}
      loading={generalApiLoading}
      noData={generalApi?.length === 0 && !generalApiLoading ? true : false}
      size={[null, null, 250, 100]}
      searchInput={
        <TableSearch
          value={generalApiSearch}
          onChange={() => setGeneralApiLoading(true)}
          onDebouncedChange={(search) => {
            setGeneralApiSearch(search);
            setGeneralApiPage({ ...generalApiPage, page: 1 });
          }}
        />
      }
      activePage={generalApiPage.page}
      pages={generalApiPage.pages}
      pageChange={(page) =>
        setGeneralApiPage({ ...generalApiPage, page: page })
      }
    >
      {generalApiLoading === true && <TablePlaceholder config={[5, 4]} />}
      {!generalApiLoading &&
        generalApi !== undefined &&
        generalApi.map((data, index) => (
          <tr key={index}>
            <td>
              <span className="text-dark fw-bold fs-6">{data.name}</span>
            </td>
            <td>
              {data.allowedIps.length !== 0 ? (
                <button
                  className="text-primary fw-semibold bg-transparent border-transparent"
                  onClick={() => {
                    setModalWidth(450);
                    setComponent(
                      <>
                        <ModalBodyTitle
                          title={`Allowed IPs list`}
                          subTitle={`You can check all of allowed IPs list here.`}
                          className="mt-8 mb-15"
                        />
                        <TextListWidget list={data.allowedIps} />
                      </>
                    );
                    setShowModal(true);
                  }}
                >
                  IPs list
                </button>
              ) : (
                <span className="text-gray-600 fw-bold fs-6">All</span>
              )}
            </td>
            <td>
              <TableBodyLink>{data.apiKey}</TableBodyLink>
            </td>
            <td>
              <div className="d-flex justify-content-start flex-shrink-0">
                <TableEditButton
                  onClick={() => {
                    setModalWidth(400);
                    setComponent(
                      <GeneralApiKeyForm
                        generalApi={{
                          id: data.id.toString(),
                          name: data.name,
                          ips: data.allowedIps,
                        }}
                      />
                    );
                    setShowModal(true);
                  }}
                />
                <TableDeleteButton
                  onClick={() => {
                    deleteHandler(data.id);
                  }}
                />
              </div>
            </td>
          </tr>
        ))}
    </TablesWidget>
  );
};
