import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import WalletWebappSendProvider from "../../_oxapay/layout/core/WalletWebappSendContext";
import { WalletWebappQrSendStepOne } from "./WalletWebappQrSendStepOne";
import { WalletWebappSendStepOne } from "./WalletWebappSendStepOne";
import { WalletWebappSendStepThree } from "./WalletWebappSendStepThree";
import { WalletWebappSendStepTwo } from "./WalletWebappSendStepTwo";

export const WalletWebAppSend = () => {
  const [step, setStep] = useState<number>(1);
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const qrAddress = searchParams.get("address");

  return (
    <>
      <WalletWebappSendProvider>
        {loading && qrAddress ? (
          <WalletWebappQrSendStepOne
            address={qrAddress}
            onSuccess={() => {
              setLoading(false);
              setStep(2);
            }}
          />
        ) : (
          <>
            {step === 1 && (
              <WalletWebappSendStepOne onSuccess={() => setStep(2)} />
            )}
            {step === 2 && (
              <WalletWebappSendStepTwo
                onSuccess={() => setStep(3)}
                onBack={() => setStep(1)}
              />
            )}
            {step === 3 && (
              <WalletWebappSendStepThree
                onBack={() => setStep(2)}
                onSuccess={() => setStep(1)}
              />
            )}
          </>
        )}
      </WalletWebappSendProvider>
    </>
  );
};
