import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { IconWarning } from "../staticIcons/IconWarning";
import * as Yup from "yup";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { WaitButtonSpan } from "../WaitButtonSpan";
import { useRequest } from "../../app/services/userHttpRequests";
import { SuccessModalFire } from "../../app/services/successSwalFire";
import { useAuth, UserModel } from "../../app/modules/auth";
import { alertsMapEnableTwoFa } from "../../app/services/alertsMap";
import { ModalContext } from "../../_oxapay/layout/core/ModalContext";
import { errorHandler } from "../../app/services/errorHandler";
import { TextCopyWidget } from "../TextCopyWidget";

export const TwoFactorForm = () => {
  const [qrLoading, setQrLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [twoFa, setTwoFa] = useState({ sKey: "", qrUrl: "" });
  const { twoFactor, enableTwoFa } = useRequest();
  const { setShowModal, setComponent } = useContext(ModalContext);
  const { setCurrentUser, currentUser } = useAuth();
  const [error, setError] = useState<string>();

  useEffect(() => {
    twoFactor()
      .then((res) => {
        if (res.status === 200) {
          setTwoFa({ sKey: res.secretKey, qrUrl: res.qrUrl });
        } else {
          FailedModalFire("Somethings went wrong, please try again later");
        }
      })
      .catch((error) => {
        errorHandler(error as Error);
      });
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.number()
        .typeError("Authentication code must be a number")
        .required("Authentication code is required"),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        const data = await enableTwoFa(values.code);
        if (data.status === 200) {
          const updatedUser = {
            ...currentUser,
            twoFa: true,
          };
          setCurrentUser(updatedUser as UserModel);
          setShowModal(false);
          resetForm();
          SuccessModalFire("Your Two-factor authentication was activated");
        } else if (data.status === 0 && data.errorNum) {
          setError(alertsMapEnableTwoFa[data.errorNum.toString()]);
        }
      } catch (error) {
        errorHandler(error as Error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    setError("");
    // eslint-disable-next-line
  }, [formik.errors.code && formik.touched.code]);

  return (
    <div className="scroll-y pt-10 pb-15 px-lg-17 px-10" data-kt-element="apps">
      <h3 className="text-dark fw-bold mb-7">Authenticator Apps</h3>
      <div className="text-gray-500 fw-semibold fs-6 mb-10">
        Using an authenticator app like{" "}
        <a
          href="https://support.google.com/accounts/answer/1066447?hl=en"
          target="_blank"
          rel="noreferrer"
        >
          Google Authenticator
        </a>
        ,{" "}
        <a
          href="https://www.microsoft.com/en-us/account/authenticator"
          target="_blank"
          rel="noreferrer"
        >
          Microsoft Authenticator
        </a>
        ,{" "}
        <a href="https://authy.com/download/" target="_blank" rel="noreferrer">
          Authy
        </a>
        , or{" "}
        <a
          href="https://support.1password.com/one-time-passwords/"
          target="_blank"
          rel="noreferrer"
        >
          1Password
        </a>{" "}
        , scan the QR code. It will generate a 6 digit code for you to enter
        below.
        <div className="pt-5 text-center">
          {qrLoading && (
            <div className="h-150px w-150px bg-secondary rounded m-auto shine my-10"></div>
          )}

          {twoFa.qrUrl && (
            <img
              src={twoFa.qrUrl}
              alt="qr"
              className={`mw-150px ${qrLoading && "d-none"}`}
              onLoad={() => {
                setQrLoading(false);
              }}
            />
          )}
        </div>
      </div>
      <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6">
        <div>
          <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
            <IconWarning />
          </span>
        </div>
        <div className="fw-semibold text-break">
          <div className="fs-6 text-gray-700">
            If you having trouble using the QR code, select manual entry on your
            app, and enter your username and the code:
            {twoFa.sKey ? (
              <div className="fw-bold text-dark pt-2">
                <TextCopyWidget text={twoFa.sKey} />
              </div>
            ) : (
              <div className="h-25px w-300px bg-secondary rounded shine mt-2"></div>
            )}
          </div>
        </div>
      </div>
      <form className="form" onSubmit={formik.handleSubmit}>
        <div className="mb-10 fv-row">
          <input
            type="text"
            className={`form-control form-control-solid ${
              ((formik.errors.code && formik.touched.code) || error) &&
              "is-invalid border border-danger"
            }`}
            placeholder="Enter authentication code"
            autoComplete="off"
            name="code"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.code}
          />
          {formik.errors.code && formik.touched.code && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
              {formik.errors.code}
            </p>
          )}
          {error && (
            <p className="text-danger fw-semibold ms-3 mt-1 mb-0">{error}</p>
          )}
        </div>
        <div className="d-flex flex-center">
          <button
            type="button"
            className="btn btn-light me-3"
            onClick={() => {
              setShowModal(false);
              setComponent(<></>);
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={formik.isSubmitting}
          >
            {loading ? <WaitButtonSpan /> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};
