import React, { useContext, useEffect, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../_oxapay/helpers";
import { AppContext } from "../_oxapay/layout/core/AppContext";
import { AcceptedCoinsPlaceholder } from "./AcceptedCoinsPlaceholder";

interface Props {
  onChange: (selectedCoins: string[]) => void;
  className?: string;
  value: string[];
  label?: string;
  labelClassName?: string;
  height?: number;
  type?: "acceptedCoin" | "filter";
}

export const AcceptedCoinsWidget = ({
  onChange,
  className,
  value,
  label = "Accepted coins",
  labelClassName = "fs-5 fw-semibold text-gray-800",
  height = 200,
  type = "acceptedCoin",
}: Props) => {
  const { coinsListMap, coinsLoading } = useContext(AppContext);
  const acceptedCoins =
    type === "acceptedCoin"
      ? Object.keys(coinsListMap).filter(
          (coinSymbol) =>
            coinsListMap[coinSymbol]?.status === true &&
            coinsListMap[coinSymbol]?.depositEnable === true
        )
      : Object.keys(coinsListMap);
  const disabledCoins = Object.keys(coinsListMap).filter(
    (coinSymbol) =>
      coinsListMap[coinSymbol]?.status === true &&
      coinsListMap[coinSymbol]?.depositEnable === false
  );
  const [selectedCoins, setSelectedCoins] = useState<string[]>(value);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    JSON.stringify(selectedCoins.sort()) ===
    JSON.stringify(acceptedCoins.sort())
      ? setSelectAll(true)
      : setSelectAll(false);
  }, [acceptedCoins, selectedCoins]);

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedCoins([]);
    } else {
      setSelectedCoins([...acceptedCoins]);
    }
  };

  const toggleCoinSelection = (coin: string) => {
    if (selectedCoins.includes(coin)) {
      setSelectedCoins(selectedCoins.filter((c) => c !== coin));
      setSelectAll(false);
    } else {
      setSelectedCoins([...selectedCoins, coin]);
      if (selectedCoins.length + 1 === acceptedCoins.length) {
        setSelectAll(true);
      }
    }
  };

  const filteredCoins = acceptedCoins.filter((coin) =>
    `${coinsListMap[coin]?.name} (${coin})`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    onChange(selectedCoins);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoins]);

  useEffect(() => {
    setSelectedCoins(value);
  }, [value]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <h4 className={labelClassName}>{label}</h4>
        <span
          id="checktool"
          className="text-primary cursor-pointer"
          onClick={toggleSelectAll}
        >
          {selectAll ? "Deselect all" : "Select all"}
        </span>
      </div>
      <div className={`bg-light rounded pt-1 ${className}`}>
        <div className="position-relative px-2">
          <span className="svg-icon svg-icon-2 position-absolute top-50 translate-middle-y ms-4">
            <KTSVG
              path="/media/icons/duotune/general/gen004.svg"
              className="svg-icon-3"
            />
          </span>
          <input
            type="text"
            className="form-control w-100 fs-7 ps-12 mt-1 mb-2"
            placeholder="Search coin..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div className={`overflow-scroll h-${height}px px-2`}>
          {coinsLoading ? (
            <div className="px-5 mt-2">
              <AcceptedCoinsPlaceholder />
              <AcceptedCoinsPlaceholder />
              <AcceptedCoinsPlaceholder />
              <AcceptedCoinsPlaceholder />
              <AcceptedCoinsPlaceholder />
              <AcceptedCoinsPlaceholder />
              <AcceptedCoinsPlaceholder />
              <AcceptedCoinsPlaceholder />
            </div>
          ) : (
            <>
              {filteredCoins.map((coin, index) => (
                <label
                  className="form-check form-check-custom form-check-solid mt-2 mb-3 ms-5 cursor-pointer"
                  key={index}
                >
                  <input
                    className="form-check-input check"
                    type="checkbox"
                    checked={selectedCoins.includes(coin)}
                    onChange={() => {
                      toggleCoinSelection(coin);
                    }}
                  />
                  <span className="form-check-label fs-7">
                    <img
                      src={toAbsoluteUrl(
                        `/media/svg/coins/${coinsListMap[coin]?.slug}.svg`
                      )}
                      alt={coin}
                      className="w-25px me-2"
                    />
                    {coinsListMap[coin]?.name}
                    <span className="fs-8 text-dark ms-1">({coin})</span>
                  </span>
                </label>
              ))}
              {type === "acceptedCoin" &&
                disabledCoins.map((coin, index) => (
                  <label
                    className="form-check form-check-custom form-check-solid mt-2 mb-3 ms-5 cursor-pointer disable"
                    key={index}
                    style={{ filter: "grayscale(1)" }}
                  >
                    <input
                      className="form-check-input check"
                      type="checkbox"
                      checked={selectedCoins.includes(coin)}
                      onChange={() => {
                        toggleCoinSelection(coin);
                      }}
                    />
                    <span className="form-check-label fs-7">
                      <img
                        src={toAbsoluteUrl(
                          `/media/svg/coins/${coinsListMap[coin]?.slug}.svg`
                        )}
                        alt={coin}
                        className="w-25px me-2"
                      />
                      {coinsListMap[coin]?.name}
                      <span className="fs-8 text-dark ms-1">({coin})</span>
                    </span>
                  </label>
                ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};
