import React, { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { DateTime, formatAmount } from "../app/services/functionServices";
import { toAbsoluteUrl } from "../_oxapay/helpers";
import { AppContext } from "../_oxapay/layout/core/AppContext";
import { WalletTxsListPlaceholder } from "./WalletTxsListPlaceholder";

export interface tx {
  id: string;
  type: string;
  amount: string;
  date: string;
  coin: string;
  status: string;
  confirm?: string;
  confirmRequired?: string;
  plus: boolean;
  url: string;
  txHash: string;
}

interface props {
  noData?: boolean;
  loading?: boolean;
  height?: number;
  transactions: tx[];
}

export const TransactionsList = ({
  noData,
  transactions,
  height = 450,
  loading = false,
}: props) => {
  const [walletWebapp, setWalletWebapp] = useState(false);
  const [copied, setCopied] = useState<boolean[]>(
    transactions.map(() => false)
  );
  const { coinsListMap } = useContext(AppContext);

  useEffect(() => {
    const pageUrl = window.location.pathname;
    if (pageUrl === "/wallet") {
      setWalletWebapp(true);
    }
  }, []);

  const handleCopy = (id: number, index: number) => {
    const transaction = transactions.find((tx) => tx.id === id.toString());
    if (transaction) {
      navigator.clipboard.writeText(transaction.txHash);
      setCopied((prevCopied) => {
        const newCopied = [...prevCopied];
        newCopied[index] = true;
        return newCopied;
      });
      setTimeout(() => {
        setCopied((prevCopied) => {
          const newCopied = [...prevCopied];
          newCopied[index] = false;
          return newCopied;
        });
      }, 2000);
    }
  };

  return (
    <div
      className={`${
        !walletWebapp && "overflow-scroll h-" + height + "px"
      } px-2`}
    >
      {noData && (
        <div className="d-flex justify-content-center align-items-center my-20">
          <img
            src={toAbsoluteUrl("/media/icons/duotune/files/fil012.svg")}
            className="w-30px opacity-20 my-10"
            alt="no-data"
          />
          <p className="ms-2 my-0 fw-semibold text-gray-500">No items found</p>
        </div>
      )}
      {loading ? (
        <div className="mt-4">
          <WalletTxsListPlaceholder />
          <WalletTxsListPlaceholder />
          <WalletTxsListPlaceholder />
          <WalletTxsListPlaceholder />
          <WalletTxsListPlaceholder />
          <WalletTxsListPlaceholder />
          <WalletTxsListPlaceholder />
          <WalletTxsListPlaceholder />
          <WalletTxsListPlaceholder />
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table">
            <tbody>
              {transactions.map((tx, index) => (
                <tr
                  key={tx.id}
                  className="d-flex align-items-center justify-content-between"
                >
                  <td className="d-flex align-items-center">
                    <div className="d-flex flex-column">
                      {tx.url ? (
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          placement="top"
                          overlay={
                            <Popover>
                              <Popover.Header>Transaction Hash</Popover.Header>
                              <Popover.Body>
                                <strong>{tx.txHash}</strong>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <a
                            href={tx.url}
                            className="mb-1 text-dark text-hover-primary fw-bold h4 d-flex align-items-center cursor-pointer"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {tx.txHash.substring(0, 3) +
                              "..." +
                              tx.txHash.substring(tx.txHash.length - 3)}
                            <span
                              className={`badge badge-${
                                tx.status === "y"
                                  ? "success"
                                  : tx.status === "f"
                                  ? "danger"
                                  : tx.status === "r"
                                  ? "danger"
                                  : "warning"
                              } ms-1`}
                            >
                              {tx.status === "y"
                                ? "Confirmed"
                                : tx.status === "w"
                                ? `Confirming (${tx.confirm}/${tx.confirmRequired})`
                                : tx.status === "p"
                                ? "Pending"
                                : tx.status === "f"
                                ? "Failed"
                                : tx.status === "r"
                                ? "Canceled"
                                : "Proccessing"}
                            </span>
                          </a>
                        </OverlayTrigger>
                      ) : (
                        <span className="mb-1 text-dark text-hover-primary fw-bold h4 d-flex align-items-center cursor-pointer">
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="top"
                            overlay={
                              <Popover>
                                {tx.txHash && (
                                  <Popover.Header>
                                    Internal transfer track ID
                                  </Popover.Header>
                                )}
                                <Popover.Body>
                                  {tx.txHash ? (
                                    <strong>{tx.txHash}</strong>
                                  ) : (
                                    <span>
                                      {tx.status === "r"
                                        ? "Transaction was canceled"
                                        : "Transaction is pending"}
                                    </span>
                                  )}
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <div
                              className="d-flex justify-content-center"
                              onClick={
                                tx.txHash
                                  ? () => handleCopy(Number(tx.id), index)
                                  : undefined
                              }
                            >
                              {copied[index] ? (
                                <span className="fs-6 text-success">
                                  Copied to clipboard
                                </span>
                              ) : (
                                <>
                                  {tx.txHash &&
                                    tx.txHash.substring(0, 3) +
                                      "..." +
                                      tx.txHash.substring(tx.txHash.length - 3)}
                                  <span
                                    className={`badge badge-${
                                      tx.status === "y"
                                        ? "success"
                                        : tx.status === "f"
                                        ? "danger"
                                        : tx.status === "r"
                                        ? "danger"
                                        : "warning"
                                    } ${tx.txHash && "ms-1"} `}
                                  >
                                    {tx.status === "y"
                                      ? "Confirmed"
                                      : tx.status === "w"
                                      ? `Confirming (${tx.confirm}/${tx.confirmRequired})`
                                      : tx.status === "p"
                                      ? "Pending"
                                      : tx.status === "f"
                                      ? "Failed"
                                      : tx.status === "r"
                                      ? "Canceled"
                                      : "Proccessing"}
                                  </span>
                                </>
                              )}
                            </div>
                          </OverlayTrigger>
                        </span>
                      )}

                      <span className="text-gray-400 fw-semibold fs-7">
                        {DateTime(parseInt(tx.date))}
                      </span>
                    </div>
                  </td>
                  <td className="d-flex flex-column align-items-end">
                    <h5
                      className={`fw-bold text-${
                        tx.plus ? "success" : "danger"
                      } lh-3 mb-1`}
                    >
                      {tx.plus ? "+" : "-"}
                      {formatAmount(
                        parseFloat(tx.amount),
                        coinsListMap[tx.coin]?.displayPrecision
                      )}{" "}
                      {tx.coin}
                    </h5>
                    <span className="text-gray-400 fw-semibold fs-7">
                      {tx.type}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
