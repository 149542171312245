import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { alertsMapSetEmailPassword } from "../../app/services/alertsMap";
import { FailedModalFire } from "../../app/services/failedSwalFire";
import { SuccessModalFire } from "../../app/services/successSwalFire";
import { useRequest } from "../../app/services/userHttpRequests";
import { WaitButtonSpan } from "../WaitButtonSpan";

interface props {
  onClick: () => void;
}

export const SetEmailForm = ({ onClick }: props) => {
  const [loading, setLoading] = useState(false);
  const { setEmailPassword } = useRequest();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("The entered value type is not email")
        .required("New email is required"),
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/,
          "Password must be more than 8 chracaters and contain at least one number."
        ),
      confirmPassword: Yup.string()
        .required("Password confirmation is required")
        .when("password", {
          is: (val: string) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Password and Confirm Password aren't match"
          ),
        }),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);

      try {
        const data = await setEmailPassword(
          values.email,
          values.password,
          values.confirmPassword
        );
        if (data.status === 200) {
          SuccessModalFire(
            "Confirmation message was sent to your telegram.",
            "Confirmation message was sent"
          );
        } else if ((data.status === 0, data.errorNum)) {
          FailedModalFire(alertsMapSetEmailPassword[data.errorNum.toString()]);
        }
      } catch (error) {
        FailedModalFire(
          "Check your internet connection and try again",
          "Connection error"
        );
      } finally {
        resetForm();
        setLoading(false);
        setSubmitting(false);
        onClick();
      }
    },
  });

  return (
    <div className="flex-row-fluid">
      <form className="form" onSubmit={formik.handleSubmit}>
        <div className="row mb-6">
          <div className="col-lg-4 mb-4 mb-lg-0">
            <div className="fv-row mb-0">
              <label className="form-label fs-6 fw-bold mb-3">
                Email Address
              </label>
              <input
                type="email"
                className={`form-control form-control-solid ${
                  formik.errors.email &&
                  formik.touched.email &&
                  "is-invalid border border-danger"
                }`}
                placeholder="Email Address"
                name="email"
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.errors.email && formik.touched.email && (
                <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                  {formik.errors.email}
                </p>
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="fv-row mb-0">
              <label className="form-label fs-6 fw-bold mb-3">Password</label>
              <input
                type="password"
                className={`form-control form-control-solid ${
                  formik.errors.password &&
                  formik.touched.password &&
                  "is-invalid border border-danger"
                }`}
                name="password"
                placeholder="Password"
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              {formik.errors.password && formik.touched.password && (
                <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                  {formik.errors.password}
                </p>
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="fv-row mb-0">
              <label className="form-label fs-6 fw-bold mb-3">
                Confirm password
              </label>
              <input
                type="password"
                className={`form-control form-control-solid ${
                  formik.errors.confirmPassword &&
                  formik.touched.confirmPassword &&
                  "is-invalid border border-danger"
                }`}
                name="confirmPassword"
                placeholder="confirm password"
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
              />
              {formik.errors.confirmPassword &&
                formik.touched.confirmPassword && (
                  <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                    {formik.errors.confirmPassword}
                  </p>
                )}
            </div>
          </div>
        </div>
        <div className="d-flex">
          <button
            type="submit"
            className="btn btn-primary me-2 px-6"
            disabled={formik.isSubmitting}
          >
            {loading ? <WaitButtonSpan /> : "Set Email & Password"}
          </button>
          <button
            type="button"
            className="btn btn-color-gray-400 btn-active-light-primary px-6"
            onClick={onClick}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};
