import React, { useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { WebappContext } from "../../_oxapay/layout/core/WebappContext";
import { WebappAddressbookForm } from "./WebappAddressbookForm";

interface props {
  showAddressBookForm: boolean;
  onClose: () => void;
  address?: string;
  updateFields?: {
    id: string;
    name: string;
    address: string;
    whitelist: boolean;
  };
}
export const WebappAddressbookFormModal = ({
  showAddressBookForm,
  onClose,
  address,
  updateFields,
}: props) => {
  const { webappColors } = useContext(WebappContext);
  const WebappFn = (window as any)?.Telegram?.WebApp;
  useEffect(() => {
    if (showAddressBookForm) {
      WebappFn?.setHeaderColor(webappColors.bgBackdrop);
    } else {
      WebappFn?.setHeaderColor(webappColors.bgGray200);
    }
    // eslint-disable-next-line
  }, [showAddressBookForm]);

  return (
    <Modal
      show={showAddressBookForm}
      onHide={onClose}
      className="sheet-modal sheet-height-60 gray-modal fadeup-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fs-1">Address Book Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="scrollable-content">
          {showAddressBookForm && (
            <WebappAddressbookForm
              updateFields={updateFields}
              address={address}
              onSuccess={onClose}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
