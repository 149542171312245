import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatAmount } from "../../app/services/functionServices";
import { toAbsoluteUrl } from "../../_oxapay/helpers";
import { AppContext } from "../../_oxapay/layout/core/AppContext";
import { Asset } from "../AssetsList";
import { WebappAssetsListSkeleton } from "./WebappAssetsListSkeleton";

interface Props {
  assets: Asset[];
  balanceLoading: boolean;
  priceLoading: boolean;
  height?: number;
}

export const WalletWebappAssetsList = ({
  assets,
  balanceLoading,
  priceLoading,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { coinsListMap, coinsLoading } = useContext(AppContext);
  const [assetsList, setAssetsList] = useState(assets);

  useEffect(() => {
    setAssetsList(assets);
  }, [assets]);

  const filteredAssets = assetsList.filter((asset) =>
    `${coinsListMap[asset.coin]?.name} ${asset.coin}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const assetValueSort = (a: Asset, b: Asset) =>
    b.amount * b.price - a.amount * a.price;
  const sortedAssets = [...filteredAssets].sort(assetValueSort);
  return (
    <>
      {coinsLoading || priceLoading || balanceLoading ? (
        <WebappAssetsListSkeleton count={14} />
      ) : (
        <>
          {sortedAssets
            .filter(
              (asset) =>
                coinsListMap[asset.coin]?.status === true ||
                Number(asset.amount) !== 0
            )
            .map((asset, index) => (
              <Link
                to={`/webapp-wallet/coin/${asset.coin}`}
                key={index}
                className="card ps-4 pe-5 py-3 mb-2"
              >
                <div className="d-flex align-items-center justify-content-between p-0">
                  <div className="d-flex align-items-center">
                    <div className="w-45px overflow-hidden me-3">
                      <div className="symbol-label">
                        <img
                          src={toAbsoluteUrl(
                            `/media/svg/coins/${
                              coinsListMap[asset.coin]?.slug
                            }.svg?v=2`
                          )}
                          alt={coinsListMap[asset.coin]?.name}
                          className="w-100"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column">
                      <h4 className="d-flex align-items-center fs-2 fw-semibold text-dark lh-1 mb-0 mt-1">
                        <span>{coinsListMap[asset.coin]?.name}</span>
                        <span className="fs-6 fw-semibold text-gray-600 ms-1">
                          ({asset.coin.toUpperCase()})
                        </span>
                      </h4>
                      <span className="d-flex align-items-center text-gray-600 fw-semibold fs-5">
                        $
                        {asset.price.toLocaleString(undefined, {
                          maximumFractionDigits: 10,
                        })}
                        <span
                          className={` px-2 py-1 badge ${
                            asset.change < 0
                              ? "badge-light-danger"
                              : "badge-light-success"
                          } fs-8 fw-semibold ms-1`}
                        >
                          {asset.change > 0 && "+"}
                          {asset.change}%
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    <h5 className="fw-semibold text-dark lh-1 letter-spacing-n-1 mb-0 fs-2 mt-1">
                      {formatAmount(
                        asset.amount,
                        coinsListMap[asset.coin]?.displayPrecision
                      )}
                    </h5>

                    <span className="text-gray-600 fw-semibold fw-semibold fs-5">
                      ${Number(asset.amount * asset.price).toLocaleString()}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
        </>
      )}
    </>
  );
};
