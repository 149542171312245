import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { errorHandler } from "../../../app/services/errorHandler";
import { DateTime } from "../../../app/services/functionServices";
import { checkTransferTxStatus } from "../../../app/services/intervalManage";
import { useRequest } from "../../../app/services/userHttpRequests";
import { WebappModalContext } from "../../../_oxapay/layout/core/WebappModalContext";
import { CheckTelegram } from "../Lotties/CheckTelegram";

interface TxData {
  address: string;
  amount: string;
  coin: string;
  date: string;
  fee: string;
  id: string;
  network: string;
  note: string;
  internal?: boolean;
  status: string;
  txId: string;
  txUrl: string;
}

interface props {
  title?: string;
  content: string;
  mainButtonText?: string;
  txData?: TxData;
  coinsListMap?: any;
}
export const WebappSuccessTransferAlert = ({
  title = "Congratulations!",
  content,
  mainButtonText = "Got it",
  txData,
  coinsListMap,
}: props) => {
  const [lastTxDatas, setLastTxData] = useState<TxData | undefined>(undefined);
  const { setShowModal, setComponent, setDFlex } =
    useContext(WebappModalContext);
  const { checkTransferTx } = useRequest();

  const WebappFn = (window as any)?.Telegram?.WebApp;
  useEffect(() => {
    setDFlex(false);
    WebappFn.HapticFeedback?.impactOccurred("rigid");
    WebappFn.MainButton.setText(mainButtonText)
      .setParams({ color: "#3478f6" })
      .show()
      .onClick(() => {
        setShowModal(false);
        setComponent(<></>);
        setDFlex(true);
      });

    return () => {
      WebappFn.MainButton.offClick(() => {
        setShowModal(false);
        setComponent(<></>);
        setDFlex(true);
      }).hide();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLastTxData(txData);
    // eslint-disable-next-line
  }, [txData]);

  useEffect(
    () => {
      const controller = new AbortController();
      const signal = controller.signal;

      const fetchStatus = async () => {
        try {
          const response = await checkTransferTx(lastTxDatas?.id as string, {
            signal,
          });
          if (response.status === 200) {
            setLastTxData(response.tx);
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            return;
          }
          errorHandler(error as Error);
        }
      };

      if (!lastTxDatas?.txId) {
        fetchStatus();
        const interval = setInterval(fetchStatus, checkTransferTxStatus);

        return () => {
          controller.abort(); // Cancel the fetch request
          clearInterval(interval); // Clean up the interval on component unmount
        };
      }
    },
    // eslint-disable-next-line
    [lastTxDatas?.txId, lastTxDatas?.id]
  );

  return (
    <>
      <div className="d-flex justify-content-center flex-column align-items-center mb-12">
        <div className="w-150px h-150px mb-12">
          <CheckTelegram />
        </div>
        <h1 className="mb-3 text-center">{title}</h1>
        <p className="fs-5 fw-400 mw-250px text-center">{content}</p>
      </div>
      <p className="fs-6 text-uppercase fw-400 text-gray-600 mb-2 ps-4">
        Transfer details
      </p>
      <div className="bg-light rounded-12px ps-6 py-4">
        <div>
          <p className="text-dark fs-2 mb-0">Currency</p>
          <p className="text-primary fs-5 fw-400 mb-0 pe-3">
            {txData?.coin && coinsListMap[txData?.coin]?.name} (
            {lastTxDatas?.coin})
          </p>
        </div>
        <hr className="border-gray-500 w-100 mx-0 my-3" />
        <div>
          <p className="text-dark fs-2 mb-0">Amount</p>
          <p className="text-primary fs-5 fw-400 mb-0 pe-3">
            {lastTxDatas?.amount} {lastTxDatas?.coin}
          </p>
        </div>
        {!lastTxDatas?.internal && (
          <>
            <hr className="border-gray-500 w-100 mx-0 my-3" />
            <p className="text-dark fs-2 mb-0">Network</p>
            <p className="text-primary fs-5 fw-400 mb-0 pe-3">
              {lastTxDatas?.network}
            </p>
          </>
        )}
        <hr className="border-gray-500 w-100 mx-0 my-3" />
        <div>
          <p className="text-dark fs-2 mb-0">Fee</p>
          <p className="text-primary fs-5 fw-400 mb-0 pe-3">
            {lastTxDatas?.fee} {lastTxDatas?.coin}
          </p>
        </div>
        <hr className="border-gray-500 w-100 mx-0 my-3" />
        <div>
          <p className="text-dark fs-2 mb-0">Receiver Address</p>

          <p className="text-primary fs-5 fw-400 mb-0 pe-3 wrap-break-word">
            {lastTxDatas?.address}
          </p>
        </div>
        <hr className="border-gray-500 w-100 mx-0 my-3" />
        <div>
          <p className="text-dark fs-2 mb-0">Transaction Hash</p>
          {lastTxDatas?.txId ? (
            <a
              href={lastTxDatas?.txUrl}
              target={"_blank"}
              className="text-primary fs-5 fw-400 mb-0 pe-3 wrap-break-word"
              rel="noreferrer"
            >
              {lastTxDatas?.txId}
            </a>
          ) : (
            <p className="text-primary fs-5 fw-400 mb-0 pe-3">
              {lastTxDatas?.txId ? lastTxDatas?.txId : "Pending"}
            </p>
          )}
        </div>
        <hr className="border-gray-500 w-100 mx-0 my-3" />
        <div>
          <p className="text-dark fs-2 mb-0">Date and Time</p>
          <p className="text-primary fs-5 fw-400 mb-0 pe-3">
            {DateTime(parseInt(lastTxDatas?.date as string))}
          </p>
        </div>
      </div>
    </>
  );
};
