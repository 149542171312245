import React, { useContext } from "react";
import { AppContext } from "../../_oxapay/layout/core/AppContext";

interface props {
  fromCurrency: string;
  toCurrency: string;
}

export const WebappSwapPairNames = ({ fromCurrency, toCurrency }: props) => {
  const { coinsListMap } = useContext(AppContext);
  return (
    <div className="d-flex justify-content-center mb-5">
      <div>
        <p className="fs-2 text-gray-600 fw-semibold m-0">Swapping</p>
        <p
          className="text-dark fw-bold"
          style={{ marginTop: "-7px", fontSize: "1.9rem" }}
        >
          {coinsListMap[fromCurrency]?.name} to {coinsListMap[toCurrency]?.name}
        </p>
      </div>
    </div>
  );
};
