import React from "react";

interface props {
  count: number;
  onClick: () => void;
}
export const ReadAllButton = ({ count, onClick }: props) => {
  return (
    <div
      className="poistion-relative d-flex justify-content-center align-items-center read-all bg-light w-40px h-40px shadow cursor-pointer"
      onClick={onClick}
      style={{ zIndex: 1 }}
    >
      <p className="bg-primary text-white unread-counts">
        {count < 100 ? count : "+99"}
      </p>
      <i className="fa-solid fa-chevron-down fs-4"></i>
    </div>
  );
};
