import React from "react";

interface Props {
  maxLength: number;
  value: string;
  name: string;
  placeholder: string;
  row?: number;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea = ({
  maxLength,
  value,
  onChange,
  name,
  row = 2,
  placeholder,
}: Props) => {
  const textLength = value?.length;

  return (
    <>
      <textarea
        className="form-control form-control form-control-solid"
        id="kt_docs_maxlength_textarea"
        maxLength={maxLength}
        placeholder={placeholder}
        rows={row}
        value={value}
        onChange={onChange}
        name={name}
      ></textarea>
      {textLength > 0 && (
        <div className="d-flex justify-content-end">
          <span
            className={`badge badge-${
              textLength === 120 ? "danger" : "primary"
            } mt-2`}
          >
            {textLength} / {maxLength}
          </span>
        </div>
      )}
    </>
  );
};
