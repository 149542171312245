/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { PasswordMeterComponent } from "../../../../_oxapay/assets/ts/components";
import { WaitButtonSpan } from "../../../../components/WaitButtonSpan";
import { generateToken } from "../../../services/ReCaptchaGenerator";
import { recoveryPassword } from "../core/_requests";
import { SuccessModalFire } from "../../../services/successSwalFire";
import { FailedModalFire } from "../../../services/failedSwalFire";
import { alertsMapRecoveryPassword } from "../../../services/alertsMap";
import { errorHandler } from "../../../services/errorHandler";
import { useAuth } from "../core/Auth";
import { PasswordInput } from "../../../../components/PasswordInput";

const initialValues = {
  password: "",
  changepassword: "",
};

const registrationSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  changepassword: Yup.string()
    .required("Password confirmation is required")
    .when("password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and Confirm Password didn't match"
      ),
    }),
});

export function RecoveryPassword() {
  const [loading, setLoading] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setLoading(true);
      try {
        const recoveryToken = await generateToken("new_password");
        const { data: result } = await recoveryPassword(
          token as string,
          recoveryToken,
          values.password,
          values.changepassword
        );
        if (result.status === 200 && result.result === true) {
          SuccessModalFire("Your password was changed successfully");
          navigate("/");
        } else if (result.status === 0 && result.errorNum) {
          FailedModalFire(
            alertsMapRecoveryPassword[result.errorNum.toString()]
          );
        }
      } catch (error) {
        errorHandler(error as Error);
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    logout();
    PasswordMeterComponent.bootstrap();
    // eslint-disable-next-line
  }, []);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">Setup New Password</h1>
        <div className="text-gray-500 fw-semibold fs-6">
          Have you already reset the password?
          <Link to="/auth" className="link-primary ms-1">
            Sign in
          </Link>
        </div>
      </div>

      {/* begin::Form group Password */}
      <div className="mb-10 fv-row" data-kt-password-meter="true">
        <div className="mb-1">
          <div className="position-relative mb-3">
            <PasswordInput
              placeholder="Password"
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx("form-control bg-transparent rounded", {
                "border border-danger":
                  formik.touched.password && formik.errors.password,
              })}
            />
            {formik.errors.password && formik.touched.password && (
              <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
                {formik.errors.password}
              </p>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className="d-flex align-items-center mb-3"
            data-kt-password-meter-control="highlight"
          >
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className="text-muted">
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>

      <div className="fv-row mb-8">
        <PasswordInput
          placeholder="Password confirmation"
          autoComplete="off"
          {...formik.getFieldProps("changepassword")}
          className={clsx("form-control bg-transparent rounded", {
            "border border-danger":
              formik.touched.changepassword && formik.errors.changepassword,
          })}
        />

        {formik.errors.changepassword && formik.touched.changepassword && (
          <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
            {formik.errors.changepassword}
          </p>
        )}
      </div>

      {/* begin::Form group */}
      <div className="d-grid mb-10">
        <button
          type="submit"
          className="btn btn-primary w-100"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {loading ? <WaitButtonSpan /> : "Submit"}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  );
}
