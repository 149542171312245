import React, { useEffect, useState } from "react";
import { WaitButtonSpan } from "../../../../components/WaitButtonSpan";
import { toAbsoluteUrl } from "../../../../_oxapay/helpers";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FailedModalFire } from "../../../services/failedSwalFire";
import { useAuth } from "../core/Auth";
import { generateToken } from "../../../services/ReCaptchaGenerator";
import OtpInput from "react-otp-input";
import {
  getUserByToken,
  googleLoginVerify,
  login,
  telegramLoginVerify,
} from "../core/_requests";
import { useNavigate } from "react-router-dom";
import {
  alertsMapGoogleLoginVerify,
  alertsMapLogin,
} from "../../../services/alertsMap";
import { errorHandler } from "../../../services/errorHandler";

export const TwoFactor = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    loginInfo,
    googleInfo,
    telegramInfo,
    saveAuth,
    setCurrentUser,
    returnPage,
  } = useAuth();

  useEffect(() => {
    if (
      loginInfo === undefined &&
      googleInfo === undefined &&
      telegramInfo === undefined
    ) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      twoFa: "",
    },
    validationSchema: Yup.object({
      twoFa: Yup.string()
        .length(6, "Two-factor must be 6 digits.")
        .required("TwoFactor code is required"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      if (googleInfo !== undefined) {
        try {
          const googleVerifyToken = await generateToken("login_google_verify");
          const { data: auth } = await googleLoginVerify(
            googleInfo,
            googleVerifyToken,
            values.twoFa
          );
          if (auth.status === 200 && auth.apiToken) {
            saveAuth(auth);
            const { data: user } = await getUserByToken(auth.apiToken);
            setCurrentUser(user);
            returnPage && navigate("/connect");
          } else if (auth.status === 0 && auth.errorNum) {
            saveAuth(undefined);
            FailedModalFire(
              alertsMapGoogleLoginVerify[auth.errorNum.toString()]
            );
          }
        } catch (error) {
          errorHandler(error as Error);
          FailedModalFire(
            "Please try again. If you continue to encounter this issue, please contact our online support for assistance.",
            "Something went wrong with your connection"
          );
          saveAuth(undefined);
        } finally {
          setLoading(false);
          setSubmitting(false);
        }
      } else if (telegramInfo !== undefined) {
        try {
          const telegramVerifyToken = await generateToken(
            "login_telegram_verify"
          );
          const { data: auth } = await telegramLoginVerify(
            telegramInfo,
            telegramVerifyToken,
            values.twoFa
          );
          if (auth.status === 200 && auth.apiToken) {
            saveAuth(auth);
            const { data: user } = await getUserByToken(auth.apiToken);
            setCurrentUser(user);
            returnPage && navigate("/connect");
          } else if (auth.status === 0 && auth.errorNum) {
            saveAuth(undefined);
            FailedModalFire(
              alertsMapGoogleLoginVerify[auth.errorNum.toString()]
            );
          }
        } catch (error) {
          errorHandler(error as Error);
          FailedModalFire(
            "Please try again. If you continue to encounter this issue, please contact our online support for assistance.",
            "Something went wrong with your connection"
          );
          saveAuth(undefined);
        } finally {
          setLoading(false);
          setSubmitting(false);
        }
      } else if (loginInfo !== undefined) {
        try {
          const loginToken = await generateToken("login");
          const { data: auth } = await login(
            loginInfo?.userAuth || "",
            loginInfo?.password || "",
            loginToken,
            values.twoFa
          );
          saveAuth(auth);
          if (auth.status === 200 && auth.apiToken) {
            const { data: user } = await getUserByToken(auth.apiToken);
            if (user.status === 200) {
              setCurrentUser(user);
              returnPage && navigate("/connect");
            } else {
              setCurrentUser(undefined);
            }
          } else if (auth.status === 201 && auth.errorNum === -7) {
            navigate("/auth/twofactor");
          } else if (auth.status === 0 && auth.errorNum) {
            saveAuth(undefined);
            FailedModalFire(alertsMapLogin[auth.errorNum.toString()]);
          }
        } catch (error) {
          errorHandler(error as Error);
          FailedModalFire(
            "Please try again.",
            "Something went wrong with your connection"
          );
          saveAuth(undefined);
        } finally {
          setSubmitting(false);
          setLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    if (formik.values.twoFa.length === 6) {
      formik.handleSubmit(); // Submit the form
    }
    // eslint-disable-next-line
  }, [formik.values.twoFa]);

  return (
    <form className="form w-100 mb-13 p-10" onSubmit={formik.handleSubmit}>
      <div className="text-center mb-10">
        <img
          alt="Logo"
          className="mh-125px"
          src={toAbsoluteUrl("/media/svg/misc/smartphone-2.svg")}
        />
      </div>
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Two Step Verification</h1>
        <div className="text-muted text-start fw-semibold fs-5 mb-5">
          Enter the code from your authenticator app, such as Google
          Authenticator or others.
        </div>
      </div>
      <div className="mb-10">
        <div className="fw-bold text-start text-dark fs-6 mb-1 ms-1">
          Type the 6 digit code
        </div>
        <OtpInput
          value={formik.values.twoFa}
          onChange={(value) => formik.setFieldValue("twoFa", value)}
          numInputs={6}
          inputType={"number"}
          renderSeparator={<span> </span>}
          renderInput={(props) => (
            <input
              {...props}
              className="form-control bg-transparent h-md-60px w-md-60px h-40px w-40px fs-2qx text-center my-2 mx-2"
            />
          )}
        />
        {formik.errors.twoFa && formik.touched.twoFa && (
          <p className="text-danger fw-semibold ms-3 mt-1 mb-0">
            {formik.errors.twoFa}
          </p>
        )}
      </div>
      <div className="d-grid mb-10">
        <button
          type="submit"
          className="btn btn-primary w-100"
          disabled={formik.isSubmitting || !formik.isValid || loading}
        >
          {loading ? <WaitButtonSpan /> : "Submit"}
        </button>
      </div>
    </form>
  );
};
