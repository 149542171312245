import React, { useContext, useEffect, useState } from "react";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { errorHandler } from "../../app/services/errorHandler";
import { shortenString } from "../../app/services/functionServices";
import { useRequest } from "../../app/services/userHttpRequests";
import { KTSVG } from "../../_oxapay/helpers";
import { AddressBook, AppContext } from "../../_oxapay/layout/core/AppContext";
import { WebappContext } from "../../_oxapay/layout/core/WebappContext";
import { VerifiedAddress } from "../addressbook/VerifiedAddress";
import { WebappAddressbookFormModal } from "./WebappAddressbookFormModal";

interface props {
  onGetData?: (value: string) => void;
  showAddressbook: boolean;
  onChange: (value: boolean) => void;
}
export const WebappAddressbookList = ({
  onGetData,
  showAddressbook,
  onChange,
}: props) => {
  const [showAddressbookForm, setShowAddressbookForm] = useState(false);
  const [updateFields, setUpdateFields] = useState<AddressBook | undefined>(
    undefined
  );
  const { addressbook, setAddressbook } = useContext(AppContext);
  const { deleteAddressbook } = useRequest();
  const WebappFn = (window as any)?.Telegram?.WebApp;
  const deleteHandler = (id: string) => {
    WebappFn.showPopup(
      {
        message: "Are you sure?",
        buttons: [
          { type: "cancel" },
          { id: "delete", type: "default", text: "Delete" },
        ],
      },
      function (buttonId: string) {
        if (buttonId === "delete") {
          deleteAddressbook(id)
            .then((res) => {
              if (res.status === 200 && res.result === true) {
                setAddressbook(res.data);
              } else if (res.status === 0) {
                WebappFn.showAlert(
                  "An error occurred while attempting to delete the address."
                );
              }
            })
            .catch((error) => {
              errorHandler(error);
            });
        }
      }
    );
  };

  const { webappColors } = useContext(WebappContext);

  useEffect(() => {
    const WebappFn = (window as any)?.Telegram?.WebApp;
    if (showAddressbook || showAddressbookForm) {
      WebappFn?.setHeaderColor(webappColors.bgBackdrop);
    } else {
      WebappFn?.setHeaderColor(webappColors.bgGray200);
    }
    // eslint-disable-next-line
  }, [showAddressbook, showAddressbookForm]);

  return (
    <>
      <Modal
        show={showAddressbook}
        onHide={() => onChange(false)}
        centered
        className="sheet-modal sheet-height-60 gray-modal fadeup-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-1">Address Book</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="scrollable-content">
            <div className="card mb-10">
              {/* Render options */}

              <div className="cursor-pointer">
                <div
                  onClick={() => {
                    setUpdateFields(undefined);
                    onChange(false);
                    setShowAddressbookForm(true);
                  }}
                  className={
                    "d-flex align-items-center justify-content-between ps-5 pe-5"
                  }
                >
                  <div className="d-flex align-items-center py-5">
                    <div className="w-40px overflow-hidden ms-2">
                      <KTSVG
                        path="/media/svg/custom/addaddress.svg"
                        className="svg-icon-2x"
                      />
                    </div>
                    <div className="d-flex flex-column">
                      <p className="fs-5 fw-semibold text-primary mb-0">
                        Add New Address
                      </p>
                    </div>
                  </div>
                </div>
                {addressbook.length !== 0 && (
                  <hr
                    className="border-gray-500 w-100 m-0 right-0"
                    style={{ maxWidth: "290px" }}
                  />
                )}
              </div>
              {addressbook
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item, index) => (
                  <div key={index} className="cursor-pointer">
                    <div
                      className={
                        "d-flex align-items-center justify-content-between ps-5 pe-5"
                      }
                    >
                      {/* Render each option's image and label */}
                      <div className="d-flex align-items-center py-3 w-100">
                        <div
                          className="w-40px overflow-hidden me-3"
                          onClick={() => {
                            onChange(false);
                            onGetData && onGetData(item.address);
                          }}
                        >
                          <div className="symbol symbol-40px symbol-circle">
                            <span className="symbol-label bg-gray text-dark fs-6 fw-bolder">
                              {item.name?.charAt(0)?.toUpperCase()}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between flex-grow-1">
                          <div
                            className="d-flex flex-column"
                            onClick={() => {
                              onChange(false);
                              onGetData && onGetData(item.address);
                            }}
                          >
                            <h4 className="d-flex align-items-center fs-2 fw-400 text-dark lh-1 mb-0 mt-1">
                              {item.name}{" "}
                              {item.whitelist && <VerifiedAddress />}
                            </h4>
                            {item?.address.length < 24 ? (
                              <p className="address fs-7 text-gray-500 text-hover-primary cursor-pointer mb-0">
                                {shortenString(item.address, 24)}
                              </p>
                            ) : (
                              <OverlayTrigger
                                trigger={["hover", "focus"]}
                                placement="top"
                                overlay={
                                  <Popover>
                                    <Popover.Body>{item.address}</Popover.Body>
                                  </Popover>
                                }
                              >
                                <p className="address fs-7 text-gray-500 text-hover-primary cursor-pointer mb-0">
                                  {shortenString(item.address, 24)}
                                </p>
                              </OverlayTrigger>
                            )}
                          </div>
                          <div className="d-flex flex-row align-items-end ms-1">
                            <button
                              className="p-2 btn me-1"
                              onClick={() => {
                                setUpdateFields(item);
                                onChange(false);
                                setShowAddressbookForm(true);
                              }}
                            >
                              <i className="fs-2 text-gray-400 fa-regular fa-pen-to-square"></i>
                            </button>
                            <button
                              className="p-2 btn"
                              onClick={() => deleteHandler(item.id)}
                            >
                              <i className="fs-2 text-gray-400 fa-regular fa-trash-can"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {index !== addressbook.length - 1 && (
                      <hr
                        className="border-gray-500 w-100 m-0 right-0"
                        style={{ maxWidth: "290px" }}
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <WebappAddressbookFormModal
        updateFields={updateFields}
        showAddressBookForm={showAddressbookForm}
        onClose={() => {
          onChange(true);
          setShowAddressbookForm(false);
        }}
      />
    </>
  );
};
