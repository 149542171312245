import Lottie from "lottie-react";
import React from "react";
import animationData from "../../../app/lottie/lock.json";

interface props {
  loop?: boolean;
}
export const Lock = ({ loop = false }: props) => {
  return <Lottie animationData={animationData} loop={loop} />;
};
