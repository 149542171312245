/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { DateTime } from "../../app/services/functionServices";
import { toAbsoluteUrl } from "../../_oxapay/helpers";

interface props {
  data: {
    ip: string;
    browserName: string;
    browserVersion: string;
    osName: string;
    osVersion: string;
  };
  date: string;
}

const LoginItem = ({ data, date }: props) => {
  const [osImage, setOsImage] = useState(
    toAbsoluteUrl(`/media/svg/os/${data.osName?.toLowerCase()}.svg`)
  );
  const [browserImage, setBrowserImage] = useState(
    toAbsoluteUrl(`/media/svg/browsers/${data.browserName?.toLowerCase()}.svg`)
  );

  const handleOsImageError = () => {
    setOsImage(toAbsoluteUrl(`/media/svg/os/unknown.svg`));
  };

  const handleBrowserImageError = () => {
    setBrowserImage(toAbsoluteUrl(`/media/svg/os/unknown.svg`));
  };

  return (
    <div className="timeline-item">
      <div className="timeline-line w-40px"></div>

      <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
        <div className="symbol-label bg-light">
          <i className="fa-solid fa-right-to-bracket text-muted fs-4"></i>
        </div>
      </div>

      <div className="mb-10 mt-n1 w-100">
        <div className="pe-3 mb-5">
          <div className="fs-5 fw-bold mb-2">Success login</div>

          <div className="d-flex align-items-center mt-1 fs-6">
            <div className="text-muted me-2 fs-7">
              {DateTime(parseInt(date))}
            </div>
          </div>
        </div>

        <div className="pb-5 w-100 mw-700px">
          <div className="d-flex align-items-center flex-column flex-sm-row justify-content-between bg-light-secondary border border-dashed border-secondary rounded px-7 py-3 mb-0">
            <p className="fs-5 text-primary fw-bold m-0 m-2">{data.ip}</p>

            <div className="d-flex justify-content-center align-items-center m-2">
              <div className="w-35px">
                <img
                  src={browserImage}
                  onError={handleBrowserImageError}
                  alt={data.browserName}
                />
              </div>
              <div className="ms-3">
                <p className="text-dark fw-bold m-0">
                  {data.browserName} browser
                </p>
                <span className="fs-7 text-muted">
                  Version: {data.browserVersion}
                </span>
              </div>
            </div>

            <div className="d-flex justify-content-center align-items-center m-2">
              <div className="w-35px">
                <img
                  src={osImage}
                  onError={handleOsImageError}
                  alt={data.osName}
                />
              </div>
              <div className="ms-3">
                <p className="text-dark fw-bold m-0">{data.osName}</p>
                <span className="fs-7 text-muted">
                  Version: {data.osVersion}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { LoginItem };
