import React from "react";
import { toAbsoluteUrl } from "../_oxapay/helpers";

interface props {
  href: string;
}
export const ConnectWidget = ({ href }: props) => {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <div className="card mb-5 px-3 bg-primary overlay-shine">
        <div className="card-body d-flex justify-content-center align-items-center flex-row">
          <img
            alt="OxaPay wallet"
            src={toAbsoluteUrl("/media/svg/icons/wallet-icon.svg")}
            className="w-75px me-3"
          />
          <p className="text-white text-center fs-4 mb-0">
            Click here to connect your <b>OxaPay Wallet</b> to telegram
          </p>
        </div>
      </div>
    </a>
  );
};
