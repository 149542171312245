import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ShowAlertContext } from "../../_oxapay/layout/core/ShowAlertContext";
import { WebappContext } from "../../_oxapay/layout/core/WebappContext";
import { WebappTextCopy } from "./webappTextCopy";

interface props {
  address: string;
}
export const WalletAddressQrButton = ({ address }: props) => {
  const [showModal, setShowModal] = useState(false);
  const [qrLoading, setQrLoading] = useState(true);
  const { setShowAlert, setAlertMessage } = useContext(ShowAlertContext);
  const { webappColors } = useContext(WebappContext);
  useEffect(() => {
    const WebappFn = (window as any)?.Telegram?.WebApp;
    if (showModal) {
      WebappFn?.setHeaderColor(webappColors.bgBackdrop);
      WebappFn.MainButton.setText("Got it")
        .setParams({ color: "#3478f6" })
        .show()
        .onClick(() => {
          setShowModal(false);
        });
    } else {
      WebappFn?.setHeaderColor(webappColors.bgGray200);
      WebappFn.MainButton.hide();
    }

    // eslint-disable-next-line
  }, [showModal]);

  return (
    <>
      <span
        onClick={() => setShowModal(true)}
        className={`cursor-pointer  badge badge-primary justify-content-center w-25px h-25px fs-5 mx-2`}
      >
        <i className="fa-solid fa-qrcode text-white fs-3"></i>
      </span>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        className="sheet-modal fadeup-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="scrollable-content">
            <div className="px-5 text-center">
              <p className="fs-1 fw-bold mb-8">OxaPay Address</p>
              {qrLoading && (
                <div className="h-150px w-150px bg-secondary m-auto mb-0 rounded shine"></div>
              )}
              <img
                className={`mx-auto d-block mb-4`}
                src={`https://api.qrserver.com/v1/create-qr-code/?data=${address}&size=150x150`}
                alt="address-qrcode"
                onLoad={() => {
                  setQrLoading(false);
                }}
              />
              <WebappTextCopy
                text={address}
                onCopy={() => {
                  setShowAlert(true);
                  setAlertMessage("Address copied to clipoboard");
                }}
              >
                <p className="fm-jetbrain fs-1 mb-7">
                  <span className="fw-bold">{address.slice(0, 3)}</span>
                  {address.slice(3)}
                </p>
              </WebappTextCopy>
              <div className="bg-gray-200 rounded-12px px-10 py-10 mb-7">
                <p className="fs-6 fw-400 m-0 text-start">
                  Use this address for OxaPay to OxaPay transfers to protect
                  your funds from potential losses.
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
