import React, { useState } from "react";
import SendProvider from "../_oxapay/layout/core/SendContext";
import { SendStepOne } from "./SendStepOne";
import { SendStepThree } from "./SendStepThree";
import { SendStepTwo } from "./SendStepTwo";

export const SendAsset = () => {
  const [step, setStep] = useState<number>(1);

  return (
    <>
      <SendProvider>
        {step === 1 && <SendStepOne onSuccess={() => setStep(2)} />}
        {step === 2 && (
          <SendStepTwo onSuccess={() => setStep(3)} onBack={() => setStep(1)} />
        )}
        {step === 3 && (
          <SendStepThree
            onBack={() => setStep(2)}
            onSuccess={() => setStep(1)}
          />
        )}
      </SendProvider>
    </>
  );
};
