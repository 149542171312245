import axios from "axios";
import { useEffect, useState } from "react";
import { getCoinsPrice } from "../app/services/appRequests";
import { errorHandler } from "../app/services/errorHandler";
import { getPriceInterval } from "../app/services/intervalManage";

interface CoinsPrice {
  [symbol: string]: {
    coin: string;
    price: number;
    changePercent: number;
  };
}

const usePrice = () => {
  const [lastPriceFetch, setLastPriceFetch] = useState<any>({});
  const [coinsPrice, setCoinsPrice] = useState<CoinsPrice>({});
  const [priceLoading, setPriceLoading] = useState(true);
  useEffect(() => {
    const controller = new AbortController();

    const fetchPrice = async () => {
      try {
        const response = await getCoinsPrice({ signal: controller.signal });
        if (response?.status === 200) {
          const priceData = response?.data?.coins;
          if (priceData) {
            // Compare previous price data with new data
            if (JSON.stringify(lastPriceFetch) !== JSON.stringify(priceData)) {
              setCoinsPrice(priceData);
              // Update previous price data
              setLastPriceFetch(priceData);
              setPriceLoading(false);
            }
          }
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }
        errorHandler(error as Error);
      }
    };

    fetchPrice();
    const interval = setInterval(fetchPrice, getPriceInterval); // Fetch price every 10 seconds

    return () => {
      controller.abort(); // Cancel the fetch request
      clearInterval(interval); // Clean up the interval on component unmount
    };
    // eslint-disable-next-line
  }, []);

  return { coinsPrice, priceLoading };
};

export default usePrice;
