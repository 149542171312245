import Lottie from "lottie-react";
import React from "react";
import animationData from "../../../app/lottie/sendmessage.json";

interface props {
  loop?: boolean;
}
export const SendMessage = ({ loop = false }: props) => {
  return <Lottie animationData={animationData} loop={loop} />;
};
