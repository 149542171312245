import { createContext, useState } from "react";
import { WithChildren } from "../../helpers";

export interface DrawerContextModel {
  component?: JSX.Element;
  setComponent: (_component: JSX.Element) => void;
  showDrawer: boolean;
  setShowDrawer: (_showDrawer: boolean) => void;
}

export const DrawerContext = createContext<DrawerContextModel>({
  component: undefined,
  setComponent: (_component?: JSX.Element) => {},
  showDrawer: false,
  setShowDrawer: (_showDrawer: boolean) => {},
});

const DrawerProvider: React.FC<WithChildren> = ({ children }) => {
  const [component, setComponent] = useState<JSX.Element | undefined>(
    undefined
  );
  const [showDrawer, setShowDrawer] = useState(false);
  const value = {
    component,
    setComponent,
    showDrawer,
    setShowDrawer,
  };

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
};

export default DrawerProvider;
