import React from "react";

export const HeaderNotifSkeleton = () => {
  return (
    <div className="d-flex flex-stack py-4">
      <div className="d-flex align-items-center">
        <div className="symbol symbol-40px me-4">
          <div className="h-40px w-40px bg-secondary rounded shine"></div>
        </div>

        <div className="mb-0 me-2">
          <div className="h-15px w-100px bg-secondary rounded shine mb-2"></div>
          <div className="text-gray-400 fs-7">
            <div className="h-15px w-90px bg-secondary rounded shine"></div>
          </div>
        </div>
      </div>

      <div className="rounded bg-secondary shine h-20px w-70px"></div>
    </div>
  );
};
