import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../app/modules/auth";

export const PanelSignin = () => {
  const { setReturnPage } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const returnQueryString = searchParams.get("return");

  useEffect(() => {
    if (returnQueryString) {
      setReturnPage(returnQueryString);
      navigate("/connect");
    } else {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  return <></>;
};
