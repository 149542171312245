/* eslint-disable jsx-a11y/anchor-is-valid */

import { DateTime } from "../../app/services/functionServices";

interface props {
  data: {
    item: string;
    name: string;
  };
  date: string;
}

const NewItem = ({ data, date }: props) => {
  return (
    <div className="timeline-item">
      <div className="timeline-line w-40px"></div>

      <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
        <div className="symbol-label bg-light-success">
          <i className="fa-solid fa-plus fs-4 text-success"></i>
        </div>
      </div>

      <div className="mb-10 mt-n1 w-100">
        <div className="pe-3 mb-5">
          <div className="fs-5 fw-bold mb-2">New {data.item} Created</div>

          <div className="d-flex align-items-center mt-1 fs-6">
            <div className="text-muted me-2 fs-7">
              {DateTime(parseInt(date))}
            </div>
          </div>
          <div className="d-flex align-items-center mt-1 fs-6">
            <div className="text-success me-2 fs-6">{data.name}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { NewItem };
