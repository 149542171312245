export const ipValidator = (
  value: string | ""
): { result: boolean; message: string } => {
  // Regular expression to match IP address pattern
  const ipv6Pattern = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;
  const ipv4Pattern = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;

  if (ipv4Pattern.test(value)) {
    return { result: true, message: "" };
  } else if (ipv6Pattern.test(value)) {
    return { result: true, message: "" };
  } else {
    return { result: false, message: "Invalid IP address" };
  }
};
