import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../app/modules/auth";
import { getUserByToken } from "../../app/modules/auth/core/_requests";
import { Error404 } from "../../app/modules/errors/components/Error404";
import {
  convertRGBToHex,
  darkenColor,
} from "../../app/services/functionServices";
import { loadTelegramWebappScript } from "../../app/services/loadTelegramWebappScript";
import { useRequest } from "../../app/services/userHttpRequests";
import AppProvider from "../../_oxapay/layout/core/AppContext";
import AssetsProvider from "../../_oxapay/layout/core/AssetsContext";
import BalanceProvider from "../../_oxapay/layout/core/BlanceContext";
import { ShowAlertContext } from "../../_oxapay/layout/core/ShowAlertContext";
import { WebappContext } from "../../_oxapay/layout/core/WebappContext";
import { WebappModalContext } from "../../_oxapay/layout/core/WebappModalContext";
import { LoadingComponent } from "../LoadingComponent";
import { WebappFailAlert } from "./AlertsModal/WebappFailAlert";
import { WebappAlert } from "./WebappAlert";
import { WebappModal } from "./WebappModal";

export const WalletWebappHighLevel = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [webapp, setWebapp] = useState(false);
  const [sourceDetected, setSourceDetected] = useState(false);
  const { saveAuth, setCurrentUser, currentUser, logout } = useAuth();
  const { webappToken } = useRequest();
  const { webappColors, setWebappColors, setWebappStatus } =
    useContext(WebappContext);
  const { showAlert } = useContext(ShowAlertContext);
  const { setShowModal, setComponent } = useContext(WebappModalContext);

  useEffect(() => {
    logout();
    loadTelegramWebappScript(() => {
      setScriptLoaded(true);
    });

    document.body.classList.add("bg-gray-200");

    // Check Colors of Theme
    const bgGray = document.querySelector(".bg-gray-200");
    if (bgGray) {
      const style = window.getComputedStyle(bgGray);
      const backgroundColor = style.getPropertyValue("background-color");
      const bgGrayColor = convertRGBToHex(backgroundColor);

      if (bgGrayColor) {
        const bgBackdropColor = darkenColor(bgGrayColor, 0.7);
        setWebappColors({
          ...webappColors,
          bgGray200: bgGrayColor,
          bgBackdrop: bgBackdropColor,
        });
      }
    }

    const bgModal = document.querySelector(".modal-content");
    if (bgModal) {
      const style = window.getComputedStyle(bgModal);
      const backgroundColor = style.getPropertyValue("background-color");
      const bgModalColor = convertRGBToHex(backgroundColor);
      bgModalColor &&
        setWebappColors({ ...webappColors, bgModal: bgModalColor });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (scriptLoaded) {
      const webappFn = (window as any)?.Telegram?.WebApp;
      webappFn?.ready();
      const queryId = webappFn?.initDataUnsafe?.query_id;
      setSourceDetected(true);
      if (queryId) {
        setWebapp(true);
        setWebappStatus(true);
        webappFn?.expand();

        const authData = webappFn?.initDataUnsafe;

        webappToken(authData).then((res) => {
          if (res.status === 200 && res.apiToken) {
            saveAuth(res);
            getUserByToken(res.apiToken).then(({ data: user }) => {
              if (user.status === 200) {
                setCurrentUser(user);
              } else {
                setCurrentUser(undefined);
              }
            });
          } else {
            setShowModal(true);
            setComponent(
              <WebappFailAlert
                title="This Telegram account isn't linked to any OxaPay account"
                content="If you encounter this issue again, please reach out to your OxaPay account manager for assistance."
                closeWebapp
              />
            );
          }
        });
      } else {
        setWebapp(false);
        setShowModal(true);
        setComponent(
          <WebappFailAlert
            title="Unable to find your Telegram account"
            content="If you encounter this issue again, please reach out to your OxaPay account manager for assistance."
            closeWebapp
          />
        );
      }
    }
    // eslint-disable-next-line
  }, [scriptLoaded]);

  useEffect(() => {
    if (scriptLoaded) {
      const webappFn = (window as any)?.Telegram?.WebApp;
      webappFn?.ready();

      try {
        console.log("test in highlevel");
        webappFn?.setHeaderColor(webappColors.bgGray200);
        webappFn?.setBackgroundColor(webappColors.bgGray200);
        webappFn?.MainButton.setParams({ color: "#3478f6" });
      } catch (error) {
        console.error("Error setting header color:", error);
        // Handle the error here, e.g., show a default color or log the issue
      }
    }
  }, [scriptLoaded, webappColors]);

  return (
    <>
      {sourceDetected && currentUser ? (
        webapp ? (
          <AppProvider>
            <div className="px-6 pb-10" style={{ height: "100%" }}>
              <BalanceProvider>
                <AssetsProvider>
                  <Outlet />
                </AssetsProvider>
              </BalanceProvider>
            </div>
            {showAlert && <WebappAlert />}
          </AppProvider>
        ) : (
          <Error404 />
        )
      ) : (
        <LoadingComponent />
      )}
      <WebappModal />
    </>
  );
};
